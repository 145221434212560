@import './bootstrap';
@import './simple_line_icons';
@import './theme';
@import './skin_app';
@import './checkbox';


html {
  direction: ltr;
  box-shadow: none !important;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: #fff;
  color: rgb(0, 0, 0);
  font-family: "IBM Plex Sans", sans-serif;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.bg-header-semi{
  background-color: hsla(0, 0%, 95%, 0.90) !important;
}
.bg-background-home{
  background-color: #e7e7e7 !important;
}

#main {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body a {
  outline: none !important;
}

html.safari:not(.no-safari-overflow-x-fix) .body {
  overflow-x: hidden;
}

html[dir="rtl"] .body {
  overflow-x: hidden;
}

li {
  line-height: 1.3em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.035rem;
}
p {
  letter-spacing: 0.015rem;
}

/* Responsive */

@media (max-width: 767px) {
  #header-new .container {
    width: 98%;
  }
}

@media (max-width: 330px) {
  body {
    font-size: 9.5px;
    line-height: 10px;
  }
}

@media (min-width: 331px) {
  body {
    font-size: 11px;
    line-height: 10px;
  }
}

@media (min-width: 566px) {
  body {
    font-size: 12px;
    line-height: 11px;
  }
}

@media (min-width: 1224px) {
  body {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 1824px) {
  body {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (min-width: 2200px) {
  body {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (min-width: 2600px) {
  body {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 3000px) {
  body {
    font-size: 22px;
    line-height: 32px;
  }
}

@media (min-width: 3400px) {
  body {
    font-size: 26px;
    line-height: 36px;
  }
}

pre {
  background: #f7f7f7;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 15px;
}

/* Header */
#header-new {
  position: relative;
  z-index: 1030;
}

#header-new .header-body {
  display: flex;
  flex-direction: column;
  /* background: #fff; */
  transition: min-height 0.3s ease;
  width: 100%;
  border-top: 3px solid #ededed;
  // border-bottom: 1px solid gray;
  z-index: 1001;
}

#header-new .header-body.header-body-bottom-border-fixed {
  border-bottom: 1px solid rgba(234, 234, 234, 0.5) !important;
}

#header-new .header-body[class*="border-color-"] {
  border-bottom: 0 !important;
}

#header-new .header-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  transition: ease height 300ms;
}

@media (max-width: 991px) {
  #header-new
    .header-container:not(.container):not(.container-fluid)
    .header-nav-main {
    padding: 0 0.75rem;
  }
}

#header-new .header-container.header-container-height-xs {
  height: 80px;
}

#header-new .header-container.header-container-height-sm {
  height: 100px;
}

#header-new .header-container.header-container-height-md {
  height: 125px;
}

// #header-new .header-container.header-container-height-lg {
//   height: 145px;
// }

#header-new .container {
  position: relative;
}

#header-new .header-row {
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-self: stretch;
  max-height: 100%;
}

#header-new .header-column {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
}

#header-new .header-column.header-column-border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

#header-new .header-column.header-column-border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}

#header-new .header-column .header-row {
  justify-content: inherit;
}

// #header-new .header-column .header-extra-info {
//   list-style: outside none none;
//   margin: 0;
//   padding: 0;
// }

// #header-new .header-column .header-extra-info li {
//   display: inline-flex;
//   margin-left: 25px;
// }



#header-new.header-no-min-height .header-body {
  min-height: 0 !important;
}

#header-new .header-top {
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
}

// #header-new .header-top.header-top-light-border-bottom {
//   border-bottom-color: rgba(255, 255, 255, 0.1);
// }

// #header-new .header-top.header-top-simple-border-bottom {
//   border-bottom-width: 1px;
// }

// #header-new .header-top.header-top-default {
//   background: #f7f7f7;
// }

#header-new .header-top.header-top-borders {
  border-bottom-width: 1px;
}

#header-new .header-top.header-top-borders .nav-item-borders {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  padding-left: 12px;
  padding-right: 12px;
}

#header-new .header-top.header-top-borders .nav-item-borders:first-child {
  padding-left: 0;
}

#header-new .header-top.header-top-borders .nav-item-borders:last-child {
  border-right: 0;
}

// #header-new .header-top.header-top-light-borders {
//   border-bottom-color: rgba(255, 255, 255, 0.2);
// }

// #header-new
//   .header-top.header-top-light-borders.header-top-borders
//   .nav-item-borders {
//   border-right-color: rgba(255, 255, 255, 0.2);
// }

// #header-new .header-top.header-top-light-2-borders {
//   border-bottom-color: rgba(255, 255, 255, 0.06);
// }

// #header-new
//   .header-top.header-top-light-2-borders.header-top-borders
//   .nav-item-borders {
//   border-right-color: rgba(255, 255, 255, 0.06);
// }

// #header-new .header-top.header-top-bottom-containered-border {
//   border-bottom: 0;
// }

// #header-new
//   .header-top.header-top-bottom-containered-border
//   > .container
//   > .header-row {
//   border-bottom: 1px solid rgba(0, 0, 0, 0.06);
// }

// #header-new .header-top.header-top-small-minheight {
//   min-height: 38px;
// }

#header-new .header-logo {
  position: relative;
  z-index: 1;
}

#header-new .header-logo img {
  transition: all 0.3s ease;
  position: relative;
  top: 0;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}

#header-new .header-nav {
  padding: 16px 0;
  padding: 1rem 0;
  min-height: 70px;
}

#header-new .header-nav-top {
  display: flex;
  align-self: stretch;
  align-items: center;
  font-family: "Roboto", sans-serif;
}

#header-new .header-nav-top .nav > li > a,
#header-new .header-nav-top .nav > li > span {
  color: #999;
  font-size: 0.9em;
  padding: 6px 10px;
  display: inline-block;
  letter-spacing: -0.5px;
}

#header-new .header-nav-top .nav > li > a [class*="fa-"],
#header-new .header-nav-top .nav > li > a .icons,
#header-new .header-nav-top .nav > li > span [class*="fa-"],
#header-new .header-nav-top .nav > li > span .icons {
  margin-right: 3px;
  font-size: 0.8em;
  position: relative;
}

#header-new .header-nav-top .nav > li > a {
  text-decoration: none;
}

#header-new .header-nav-top .nav > li > a:hover,
#header-new .header-nav-top .nav > li > a a:focus {
  background: transparent;
}

#header-new .header-nav-top .nav > li.open > .dropdown-menu-toggle,
#header-new
  .header-nav-top
  .nav
  > li.accessibility-open
  > .dropdown-menu-toggle {
  border-radius: 4px 4px 0 0;
}

#header-new .header-nav-top .nav > li + .nav-item-left-border {
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
}

#header-new .header-nav-top .nav > li + .nav-item-left-border:after {
  background: rgba(0, 0, 0, 0.08);
  content: "";
  width: 1px;
  height: 16px;
  position: absolute;
  display: block;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  left: 0;
}

#header-new .header-nav-top .nav > li + .nav-item-right-border {
  margin-right: 10px;
  padding-right: 10px;
  position: relative;
}

#header-new .header-nav-top .nav > li + .nav-item-right-border:before {
  background: rgba(0, 0, 0, 0.08);
  content: "";
  width: 1px;
  height: 16px;
  position: absolute;
  display: block;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  right: 0;
}

#header-new
  .header-nav-top
  .nav
  > li.nav-item-left-border.nav-item-left-border-remove:after {
  content: none;
}

#header-new
  .header-nav-top
  .nav
  > li.nav-item-left-border.nav-item-right-border-remove:before {
  content: none;
}

@media (max-width: 575px) {
  #header-new .header-nav-top .nav > li:not(.d-none) {
    margin-left: 0;
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  #header-new .header-nav-top .nav > li.nav-item-left-border-remove.d-none {
    margin-left: 0;
    padding-left: 0;
  }

  #header-new .header-nav-top .nav > li.nav-item-right-border-remove.d-none {
    margin-left: 0;
    padding-left: 0;
  }
}

#header-new
  .header-nav-top
  .nav
  > li.nav-item-left-border.nav-item-left-border-show:after {
  content: "";
}

#header-new
  .header-nav-top
  .nav
  > li.nav-item-right-border.nav-item-right-border-show:after {
  content: "";
}

@media (min-width: 576px) {
  #header-new
    .header-nav-top
    .nav
    > li.nav-item-left-border.nav-item-left-border-sm-show:after {
    content: "";
  }

  #header-new
    .header-nav-top
    .nav
    > li.nav-item-right-border.nav-item-right-border-sm-show:after {
    content: "";
  }
}

@media (min-width: 768px) {
  #header-new
    .header-nav-top
    .nav
    > li.nav-item-left-border.nav-item-left-border-md-show:after {
    content: "";
  }

  #header-new
    .header-nav-top
    .nav
    > li.nav-item-right-border.nav-item-right-border-md-show:after {
    content: "";
  }
}

@media (min-width: 992px) {
  #header-new
    .header-nav-top
    .nav
    > li.nav-item-left-border.nav-item-left-border-lg-show:after {
    content: "";
  }

  #header-new
    .header-nav-top
    .nav
    > li.nav-item-right-border.nav-item-right-border-lg-show:after {
    content: "";
  }
}

@media (min-width: 1200px) {
  #header-new
    .header-nav-top
    .nav
    > li.nav-item-left-border.nav-item-left-border-xl-show:after {
    content: "";
  }

  #header-new
    .header-nav-top
    .nav
    > li.nav-item-right-border.nav-item-right-border-xl-show:after {
    content: "";
  }
}

#header-new .header-nav-top .nav > li.nav-item.dropdown.show .nav-link {
  background: transparent;
}

#header-new
  .header-nav-top
  .nav
  > li.nav-item:not(.dropdown)
  > a
  [class*="fa-"],
#header-new .header-nav-top .nav > li.nav-item:not(.dropdown) > a .icons {
  left: -1px;
  top: -1px;
}



#header-new .header-nav-top .dropdown .dropdown-item {
  font-size: 1em;
  padding: 0.3em 0.5em;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 480px) {
  #header-new .header-nav-top .dropdown .dropdown-item {
    font-size: 0.75em;
  }
}

/* language dropdown item */
@media screen and (min-width: 2400px) {
  #header-new .header-nav-top .dropdown .dropdown-item {
    font-size: 1.6em;
    padding: 0.2em 0.5em;
  }
}

#header-new .header-nav-top .dropdown-menu {
  border: 0;
  margin-top: -2px;
  margin-left: -2px;
  padding: 0;
  box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 0.4em;
  z-index: 10000;
  margin-right: 0;
  margin-top: 1px;
  transition: opacity 0.2s ease-out;
  border-radius: 0;
}

#header-new .header-nav-top .dropdown-menu:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0.75em;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  transform: translate3d(-7px, -6px, 0);
}

#header-new .header-nav-top .dropdown-menu.dropdow-menu-arrow-right:before {
  right: auto;
  left: 20px;
}

#header-new .header-nav-top .dropdown-menu a {
  color: #999;
  padding: 5px 12px;
  font-size: 0.9em;
}

#header-new .header-nav-top .dropdown-menu a:active {
  color: #fff;
}

#header-new .header-nav-top .flag {
  margin-right: 2px;
  position: relative;
  top: -1px;
}

#header-new .header-nav-features {
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
}

#header-new .header-nav-features:before,
#header-new .header-nav-features:after {
  background: rgba(0, 0, 0, 0.03);
  content: none;
  width: 1px;
  height: 16px;
  position: absolute;
  display: block;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  left: 0;
}



#header-new .header-nav-features:not(.header-nav-features-no-border):after {
  display: none;
}

#header-new .header-nav-features.header-nav-features-show-border:before,
#header-new .header-nav-features.header-nav-features-show-border:after {
  content: "";
}

@media (min-width: 576px) {
  #header-new .header-nav-features.header-nav-features-sm-show-border:before,
  #header-new .header-nav-features.header-nav-features-sm-show-border:after {
    content: "";
  }
}

@media (min-width: 768px) {
  #header-new .header-nav-features.header-nav-features-md-show-border:before,
  #header-new .header-nav-features.header-nav-features-md-show-border:after {
    content: "";
  }
}

@media (min-width: 992px) {
  #header-new .header-nav-features.header-nav-features-lg-show-border:before,
  #header-new .header-nav-features.header-nav-features-lg-show-border:after {
    content: "";
  }
}

@media (min-width: 1200px) {
  #header-new .header-nav-features.header-nav-features-xl-show-border:before,
  #header-new .header-nav-features.header-nav-features-xl-show-border:after {
    content: "";
  }
}

#header-new .header-nav-features.header-nav-features-start {
  padding-left: 0;
  margin-left: 0;
  padding-right: 20px;
  margin-right: 10px;
}

#header-new
  .header-nav-features.header-nav-features-start:not(.header-nav-features-no-border):before {
  left: auto;
  right: 0;
}

#header-new .header-nav-features.header-nav-features-center {
  padding-left: 20px;
  margin-left: 10px;
  padding-right: 20px;
  margin-right: 10px;
}

#header-new .header-nav-features.header-nav-features-center:after {
  display: block;
  left: auto;
  right: 0;
}

#header-new .header-nav-features .header-nav-top-icon,
#header-new .header-nav-features .header-nav-top-icon-img {
  font-size: 12.8px;
  font-size: 0.8rem;
  position: relative;
  top: -1px;
  color: #444;
}

#header-new .header-nav-features.header-nav-features-light .header-nav-top-icon,
#header-new
  .header-nav-features.header-nav-features-light
  .header-nav-top-icon-img {
  color: #fff;
}

#header-new .header-nav-features .header-nav-top-icon-img {
  top: -2px;
}

#header-new .header-nav-features .header-nav-feature {
  position: relative;
}

#header-new .header-nav-features .header-nav-features-dropdown {
  opacity: 0;
  top: -10000px;
  position: absolute;
  box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 17px;
  min-width: 300px;
  z-index: 10000;
  right: 100%;
  margin-right: -25px;
  margin-top: 30px;
  transition: opacity 0.2s ease-out;
  pointer-events: none;
}

#header-new .header-nav-features .header-nav-features-dropdown:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 5px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  transform: translate3d(-7px, -6px, 0);
}

#header-new .header-nav-features .header-nav-features-dropdown.show {
  opacity: 1;
  top: auto;
  pointer-events: all;
}

@media (min-width: 992px) {
  #header-new
    .header-nav-features
    .header-nav-features-dropdown.header-nav-features-dropdown-reverse {
    right: auto;
    margin-right: 0;
    left: 100%;
    margin-left: -25px;
  }

  #header-new
    .header-nav-features
    .header-nav-features-dropdown.header-nav-features-dropdown-reverse:before {
    right: auto;
    left: 5px;
    transform: translate3d(7px, -6px, 0);
  }
}

@media (max-width: 440px) {
  #header-new
    .header-nav-features
    .header-nav-features-dropdown.header-nav-features-dropdown-mobile-fixed {
    position: fixed;
    right: auto;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  #header-new
    .header-nav-features
    .header-nav-features-dropdown.header-nav-features-dropdown-mobile-fixed:before {
    content: none;
  }
}

#header-new
  .header-nav-features
  .header-nav-features-dropdown.header-nav-features-dropdown-force-right {
  margin-right: 6px !important;
  right: 0;
}

#header-new
  .header-nav-features
  .header-nav-features-dropdown.header-nav-features-dropdown-dark {
  background: #212529;
}

#header-new
  .header-nav-features
  .header-nav-features-dropdown.header-nav-features-dropdown-dark:before {
  border-bottom-color: #212529;
}

#header-new .header-nav-features .header-nav-features-cart {
  z-index: 2;
}

#header-new
  .header-nav-features
  .header-nav-features-dropdown {
  padding: 20px;
}


#header-new .header-border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}

#header-new .header-border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

#header-new .header-border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

#header-new .header-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

@media (max-width: 991px) {
  #header-new .header-mobile-border-top:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
}

#header-new .header-btn-collapse-nav {
  background: #ff6600;
  color: #002345;
  display: none;
  float: right;
  margin: 0 0 0 16px;
  margin: 0 0 0 1rem;
}

#header-new.header-narrow .header-body {
  min-height: 0;
}

#header-new.header-narrow .header-logo {
  margin-top: 0;
  margin-bottom: 0;
}

#header-new.header-narrow .header-nav {
  padding-top: 0;
  padding-bottom: 0;
}

#header-new.header-no-border-bottom .header-body {
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
}

#header-new.header-no-border-bottom
  .header-nav.header-nav-links
  nav
  > ul
  > li
  > a,
#header-new.header-no-border-bottom
  .header-nav.header-nav-links
  nav
  > ul
  > li:hover
  > a,
#header-new.header-no-border-bottom
  .header-nav.header-nav-line
  nav
  > ul
  > li
  > a,
#header-new.header-no-border-bottom
  .header-nav.header-nav-line
  nav
  > ul
  > li:hover
  > a {
  margin: 0;
}

#header-new.header-effect-shrink {
  transition: ease height 300ms;
}

#header-new.header-effect-shrink .header-top {
  transition: ease height 300ms;
}



#header-new.header-effect-shrink .header-logo {
  display: flex;
  align-items: center;
}

#header-new.header-dark .header-body {
  background: #212529;
}

#header-new.header-dark
  .header-body
  .header-nav.header-nav-links-side-header
  nav
  > ul
  > li
  > a {
  border-bottom-color: #2a2a2a;
}

@media (max-width: 991px) {
  html.mobile-menu-opened #header-new .header-body[class*="border-color-"] {
    border-bottom: 1px solid rgba(234, 234, 234, 0.5) !important;
  }
}

html:not(.boxed) #header-new.header-floating-bar {
  position: absolute;
  top: 45px;
  width: 100%;
}

html:not(.boxed) #header-new.header-floating-bar .header-body {
  border: medium none;
  margin: 0 auto;
  padding: 0;
  position: relative;
  width: auto;
}

html:not(.boxed)
  #header-new.header-floating-bar
  .header-nav-main
  nav
  > ul
  > li.dropdown-mega
  > .dropdown-menu {
  left: 0;
  right: 0;
}

@media (max-width: 991px) {
  html:not(.boxed) #header-new.header-floating-bar {
    top: 0;
  }
}

@media (max-width: 991px) {
  html:not(.boxed):not(.sticky-header-active)
    #header-new.header-floating-bar
    .header-body {
    margin: 10px;
  }

  html:not(.boxed):not(.sticky-header-active)
    #header-new.header-floating-bar
    .header-nav-main:before {
    display: none;
  }
}

@media (min-width: 1200px) {
  html:not(.sticky-header-active) #header-new.header-floating-bar .header-body {
    max-width: 1140px;
    right: 15px;
  }

  html:not(.sticky-header-active)
    #header-new.header-floating-bar
    .header-container {
    padding-right: 0px;
    padding-left: 30px;
  }
}

html:not(.boxed) #header-new.header-full-width .header-container {
  width: 100%;
  max-width: none;
}

html #header-new.header-bottom-slider {
  position: absolute;
  bottom: 0;
}

// html #header-new.header-bottom-slider .header-body {
//   border-top: 1px solid rgba(255, 255, 255, 0.4);
// }

// html
//   #header-new.header-bottom-slider.header-transparent-light-top-border
//   .header-body {
//   border-top: 1px solid rgba(68, 68, 68, 0.4);
// }

// html
//   #header-new.header-bottom-slider.header-transparent-light-top-border-1
//   .header-body {
//   border-top: 1px solid rgba(68, 68, 68, 0.1);
// }

// html
//   #header-new.header-bottom-slider.header-transparent-dark-top-border
//   .header-body {
//   border-top: 1px solid rgba(255, 255, 255, 0.4);
// }

// html
//   #header-new.header-bottom-slider.header-transparent-dark-top-border-1
//   .header-body {
//   border-top: 1px solid rgba(255, 255, 255, 0.1);
// }

// html.sticky-header-active #header-new.header-bottom-slider .header-body {
//   border-top-color: transparent;
// }

@media (max-width: 991px) {
  html #header-new.header-bottom-slider .header-nav-main {
    top: calc(100% + 15px);
  }

  html.sticky-header-active #header-new.header-bottom-slider .header-nav-main {
    top: 100%;
  }
}

html #header-new.header-transparent {
  min-height: 0 !important;
  width: 100%;
  position: absolute;
}

html #header-new.header-transparent .header-body:not(.header-border-bottom) {
  border-bottom: 0;
}

// html:not(.sticky-header-active)
//   #header-new.header-transparent:not(.header-semi-transparent)
//   .header-body {
//   background-color: transparent !important;
// }

html:not(.sticky-header-active)
  #header-new.header-semi-transparent-light
  .header-body {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

html:not(.sticky-header-active)
  #header-new.header-semi-transparent-dark
  .header-body {
  background-color: rgba(68, 68, 68, 0.4) !important;
}


@media (max-width: 991px) {
  html #header-new.header-transparent .header-body {
    overflow: hidden;
  }

  html
    #header-new.header-transparent
    .header-body.bg-dark
    .header-nav-main:before,
  html
    #header-new.header-transparent
    .header-body.bg-color-dark
    .header-nav-main:before {
    background-color: #212529;
  }

  html #header-new.header-transparent .header-nav-main:before {
    width: calc(100% - 30px);
  }

  html #header-new.header-transparent .header-nav-main nav {
    padding: 0 30px;
  }

  html.sticky-header-active
    #header-new.header-transparent
    .header-nav-main
    nav {
    padding: 0 15px;
  }
}

html.sticky-header-active #header-new .header-body {
  position: fixed;
  border-bottom-color: rgba(234, 234, 234, 0.5);
  box-shadow: 0 0 3px rgba(234, 234, 234, 0.5);
}

html.sticky-header-active #header-new .header-body.header-body-bottom-border {
  border-bottom: 1px solid rgba(234, 234, 234, 0.5) !important;
}

html.sticky-header-active #header-new.header-effect-shrink .header-body {
  position: relative;
}

html.sticky-header-reveal.sticky-header-active #header-new .header-body {
  position: fixed;
  border-bottom: none;
  width: 100%;
  max-width: 100%;
}

// html.sticky-header-enabled #header-new .header-logo-sticky-change {
//   position: relative;
// }

// html.sticky-header-enabled #header-new .header-logo-sticky-change img {
//   position: absolute;
//   top: 0;
//   left: 0;
// }

// html.sticky-header-enabled
//   #header-new
//   .header-logo-sticky-change
//   .header-logo-non-sticky {
//   opacity: 1 !important;
// }

// html.sticky-header-enabled
//   #header-new
//   .header-logo-sticky-change
//   .header-logo-sticky {
//   opacity: 0 !important;
// }

// html.sticky-header-enabled.sticky-header-active
//   #header-new
//   .header-logo-sticky-change
//   .header-logo-non-sticky {
//   opacity: 0 !important;
// }

// html.sticky-header-enabled.sticky-header-active
//   #header-new
//   .header-logo-sticky-change
//   .header-logo-sticky {
//   opacity: 1 !important;
// }

/* Header Nav Main */
@media (min-width: 992px) {
  #header-new .header-nav-main {
    display: flex !important;
    height: auto !important;
  }

  #header-new .header-nav-main nav {
    display: flex !important;
  }

  #header-new .header-nav-main nav > ul > li {
    height: 100%;
    align-self: stretch;
    margin-left: 2px;
  }

  #header-new .header-nav-main nav > ul > li > a {
    display: inline-flex;
    align-items: center;
    white-space: normal;
    border-radius: 4px;
    color: #ccc;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    padding: 0.5rem 1rem;
    letter-spacing: -0.5px;
    text-transform: uppercase;
  }

  #header-new .header-nav-main nav > ul > li > a:after {
    display: none;
  }

  #header-new .header-nav-main nav > ul > li > a:active {
    background-color: transparent;
    text-decoration: none;
    color: #ccc;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li
    > a.dropdown-toggle
    .fa-chevron-down {
    display: none;
  }

  #header-new .header-nav-main nav > ul > li > a.active {
    background-color: transparent;
  }

  #header-new .header-nav-main nav > ul > li.dropdown .dropdown-menu {
    top: -10000px;
    display: block;
    opacity: 0;
    left: auto;
    border-radius: 0 4px 4px;
    border: 0;
    border-top: 3px solid #ccc;
    box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.07);
    margin: 0;
    min-width: 200px;
    padding: 5px 0;
    text-align: left;
    background-color: #f8fdfa;
  }

  #header-new .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
    border-bottom: 1px solid #f7f7f7;
    color: rgb(71, 71, 71);
    font-size: 1em;
    font-weight: 500;
    padding: 0.6em 1.2em 0.6em 1.2em;
    position: relative;
    text-transform: none;
    letter-spacing: -0.5px;
  }

  /* about page 2400 px upper */
  @media screen and (min-width: 2200px) {
    #header-new .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
      font-size: 1.6em;
      font-weight: 500;
      padding: 1em 2em 1em 2em;
    }
  }

  #header-new .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:hover,
  #header-new .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:focus,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a.active,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a:active {
    background-color: transparent;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li:hover
    > a,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li:focus
    > a,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.active
    > a,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li:active
    > a,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.open
    > a,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.accessibility-open
    > a {
    background-color: #e2e2e2;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu {
    position: relative;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > a
    .fa-chevron-down {
    display: none;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > a:after {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    font-weight: 900;
    position: absolute;
    top: 50%;
    font-size: 0.5rem;
    opacity: 0.7;
    right: 20px;
    transform: translateY(-50%);
    transition: all 0.2s ease-out;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    left: 100%;
    display: block;
    margin-top: -8px;
    margin-left: -1px;
    border-radius: 4px;
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu.open
    > a:after,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu.accessibility-open
    > a:after,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu:hover
    > a:after {
    opacity: 1;
    right: 15px;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu.open
    > .dropdown-menu,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu.accessibility-open
    > .dropdown-menu,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu:hover
    > .dropdown-menu {
    top: 0;
    opacity: 1;
  }

  // #header-new
  //   .header-nav-main
  //   nav
  //   > ul
  //   > li.dropdown
  //   .dropdown-menu
  //   li.dropdown-submenu.dropdown-reverse:hover
  //   > a:after {
  //   right: 30px;
  //   transform: translateY(-50%) rotateY(180deg);
  // }

  // #header-new
  //   .header-nav-main
  //   nav
  //   > ul
  //   > li.dropdown
  //   .dropdown-menu
  //   li.dropdown-submenu.dropdown-reverse
  //   > .dropdown-menu {
  //   left: auto;
  //   right: 100%;
  //   transform: translate3d(0, 0, 0);
  // }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li:last-child
    a {
    border-bottom: 0;
  }

  #header-new .header-nav-main nav > ul > li.dropdown.open > a,
  #header-new .header-nav-main nav > ul > li.dropdown.accessibility-open > a,
  #header-new .header-nav-main nav > ul > li.dropdown:hover > a {
    border-radius: 4px 4px 0 0;
    position: relative;
  }

  #header-new .header-nav-main nav > ul > li.dropdown.open > a:before,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown.accessibility-open
    > a:before,
  #header-new .header-nav-main nav > ul > li.dropdown:hover > a:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    border-bottom: 5px solid #ccc;
  }

//// Hover effect

  #header-new .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu,
  #header-new .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    top: auto;
    display: block;
    opacity: 1;
  }

  .dropdown-not-hover{
    position: relative;
  }

  #header-new .header-nav-main nav > ul > li.dropdown-mega {
    position: static;
  }

  #header-new .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu {
    border-radius: 4px;
    left: 15px;
    right: 15px;
    width: auto;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-content {
    padding: 1.2em 1em;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-title {
    color: #333333;
    display: block;
    font-size: 1.2em;
    font-weight: 600;
    margin-top: 20px;
    padding-bottom: 10px;
    text-transform: none;
    letter-spacing: -0.5px;
  }

  /* navigatiu title dropdown mega sub menu title */
  @media screen and (min-width: 1366px) {
    #header-new .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu {
      width: 76%;
      margin: 0 0 0 auto;
    }
  }

  /* navigatiu title dropdown mega sub menu title */
  @media screen and (min-width: 2200px) {
    #header-new
      .header-nav-main
      nav
      > ul
      > li.dropdown-mega
      .dropdown-mega-sub-title {
      font-size: 1.9em;
      margin-top: 1.2em;
    }

    #header-new .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu {
      width: 72%;
      margin: 0 0 0 auto;
    }
  }

  @media screen and (min-width: 2801px) {
    #header-new
      .header-nav-main
      nav
      > ul
      > li.dropdown-mega
      .dropdown-mega-sub-title {
      font-size: 2.3em;
      font-weight: 600;
      margin-top: 1.2em;
      padding-bottom: 0.75em;
    }

    #header-new .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu {
      width: 60%;
      margin: 0 0 0 auto;
    }
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-title:first-child {
    margin-top: 0;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a {
    border: 0 none;
    border-radius: 0.2em;
    width: 100%;
    color: #777;
    display: block;
    font-size: 0.95em;
    font-weight: normal;
    margin: 0 0 0.2em -0.5em;
    padding: 0.2em 0.5em;
    text-shadow: none;
    text-transform: none;
    text-decoration: none;
    word-wrap: break-word;
  }
  /* services dropdown item  */

  @media screen and (min-width: 1366px) {
    #header-new
      .header-nav-main
      nav
      > ul
      > li.dropdown-mega
      .dropdown-mega-sub-nav
      > li
      > a {
      border-radius: 0.3em;
      font-size: 1.05em;
      padding: 0.4em 0.5em;
    }
  }

  @media screen and (min-width: 2200px) {
    #header-new
      .header-nav-main
      nav
      > ul
      > li.dropdown-mega
      .dropdown-mega-sub-nav
      > li
      > a {
      border-radius: 0.3em;
      font-size: 1.6em;
      padding: 0.4em 0.5em;
    }
  }



  /* changed  */
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li:hover
    > a,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li:active
    > a,
  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li:focus
    > a {
    color:#ff6600 !important;
    background-color: transparent !important;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega.dropdown-mega-shop
    > .dropdown-item {
    padding: 0.5rem 0.8rem;
  }

  #header-new .header-nav-main nav > ul > li .wrapper-items-cloned i {
    display: none;
  }

  #header-new .header-nav-main.header-nav-main-square nav > ul > li > a {
    border-radius: 0 !important;
  }

  #header-new
    .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    border-radius: 0 !important;
  }

  #header-new
    .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    border-radius: 0 !important;
  }



  #header-new
    .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown-mega
    > .dropdown-menu {
    border-radius: 0 !important;
  }

  #header-new
    .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a {
    border-radius: 0 !important;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-no-borders
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    border: 0 !important;
    border-radius: 4px;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-no-borders
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    margin-top: -5px;
  }

  #header-new .header-nav-main.header-nav-main-font-sm nav > ul > li > a {
    font-size: 0.7rem;
  }

  #header-new .header-nav-main.header-nav-main-font-md nav > ul > li > a {
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: -1px;
  }

  #header-new .header-nav-main.header-nav-main-font-lg nav > ul > li > a {
    font-size: 1.1rem;
    font-weight: 400;
    text-transform: none;
    letter-spacing: -1px;
  }

  #header-new .header-nav-main.header-nav-main-font-lg-upper nav > ul > li > a {
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -1px;
  }

  #header-new
    .header-nav-main.header-nav-main-font-lg-upper-2
    nav
    > ul
    > li
    > a {
    font-size: 0.82rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0;
  }


  #header-new
    .header-nav-main.header-nav-main-dropdown-modern
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    border: 0 !important;
    padding: 15px 8px;
    margin-left: -10px;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-modern
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a {
    border-bottom: 0;
    background: transparent !important;
    font-size: 0.85em;
    transition: all 0.2s ease-out;
    left: 0;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-modern
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a:hover,
  #header-new
    .header-nav-main.header-nav-main-dropdown-modern
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a:active {
    left: 5px;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-modern
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    margin-top: -15px;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-center
    nav
    > ul
    > li.dropdown:not(.dropdown-mega)
    > .dropdown-menu {
    transform: translate3d(-50%, 10px, 0) !important;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-center
    nav
    > ul
    > li.dropdown:not(.dropdown-mega):hover
    > .dropdown-menu {
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0) !important;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-center.header-nav-main-dropdown-center-bottom
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu {
    margin-top: -14px !important;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown
    > .dropdown-item {
    position: relative;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown
    > .dropdown-item:before {
    content: "";
    display: block;
    position: absolute;
    top: auto;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
    transform: translate3d(-7px, 10px, 0);
    bottom: 14px;
    margin-top: auto;
    z-index: 1001;
    opacity: 0;
    transition: ease transform 300ms;
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu,
  #header-new
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu {
    margin-top: -14px !important;
    box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.1);
  }

  #header-new
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown.open
    > .dropdown-item:before,
  #header-new
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-item:before,
  #header-new
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-item:before {
    opacity: 1 !important;
    transform: translate3d(-7px, 0, 0);
  }

  #header-new .header-nav-main.header-nav-main-dark nav > ul > li > a {
    color: #444;
  }

  #header-new .header-nav-main.header-nav-main-slide nav > ul > li > a {
    background-color: transparent;
    color: #fff;
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    padding: 18px 18px 22px !important;
    margin: 0 !important;
    transition: ease transform 300ms;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  #header-new
    .header-nav-main.header-nav-main-slide
    nav
    > ul
    > li
    > a.dropdown-toggle:after {
    visibility: hidden;
  }
}

@media (min-width: 992px) {
  #header-new
    .header-nav-main.header-nav-main-slide
    nav
    > ul
    > li
    > a.item-original {
    display: none;
  }

  #header-new .header-nav-main.header-nav-main-slide nav > ul > li > span {
    display: flex;
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  #header-new .header-nav-main.header-nav-main-slide nav > ul > li > span > a {
    display: flex;
    align-items: center;
    white-space: normal;
    border-radius: 4px;
    font-size: 1.6em;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    position: relative;
    background: transparent !important;
    color: #444;
    padding: 0 0.9rem;
    margin: 1px 0 0;
    min-height: 60px;
    height: 100%;
    transition: ease all 300ms;
    transform: translateY(0%);
  }

  #header-new
    .header-nav-main.header-nav-main-slide
    nav
    > ul
    > li
    > span
    > a.dropdown-toggle:after {
    display: none;
  }

  #header-new
    .header-nav-main.header-nav-main-slide
    nav
    > ul
    > li
    > span
    > a
    .fa-caret-down {
    display: none;
  }

  #header-new
    .header-nav-main.header-nav-main-slide
    nav
    > ul
    > li
    > span
    > a.active:first-child {
    transform: translateY(-40%);
    opacity: 0;
  }

  #header-new
    .header-nav-main.header-nav-main-slide
    nav
    > ul
    > li
    > span
    > a.active.item-two {
    transform: translateY(0);
    opacity: 1;
    color: #ff6600;
  }

  #header-new
    .header-nav-main.header-nav-main-slide
    nav
    > ul
    > li
    > span
    .item-two {
    position: absolute;
    transform: translateY(40%);
    opacity: 0;
    width: auto;
    left: 0;
    color: #ff6600;
  }

  #header-new
    .header-nav-main.header-nav-main-slide
    nav
    > ul
    > li:hover
    > span
    > a:first-child {
    transform: translateY(-40%);
    opacity: 0;
  }

  #header-new
    .header-nav-main.header-nav-main-slide
    nav
    > ul
    > li:hover
    > span
    .item-two {
    transform: translateY(0%);
    opacity: 1;
  }

  #header-new .header-nav-main.header-nav-main-transition nav > ul > li > a {
    transition: ease all 300ms;
  }

  #header-new
    .header-nav-main.header-nav-main-text-capitalize
    nav
    > ul
    > li
    > a {
    text-transform: capitalize;
  }

  #header-new .header-nav-main.header-nav-main-text-size-2 nav > ul > li > a {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
  }

  #header-new .header-nav-main.header-nav-main-text-size-3 nav > ul > li > a {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
  }

  #header-new
    .header-nav-main.header-nav-main-text-weight-600
    nav
    > ul
    > li
    > a {
    font-weight: 600;
  }

  #header-new .header-nav-main a > .thumb-info-preview {
    position: absolute;
    display: block;
    left: 100%;
    opacity: 0;
    border: 0;
    padding-left: 10px;
    background: transparent;
    overflow: visible;
    margin-top: 15px;
    top: -10000px;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(-20px, 0, 0);
  }

  #header-new .header-nav-main a > .thumb-info-preview .thumb-info-wrapper {
    background: #fff;
    display: block;
    border-radius: 4px;
    border: 0;
    box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
    margin: 0;
    padding: 4px;
    text-align: left;
    width: 190px;
  }

  #header-new .header-nav-main a > .thumb-info-preview .thumb-info-image {
    transition: all 6s linear 0s;
    width: 182px;
    height: 136px;
    min-height: 0;
  }

  #header-new .header-nav-main a:hover > .thumb-info-preview {
    transform: translate3d(0, 0, 0);
    top: 0;
    opacity: 1;
    margin-top: -5px;
  }

  #header-new .header-nav-main .dropdown-reverse a > .thumb-info-preview {
    transform: translate3d(20px, 0, 0);
    right: 100%;
    left: auto;
    padding-left: 0;
    margin-right: 10px;
  }

  #header-new .header-nav-main .dropdown-reverse a:hover > .thumb-info-preview {
    transform: translate3d(0, 0, 0);
  }

  #header-new .header-nav-bar {
    z-index: 1;
  }

  #header-new .header-nav-bar.header-nav-bar-top-border {
    border-top: 1px solid #ededed;
  }

  #header-new .header-nav-bar .header-container {
    min-height: 0;
    height: auto !important;
  }

  #header-new .header-nav-bar .header-container .header-container-md {
    height: 120px;
  }

  #header-new .header-nav-bar .header-nav {
    min-height: 60px;
  }

  #header-new
    .header-nav-bar
    .header-nav
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown
    > .dropdown-item:before {
    bottom: 1px !important;
    margin-left: 0;
  }

  #header-new
    .header-nav-bar
    .header-nav
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav-bar
    .header-nav
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu,
  #header-new
    .header-nav-bar
    .header-nav
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu {
    margin-top: -1px !important;
  }

  #header-new
    .header-nav-bar
    .header-nav-links:not(.justify-content-lg-center)
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li {
    margin-left: 0;
    margin-right: 0;
  }

  #header-new
    .header-nav-bar
    .header-nav-links:not(.justify-content-lg-center)
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown
    > .dropdown-item {
    padding: 0 0 0 2rem !important;
  }

  #header-new
    .header-nav-bar
    .header-nav-links:not(.justify-content-lg-center)
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown
    > .dropdown-item:before {
    bottom: 1px !important;
    margin-left: 17px;
  }

  #header-new
    .header-nav-bar
    .header-nav-links:not(.justify-content-lg-center)
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav-bar
    .header-nav-links:not(.justify-content-lg-center)
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu,
  #header-new
    .header-nav-bar
    .header-nav-links:not(.justify-content-lg-center)
    .header-nav-main.header-nav-main-dropdown-arrow
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu {
    margin-top: -1px !important;
  }

  #header-new .header-nav {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    align-self: stretch;
  }

  // #header-new .header-nav.header-nav-stripe {
  //   padding: 0;
  // }

  // #header-new .header-nav.header-nav-stripe .header-nav-main {
  //   align-self: stretch;
  //   margin-top: -1px;
  //   min-height: 0;
  // }

 
  #header-new .header-nav.header-nav-links,
  #header-new .header-nav.header-nav-line {
    padding: 0;
  }

  #header-new .header-nav.header-nav-links .header-nav-main,
  #header-new .header-nav.header-nav-line .header-nav-main {
    align-self: stretch;
    min-height: 0;
    margin-top: 0;
  }

  // #header-new .header-nav.header-nav-links nav > ul > li > a,
  // #header-new .header-nav.header-nav-links nav > ul > li:hover > a,
  #header-new .header-nav.header-nav-line nav > ul > li > a,
  #header-new .header-nav.header-nav-line nav > ul > li:hover > a {
    position: relative;
    background: transparent !important;
    padding: 0 0.5em;
    margin: 1px 0 0;
    min-height: 4em;
    height: 100%;
    font-size: 1.5em;
    cursor: pointer;
  }

  #header-new .header-nav.header-nav-line nav > ul > li > a:hover .caret-down {
    transform: rotate(180deg);
  }

  // #header-new .header-nav.header-nav-links nav > ul > li:hover > a:before,
  // #header-new .header-nav.header-nav-links nav > ul > li.open > a:before,
  #header-new
    .header-nav.header-nav-links
    nav
    > ul
    > li.accessibility-open
    > a:before,
  #header-new .header-nav.header-nav-line nav > ul > li:hover > a:before,
  #header-new .header-nav.header-nav-line nav > ul > li.open > a:before,
  #header-new
    .header-nav.header-nav-line
    nav
    > ul
    > li.accessibility-open
    > a:before {
    opacity: 1;
  }

  // #header-new .header-nav.header-nav-links nav > ul > li > a.active,
  #header-new .header-nav.header-nav-line nav > ul > li > a.active {
    background: transparent;
    color: #ff6600 !important;
  }

  // #header-new .header-nav.header-nav-links nav > ul > li > a.active:before,
  #header-new .header-nav.header-nav-line nav > ul > li > a.active:before {
    opacity: 1;
  }

  // #header-new .header-nav.header-nav-links nav > ul > li.dropdown > a:before,
  #header-new .header-nav.header-nav-line nav > ul > li.dropdown > a:before {
    border-bottom: 0;
  }

  #header-new
    .header-nav.header-nav-links
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-links
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-links
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-line
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu {
    margin-top: 0;
  }

  #header-new
    .header-nav.header-nav-links:not(.header-nav-light-text)
    nav
    > ul
    > li
    > a,
  #header-new
    .header-nav.header-nav-line:not(.header-nav-light-text)
    nav
    > ul
    > li
    > a {
    color: #002345;
  }

  #header-new
    .header-nav.header-nav-links.header-nav-light-text
    nav
    > ul
    > li
    > a,
  #header-new
    .header-nav.header-nav-line.header-nav-light-text
    nav
    > ul
    > li
    > a {
    color: #fff;
  }

  #header-new
    .header-nav.header-nav-links.header-nav-force-light-text
    nav
    > ul
    > li
    > a,
  #header-new
    .header-nav.header-nav-line.header-nav-force-light-text
    nav
    > ul
    > li
    > a {
    color: #fff !important;
  }


  #header-new .header-nav.header-nav-line nav > ul > li > a:before,
  #header-new .header-nav.header-nav-line nav > ul > li:hover > a:before {
    content: "";
    position: absolute;
    background: transparent;
    width: auto;
    height: 3px;
    top: -2px;
    left: -1px;
    right: -1px;
    opacity: 0;
  }

  #header-new .header-nav.header-nav-line nav > ul > li:hover > a:before {
    opacity: 1 !important;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-top-line
    nav
    > ul
    > li
    > a:before,
  #header-new
    .header-nav.header-nav-line.header-nav-top-line
    nav
    > ul
    > li:hover
    > a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    top: -2px;
    left: 0;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-top-line.header-nav-top-line-with-border
    nav
    > ul
    > li
    > a:before,
  #header-new
    .header-nav.header-nav-line.header-nav-top-line.header-nav-top-line-with-border
    nav
    > ul
    > li:hover
    > a:before {
    top: -4px;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-top-line-animated
    nav
    > ul
    > li
    > a:before {
    transition: all 0.3s ease;
    width: 0%;
    left: 50%;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-top-line-animated
    nav
    > ul
    > li:hover
    > a:before {
    left: 0;
    width: 100%;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line
    nav
    > ul
    > li
    > a:before,
  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line
    nav
    > ul
    > li:hover
    > a:before {
    content: "";
    position: absolute;
    width: auto;
    height: 3px;
    top: 50%;
    left: 15px;
    right: 15px;
    margin-top: 15px;
    transform: translateY(-3px);
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-no-transform
    nav
    > ul
    > li
    > a:before,
  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-no-transform
    nav
    > ul
    > li:hover
    > a:before {
    margin-top: 12px;
    transform: none;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-line-under-text
    nav
    > ul
    > li
    > a:before,
  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-line-under-text
    nav
    > ul
    > li:hover
    > a:before {
    left: 0;
    right: 0;
    height: 7px;
    margin: 6px 10px 15px 10px;
    opacity: 0.3 !important;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-effect-1
    nav
    > ul
    > li
    > a:before {
    transition: cubic-bezier(0.55, 0, 0.1, 1) right 300ms;
    right: 100%;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-effect-1
    nav
    > ul
    > li
    > a.active:before {
    right: 15px;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-effect-1
    nav
    > ul
    > li:hover
    > a:before {
    right: 15px;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-effect-1.header-nav-line-under-text
    nav
    > ul
    > li
    > a.active:before {
    right: 0;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-effect-1.header-nav-line-under-text
    nav
    > ul
    > li:hover
    > a:before {
    right: 0;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-active-text-light
    nav
    > ul
    > li:hover
    > a {
    color: #fff !important;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-active-text-light
    nav
    > ul:not(:hover)
    li
    > a.active {
    color: #fff !important;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-active-text-dark
    nav
    > ul
    > li:hover
    > a {
    color: #444 !important;
  }

  #header-new
    .header-nav.header-nav-line.header-nav-bottom-line.header-nav-bottom-line-active-text-dark
    nav
    > ul:not(:hover)
    li
    > a.active {
    color: #444 !important;
  }

  #header-new
    .header-nav.header-nav-no-space-dropdown
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-no-space-dropdown
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-no-space-dropdown
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu {
    margin-top: -1px;
  }

  #header-new
    .header-nav.header-nav-force-light-text
    .header-nav-main
    nav
    > ul
    > li
    > a,
  #header-new
    .header-nav.header-nav-force-light-text
    .header-nav-main
    nav
    > ul
    > li
    .wrapper-items-cloned
    > a {
    color: #fff !important;
  }

  #header-new
    .header-nav.header-nav-force-light-text:not(.header-nav-force-light-text-active-skin-color)
    .header-nav-main
    nav
    > ul
    > li:hover
    > a,
  #header-new
    .header-nav.header-nav-force-light-text:not(.header-nav-force-light-text-active-skin-color)
    .header-nav-main
    nav
    > ul
    > li
    .wrapper-items-cloned:hover
    > a {
    color: #333 !important;
  }

  #header-new
    .header-nav.header-nav-force-light-text:not(.header-nav-force-light-text-active-skin-color)
    .header-nav-main
    nav
    > ul:not(:hover)
    > li
    > a.active,
  #header-new
    .header-nav.header-nav-force-light-text:not(.header-nav-force-light-text-active-skin-color)
    .header-nav-main
    nav
    > ul:not(:hover)
    > li
    .wrapper-items-cloned
    > a.active {
    color: #333 !important;
  }

  #header-new .header-nav.header-nav-icons nav > ul > li > a [class*="fa-"],
  #header-new .header-nav.header-nav-icons nav > ul > li > a .icons {
    font-size: 1.1rem;
    margin-right: 0.28rem;
    margin-top: 0.1rem;
  }

  #header-new .header-nav.header-nav-divisor nav > ul > li {
    margin: 0;
  }

  #header-new .header-nav.header-nav-divisor nav > ul > li > a {
    border-right: 1px solid rgba(0, 0, 0, 0.07);
  }

  #header-new .header-nav.header-nav-divisor nav > ul > li:last-child > a {
    border-right: 0;
  }

  #header-new .header-nav.header-nav-spaced nav > ul > li > a {
    margin: 0 !important;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  #header-new
    .header-nav.header-nav-first-item-no-padding
    nav
    > ul
    > li:first-child
    > a {
    padding-left: 0 !important;
  }

  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown
    > a:before {
    border-bottom: 0;
  }

  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown
    li
    a {
    border-bottom-color: #2a2a2a;
  }

  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    background: #1e1e1e;
    margin-top: 0;
  }

  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li
    > a {
    color: #adadad;
  }

  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li:hover
    > a,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li:focus
    > a,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li:active
    > a,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li.active
    > a,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li.open
    > a,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li.accessibility-open
    > a {
    background: #282828;
    color: #fff;
  }

  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-title {
    color: #fff;
  }

  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a {
    color: #adadad;
  }

  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-nav
    > li:hover
    > a,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-nav
    > li:focus
    > a,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-nav
    > li:active
    > a,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-nav
    > li.active
    > a,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-nav
    > li.open
    > a,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-nav
    > li.accessibility-open
    > a {
    color: #fff;
    background: #282828;
  }

  #header-new
    .header-nav.header-nav-sub-title
    .header-nav-main
    nav
    > ul
    > li
    > a,
  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li
    > a {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
  }

  #header-new
    .header-nav.header-nav-sub-title
    .header-nav-main
    nav
    > ul
    > li
    > a
    > span,
  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li
    > a
    > span {
    font-size: 0.9em;
    text-transform: none;
    font-weight: normal;
    margin-top: 0.3rem;
  }

  #header-new
    .header-nav.header-nav-sub-title
    .header-nav-main
    nav
    > ul
    > li
    > a
    > span {
    margin-top: -0.5rem;
  }

  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li
    > a {
    transition: ease padding-bottom 300ms;
  }

  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li
    > a.active {
    padding-bottom: 15px;
  }

  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li
    > a.active
    > span {
    top: 60%;
    left: 0;
    opacity: 1;
  }

  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li
    > a
    > span {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: 0;
    opacity: 0;
    transform: translate3d(0, -50%, 0);
    transition: ease opacity 200ms, ease top 300ms;
  }

  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li:hover
    > a,
  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li:focus
    > a {
    padding-bottom: 15px;
  }

  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li:hover
    > a
    > span,
  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li:focus
    > a
    > span {
    top: 60%;
    left: 0;
    opacity: 1;
  }

  #header-new .header-nav.header-nav-links-side-header nav > ul > li > a {
    padding: 10px 15px !important;
    min-height: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0;
  }

  #header-new
    .header-nav.header-nav-links-side-header
    nav
    > ul
    > li:last-child
    > a {
    border-bottom: 0;
  }

  #header-new .header-nav.header-nav-links-side-header nav > ul > li.dropdown {
    position: static;
  }

  #header-new
    .header-nav.header-nav-links-side-header
    nav
    > ul
    > li.dropdown
    > .dropdown-item {
    position: relative;
  }

  #header-new
    .header-nav.header-nav-links-side-header
    nav
    > ul
    > li.dropdown
    > .dropdown-item:before {
    content: "\f054" !important;
    font-weight: 900;
    position: absolute;
    top: 50%;
    font-size: 0.5rem;
    opacity: 0.7 !important;
    left: auto;
    right: 20px;
    transform: translateY(-50%);
    transition: all 0.2s ease-out;
    display: block !important;
  }
}

/* Header Arrows */
@media (min-width: 992px) {
  #header-new .header-nav-main-arrows nav > ul > li > a.dropdown-toggle:after {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    content: "\f078";
    font-weight: 900;
    border: 0;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0 0 0 4px;
    font-size: 0.6rem;
  }
}

/* Header Nav Main Dark Text */
@media (min-width: 992px) {
  #header-new .header-nav-main-dark-text nav > .nav-pills > li > a {
    color: #333;
  }
}



/* Header Nav Main Dark Text */
@media (min-width: 992px) {
  #header-new .header-nav-main-rounded nav > .nav-pills > li > a {
    justify-content: center;
    align-self: center;
    border-radius: 1.5rem;
  }

  #header-new .header-nav-main-rounded nav > .nav-pills > li > a:before {
    display: none !important;
  }

  #header-new .header-nav-main-rounded nav > ul > li.dropdown.open > a,
  #header-new
    .header-nav-main-rounded
    nav
    > ul
    > li.dropdown.accessibility-open
    > a,
  #header-new .header-nav-main-rounded nav > ul > li.dropdown:hover > a {
    border-radius: 1.5rem;
  }

  #header-new .header-nav-main-rounded nav > ul > li.dropdown .dropdown-menu {
    border-radius: 4px;
  }
}




























@media (min-width: 992px) {
  #header-new .header-nav-main.header-nav-main-light nav > ul > li > a {
    color: #fff;
  }

  #header-new .header-nav-main.header-nav-main-light nav > ul > li > a.active {
    color: #ccc;
    background: #fff;
  }

  #header-new .header-nav-main.header-nav-main-light nav > ul > li.open > a,
  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.accessibility-open
    > a,
  #header-new .header-nav-main.header-nav-main-light nav > ul > li.active > a,
  #header-new .header-nav-main.header-nav-main-light nav > ul > li:hover > a {
    background: #fff;
  }

  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.open
    > .dropdown-menu,
  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.accessibility-open
    > .dropdown-menu,
  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.active
    > .dropdown-menu,
  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li:hover
    > .dropdown-menu {
    border-top-color: #fff;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
  }

  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.open
    > .dropdown-menu
    .dropdown-submenu:hover
    > .dropdown-menu,
  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.accessibility-open
    > .dropdown-menu
    .dropdown-submenu:hover
    > .dropdown-menu,
  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.active
    > .dropdown-menu
    .dropdown-submenu:hover
    > .dropdown-menu,
  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li:hover
    > .dropdown-menu
    .dropdown-submenu:hover
    > .dropdown-menu {
    border-top-color: #fff;
  }

  #header-new .header-nav-main.header-nav-main-light nav > ul > li.active > a {
    background: #fff;
  }

  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.dropdown.open
    > a:before,
  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.dropdown.accessibility-open
    > a:before,
  #header-new
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.dropdown:hover
    > a:before {
    border-bottom-color: #fff;
  }

  #header-new
    .header-nav-main.header-nav-main-light
    .dropdown-menu
    > li
    > a:hover,
  #header-new
    .header-nav-main.header-nav-main-light
    .dropdown-menu
    > li
    > a:focus {
    background: #f5f5f5;
  }
}

@media (min-width: 992px) {
  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a,
  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-mega-sub-nav
    li
    a {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
  }

  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu
    li
    a,
  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    .dropdown-mega-sub-nav
    li
    a,
  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu
    li
    a,
  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.open
    .dropdown-mega-sub-nav
    li
    a,
  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu
    li
    a,
  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.accessibility-open
    .dropdown-mega-sub-nav
    li
    a {
    transform: translate3d(0, 0, 0);
  }

  #header-new .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
  }

  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu,
  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu {
    transform: translate3d(0, 2px, 0);
  }

  #header-new
    .header-nav.header-nav-stripe
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-stripe
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-stripe
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-links
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-links
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-links
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-line
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-line
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-line
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav.header-nav-dropdowns-dark
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 992px) {
  #header-new .header-nav-main-effect-2 nav > ul > li.dropdown .dropdown-menu {
    transition: opacity 0.2s ease-out;
    opacity: 0;
  }

  #header-new
    .header-nav-main-effect-2
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu,
  #header-new
    .header-nav-main-effect-2
    nav
    > ul
    > li.dropdown.open
    > .dropdown-menu,
  #header-new
    .header-nav-main-effect-2
    nav
    > ul
    > li.dropdown.accessibility-open
    > .dropdown-menu {
    opacity: 1;
  }
}





.dropdown-item.active,
.dropdown-item:active {
  color: #002345;
  background-color: inherit;
}





@media (min-width: 992px) {
  #header-new
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(-10px, 0, 0);
    opacity: 0;
  }

  #header-new
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu:hover
    > .dropdown-menu,
  #header-new
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu.open
    > .dropdown-menu,
  #header-new
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu.accessibility-open
    > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  #header-new
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu.dropdown-reverse
    > .dropdown-menu {
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transform: translate3d(10px, 0, 0);
    left: auto;
    right: 100%;
    opacity: 0;
  }

  #header-new
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu.dropdown-reverse:hover
    > .dropdown-menu,
  #header-new
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu.dropdown-reverse.open
    > .dropdown-menu,
  #header-new
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu.dropdown-reverse.accessibility-open
    > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/* Header Nav Main Mobile */
@media (max-width: 991px) {
  #header-new .header-nav-main {
    position: absolute;
    background: transparent;
    width: 100%;
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  #header-new .header-nav-main:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    /* background: #fff; */
    z-index: -1;
    transform: translateX(-50%);
  }

  #header-new .header-nav-main nav {
    max-height: 90vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 15px;
    transition: ease all 500ms;
  }

  #header-new .header-nav-main nav.collapsing {
    overflow-y: hidden;
  }

  #header-new .header-nav-main nav::-webkit-scrollbar {
    width: 5px;
  }

  #header-new .header-nav-main nav::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: rgba(204, 204, 204, 0.5);
  }

  #header-new .header-nav-main nav > ul {
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: column;
  }

  #header-new .header-nav-main nav > ul li {
    border-bottom: 1px solid #e8e8e8;
    clear: both;
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  #header-new .header-nav-main nav > ul li a {
    font-size: 13px;
    font-style: normal;
    line-height: 20px;
    padding: 7px 8px;
    margin: 1px 0;
    border-radius: 4px;
    text-align: left;
  }

  #header-new .header-nav-main nav > ul li a .fa-caret-down {
    line-height: 35px;
    min-height: 38px;
    min-width: 30px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 0;
  }

  #header-new .header-nav-main nav > ul li a.dropdown-toggle {
    position: relative;
  }

  #header-new .header-nav-main nav > ul li a.dropdown-toggle:after {
    content: none;
  }

  #header-new .header-nav-main nav > ul li a.dropdown-toggle .fa-chevron-down {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
  }

  #header-new .header-nav-main nav > ul li a:active {
    background-color: #f8f9fa;
    color: inherit;
  }

  #header-new .header-nav-main nav > ul li.dropdown .dropdown-menu {
    background: transparent;
    padding: 0;
    margin: 0;
    font-size: 13px;
    box-shadow: none;
    border-radius: 0;
    border: 0;
    clear: both;
    display: none;
    float: none;
    position: static;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > a {
    position: relative;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > a:after {
    content: none;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > a
    .fa-chevron-down {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    li.dropdown
    .dropdown-menu
    li.dropdown-submenu.open
    > .dropdown-menu {
    display: block;
    margin-left: 20px;
  }

  #header-new .header-nav-main nav > ul li.dropdown.open > .dropdown-menu {
    display: block;
    margin-left: 1em;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    li.dropdown-mega
    .dropdown-mega-sub-title {
    margin-top: 10px;
    display: block;
    text-align: left;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    li.dropdown-mega
    .dropdown-mega-sub-nav {
    margin: 0 0 0 20px;
    padding: 0;
    list-style: none;
  }

  #header-new
    .header-nav-main
    nav
    > ul
    li.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a {
    display: block;
    text-decoration: none;
  }

  #header-new .header-nav-main nav > ul li:last-child {
    border-bottom: 0;
  }

  #header-new .header-nav-main nav > ul > li > a {
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1px;
    margin-bottom: 1px;
    /* color: #ccc; */
  }

  #header-new .header-nav-main nav > ul > li > a:hover {
    font-weight: 700;
    margin-top: 1px;
    margin-bottom: 1px;
    color: #002345;
    background-color: #ff6600;
  }

  #header-new .header-nav-main nav > ul > li > a.active {
    color: #ff6600 !important;
  }

  #header-new .header-nav-main nav > ul > li > a.active:focus,
  #header-new .header-nav-main nav > ul > li > a.active:hover {
    color: #fff;
    background: #ccc;
  }

  #header-new .header-nav-main nav .not-included {
    margin: 0;
  }

  #header-new .header-nav-main a > .thumb-info-preview {
    display: none !important;
  }

  #header-new .header-nav-main.header-nav-main-square nav > ul > li a {
    border-radius: 0 !important;
  }

  #header-new .header-nav-main.header-nav-main-slide .wrapper-items-cloned {
    display: none;
  }

  #header-new .header-nav-bar .header-container {
    min-height: 0;
    height: auto !important;
  }

  #header-new .header-nav-bar .header-nav {
    min-height: 60px !important;
    padding: 0;
  }

  #header-new .header-btn-collapse-nav {
    outline: 0;
    display: block;
    position: relative;
    z-index: 1;
  }

  #header-new .header-nav.header-nav-stripe {
    min-height: 90px;
  }

  #header-new .header-nav.header-nav-links,
  #header-new .header-nav.header-nav-line {
    min-height: 70px;
  }

  #header-new .header-nav.header-nav-dropdowns-dark {
    min-height: 70px;
  }

  #header-new
    .header-nav.header-nav-sub-title
    .header-nav-main
    nav
    > ul
    > li
    > a
    > span,
  #header-new
    .header-nav.header-nav-sub-title-animated
    .header-nav-main
    nav
    > ul
    > li
    > a
    > span {
    display: block;
    text-transform: none;
    font-weight: normal;
  }
}


/* Responsive */
@media (min-width: 992px) {
  #header-new .header-body:not(.h-100) {
    height: auto !important;
  }
}

@media (max-width: 991px) {
  #header-new .header-logo img {
    z-index: 1;
  }

  #header-new .header-nav {
    display: flex;
    align-items: center;
  }

}

















































body[data-plugin-section-scroll] #header-new .header-body {
  background: transparent;
}



html.ie
  #header-new
  .header-column
  .header-extra-info
  .feature-box
  .feature-box-info {
  flex: none;
}

@media (max-width: 991px) {
  html.safari #header-new .header-body {
    overflow: visible !important;
  }
}

/* Page Header */
.page-header {
  // background-color: #212529;
  margin: 0 0 2em 0;
  // padding: 2em 0;
  position: relative;
  text-align: left;
}

.page-header h1 {
  color: #fff;
  display: inline-block;
  font-size: 30px;
  line-height: 1;
  margin: 0;
  padding: 0;
  font-weight: 400;
  position: relative;
  top: 1px;
}

.page-header .sub-title {
  display: block;
  font-size: 1.2em;
  font-weight: 300;
  margin: 0;
  opacity: 0.8;
  color: #fff;
}

.page-header .page-header-extra-button {
  position: absolute;
  width: 100%;
  bottom: 50px;
  left: 0;
  right: 0;
  z-index: 5;
}

.page-header.page-header-sm {
  padding: 1em 0;
}

.page-header.page-header-sm h1 {
  font-size: 22px;
}

.page-header.page-header-lg {
  padding: 50px 0;
}

.page-header.page-header-lg h1 {
  font-size: 40px;
}

.page-header.page-header-classic:after {
  content: "";
  width: 100%;
  height: 5px;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
}

.page-header.page-header-classic .page-header-title-border {
  width: 0;
  height: 5px;
  position: absolute;
  bottom: 0;
  background: transparent;
  z-index: 1;
}

.page-header.page-header-modern h1 {
  font-size: 28px;
}

.page-header.page-header-modern.page-header-sm h1 {
  font-size: 22px;
}

.page-header.page-header-modern.page-header-md {
  padding: 50px 0;
}

.page-header.page-header-modern.page-header-lg {
  padding: 65px 0;
}

.page-header.page-header-modern.page-header-lg h1 {
  font-size: 32px;
}

.page-header.page-header-modern.page-header-background {
  padding: 130px 0;
  background-size: cover;
}

.page-header.page-header-modern.page-header-background.page-header-background-pattern {
  background-size: auto;
  background-repeat: repeat;
  background-attachment: fixed;
}

.page-header.page-header-modern.page-header-background.page-header-background-sm {
  padding: 50px 0;
}

.page-header.page-header-modern.page-header-background.page-header-background-md {
  padding: 150px 0;
}

.page-header.page-header-modern.page-header-background.page-header-background-lg {
  padding: 250px 0;
}

/* Position */
.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-static {
  position: static !important;
}

/* Box Shadow */
.box-shadow-none {
  box-shadow: none !important;
}

/* Background Clip */
.bclip-border-box {
  background-clip: border-box !important;
}

/* Inverted */
.inverted {
  color: #fff;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}

h1 .inverted {
  padding-left: 10px;
  padding-right: 10px;
}

h2 .inverted {
  padding-left: 7px;
  padding-right: 7px;
}

h3 .inverted {
  padding-left: 2px;
  padding-right: 2px;
}

h4 .inverted {
  padding-left: 4px;
  padding-right: 4px;
}

h5 .inverted {
  padding-left: 2px;
  padding-right: 2px;
}

h6 .inverted {
  padding-left: 2px;
  padding-right: 2px;
}

/* Letter Spacing */
.ls-0 {
  letter-spacing: 0 !important;
}

.ls-1 {
  letter-spacing: 0.5px !important;
}

.negative-ls-05 {
  letter-spacing: -0.05em;
}

.negative-ls-1 {
  letter-spacing: -1px;
}

.negative-ls-2 {
  letter-spacing: -2px;
}

.negative-ls-3 {
  letter-spacing: -2.5px;
}

.positive-ls-1 {
  letter-spacing: 1px;
}

.positive-ls-2 {
  letter-spacing: 2px;
}

.positive-ls-3 {
  letter-spacing: 2.5px;
}

/* Cursor */
.cur-pointer {
  cursor: pointer;
}

/* Pointer Events */
.p-events-none {
  pointer-events: none;
}

/* Font Size */
.text-0 {
  font-size: 0.7em !important;
}

.text-1 {
  font-size: 0.8em !important;
}

.text-2 {
  font-size: 0.9em !important;
}

.text-2-3,
.text-2-5 {
  font-size: 0.95em !important;
}

.text-3 {
  font-size: 1em !important;
}

.text-3-4,
.text-3-5 {
  font-size: 1.1em !important;
}

.text-4 {
  font-size: 1.2em !important;
}

.text-4-5 {
  font-size: 1.35em !important;
}

.text-5 {
  font-size: 1.5em !important;
}

.text-5-6,
.text-5-5 {
  font-size: 1.65em !important;
}

.text-6 {
  font-size: 1.8em !important;
}

.text-6-7,
.text-6-5 {
  font-size: 1.9em !important;
}

.text-7 {
  font-size: 2em !important;
}

.text-8 {
  font-size: 2.3em !important;
}

.text-9 {
  font-size: 2.5em !important;
}

.text-10 {
  font-size: 2.75em !important;
}

.text-11 {
  font-size: 3em !important;
}

.text-12 {
  font-size: 3.5em !important;
}

.text-12-13,
.text-12-5 {
  font-size: 3.75em !important;
}

.text-13 {
  font-size: 4em !important;
}

.text-14 {
  font-size: 4.5em !important;
}

.text-15 {
  font-size: 5em !important;
}

@media (min-width: 576px) {
  .text-sm-0 {
    font-size: 0.7em !important;
  }

  .text-sm-1 {
    font-size: 0.8em !important;
  }

  .text-sm-2 {
    font-size: 0.9em !important;
  }

  .text-sm-2-3,
  .text-sm-2-5 {
    font-size: 0.95em !important;
  }

  .text-sm-3 {
    font-size: 1em !important;
  }

  .text-sm-3-4,
  .text-sm-3-5 {
    font-size: 1.1em !important;
  }

  .text-sm-4 {
    font-size: 1.2em !important;
  }

  .text-sm-4-5 {
    font-size: 1.35em !important;
  }

  .text-sm-5 {
    font-size: 1.5em !important;
  }

  .text-sm-5-6,
  .text-sm-5-5 {
    font-size: 1.65em !important;
  }

  .text-sm-6 {
    font-size: 1.8em !important;
  }

  .text-sm-6-7,
  .text-sm-6-5 {
    font-size: 1.9em !important;
  }

  .text-sm-7 {
    font-size: 2em !important;
  }

  .text-sm-8 {
    font-size: 2.3em !important;
  }

  .text-sm-9 {
    font-size: 2.5em !important;
  }

  .text-sm-10 {
    font-size: 2.75em !important;
  }

  .text-sm-11 {
    font-size: 3em !important;
  }

  .text-sm-12 {
    font-size: 3.5em !important;
  }

  .text-sm-12-13,
  .text-sm-12-5 {
    font-size: 3.75em !important;
  }

  .text-sm-13 {
    font-size: 4em !important;
  }

  .text-sm-14 {
    font-size: 4.5em !important;
  }

  .text-sm-15 {
    font-size: 5em !important;
  }
}

@media (min-width: 768px) {
  .text-md-0 {
    font-size: 0.7em !important;
  }

  .text-md-1 {
    font-size: 0.8em !important;
  }

  .text-md-2 {
    font-size: 0.9em !important;
  }

  .text-md-2-3,
  .text-md-2-5 {
    font-size: 0.95em !important;
  }

  .text-md-3 {
    font-size: 1em !important;
  }

  .text-md-3-4,
  .text-md-3-5 {
    font-size: 1.1em !important;
  }

  .text-md-4 {
    font-size: 1.2em !important;
  }

  .text-md-4-5 {
    font-size: 1.35em !important;
  }

  .text-md-5 {
    font-size: 1.5em !important;
  }

  .text-md-5-6,
  .text-md-5-5 {
    font-size: 1.65em !important;
  }

  .text-md-6 {
    font-size: 1.8em !important;
  }

  .text-md-6-7,
  .text-md-6-5 {
    font-size: 1.9em !important;
  }

  .text-md-7 {
    font-size: 2em !important;
  }

  .text-md-8 {
    font-size: 2.3em !important;
  }

  .text-md-9 {
    font-size: 2.5em !important;
  }

  .text-md-10 {
    font-size: 2.75em !important;
  }

  .text-md-11 {
    font-size: 3em !important;
  }

  .text-md-12 {
    font-size: 3.5em !important;
  }

  .text-md-12-13,
  .text-md-12-5 {
    font-size: 3.75em !important;
  }

  .text-md-13 {
    font-size: 4em !important;
  }

  .text-md-14 {
    font-size: 4.5em !important;
  }

  .text-md-15 {
    font-size: 5em !important;
  }
}

@media (min-width: 992px) {
  .text-lg-0 {
    font-size: 0.7em !important;
  }

  .text-lg-1 {
    font-size: 0.8em !important;
  }

  .text-lg-2 {
    font-size: 0.9em !important;
  }

  .text-lg-2-3,
  .text-lg-2-5 {
    font-size: 0.95em !important;
  }

  .text-lg-3 {
    font-size: 1em !important;
  }

  .text-lg-3-4,
  .text-lg-3-5 {
    font-size: 1.1em !important;
  }

  .text-lg-4 {
    font-size: 1.2em !important;
  }

  .text-lg-4-5 {
    font-size: 1.35em !important;
  }

  .text-lg-5 {
    font-size: 1.5em !important;
  }

  .text-lg-5-6,
  .text-lg-5-5 {
    font-size: 1.65em !important;
  }

  .text-lg-6 {
    font-size: 1.8em !important;
  }

  .text-lg-6-7,
  .text-lg-6-5 {
    font-size: 1.9em !important;
  }

  .text-lg-7 {
    font-size: 2em !important;
  }

  .text-lg-8 {
    font-size: 2.3em !important;
  }

  .text-lg-9 {
    font-size: 2.5em !important;
  }

  .text-lg-10 {
    font-size: 2.75em !important;
  }

  .text-lg-11 {
    font-size: 3em !important;
  }

  .text-lg-12 {
    font-size: 3.5em !important;
  }

  .text-lg-12-13,
  .text-lg-12-5 {
    font-size: 3.75em !important;
  }

  .text-lg-13 {
    font-size: 4em !important;
  }

  .text-lg-14 {
    font-size: 4.5em !important;
  }

  .text-lg-15 {
    font-size: 5em !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-0 {
    font-size: 0.7em !important;
  }

  .text-xl-1 {
    font-size: 0.8em !important;
  }

  .text-xl-2 {
    font-size: 0.9em !important;
  }

  .text-xl-2-3,
  .text-xl-2-5 {
    font-size: 0.95em !important;
  }

  .text-xl-3 {
    font-size: 1em !important;
  }

  .text-xl-3-4,
  .text-xl-3-5 {
    font-size: 1.1em !important;
  }

  .text-xl-4 {
    font-size: 1.2em !important;
  }

  .text-xl-4-5 {
    font-size: 1.35em !important;
  }

  .text-xl-5 {
    font-size: 1.5em !important;
  }

  .text-xl-5-6,
  .text-xl-5-5 {
    font-size: 1.65em !important;
  }

  .text-xl-6 {
    font-size: 1.8em !important;
  }

  .text-xl-6-7,
  .text-xl-6-5 {
    font-size: 1.9em !important;
  }

  .text-xl-7 {
    font-size: 2em !important;
  }

  .text-xl-8 {
    font-size: 2.3em !important;
  }

  .text-xl-9 {
    font-size: 2.5em !important;
  }

  .text-xl-10 {
    font-size: 2.75em !important;
  }

  .text-xl-11 {
    font-size: 3em !important;
  }

  .text-xl-12 {
    font-size: 3.5em !important;
  }

  .text-xl-12-13,
  .text-xl-12-5 {
    font-size: 3.75em !important;
  }

  .text-xl-13 {
    font-size: 4em !important;
  }

  .text-xl-14 {
    font-size: 4.5em !important;
  }

  .text-xl-15 {
    font-size: 5em !important;
  }
}

.text-1rem {
  font-size: 16px !important;
  font-size: 1rem !important;
}

/* Line Height */
.line-height-initial {
  line-height: initial !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-1-1 {
  line-height: 1.1 !important;
}

.line-height-2 {
  line-height: 1.2 !important;
}

.line-height-3 {
  line-height: 1.3 !important;
}

.line-height-4 {
  line-height: 1.4 !important;
}

.line-height-5 {
  line-height: 1.5 !important;
}

.line-height-6 {
  line-height: 1.6 !important;
}

.line-height-7 {
  line-height: 1.7 !important;
}

.line-height-8 {
  line-height: 1.8 !important;
}

.line-height-9 {
  line-height: 1.9 !important;
}

@media (min-width: 576px) {
  .line-height-sm-initial {
    line-height: initial !important;
  }

  .line-height-sm-1 {
    line-height: 1 !important;
  }

  .line-height-sm-1-1 {
    line-height: 1.1 !important;
  }

  .line-height-sm-2 {
    line-height: 1.2 !important;
  }

  .line-height-sm-3 {
    line-height: 1.3 !important;
  }

  .line-height-sm-4 {
    line-height: 1.4 !important;
  }

  .line-height-sm-5 {
    line-height: 1.5 !important;
  }

  .line-height-sm-6 {
    line-height: 1.6 !important;
  }

  .line-height-sm-7 {
    line-height: 1.7 !important;
  }

  .line-height-sm-8 {
    line-height: 1.8 !important;
  }

  .line-height-sm-9 {
    line-height: 1.9 !important;
  }
}

@media (min-width: 768px) {
  .line-height-md-initial {
    line-height: initial !important;
  }

  .line-height-md-1 {
    line-height: 1 !important;
  }

  .line-height-md-1-1 {
    line-height: 1.1 !important;
  }

  .line-height-md-2 {
    line-height: 1.2 !important;
  }

  .line-height-md-3 {
    line-height: 1.3 !important;
  }

  .line-height-md-4 {
    line-height: 1.4 !important;
  }

  .line-height-md-5 {
    line-height: 1.5 !important;
  }

  .line-height-md-6 {
    line-height: 1.6 !important;
  }

  .line-height-md-7 {
    line-height: 1.7 !important;
  }

  .line-height-md-8 {
    line-height: 1.8 !important;
  }

  .line-height-md-9 {
    line-height: 1.9 !important;
  }
}

@media (min-width: 992px) {
  .line-height-lg-initial {
    line-height: initial !important;
  }

  .line-height-lg-1 {
    line-height: 1 !important;
  }

  .line-height-lg-1-1 {
    line-height: 1.1 !important;
  }

  .line-height-lg-2 {
    line-height: 1.2 !important;
  }

  .line-height-lg-3 {
    line-height: 1.3 !important;
  }

  .line-height-lg-4 {
    line-height: 1.4 !important;
  }

  .line-height-lg-5 {
    line-height: 1.5 !important;
  }

  .line-height-lg-6 {
    line-height: 1.6 !important;
  }

  .line-height-lg-7 {
    line-height: 1.7 !important;
  }

  .line-height-lg-8 {
    line-height: 1.8 !important;
  }

  .line-height-lg-9 {
    line-height: 1.9 !important;
  }
}

@media (min-width: 1200px) {
  .line-height-xl-initial {
    line-height: initial !important;
  }

  .line-height-xl-1 {
    line-height: 1 !important;
  }

  .line-height-xl-1-1 {
    line-height: 1.1 !important;
  }

  .line-height-xl-2 {
    line-height: 1.2 !important;
  }

  .line-height-xl-3 {
    line-height: 1.3 !important;
  }

  .line-height-xl-4 {
    line-height: 1.4 !important;
  }

  .line-height-xl-5 {
    line-height: 1.5 !important;
  }

  .line-height-xl-6 {
    line-height: 1.6 !important;
  }

  .line-height-xl-7 {
    line-height: 1.7 !important;
  }

  .line-height-xl-8 {
    line-height: 1.8 !important;
  }

  .line-height-xl-9 {
    line-height: 1.9 !important;
  }
}

/* Opacity */
.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

/* Scale */
.scale-1 {
  transform: scale(1.1) !important;
}

.scale-2 {
  transform: scale(1.2) !important;
}

.scale-3 {
  transform: scale(1.3) !important;
}

.scale-4 {
  transform: scale(1.4) !important;
}

.scale-5 {
  transform: scale(1.5) !important;
}

.scale-6 {
  transform: scale(1.6) !important;
}

/* Top / Bottom / Left / Right */
.top-auto {
  top: auto !important;
}

.top-50pct {
  top: 50%;
}

.bottom-auto {
  top: auto !important;
}

.left-50pct {
  left: 50%;
}

.left-100pct {
  left: 100%;
}

.right-100pct {
  right: 100%;
}

.top-0 {
  top: 0px !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.left-0 {
  left: 0px !important;
}

.right-0 {
  right: 0px !important;
}

.top-1 {
  top: 1px !important;
}

.bottom-1 {
  bottom: 1px !important;
}

.left-1 {
  left: 1px !important;
}

.right-1 {
  right: 1px !important;
}

.top-2 {
  top: 2px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.left-2 {
  left: 2px !important;
}

.right-2 {
  right: 2px !important;
}

.top-3 {
  top: 3px !important;
}

.bottom-3 {
  bottom: 3px !important;
}

.left-3 {
  left: 3px !important;
}

.right-3 {
  right: 3px !important;
}

.top-4 {
  top: 4px !important;
}

.bottom-4 {
  bottom: 4px !important;
}

.left-4 {
  left: 4px !important;
}

.right-4 {
  right: 4px !important;
}

.top-5 {
  top: 5px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.left-5 {
  left: 5px !important;
}

.right-5 {
  right: 5px !important;
}

.top-6 {
  top: 6px !important;
}

.bottom-6 {
  bottom: 6px !important;
}

.left-6 {
  left: 6px !important;
}

.right-6 {
  right: 6px !important;
}

.top-7 {
  top: 7px !important;
}

.bottom-7 {
  bottom: 7px !important;
}

.left-7 {
  left: 7px !important;
}

.right-7 {
  right: 7px !important;
}

.top-8 {
  top: 8px !important;
}

.bottom-8 {
  bottom: 8px !important;
}

.left-8 {
  left: 8px !important;
}

.right-8 {
  right: 8px !important;
}

.top-9 {
  top: 9px !important;
}

.bottom-9 {
  bottom: 9px !important;
}

.left-9 {
  left: 9px !important;
}

.right-9 {
  right: 9px !important;
}

.top-10 {
  top: 10px !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.left-10 {
  left: 10px !important;
}

.right-10 {
  right: 10px !important;
}

.top-11 {
  top: 11px !important;
}

.bottom-11 {
  bottom: 11px !important;
}

.left-11 {
  left: 11px !important;
}

.right-11 {
  right: 11px !important;
}

.top-12 {
  top: 12px !important;
}

.bottom-12 {
  bottom: 12px !important;
}

.left-12 {
  left: 12px !important;
}

.right-12 {
  right: 12px !important;
}

.top-13 {
  top: 13px !important;
}

.bottom-13 {
  bottom: 13px !important;
}

.left-13 {
  left: 13px !important;
}

.right-13 {
  right: 13px !important;
}

.top-14 {
  top: 14px !important;
}

.bottom-14 {
  bottom: 14px !important;
}

.left-14 {
  left: 14px !important;
}

.right-14 {
  right: 14px !important;
}

.top-15 {
  top: 15px !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.left-15 {
  left: 15px !important;
}

.right-15 {
  right: 15px !important;
}

/* Transform */
.transform3dx-n50 {
  transform: translate3d(-50%, 0, 0);
}

.transform3dy-n50 {
  transform: translate3d(0, -50%, 0);
}

.transform3dxy-n50 {
  transform: translate3d(-50%, -50%, 0);
}

/* Outline */
.outline-none {
  outline: 0 !important;
}

/* Text Decoration */
.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

/* Text Transform */
.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-transform-none {
  text-transform: none !important;
}

/* States */
.text-muted {
  color: #999 !important;
}

html.dark .text-muted {
  color: #505461 !important;
}

/* Overflow */
.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

/* Z-Index */
.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

@media (max-width: 991px) {
  .z-index-mobile-0 {
    z-index: 0 !important;
  }
}

/* Colors */
.text-dark {
  color: #212529 !important;
}

.text-light {
  color: #fff !important;
}

/* Weights */
.font-weight-thin {
  font-weight: 100 !important;
}

.font-weight-extralight,
.font-weight-extra-light {
  font-weight: 200 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular,
.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold,
.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extrabold,
.font-weight-extra-bold {
  font-weight: 800 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

/* Letter Space */
.letter-spacing-minus-1 {
  letter-spacing: -1px;
}

/* Borders */
.no-borders {
  border: none !important;
}

.rounded {
  border-radius: 5px !important;
}

.b-thin {
  border-width: 3px !important;
}

.b-normal {
  border-width: 5px !important;
}

.b-thick {
  border-width: 7px !important;
}

/* General Helpers */
.ws-nowrap {
  white-space: nowrap !important;
}

.ws-normal {
  white-space: normal !important;
}

.wb-all {
  word-break: break-all !important;
}

/* Width */
.w-auto {
  width: auto !important;
}

.w-25pct {
  width: 25% !important;
}

.w-50pct {
  width: 50% !important;
}

.w-75pct {
  width: 75% !important;
}

.w-100pct {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-25pct {
    width: 25% !important;
  }

  .w-sm-50pct {
    width: 50% !important;
  }

  .w-sm-75pct {
    width: 75% !important;
  }

  .w-sm-100pct {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }

  .w-md-25pct {
    width: 25% !important;
  }

  .w-md-50pct {
    width: 50% !important;
  }

  .w-md-75pct {
    width: 75% !important;
  }

  .w-md-100pct {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-25pct {
    width: 25% !important;
  }

  .w-lg-50pct {
    width: 50% !important;
  }

  .w-lg-75pct {
    width: 75% !important;
  }

  .w-lg-100pct {
    width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-25pct {
    width: 25% !important;
  }

  .w-xl-50pct {
    width: 50% !important;
  }

  .w-xl-75pct {
    width: 75% !important;
  }

  .w-xl-100pct {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .w-auto-mobile {
    width: auto !important;
  }

  .w-100-mobile {
    width: 100% !important;
  }
}

.min-width-0 {
  min-width: 0 !important;
}

/* Grid */
.col-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5,
.col-xl-1-5,
.col-2-5,
.col-sm-2-5,
.col-md-2-5,
.col-lg-2-5,
.col-xl-2-5,
.col-3-5,
.col-sm-3-5,
.col-md-3-5,
.col-lg-3-5,
.col-xl-3-5,
.col-4-5,
.col-sm-4-5,
.col-md-4-5,
.col-lg-4-5,
.col-xl-4-5 {
  position: relative;
  min-height: 1px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-1-5 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-2-5 {
  flex: 0 0 40%;
  max-width: 40%;
}

.col-3-5 {
  flex: 0 0 60%;
  max-width: 60%;
}

.col-4-5 {
  flex: 0 0 80%;
  max-width: 80%;
}

@media (min-width: 576px) {
  .col-sm-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-sm-2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-sm-3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-sm-4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

@media (min-width: 768px) {
  .col-md-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-md-2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-md-3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-md-4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

@media (min-width: 992px) {
  .col-lg-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-lg-2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-lg-3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-lg-4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

@media (min-width: 1200px) {
  .col-xl-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xl-2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-xl-3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-xl-4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

/* RTL */
/* #Footer */
#footer {
  background: #212529;
  border-top: 4px solid #212529;
  font-size: 0.9em;
  margin-top: 50px;
  padding: 0;
  position: relative;
  clear: both;
}

#footer .footer-ribbon {
  background: #999;
  position: absolute;
  margin: -44px 0 0 0;
  padding: 10px 20px 6px 20px;
}

#footer .footer-ribbon:before {
  border-right: 10px solid #646464;
  border-top: 16px solid transparent;
  content: "";
  display: block;
  height: 0;
  right: 100%;
  position: absolute;
  top: 0;
  width: 7px;
}

#footer .footer-ribbon span {
  color: #fff;
  font-size: 1.6em;
  font-family: "Shadows Into Light", cursive;
}

#footer h1,
#footer h2,
#footer h3,
#footer h4,
#footer h5,
#footer h6 {
  color: #fff;
}

#footer a:not(.btn):not(.no-footer-css) {
  transition: all 0.1s ease-in-out;
}

#footer a:not(.btn):not(.no-footer-css):hover {
  text-decoration: none;
  color: #ff6600;
}

#footer a:not(.btn):not(.no-footer-css):focus,
#footer a:not(.btn):not(.no-footer-css):active {
  color: #ff6600;
}

#footer a:not(.btn):not(.no-footer-css).text-color-light {
  color: #fff !important;
}

#footer a:not(.btn):not(.no-footer-css).text-color-light:hover {
  color: #e6e5e5 !important;
}

#footer a:not(.btn):not(.no-footer-css).text-color-light:focus,
#footer a:not(.btn):not(.no-footer-css).text-color-light:active {
  color: #cccccc !important;
}

#footer a:not(.btn):not(.no-footer-css).text-color-default {
  color: #777 !important;
}

#footer a:not(.btn):not(.no-footer-css).text-color-default:hover {
  color: #919090 !important;
}

#footer a:not(.btn):not(.no-footer-css).text-color-default:focus,
#footer a:not(.btn):not(.no-footer-css).text-color-default:active {
  color: #5e5d5d !important;
}

#footer a:not(.btn):not(.no-footer-css).link-hover-style-1 {
  position: relative;
  left: 0px;
  transition: all 0.1s ease-in-out;
}

#footer a:not(.btn):not(.no-footer-css).link-hover-style-1:hover {
  left: 3px;
}

#footer ul.nav-list > li a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  line-height: 20px;
  padding-bottom: 11px;
}

#footer ul.nav-list > li:last-child a {
  border-bottom: 0;
}

#footer ul.nav-list > li a:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

#footer ul.list.icons li {
  margin-bottom: 5px;
}

#footer.footer-texts-more-lighten p,
#footer.footer-texts-more-lighten span,
#footer.footer-texts-more-lighten a:not(.btn):not(.no-footer-css),
#footer.footer-texts-more-lighten li {
  color: #a8a8a8;
}

#footer.footer-texts-more-lighten a:not(.btn):not(.no-footer-css):hover {
  text-decoration: none;
  color: #fff;
}

#footer .footer-nav nav {
  display: flex;
}

#footer .footer-nav nav > ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

#footer .footer-nav nav > ul > li {
  position: relative;
  height: 100%;
  align-self: stretch;
}

#footer .footer-nav nav > ul > li > a {
  position: relative;
  background: transparent;
  padding: 0 14.4px;
  padding: 0 0.9rem;
  margin: 0;
  height: 100%;
  display: inline-flex;
  align-items: center;
  white-space: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}

#footer .footer-nav.footer-nav-links nav > ul > li:first-child > a {
  padding-left: 0;
}

#footer .footer-nav.footer-nav-links nav > ul > li:first-child > a:before {
  left: 0;
}

@media (min-width: 768px) {
  #footer .footer-nav.footer-nav-bottom-line nav > ul > li > a:before {
    content: "";
    position: absolute;
    width: auto;
    height: 3px;
    top: 50%;
    left: 0.85rem;
    right: 0.85rem;
    margin-top: 15px;
    transform: translate3d(0, -50%, 0);
  }
}

@media (max-width: 991px) {
  #footer .footer-nav {
    width: 100%;
  }

  #footer .footer-nav nav {
    text-align: center;
  }

  #footer .footer-nav nav > ul {
    width: 100%;
  }

  #footer .footer-nav nav > ul > li > a {
    text-align: center;
    padding: 5px 12px;
  }
}

@media (max-width: 767px) {
  #footer .footer-nav {
    width: 100%;
  }

  #footer .footer-nav nav {
    text-align: center;
  }

  #footer .footer-nav nav > ul {
    width: 100%;
  }

  #footer .footer-nav nav > ul > li {
    height: auto;
    width: 100%;
  }

  #footer .footer-nav nav > ul > li > a {
    text-align: center;
    padding: 1em 0;
  }
}

#footer form {
  opacity: 0.85;
}

#footer form label.error {
  line-height: 16px;
  margin: 5px 0 -5px;
  display: block;
  clear: both;
}

#footer form .alert {
  padding: 6px;
  text-align: center;
}

#footer .logo img {
  position: relative;
  top: 2px;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}

#footer .twitter [class*="fa-"],
#footer .twitter .icons {
  clear: both;
  font-size: 1.5em;
  position: relative;
  top: 3px;
  margin-right: -22px;
  left: -30px;
  color: #fff;
}

#footer .twitter .meta {
  color: #999;
  display: block;
  font-size: 0.9em;
  padding-top: 3px;
  opacity: 0.5;
}

#footer .twitter ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#footer .twitter ul li {
  padding-bottom: 20px;
  padding-left: 30px;
}

#footer .twitter ul li:last-child {
  padding-bottom: 0;
}

#footer .twitter.twitter-dark [class*="fa-"],
#footer .twitter.twitter-dark .icons {
  color: #333;
}

#footer .twitter.twitter-dark .meta a {
  color: #333;
}

#footer .twitter.twitter-light {
  color: #fff;
}

#footer .twitter.twitter-light [class*="fa-"],
#footer .twitter.twitter-light .icons {
  color: #fff;
}

#footer .twitter.twitter-light .meta a {
  color: #fff;
  opacity: 0.7;
}

#footer .twitter-account {
  color: #fff;
  display: block;
  font-size: 0.9em;
  margin: -15px 0 5px;
  opacity: 0.55;
}

#footer .twitter-account:hover {
  opacity: 1;
}

#footer .footer-bg-color-2 {
  background: #1c2023;
}

#footer .footer-copyright {
  background: #1c2023;
}

#footer .footer-copyright nav {
  font-size: 0.9em;
}

#footer .footer-copyright nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#footer .footer-copyright nav ul li {
  display: inline-block;
  line-height: 12px;
  margin: 0;
  padding: 0 8px;
}

#footer .footer-copyright nav ul li:first-child {
  border: medium none;
  padding-left: 0;
}

#footer .footer-copyright nav ul li:last-child {
  padding-right: 0;
}

#footer .footer-copyright p {
  color: #555;
  margin: 0;
  padding: 0;
  font-size: 0.9em;
}

#footer .footer-copyright.footer-copyright-style-2 {
  background: #212529;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

#footer .footer-copyright.footer-copyright-border-grey {
  border-top: 1px solid #e5e5e5;
}

#footer .footer-copyright.footer-copyright-full-width-border-top {
  position: relative;
  border-top: 0;
}

#footer .footer-copyright.footer-copyright-full-width-border-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  transform: translate3d(-50%, 0, 0);
}

#footer.footer-reveal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -10;
}

.footer-top-border {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.footer-bottom-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.footer-left-border {
  border-left: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.footer-right-border {
  border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.footer-top-light-border {
  border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
}

.footer-bottom-light-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
}

.footer-left-light-border {
  border-left: 1px solid rgba(255, 255, 255, 0.05) !important;
}

.footer-right-light-border {
  border-right: 1px solid rgba(255, 255, 255, 0.05) !important;
}

.map-above {
  min-height: 630px;
  position: relative;
}

.map-above .map-above-map {
  position: absolute;
  top: 0;
  min-height: 630px;
  height: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .map-above .map-above-map {
    position: relative;
  }
}

.map-above .map-above-content {
  background: transparent;
}


/////

.mobile-nav-wrapper {
  z-index: 1;
  width: 97.6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  // animate nav opening details start
  opacity: 0;
  visibility: hidden;
  height: 0%;
  transition: opacity 0.75s, visibility 0.75s, height 0.75s;
  overflow: hidden;
}

.mobile-nav-wrapper.open {
  // animate nav opening details end
  opacity: 1;
  visibility: visible;
  height: 100vh;
}

.mobile-link {
  width: 100%;
  text-align: center;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 3px;
  margin: 0 0.5rem;
}

.mobile-link.active {
  border-radius: 5px;
  width: 90%;
}


.navigation-wrapper {
  z-index: 3;
  padding: .25rem;

  .navigation-header {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navigation-names > a > span {
      display: none;
    }

    .navigation-links {
      // mobile menu styling / animating
      .mobile-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // align-items: center;
        width: 1.6rem;
        height: 1.6rem;
        background: transparent;
        border: none;
        padding: 0 .2rem;
        // right: 1.5rem;
        // top: 20px;

        &:focus {
          outline: none;
        }

        .bar-one,
        .bar-two,
        .bar-three {
          width: 1.2rem;
          height: 0.2rem;
          background: #002345;
          border-radius: 10px;
          transition: all 0.3s linear;
          position: relative;
          transform-origin: 1px;
        }

        .bar-one {
          transform: rotate(0);
        }

        .bar-two {
          opacity: 1;
          transform: translateX(0);
        }

        .bar-three {
          transform: rotate(0);
        }
      }

      .open {
        .bar-one {
          transform: rotate(45deg);
          width: 1.63rem;
        }

        .bar-two {
          opacity: 0;
          transform: translateX(20px);
        }

        .bar-three {
          transform: rotate(-45deg);
          width: 1.63rem;
        }
      }
    }
  }
}


/* Dark Version */
// html.dark body,
// html.dark .body {
//   background-color: #212529;
// }

// html.dark.boxed .body {
//   background-color: #212529;
// }

// html.dark #header-new .header-top {
//   background: #2c3237;
//   border-bottom-color: #333940;
// }

// html.dark #header-new .header-search .form-control {
//   background: #262a2f;
// }

// html.dark #header-new .header-body {
//   background: #212529;
//   border-top-color: #333940;
// }

// html.dark #header-new .header-nav-bar {
//   background-color: #262a2f;
// }

// html.dark #header-new .header-nav-top .nav > li > a:hover,
// html.dark #header-new .header-nav-top .nav > li > a a:focus {
//   background-color: #262a2f;
// }

// html.dark #header-new .header-nav-top .dropdown-menu {
//   background: #262a2f;
//   border-color: #262a2f;
// }

// html.dark #header-new .header-nav-top .dropdown-menu a:hover,
// html.dark #header-new .header-nav-top .dropdown-menu a:focus {
//   background: #2c3237 !important;
// }

// html.dark #header-new .header-nav-features .header-nav-top-icon,
// html.dark #header-new .header-nav-features .header-nav-top-icon-img {
//   color: #fff;
// }

// html.dark #header-new .header-nav-features .header-nav-features-dropdown {
//   background: #262a2f;
// }

// html.dark
//   #header-new
//   .header-nav-features
//   .header-nav-features-dropdown:before {
//   border-bottom-color: #262a2f;
// }

// html.dark.sticky-header-active #header-new .header-body {
//   border-bottom-color: #333940;
//   box-shadow: none;
// }

// html.dark h1,
// html.dark h2,
// html.dark h3,
// html.dark h4,
// html.dark h5,
// html.dark h6 {
//   color: #fff;
// }

// html.dark h1.card-title,
// html.dark h2.card-title,
// html.dark h3.card-title,
// html.dark h4.card-title,
// html.dark h5.card-title,
// html.dark h6.card-title {
//   color: #fff;
// }

// html.dark .alert h1,
// html.dark .alert h2,
// html.dark .alert h3,
// html.dark .alert h4,
// html.dark .alert h5,
// html.dark .alert h6 {
//   color: #111;
// }

// html.dark blockquote {
//   border-color: #2c3237;
// }

// html.dark section.featured {
//   background-color: #1c2023;
//   border-top-color: #333940;
// }

// html.dark section.section {
//   background-color: #262a2f;
//   border-top-color: #2c3237;
// }

// html.dark .dropdown-menu > li a {
//   color: #fff;
// }

// html.dark .dropdown-menu > li a:hover,
// html.dark .dropdown-menu > li a:focus {
//   background-color: #333940;
// }

// html.dark .simple-search .input-group-append {
//   border-color: #2c3237;
// }

// html.dark .home-intro {
//   background-color: #262a2f;
// }

// html.dark .tabs .nav-tabs li a,
// html.dark .tabs .nav-tabs li a:focus {
//   border-top-color: #2c3237;
//   border-left-color: #2c3237;
//   border-right-color: #2c3237;
//   background: #2c3237;
// }

// html.dark .tabs .nav-tabs li a:hover {
//   border-top-color: #808697;
// }

// html.dark .tabs .nav-tabs li.active a,
// html.dark .tabs .nav-tabs li.active a:hover,
// html.dark .tabs .nav-tabs li.active a:focus {
//   border-top-color: #808697;
// }

// html.dark .tabs .nav-tabs.nav-justified {
//   border-left-width: 0;
//   border-right-width: 0;
//   border-left-color: transparent;
//   border-right-color: transparent;
// }

// html.dark .tabs .nav-tabs.nav-justified li a,
// html.dark .tabs .nav-tabs.nav-justified li a:hover,
// html.dark .tabs .nav-tabs.nav-justified li a:focus {
//   border-bottom-color: #2c3237;
// }

// html.dark .tabs.tabs-left .nav-tabs > li a,
// html.dark .tabs.tabs-right .nav-tabs > li a {
//   background: #2c3237;
//   border-left-color: #2c3237;
//   border-right-color: #2c3237;
// }

// html.dark .tabs.tabs-left .nav-tabs > li:last-child a,
// html.dark .tabs.tabs-right .nav-tabs > li:last-child a {
//   border-bottom-color: #2c3237;
// }

// html.dark .tabs .nav-tabs {
//   border-color: #2c3237;
// }

// html.dark .tabs .nav-tabs li.active a,
// html.dark .tabs .nav-tabs li.active a:hover,
// html.dark .tabs .nav-tabs li.active a:focus,
// html.dark .tabs .nav-tabs.nav-justified li.active a,
// html.dark .tabs .nav-tabs.nav-justified li.active a:hover,
// html.dark .tabs .nav-tabs.nav-justified li.active a:focus {
//   background: #333940;
//   border-left-color: #333940;
//   border-right-color: #333940;
// }

// html.dark .tabs .nav-tabs.nav-justified li.active a {
//   border-bottom-color: #333940;
// }

// html.dark .tabs.tabs-vertical {
//   border-top-color: #333940;
// }

// html.dark .tabs.tabs-bottom .nav-tabs li a,
// html.dark .tabs.tabs-bottom .nav-tabs li a:focus {
//   border-bottom-color: #2c3237;
//   border-top-color: #333940;
// }

// html.dark .tabs.tabs-bottom .nav-tabs li a:hover {
//   border-bottom-color: #808697;
//   border-top-color: #333940;
// }

// html.dark .tabs.tabs-bottom .nav-tabs li.active a,
// html.dark .tabs.tabs-bottom .nav-tabs li.active a:hover,
// html.dark .tabs.tabs-bottom .nav-tabs li.active a:focus {
//   border-bottom-color: #808697;
//   border-top-color: #333940;
// }

// html.dark .tabs .tab-content {
//   background: #333940;
//   border-color: #333940;
// }

// html.dark .tabs-primary.tabs-bottom .nav-tabs li a,
// html.dark .tabs-primary.tabs-bottom .nav-tabs li a:hover,
// html.dark .tabs-primary.tabs-bottom .nav-tabs li a:focus,
// html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a,
// html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:hover,
// html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:focus {
//   border-top-color: #333940 !important;
// }

// html.dark .nav-tabs li.active a,
// html.dark .nav-tabs li.active a:hover,
// html.dark .nav-tabs li.active a:focus,
// html.dark .nav-tabs li a {
//   color: #808697;
// }

// html.dark .tab-content {
//   background: #333940;
//   border-color: #212529;
// }

// html.dark .tabs-simple .tab-content,
// html.dark .tabs-simple .nav-tabs li a,
// html.dark .tabs-simple .nav-tabs li.active a {
//   background: transparent !important;
// }

// html.dark .nav > li > a:hover,
// html.dark .nav > li > a:focus {
//   background-color: #333940;
// }

// html.dark .call-to-action.call-to-action-default {
//   background: #2c3237;
// }

// html.dark .call-to-action.call-to-action-dark {
//   background: #333940;
// }

// html.dark .call-to-action.with-borders {
//   border-top-color: #333940;
//   border-bottom-color: #333940;
//   border-left-color: #2c3237;
//   border-right-color: #2c3237;
// }

// html.dark .call-to-action.with-full-borders {
//   border-color: #333940;
// }

// html.dark .call-to-action.featured {
//   background: linear-gradient(to bottom, #2c3237 1%, #333940 98%) repeat scroll
//     0 0 rgba(0, 0, 0, 0);
//   border-bottom-color: #333940;
//   border-left-color: #2c3237;
//   border-right-color: #2c3237;
// }

// html.dark .call-to-action.call-to-action-in-footer:before {
//   border-top-color: #212529;
// }

// html.dark .counters.with-borders .counter {
//   border-top: 1px solid #333940;
//   border-bottom: 1px solid #333940;
//   border-left: 1px solid #2c3237;
//   border-right: 1px solid #2c3237;
// }

// html.dark .counters.counters-text-dark .counter {
//   color: #fff !important;
// }

// html.dark .counters.counters-text-dark .counter [class*="fa-"],
// html.dark .counters.counters-text-dark .counter .icons,
// html.dark .counters.counters-text-dark .counter strong,
// html.dark .counters.counters-text-dark .counter label {
//   color: #fff !important;
// }

// html.dark section.timeline:after {
//   background: #505050;
//   background: linear-gradient(
//     to bottom,
//     rgba(80, 80, 80, 0) 0%,
//     #505050 8%,
//     #505050 92%,
//     rgba(80, 80, 80, 0) 100%
//   );
// }

// html.dark section.timeline .timeline-date {
//   background-color: #2c3237;
//   border-color: #2c3237;
//   text-shadow: none;
// }

// html.dark section.timeline .timeline-title {
//   background: #2c3237;
// }

// html.dark section.timeline .timeline-box {
//   border-color: #2c3237;
//   background: #2c3237;
// }

// html.dark section.timeline .timeline-box.left:before {
//   box-shadow: 0 0 0 3px #2c3237, 0 0 0 6px #2c3237;
// }

// html.dark section.timeline .timeline-box.left:after {
//   background: #2c3237;
//   border-right-color: #2c3237;
//   border-top-color: #2c3237;
// }

// html.dark section.timeline .timeline-box.right:before {
//   box-shadow: 0 0 0 3px #2c3237, 0 0 0 6px #2c3237;
// }

// html.dark section.timeline .timeline-box.right:after {
//   background: #2c3237;
//   border-left-color: #2c3237;
//   border-bottom-color: #2c3237;
// }

// html.dark form:not(.form-style-4) .form-control {
//   background-color: #2c3237;
//   border-color: #2c3237;
// }

// html.dark form.form-style-4 .form-control {
//   border-bottom-color: #3c3c3c;
// }

// html.dark .btn-light {
//   background-color: #2c3237;
//   border-color: #2c3237;
//   color: #fff;
// }

// html.dark .btn.disabled,
// html.dark .btn.disabled:hover,
// html.dark .btn.disabled:active,
// html.dark .btn.disabled:focus,
// html.dark .btn[disabled],
// html.dark .btn[disabled]:hover,
// html.dark .btn[disabled]:active,
// html.dark .btn[disabled]:focus {
//   border-color: #2c3237 !important;
// }

// html.dark .pagination > li > a,
// html.dark .pagination > li > span {
//   background-color: #2c3237;
//   border-color: #282d31;
// }

// html.dark section.section-custom-map {
//   background-color: #212529;
// }

// html.dark section.section-custom-map section.section {
//   background: rgba(38, 42, 47, 0.8);
// }

// html.dark .page-header {
//   border-bottom-color: #1c2023;
// }

// html.dark .page-header-light {
//   background-color: #333940;
// }

// html.dark .card {
//   background-color: #1c2023;
// }

// html.dark .accordion .card {
//   background-color: #2c3237;
// }

// html.dark .accordion .card-default {
//   border-color: #2c3237;
// }

// html.dark .accordion .card-header {
//   background-color: #333940;
// }

// html.dark .accordion .form-control {
//   background-color: #262a2f;
//   border-color: #262a2f;
// }

// html.dark .accordion.accordion-modern .card .card-header {
//   background-color: #262a2f;
// }

// html.dark .accordion.accordion-modern .card:first-of-type,
// html.dark
//   .accordion.accordion-modern
//   .card:not(:first-of-type):not(:last-of-type),
// html.dark .accordion.accordion-modern .card:last-of-type {
//   border-color: #262a2f;
// }

// html.dark .toggle > label,
// html.dark .toggle > .toggle-title {
//   background-color: #333940;
// }

// html.dark .toggle.toggle-simple .toggle > label,
// html.dark .toggle.toggle-simple .toggle > .toggle-title {
//   color: #fff;
// }

// html.dark .featured-box {
//   background: #333940;
//   border-left-color: #333940;
//   border-right-color: #333940;
//   border-bottom-color: #333940;
// }

// html.dark .featured-box.secondary h4 {
//   color: #fff;
// }

// html.dark .featured-boxes-flat .featured-box .box-content {
//   background: #333940;
// }

// html.dark .featured-boxes-style-5 .featured-box .box-content h4,
// html.dark .featured-boxes-style-6 .featured-box .box-content h4,
// html.dark .featured-boxes-style-7 .featured-box .box-content h4 {
//   color: #fff;
// }

// html.dark .featured-boxes-style-2 .featured-box {
//   background: transparent;
// }

// html.dark .featured-boxes-style-3 .featured-box .icon-featured {
//   background: #212529;
// }

// html.dark .featured-boxes-style-4 .featured-box {
//   background: transparent;
// }

// html.dark .featured-boxes-style-5 .featured-box {
//   background: transparent;
// }

// html.dark .featured-boxes-style-5 .featured-box .icon-featured {
//   background: #2c3237;
//   border-color: #333940;
// }

// html.dark .featured-boxes-style-6 .featured-box {
//   background: transparent;
// }

// html.dark .featured-boxes-style-6 .featured-box .icon-featured {
//   background: #2c3237;
//   border-color: #333940;
// }

// html.dark .featured-boxes-style-6 .featured-box .icon-featured:after {
//   border-color: #333940;
// }

// html.dark .featured-boxes-style-7 .featured-box {
//   background: transparent;
// }

// html.dark .featured-boxes-style-7 .featured-box .icon-featured {
//   background: #2c3237;
//   border-color: #333940;
// }

// html.dark .featured-boxes-style-7 .featured-box .icon-featured:after {
//   box-shadow: 3px 3px #1a1d21;
// }

// html.dark .featured-boxes-style-8 .featured-box .icon-featured {
//   background: #333940;
// }

// html.dark .featured-box-effect-1 .icon-featured:after {
//   box-shadow: 0 0 0 3px #333940;
// }

// html.dark .feature-box.feature-box-style-2 h4,
// html.dark .feature-box.feature-box-style-3 h4,
// html.dark .feature-box.feature-box-style-4 h4 {
//   color: #fff;
// }

// html.dark .feature-box.feature-box-style-6 .feature-box-icon {
//   border-color: #333940;
// }

// html.dark .feature-box.feature-box-style-6 .feature-box-icon:after {
//   border-color: #333940;
// }

// html.dark .owl-dots button.owl-dot span {
//   background: #333940;
// }

// html.dark .owl-carousel.top-border {
//   border-top-color: #3f4247;
// }

// html.dark .progress {
//   background: #333940;
// }

// html.dark .thumbnail,
// html.dark .img-thumbnail,
// html.dark .thumb-info {
//   background-color: #2c3237;
//   border-color: #2c3237;
// }

// html.dark .thumb-info .thumb-info-wrapper:after {
//   background-color: rgba(33, 37, 41, 0.9);
// }

// html.dark .thumb-info-social-icons {
//   border-top-color: #3c444b;
// }

// html.dark ul.nav-list > li a {
//   border-bottom-color: #2c3237;
// }

// html.dark ul.nav-list > li a:hover {
//   background-color: #2c3237;
// }

// html.dark .content-grid .content-grid-item:before {
//   border-left-color: #333940;
// }

// html.dark .content-grid .content-grid-item:after {
//   border-bottom-color: #333940;
// }

// html.dark .content-grid.content-grid-dashed .content-grid-item:before {
//   border-left-color: #333940;
// }

// html.dark .content-grid.content-grid-dashed .content-grid-item:after {
//   border-bottom-color: #333940;
// }

// html.dark .testimonial .testimonial-author strong {
//   color: #fff;
// }

// html.dark .testimonial.testimonial-style-3 blockquote {
//   background: #333940 !important;
// }

// html.dark .testimonial.testimonial-style-3 .testimonial-arrow-down {
//   border-top-color: #333940 !important;
// }

// html.dark .testimonial.testimonial-style-4 {
//   border-color: #333940 !important;
// }

// html.dark .testimonial.testimonial-style-5 .testimonial-author {
//   border-top-color: #333940 !important;
// }

// html.dark .popover {
//   background-color: #333940;
//   border: 1px solid #2c3237;
// }

// html.dark .popover.top > .arrow {
//   border-top-color: #2c3237;
// }

// html.dark .popover.top > .arrow:after {
//   border-top-color: #333940;
// }

// html.dark .popover.right > .arrow {
//   border-right-color: #2c3237;
// }

// html.dark .popover.right > .arrow:after {
//   border-right-color: #333940;
// }

// html.dark .popover.bottom > .arrow {
//   border-bottom-color: #2c3237;
// }

// html.dark .popover.bottom > .arrow:after {
//   border-bottom-color: #333940;
// }

// html.dark .popover.left > .arrow {
//   border-left-color: #2c3237;
// }

// html.dark .popover.left > .arrow:after {
//   border-left-color: #333940;
// }

// html.dark .popover-title {
//   background-color: #2c3237;
//   border-bottom: #333940;
// }

// html.dark .page-header {
//   border-bottom-color: #2c3237;
// }

// html.dark .table > thead > tr > th,
// html.dark .table > tbody > tr > th,
// html.dark .table > tfoot > tr > th,
// html.dark .table > thead > tr > td,
// html.dark .table > tbody > tr > td,
// html.dark .table > tfoot > tr > td,
// html.dark .table-bordered {
//   border-color: #2c3237;
// }

// html.dark .table-striped > tbody > tr:nth-child(2n + 1) > td,
// html.dark .table-striped > tbody > tr:nth-child(2n + 1) > th {
//   background-color: #333940;
// }

// html.dark pre {
//   background-color: #2c3237;
//   border-color: #2c3237;
//   color: #777;
// }

// html.dark .show-grid [class*="col-lg-"] .show-grid-block {
//   background-color: #2c3237;
//   border-color: #2c3237;
// }

// html.dark .google-map-borders,
// html.dark .embed-responsive-borders {
//   border-color: #333940;
// }

// html.dark .alert.alert-default {
//   border-color: #333940;
//   background-color: #2c3237;
// }

// html.dark hr {
//   background-image: linear-gradient(to left, #212529, #3f4247, #212529);
// }

// html.dark hr.light {
//   background-image: linear-gradient(to left, #212529, #3f4247, #212529);
// }

// html.dark hr.solid {
//   background: #3f4247;
// }

// html.dark .divider {
//   background-image: linear-gradient(to left, transparent, #3f4247, transparent);
// }

// html.dark .divider [class*="fa-"],
// html.dark .divider .icons {
//   background: #212529;
// }

// html.dark .divider.divider-solid {
//   background: #3f4247;
// }

// html.dark .divider.divider-style-2 [class*="fa-"],
// html.dark .divider.divider-style-2 .icons {
//   background: #262a2f;
// }

// html.dark .divider.divider-style-3 [class*="fa-"],
// html.dark .divider.divider-style-3 .icons {
//   border-color: #3f4247;
// }

// html.dark .divider.divider-style-4 [class*="fa-"],
// html.dark .divider.divider-style-4 .icons {
//   border-color: #3f4247;
// }

// html.dark .divider.divider-style-4 [class*="fa-"]:after,
// html.dark .divider.divider-style-4 .icons:after {
//   border-color: #262a2f;
// }

// html.dark .divider.divider-small {
//   background: transparent;
// }

// html.dark .divider.divider-small hr {
//   background: #3f4247;
// }

// html.dark .divider.divider-small.divider-light hr {
//   background: #3f4247;
// }

// html.dark hr.dashed:after,
// html.dark .divider.dashed:after {
//   border-color: #3f4247;
// }

// html.dark .heading.heading-bottom-border h1 {
//   border-bottom-color: #3f4247;
// }

// html.dark .heading.heading-bottom-border h2,
// html.dark .heading.heading-bottom-border h3 {
//   border-bottom-color: #3f4247;
// }

// html.dark .heading.heading-bottom-border h4,
// html.dark .heading.heading-bottom-border h5,
// html.dark .heading.heading-bottom-border h6 {
//   border-bottom-color: #3f4247;
// }

// html.dark .heading.heading-bottom-double-border h1,
// html.dark .heading.heading-bottom-double-border h2,
// html.dark .heading.heading-bottom-double-border h3 {
//   border-bottom-color: #3f4247;
// }

// html.dark .heading.heading-bottom-double-border h4,
// html.dark .heading.heading-bottom-double-border h5,
// html.dark .heading.heading-bottom-double-border h6 {
//   border-bottom-color: #3f4247;
// }

// html.dark .heading.heading-middle-border:before {
//   border-top-color: #3f4247;
// }

// html.dark .heading.heading-middle-border h1,
// html.dark .heading.heading-middle-border h2,
// html.dark .heading.heading-middle-border h3,
// html.dark .heading.heading-middle-border h4,
// html.dark .heading.heading-middle-border h5,
// html.dark .heading.heading-middle-border h6 {
//   background: #212529;
// }

// html.dark .recent-posts .date .day,
// html.dark section.section .recent-posts .date .day {
//   background-color: #333940;
// }

// html.dark .blog-posts article {
//   border-color: #2c3237;
// }

// html.dark section.featured .recent-posts .date .day,
// html.dark article.post .post-date .day {
//   background-color: #2c3237;
// }

// html.dark article .post-video,
// html.dark article .post-video iframe,
// html.dark article .post-audio,
// html.dark article .post-audio iframe {
//   background-color: #2c3237;
//   border-color: #2c3237;
// }

// html.dark ul.simple-post-list li {
//   border-bottom-color: #262a2f;
// }

// html.dark .post-block {
//   border-top-color: #1c2023;
// }

// html.dark ul.comments .comment-block {
//   background-color: #2c3237;
// }

// html.dark ul.comments .comment-arrow {
//   border-right-color: #2c3237;
// }

// html.dark .pricing-table li {
//   border-top-color: #262a2f;
// }

// html.dark .pricing-table h3 {
//   background-color: #262a2f;
//   text-shadow: none;
// }

// html.dark .pricing-table h3 span {
//   background: #2c3237;
//   border-color: #282d31;
//   box-shadow: 0 5px 20px #282d31 inset, 0 3px 0 #2c3237 inset;
//   color: #777;
// }

// html.dark .pricing-table .most-popular {
//   border-color: #2c3237;
// }

// html.dark .pricing-table .most-popular h3 {
//   background-color: #2c3237;
//   color: #fff;
//   text-shadow: none;
// }

// html.dark .pricing-table .plan-ribbon {
//   background-color: #2c3237;
// }

// html.dark .pricing-table .plan {
//   background: #2c3237;
//   border: 1px solid #2c3237;
//   color: #777;
//   text-shadow: none;
// }

// html.dark .product-thumb-info {
//   background-color: #2c3237;
//   border-color: transparent;
// }

// html.dark .shop .quantity .qty {
//   background-color: #2c3237;
//   border-color: transparent;
// }

// html.dark .shop .quantity .minus,
// html.dark .shop .quantity .plus {
//   background-color: #262a2f;
//   border-color: transparent;
// }

// html.dark .shop table.cart td,
// html.dark .shop .cart-totals th,
// html.dark .shop .cart-totals td {
//   border-color: #2c3237;
// }

// html.dark .dialog {
//   background-color: #212529;
// }

// html.dark .modal-content {
//   background-color: #212529;
// }

// html.dark .modal-header {
//   border-bottom-color: #2c3237;
// }

// html.dark .modal-header h1,
// html.dark .modal-header h2,
// html.dark .modal-header h3,
// html.dark .modal-header h4,
// html.dark .modal-header h5,
// html.dark .modal-header h6 {
//   color: #777;
// }

// html.dark .modal-header .close {
//   text-shadow: none;
//   color: #fff;
// }

// html.dark .modal-footer {
//   border-top-color: #2c3237;
// }

// html.dark .popup-inline-content,
// html.dark .mfp-content .ajax-container {
//   background: #212529 !important;
// }

// html.dark .loading-overlay {
//   background: #212529;
// }

// html.dark .sort-destination-loader:after {
//   background-color: #212529;
// }

// html.dark #footer .newsletter form .btn-light {
//   background-color: #262a2f;
//   border-color: #262a2f;
//   color: #777;
// }

// html.dark #footer .newsletter form .form-control {
//   border: 0;
// }

// /* Responsive */
// @media (min-width: 992px) {
//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown:not(.dropdown-full-color)
//     .dropdown-menu {
//     background: #262a2f;
//   }

//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown:not(.dropdown-full-color)
//     .dropdown-menu
//     li:hover
//     > a,
//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown:not(.dropdown-full-color)
//     .dropdown-menu
//     li:focus
//     > a,
//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown:not(.dropdown-full-color)
//     .dropdown-menu
//     li.active
//     > a,
//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown:not(.dropdown-full-color)
//     .dropdown-menu
//     li:active
//     > a {
//     background: #333940;
//   }

//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown:not(.dropdown-full-color)
//     .dropdown-menu
//     li
//     a {
//     border-bottom-color: #333940;
//   }

//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown:not(.dropdown-full-color)
//     .dropdown-menu
//     li
//     a:hover,
//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown:not(.dropdown-full-color)
//     .dropdown-menu
//     li
//     a:focus,
//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown:not(.dropdown-full-color)
//     .dropdown-menu
//     li
//     a.active,
//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown:not(.dropdown-full-color)
//     .dropdown-menu
//     li
//     a:active {
//     background: #333940;
//   }

//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown-mega:not(.dropdown-full-color)
//     .dropdown-mega-sub-title {
//     color: #999;
//   }

//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     nav
//     > ul
//     > li.dropdown-mega:not(.dropdown-full-color)
//     .dropdown-mega-sub-nav
//     > li:hover
//     > a {
//     background: #333940;
//   }

//   html.dark
//     #header-new
//     .header-nav-main:not(.header-nav-main-light)
//     a
//     > .thumb-info-preview
//     .thumb-info-wrapper {
//     background: #333940;
//   }

//   html.dark #header-new .header-nav.header-nav-stripe nav > ul > li > a {
//     color: #ccc;
//   }

//   html.dark #header-new .header-nav.header-nav-stripe nav > ul > li:hover > a {
//     color: #fff;
//   }

//   html.dark #header-new .header-nav.header-nav-links nav > ul > li > a,
//   html.dark #header-new .header-nav.header-nav-links nav > ul > li:hover > a,
//   html.dark #header-new .header-nav.header-nav-line nav > ul > li > a,
//   html.dark #header-new .header-nav.header-nav-line nav > ul > li:hover > a {
//     color: rgb(175, 173, 173);
//   }

//   html.dark #header-new .header-nav.header-nav-dropdowns-dark nav > ul > li > a,
//   html.dark
//     #header-new
//     .header-nav.header-nav-dropdowns-dark
//     nav
//     > ul
//     > li:hover
//     > a {
//     color: #ccc;
//   }

//   html.dark .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu {
//     background-color: #212529;
//   }
// }

// @media (max-width: 991px) {
//   html.dark #header-new .header-nav-main:before {
//     background-color: #212529;
//   }

//   html.dark #header-new .header-nav-main nav::-webkit-scrollbar-thumb {
//     border-color: transparent;
//     background: #39404c;
//   }

//   html.dark #header-new .header-nav-main nav > ul li {
//     border-bottom-color: #333940;
//   }

//   html.dark
//     #header-new
//     .header-nav-main
//     nav
//     > ul
//     > li.dropdown-mega:not(.dropdown-full-color)
//     .dropdown-mega-sub-title {
//     color: #fff;
//   }

//   html.dark #header-new .header-nav-main nav ul li a:active {
//     background-color: #1e1e1e;
//     color: #fff;
//   }

//   html.dark .home-concept {
//     background-image: none;
//   }
// }

/* Boxed Layout */
// html.boxed body {
//   background-color: transparent;
//   background-position: 0 0;
//   background-repeat: repeat;
// }

// html.boxed .body {
//   position: relative;
//   background-color: transparent;
//   border-radius: 5px;
//   border-top: 5px solid #ccc;
//   box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
//   margin: 25px auto;
//   max-width: 1200px;
//   height: auto;
// }

// html.boxed #header-new .header-body {
//   border-top-color: transparent;
//   border-top: 0;
// }

/* html.boxed #header-new.header-effect-shrink .header-body {
  max-width: 1200px;
} */

// html.boxed .main {
//   background-color: #fff;
//   overflow: hidden;
// }

// html.boxed section.section.section-footer {
//   padding-bottom: 100px;
// }

// html.boxed #footer {
//   margin-top: 0;
//   border-bottom: 4px solid #1a1c1e;
//   border-radius: 0 0 5px 5px;
// }

// html.boxed .footer-reveal {
//   max-width: 1200px;
//   left: auto !important;
// }

/* Responsive */
// @media (min-width: 1200px) {
//   html.boxed.sticky-header-active #header-new .header-body {
//     width: 100%;
//     max-width: 1200px;
//   }

//   html.boxed .footer-reveal {
//     bottom: 25px !important;
//   }
// }

// @media (max-width: 1199px) {
//   html.boxed .footer-reveal {
//     border-bottom: none !important;
//   }
// }

// @media (min-width: 992px) {
//   html.boxed #header-new .header-top.header-top-colored {
//     margin-top: -18px;
//     border-radius: 4px 4px 0 0;
//   }

//   html.boxed.sticky-header-active
//     #header-new:not(.header-effect-shrink)
//     .header-body {
//     position: fixed !important;
//     padding-left: 15px;
//     padding-right: 15px;
//   }

//   html.boxed.sticky-header-active
//     #header-new:not(.header-effect-shrink)
//     .header-nav-bar {
//     margin: 0 -15px -9px;
//   }
// }

// @media (max-width: 991px) {
//   html.boxed .body {
//     margin: 0 auto;
//     border-radius: 0;
//   }
// }

// @media (max-width: 767px) {
//   /* Boxed */
//   html.boxed {
//     background: none !important;
//   }
// }

// html.gap-outside .body {
//   margin-top: 25px;
//   margin-bottom: 25px;
// }

// html.gap-outside .main,
// html.gap-outside #footer {
//   margin-right: 25px;
//   margin-left: 25px;
// }

// html.gap-outside .slider-container {
//   width: 100% !important;
//   left: auto !important;
// }

// html.gap-outside #header-new:not(.side-header) {
//   margin-top: -25px;
// }

// html.gap-outside #header-new:not(.side-header) .header-body {
//   left: 0;
//   padding-right: 25px;
//   padding-left: 25px;
// }

// html.gap-outside #header-new:not(.side-header) .header-container:after {
//   content: none;
// }

// @media (max-width: 991px) {
//   html.gap-outside #header-new:not(.side-header) .header-nav-main:before {
//     width: calc(100vw - 50px);
//   }
// }

// html.gap-outside .sticky-wrapper {
//   left: 25px !important;
//   width: calc(100vw - 67px) !important;
// }

// html.gap-outside .pin-wrapper .sticky-wrapper {
//   left: 25px !important;
//   width: calc(100vw - 67px) !important;
// }

// html.gap-outside .pin-wrapper .sticky-wrapper .sticky-body {
//   width: calc(100vw - 67px) !important;
// }

// html.gap-outside .pin-wrapper .sticky-wrapper.sticky-effect-active {
//   top: -25px !important;
//   left: 0 !important;
//   width: 100% !important;
// }

// html.gap-outside
//   .pin-wrapper
//   .sticky-wrapper.sticky-effect-active
//   .sticky-body {
//   width: 100% !important;
// }

// html.gap-outside
//   .pin-wrapper
//   .sticky-wrapper.sticky-effect-active
//   .sticky-body
//   .container-fluid {
//   padding-left: 25px;
//   padding-right: 25px;
// }

.logo {
  width: 160px;
  height: 79px;
}

@media screen and (max-width: 500px) {
  .logo {
    width: 102px;
    height: 50px;
  }
  #search-button {
    padding: 15px 0;
  }
  #search-button:hover #search-input {
    width: 100px !important;
    visibility: visible;
  }
  #search-input {
    right: 20px;
  }
}

/* @media screen and (max-width: 480px) {
  .home-youtube {
    width: 300px;
    height: 225px;
    margin: 0 auto;
  }
} */
@media screen and (max-width: 990px) {
  .home-youtube {
    max-width: 80%;
    height: calc(80vw / 4 * 3);
    margin: 0 auto;
  }
}

@media screen and (min-width: 990px) {
  .home-youtube {
    width: 440px;
    height: 330px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .home-youtube {
    width: 520px;
    height: 390px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1960px) {
  .home-youtube {
    width: 720px;
    height: 540px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 2600px) {
  .home-youtube {
    width: 800px;
    height: 600px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 3200px) {
  .home-youtube {
    width: 1200px;
    height: 900px;
    margin: 0 auto;
  }
}

.form-geoip {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.geoip-input-item {
  height: 4em;
  width: 70%;
}

.geoip-input-item input {
  height: 100%;
  font-size: 1.4em;
}

.geoip-button-group {
  width: 30%;
  height: 4em;
  display: flex;
}

.geoip-button-item {
  height: 100%;
  width: 50%;
  font-size: 1em !important;
}

.geoip-input-item label {
  overflow: hidden;
  font-size: 0.9em;
  line-height: 1em;
}

@media (max-width: 566px) {
  .geoip-input-item {
    width: 100%;
  }

  .geoip-input-item label {
    font-size: 0.5em;
  }
  .geoip-button-group {
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5em;
  }
  .geoip-button-item {
    width: 100%;
    height: auto;
  }
}

/* audit */
.audit-img {
  top: 20%;
  border-radius: 50%;
  overflow: hidden;
  width: 1000px;
  height: 1000px;
  opacity: 0.1;
  right: 0;
}

@media screen and (max-width: 762px) {
  .audit-img {
    width: 600px;
    height: 600px;
  }
}

@media screen and (max-width: 480px) {
  .audit-img {
    width: 400px;
    height: 400px;
  }
}

/* extended search bar */

.search-bar {
  /* border: 0.1em solid #ff6600; */
  display: flex;
  padding: 0.1rem;
  /* border-radius: .3em; */
  height: 2.5em;
  width: 2.5em;
  position: relative;
  transition: width 800ms cubic-bezier(0.18, 0.89, 0.32, 1.2);
  align-items: center;
}

.search-bar__input {
  flex-grow: 1;
  font-size: 1.1em;
  padding: 0 0.5em;
  border: 0;
  position: absolute;
  background: transparent;
  top: 0;
  bottom: 0;
  left: 0;
  line-height: 2.2em;
  opacity: 0;
  cursor: pointer;
}

.search-bar .invalid-feedback {
  opacity: 0;
  display: none;
  position: absolute;
  top: 30px;
}

.search-bar__input:focus {
  outline: 0;
}



.search-bar__button {
  color: #002345;
  font-size: 1em;
  cursor: pointer;
  border: 0;
  background: transparent;
  /* border-radius: 10%; */
  width: 2em;
  height: 2em;
  margin-left: auto;
  transition: background 150ms ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar:focus-within {
  width: 13em;
  border-bottom: 0.1em solid #ff6600;
  
}

.search-bar:focus-within .search-bar__input {
  opacity: 1;
  cursor: initial;
  // z-index: -1;
  width: calc(100% - 2em);
  color: rgb(46, 46, 46) !important;
}

.search-bar:focus-within .invalid-feedback {
  opacity: 1;
  display: block;
}

.search-bar:focus-within .search-bar__button {
  /* background: #002345; */
  background: transparent;
  color: #ff6600;
}

.search-bar:focus-within .search-bar__button:focus,
.search-bar:focus-within .search-bar__button:hover {
  outline: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}

.search-bar__input::placeholder {
  color: #000 !important;
}

.search-bar__button:-ms-input-placeholder {
  color: #000 !important;
}

.search-bar__button::-ms-input-placeholder {
  color: #000 !important;
}


@media screen and (max-width: 480px) {
  .search-bar__input {
    right: 0;
    font-size: 0.875em;
  }
}

@media screen and (min-width: 2400px) {
  .search-bar__input {
    font-size: 1.2em;
  }
}
/* hompe page img */

.button-nooutline {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.button-nooutline:focus {
  outline: none;
}

.meter {
  justify-content: space-around;
  border: 1px solid rgb(196, 196, 196);
  height: 5em;
}

.secrets {
  height: 3em;
  background-color: #457b9d;
  box-sizing: border-box;
}

.secrets:hover {
  background-color: #2d5f7e;
}

.secret-password {
  position: relative;
  text-align: center;
  overflow-wrap: break-word;
  word-break: break-word;
  font-weight: bold;
  line-height: 1.2;
  padding: 0.1em 0.2em;
  font-size: 1.2em;
  border-radius: 0.2rem;
  transition: all 300ms ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.secret-password:hover {
  cursor: copy;
}

.secret-password::before {
  content: "Copied!";
  font-weight: bold;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms ease-in-out 0s;
}

.notify {
  color: #ffff;
}
.notify span {
  opacity: 0;
}

.notify::before {
  opacity: 1;
}

.geoip-copy {
  position: relative;
  overflow-wrap: break-word;
  word-break: break-word;
  border-radius: 0.2rem;
  transition: all 300ms ease 0s;
}

.geoip-copy:hover {
  cursor: copy;
}

.geoip-copy::before {
  content: "Copied!";
  font-weight: bold;
  width: 100%;
  height: 100%;
  position: absolute;
  color: #ff6600;
  top: 15%;
  left: 25%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms ease-in-out 0s;
}

.notify-geo {
  color: rgb(0, 0, 0);
}
.notify-geo span {
  opacity: 0;
}

.notify-geo::before {
  opacity: 1;
}

/* caret svg */

.caret-down {
  transition: all ease-in-out 0.4s;
}

.rotate__up {
  transform: rotate(180deg);
}

/* range slider */

.range {
  display: flex;
  align-items: center;
}

.range__counter {
  display: flex;
  align-self: center;
  justify-items: center;
  padding: 0.1em 0;
}

form,
input,
.range__input,
.range__counter-sr {
  width: 100%;
}
.form-range {
  padding: 0.75em 0;
  max-width: 30em;
}
label {
  font-weight: bold;
}
.range:not(:last-child) {
  margin-bottom: 1.5em;
}
.range input[type="range"],
.range input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}
.range input[type="range"],
.range__input-fill {
  border-radius: 0.25em;
  height: 0.5em;
}
.range input[type="range"] {
  // background-color: #002345;
  display: block;
  margin: 0.5em 0;
  padding: 0;
}
.range input[type="range"]:focus {
  outline: transparent;
}
.range input[type="range"]::-webkit-slider-thumb {
  background-color: #ff6600;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transition: background 0.1s linear;
  width: 1.5em;
  height: 1.5em;
  z-index: 1;
}
.range input[type="range"]::-moz-range-thumb {
  background-color: #ff6600;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transform: translateZ(1px);
  transition: background-color 0.1s linear;
  width: 1.5em;
  height: 1.5em;
  z-index: 1;
}
.range input[type="range"]::-moz-focus-outer {
  border: 0;
}
.range__input,
.range__input-fill,
.range__counter-column,
.range__counter-digit {
  display: block;
}
.range__input,
.range__counter {
  position: relative;
}
.range__input {
  margin-right: 0.375em;
}
.range__input:active input[type="range"]::-webkit-slider-thumb,
.range input[type="range"]:focus::-webkit-slider-thumb,
.range input[type="range"]::-webkit-slider-thumb:hover {
  // background-color: #002345;
  background-color: #ffffff;
}
.range__input:active input[type="range"]::-moz-range-thumb,
.range input[type="range"]:focus::-moz-range-thumb,
.range input[type="range"]::-moz-range-thumb:hover {
  background-color: #ff6600;
}
.range__input-fill,
.range__counter-sr {
  position: absolute;
  left: 0;
}
.range__input-fill {
  background-color: #ff6600;
  pointer-events: none;
  top: calc(50% - 0.25em);
}
.range__counter,
.range__counter-digit {
  height: 1.5em;
}
.range__counter {
  margin: auto 0;
  overflow: hidden;
  text-align: center;
}
.range__counter-sr {
  color: white;
  letter-spacing: 0.06em;
  top: 0;
  text-align: right;
  z-index: 1;
}
.range__counter-column {
  transition: transform 0.25s ease-in-out;
  width: 0.66em;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.range__counter-column--pause {
  transition: none;
}

.btn:disabled {
  cursor: not-allowed;
}

/* modal */

.modal {
  margin-top: 10%;
}

.modal-dialog {
  max-width: 800px;
  margin: 2em auto;
}

@media screen and (max-width: 960px) {
  .modal {
    margin-top: 30%;
  }
  .modal-dialog {
    max-width: 80%;
    margin: 2em auto;
  }
}

@media screen and (min-width: 1800px) {
  .modal-dialog {
    max-width: 1000px;
    margin: 2em auto;
  }
}

@media screen and (min-width: 2800px) {
  .modal-dialog {
    max-width: 1600px;
    margin: 2em auto;
  }
}

.modal-body {
  position: relative;
  padding: 0px;
}

.close {
  position: absolute;
  right: -.8em;
  top: 0;
  z-index: 1050;
  font-size: 2.5em;
  font-weight: normal;
  color: #fff;
  background-color: #ff6600 !important;
  opacity: 1;
}

.close:hover{
  color: #000;
  background-color: #fff;

}

@media screen and (max-width: 500px) {
  .text_align_center {
    text-align: center;
    align-self: center;
  }
}

.contact-form-home {
  z-index: 100;
  position: fixed;
  bottom: 2em;
  left: 2em;
}

@media screen and (max-width: 991px) {
  .contact-form-home {
    bottom: 0.5em;
    left: 0.5em;
  }
}

.text-justified {
  text-align: justify;
}

.mail-button {
  background-color: #ff6600;
  transition: all 0.5s ease-in-out;
}

.mail-button:hover {
  background-color: #002345;
  color: white;
  font-weight: bold;
  font-size: 2em;
}

.mail-button:hover .mail-at-home {
  display: block;
  opacity: 1;
  width: auto;
}

.mail-at-home {
  display: none;
  opacity: 0;
  width: 0;
}

.whychoose-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (max-width: 991px) {
  .item-mega-menu {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 566px) {
  .whychoose-item {
    align-items: center;
  }
  .whychoose-item > h4, .whychoose-item > p {
    text-align: center;
  }
}

.iso-27001 .pathOne {
  cursor: pointer;
  margin-bottom: 1em;
}

.iso-27001:hover .pathOne {
  fill: #ff6600;
}



.pathOne{
  fill: none;
  stroke: #002345;
  stroke-miterlimit: 10;
  stroke-width: 3px;
  transition: all ease-in-out .5s;
}



.lang-active {
  color: #ff6600;
}







