@supports(-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'],
    input[type='radio'] {
      --active: #ff6600;
      --active-inner: #fff;
      --focus: 2px rgba(39, 94, 254, .3);
      --border: #BBC1E1;
      --border-hover: #275EFE;
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: .9;
        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
        & + label {
          cursor: not-allowed;
        }
      }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      &:not(.switch) {
        width: 21px;
        &:after {
          opacity: var(--o, 0);
        }
        &:checked {
          --o: 1;
        }
      }
      & + label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
      }
    }
    input[type='checkbox'] {
      &:not(.switch) {
        border-radius: 7px;
        &:after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 7px;
          top: 4px;
          transform: rotate(var(--r, 20deg));
        }
        &:checked {
          --r: 43deg;
        }
      }
      &.switch {
        width: 38px;
        border-radius: 11px;
        &:after {
          left: 2px;
          top: 2px;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          background: var(--ab, var(--border));
          transform: translateX(var(--x, 0));
        }
        &:checked {
          --ab: var(--active-inner);
          --x: 17px;
        }
        &:disabled {
          &:not(:checked) {
            &:after {
              opacity: .6;
            }
          }
        }
      }
    }
    input[type='radio'] {
      border-radius: 50%;
      &:after {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 0;
        transform: scale(var(--s, .7));
      }
      &:checked {
        --s: .5;
      }
    }
  }
  
  

  .img-pentest-width{
    width: 400px;
  }
  .img-iso-width{
    width: 300px;
  }
  .img-consultancy{
    width: 300px;
  }
  .img-small-width{
    width: 240px;
    & .tscm{
      height: 100%;
    }
  }

  @media screen and (max-width: 566px){
    .img-pentest-width{
      width: 90%;
      margin: auto;
      float: none !important;
    }
    .img-iso-width{
      width: 90%;
      margin: auto;
      float: none !important;
    }
    .img-small-width{
      width: 80%;
      margin: auto;
      float: none !important;

      & .tscm{
        height: 80%;
      }
      
    }
    .img-consultancy{
      width: 80%;
    }

  }

  @media screen and (min-width: 1366px){
    .img-pentest-width{
      width: 500px;
      
    }
    .img-iso-width{
      width: 400px;
      
    }
    .img-small-width{
      width: 300px;
    }
    .img-consultancy{
      width: 400px;
    }
  }

  @media screen and (min-width: 1940px){
    .img-pentest-width{
      width: 600px;
    }
    .img-iso-width{
      width: 500px;
    }
    .img-small-width{
      width: 400px;
    }
    .img-consultancy{
      width: 400px;
    }
  }

  @media screen and (min-width: 2600px){
    .img-pentest-width{
      width: 800px;
   
    }
    .img-iso-width{
      width: 600px;
   
    }
    .img-small-width{
      width: 500px;
    }
  }

/* Add here all your CSS customizations */


.bg-sub-menu{
	background-color: #F95738;
}

.bg-info-geo{
    background-color: rgb(94, 144, 167);
}

.process-item{
    text-decoration: none;
    color: #002345; 
}
.process-item-link{
    color: #002345;
    text-decoration: none;
}

.process-item-topnav{
    text-decoration: none;
    color: #fff; 
}


.process-item:hover{
    text-decoration: none;
    color: #ff6600; 
}
.process-item-topnav:hover{
    text-decoration: none;
    color: #fff; 
}

.process-item-topnav:hover{
    color: #ff6600; 
}

.consult-item:hover {
    text-decoration: none;
    color: #ff6600; 
}

.consult-item:hover .consult-item-pic{
    transform: scaleX(1.02);
}

@media (min-width: 576px) {
    .container-fluid {
            padding-left: 3%;
            padding-right: 3%;
    }
}

@media (min-width: 1224px) {
	.container {
		max-width: 80%
	}
}







.section-concept p,
.custom-text-color-1 {
    color: rgba(255, 255, 255, 0.6) !important;
}

.color-inherit {
    color: inherit !important;
}

.custom-header-bar:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    width: 45vw;
    background-image: linear-gradient(150deg, #d3651b 0%, #db6618 30%, #e6650f 70%, #ff6600 100%);
    border-radius: 4px;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.06);
}

@media (max-width: 1200px) {
    .custom-header-bar:before {
            width: 110vw;
    }
}

.backdrop_def {
  background-color: #000000;
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  width: 100%;
  height: auto;
  display: none;
  transition: background .3s ease-in;
  &.open{
    display: block;
    z-index: 1050;
    opacity: .6;
  }
}


/* Simple Post List */
ul.simple-post-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.simple-post-list li {
	border-bottom: 1px dotted #E2E2E2;
	padding: 15px 0;
}

ul.simple-post-list li::after {
	clear: both;
	content: "";
	display: block;
}

ul.simple-post-list li:last-child {
	border-bottom: 0;
}

ul.simple-post-list .post-image {
	float: left;
	margin-right: 12px;
}

ul.simple-post-list .post-meta {
	color: #888;
	font-size: 0.8em;
}

ul.simple-post-list .post-info {
	line-height: 20px;
}

