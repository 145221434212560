.text-color-primary,
.text-primary {
  color: #002345 !important;
  
}

.text-nav-primary {
  color: #002345 !important;
  .caret-right{
    fill: #002345 !important;
  }
}

.text-nav-primary:hover {
  color: #ff6600 !important;
  .caret-right{
    fill: #ff6600 !important;
  }
}

.caret-right-button{
  fill: #ffffff !important;
}



.text-color-hover-primary:hover,
.text-hover-primary:hover {
  color: #002345 !important;
}

.text-color-secondary,
.text-secondary {
  color: #ff6600 !important;
}

.text-color-hover-secondary:hover,
.text-hover-secondary:hover {
  color: #ff6600 !important;
}

.text-color-tertiary,
.text-tertiary {
  color: #6d7275 !important;
}

.text-color-hover-tertiary:hover,
.text-hover-tertiary:hover {
  color: #6d7275 !important;
}

.text-color-quaternary,
.text-quaternary {
  color: #000 !important;
}

.text-color-hover-quaternary:hover,
.text-hover-quaternary:hover {
  color: #000 !important;
}

.text-color-dark,
.text-dark {
  color: #212529 !important;
}

.text-color-hover-dark:hover,
.text-hover-dark:hover {
  color: #212529 !important;
}

.text-color-light,
.text-light {
  color: #fff !important;
}

.text-color-hover-light:hover,
.text-hover-light:hover {
  color: #fff !important;
}

.bg-color-primary,
.bg-primary {
  background-color: #002345 !important;
}

.bg-color-hover-primary:hover,
.bg-hover-primary:hover {
  background-color: #002345 !important;
}

.bg-color-after-primary:after {
  background-color: #002345 !important;
}

.bg-color-hover-after-primary:after:hover {
  background-color: #002345 !important;
}

.bg-color-before-primary:before {
  background-color: #002345 !important;
}

.bg-color-hover-before-primary:before:hover {
  background-color: #002345 !important;
}

.bg-color-secondary,
.bg-secondary {
  background-color: #ff6600 !important;
}

.bg-color-hover-secondary:hover,
.bg-hover-secondary:hover {
  background-color: #ff6600 !important;
}

.bg-color-after-secondary:after {
  background-color: #ff6600 !important;
}

.bg-color-hover-after-secondary:after:hover {
  background-color: #ff6600 !important;
}

.bg-color-before-secondary:before {
  background-color: #ff6600 !important;
}

.bg-color-hover-before-secondary:before:hover {
  background-color: #ff6600 !important;
}

.bg-color-tertiary,
.bg-tertiary {
  background-color: #6d7275 !important;
}

.bg-color-hover-tertiary:hover,
.bg-hover-tertiary:hover {
  background-color: #6d7275 !important;
}

.bg-color-after-tertiary:after {
  background-color: #6d7275 !important;
}

.bg-color-hover-after-tertiary:after:hover {
  background-color: #6d7275 !important;
}

.bg-color-before-tertiary:before {
  background-color: #6d7275 !important;
}

.bg-color-hover-before-tertiary:before:hover {
  background-color: #6d7275 !important;
}

.bg-color-quaternary,
.bg-quaternary {
  background-color: #eee !important;
}

.bg-color-hover-quaternary:hover,
.bg-hover-quaternary:hover {
  background-color: #d1d1d1 !important;
}

.bg-color-after-quaternary:after {
  background-color: #d1d1d1 !important;
}

.bg-color-hover-after-quaternary:after:hover {
  background-color: #d1d1d1 !important;
}

.bg-color-before-quaternary:before {
  background-color: #d1d1d1 !important;
}

.bg-color-hover-before-quaternary:before:hover {
  background-color: #d1d1d1 !important;
}

.bg-color-dark,
.bg-dark {
  background-color: #212529 !important;
}

.bg-color-hover-dark:hover,
.bg-hover-dark:hover {
  background-color: #212529 !important;
}

.bg-color-after-dark:after {
  background-color: #212529 !important;
}

.bg-color-hover-after-dark:after:hover {
  background-color: #212529 !important;
}

.bg-color-before-dark:before {
  background-color: #212529 !important;
}

.bg-color-hover-before-dark:before:hover {
  background-color: #212529 !important;
}

.bg-color-light,
.bg-light {
  background-color: #fff !important;
}

.bg-color-hover-light:hover,
.bg-hover-light:hover {
  background-color: #fff !important;
}

.bg-color-after-light:after {
  background-color: #fff !important;
}

.bg-color-hover-after-light:after:hover {
  background-color: #fff !important;
}

.bg-color-before-light:before {
  background-color: #fff !important;
}

.bg-color-hover-before-light:before:hover {
  background-color: #fff !important;
}

.bg-gradient {
  background: linear-gradient(135deg, #002345 0%, #ff6600 80%);
}


.border-color-primary {
  border-color: #002345 !important;
}

.border-color-hover-primary:hover {
  border-color: #002345 !important;
}

.border-color-secondary {
  border-color: #ff6600 !important;
}

.border-color-hover-secondary:hover {
  border-color: #ff6600 !important;
}

.border-color-tertiary {
  border-color: #6d7275 !important;
}

.border-color-hover-tertiary:hover {
  border-color: #6d7275 !important;
}

.border-color-quaternary {
  border-color: #000 !important;
}

.border-color-hover-quaternary:hover {
  border-color: #000 !important;
}

.border-color-dark {
  border-color: #212529 !important;
}

.border-color-hover-dark:hover {
  border-color: #212529 !important;
}

.border-color-light {
  border-color: #fff !important;
}

.border-color-hover-light:hover {
  border-color: #fff !important;
}

.alternative-font {
  color: #002345;
}

.box-shadow-1-primary:before {
  box-shadow: 0 30px 90px #002345 !important;
}

.box-shadow-1-secondary:before {
  box-shadow: 0 30px 90px #ff6600 !important;
}

.box-shadow-1-tertiary:before {
  box-shadow: 0 30px 90px #6d7275 !important;
}

.box-shadow-1-quaternary:before {
  box-shadow: 0 30px 90px #002345 !important;
}

.box-shadow-1-dark:before {
  box-shadow: 0 30px 90px #212529 !important;
}

.box-shadow-1-light:before {
  box-shadow: 0 30px 90px #fff !important;
}

.blockquote-primary {
  border-color: #002345 !important;
}

.blockquote-secondary {
  border-color: #ff6600 !important;
}

.blockquote-tertiary {
  border-color: #6d7275 !important;
}

.blockquote-quaternary {
  border-color: #002345 !important;
}

.blockquote-dark {
  border-color: #212529 !important;
}

.blockquote-light {
  border-color: #fff !important;
}


.nav-color-primary nav > ul > li > a {
  color: #002345 !important;
}

.nav-color-primary:not(.header-nav-main-dropdown-arrow)
  nav
  > ul
  > li
  > a:before {
  background-color: #002345 !important;
}

.nav-color-primary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #002345 !important;
}

.nav-color-secondary nav > ul > li > a {
  color: #ff6600 !important;
}

.nav-color-secondary:not(.header-nav-main-dropdown-arrow)
  nav
  > ul
  > li
  > a:before {
  background-color: #ff6600 !important;
}

.nav-color-secondary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #ff6600 !important;
}

.nav-color-tertiary nav > ul > li > a {
  color: #6d7275 !important;
}

.nav-color-tertiary:not(.header-nav-main-dropdown-arrow)
  nav
  > ul
  > li
  > a:before {
  background-color: #6d7275 !important;
}

.nav-color-tertiary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #6d7275 !important;
}

.nav-color-quaternary nav > ul > li > a {
  color: #000 !important;
}

.nav-color-quaternary:not(.header-nav-main-dropdown-arrow)
  nav
  > ul
  > li
  > a:before {
  background-color: #000 !important;
}

.nav-color-quaternary:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #000 !important;
}

.nav-color-dark nav > ul > li > a {
  color: #212529 !important;
}

.nav-color-dark:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
  background-color: #212529 !important;
}

.nav-color-dark:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #212529 !important;
}

.nav-color-light nav > ul > li > a {
  color: #fff !important;
}

.nav-color-light:not(.header-nav-main-dropdown-arrow) nav > ul > li > a:before {
  background-color: #fff !important;
}

.nav-color-light:not(.header-nav-main-dropdown-arrow) nav > ul > li ul {
  border-top-color: #fff !important;
}

.nav-pills > li.active > a,
.nav-pills .nav-link.active {
  background-color: #002345;
}

.nav-pills > li.active > a:hover,
.nav-pills .nav-link.active:hover,
.nav-pills > li.active > a:focus,
.nav-pills .nav-link.active:focus {
  background-color: #002345;
}

.nav-active-style-1 > li > a:hover,
.nav-active-style-1 > li > a:focus,
.nav-active-style-1 > li > a.active {
  border-bottom-color: #002345;
}

.nav-pills-primary a {
  color: #002345;
}

.nav-pills-primary a:hover {
  color: #001e47;
}

.nav-pills-primary a:focus {
  color: #001e47;
}

.nav-pills-primary a:active {
  color: #001e47;
}

.nav-pills-primary .nav-link.active,
.nav-pills-primary > li.active > a {
  background-color: #002345;
}

.nav-pills-primary .nav-link.active:hover,
.nav-pills-primary > li.active > a:hover,
.nav-pills-primary .nav-link.active:focus,
.nav-pills-primary > li.active > a:focus {
  background-color: #002345;
}

.nav-pills-secondary a {
  color: #ff6600;
}

.nav-pills-secondary a:hover {
  color: #ffffff;
}

.nav-pills-secondary a:focus {
  color: #ffffff;
}

.nav-pills-secondary a:active {
  color: #ffdbd9;
}

.nav-pills-secondary .nav-link.active,
.nav-pills-secondary > li.active > a {
  background-color: #ff6600;
}

.nav-pills-secondary .nav-link.active:hover,
.nav-pills-secondary > li.active > a:hover,
.nav-pills-secondary .nav-link.active:focus,
.nav-pills-secondary > li.active > a:focus {
  background-color: #ff6600;
}


.section-scroll-dots-navigation.scroll-dots-navigation-colored
  > ul
  > li
  > a:before {
  background: #002345;
}




.btn-link {
  color: #002345;
}

.btn-link:hover {
  color: #001e47;
}

.btn-link:active {
  color: #f24e42;
}

.btn-primary {
  background-color: #002345;
  color: #fff;
}

.btn-primary:hover,
.btn-primary.hover {
  background-color: #ff6600;
  color: #fff;
}

.btn-primary:focus,
.btn-primary.focus {
  background-color: #4b4b4b;
  color: #fff;
  box-shadow: 0 0 0 3px rgba(244, 100, 90, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #002345;
  cursor: not-allowed;
}

.btn-primary:active,
.btn-primary.active {
  background-color: #4b4b4b !important;
  border: none;
}

.btn-primary-scale-2 {
  background-color: #f1372a;
  border-color: #f1372a #f1372a #da1c0e;
  color: #fff;
}

.btn-primary-scale-2:hover,
.btn-primary-scale-2.hover {
  background-color: #f3594e;
  border-color: #002345 #002345 #f1372a;
  color: #fff;
}

.btn-primary-scale-2:focus,
.btn-primary-scale-2.focus {
  background-color: #e51d0f;
  border-color: #da1c0e #da1c0e #aa160b;
  color: #fff;
  box-shadow: 0 0 0 3px rgba(241, 55, 42, 0.5);
}

.btn-primary-scale-2.disabled,
.btn-primary-scale-2:disabled {
  background-color: #f1372a;
  border-color: #f1372a #f1372a #da1c0e;
}

.btn-primary-scale-2:active,
.btn-primary-scale-2.active {
  background-color: #e51d0f !important;
  background-image: none !important;
  border-color: #da1c0e #da1c0e #aa160b !important;
}

.show > .btn-primary.dropdown-toggle,
.show > .btn-primary-scale-2.dropdown-toggle {
  background-color: #f24236 !important;
  background-image: none !important;
  border-color: #f1372a #f1372a #da1c0e !important;
}

.btn-secondary {
  background-color: #ff6600;
  border-color: #ff6600 #ff6600 #ffc4bf;
  color: #777;
}

.btn-secondary:hover,
.btn-secondary.hover {
  background-color: #ffffff;
  border-color: #ffffff #ffffff #ff6600;
  color: #777;
}

.btn-secondary:focus,
.btn-secondary.focus {
  background-color: #ffd0cc;
  border-color: #ffc4bf #ffc4bf #ff958c;
  color: #777;
  box-shadow: 0 0 0 3px rgba(255, 243, 242, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #ff6600;
  border-color: #ff6600 #ff6600 #ffc4bf;
}

.btn-secondary:active,
.btn-secondary.active {
  background-color: #ffd0cc !important;
  background-image: none !important;
  border-color: #ffc4bf #ffc4bf #ff958c !important;
}

.btn-secondary-scale-2 {
  background-color: #ffc4bf;
  border-color: #ffc4bf #ffc4bf #ff958c;
  color: #777;
}

.btn-secondary-scale-2:hover,
.btn-secondary-scale-2.hover {
  background-color: #ffe7e5;
  border-color: #ff6600 #ff6600 #ffc4bf;
  color: #777;
}

.btn-secondary-scale-2:focus,
.btn-secondary-scale-2.focus {
  background-color: #ffa199;
  border-color: #ff958c #ff958c #ff6659;
  color: #777;
  box-shadow: 0 0 0 3px rgba(255, 196, 191, 0.5);
}

.btn-secondary-scale-2.disabled,
.btn-secondary-scale-2:disabled {
  background-color: #ffc4bf;
  border-color: #ffc4bf #ffc4bf #ff958c;
}

.btn-secondary-scale-2:active,
.btn-secondary-scale-2.active {
  background-color: #ffa199 !important;
  background-image: none !important;
  border-color: #ff958c #ff958c #ff6659 !important;
}

.show > .btn-secondary.dropdown-toggle,
.show > .btn-secondary-scale-2.dropdown-toggle {
  background-color: #ffd0cc !important;
  background-image: none !important;
  border-color: #ffc4bf #ffc4bf #ff958c !important;
}

.btn-tertiary {
  background-color: #6d7275;
  border-color: #6d7275 #6d7275 #0f1114;
  color: #fff;
}

.btn-tertiary:hover,
.btn-tertiary.hover {
  background-color: #343e48;
  border-color: #394550 #394550 #6d7275;
  color: #fff;
}

.btn-tertiary:focus,
.btn-tertiary.focus {
  background-color: #14181c;
  border-color: #0f1114 #0f1114 #000000;
  color: #fff;
  box-shadow: 0 0 0 3px rgba(36, 43, 50, 0.5);
}

.btn-tertiary.disabled,
.btn-tertiary:disabled {
  background-color: #6d7275;
  border-color: #6d7275 #6d7275 #0f1114;
}

.btn-tertiary:active,
.btn-tertiary.active {
  background-color: #14181c !important;
  background-image: none !important;
  border-color: #0f1114 #0f1114 #000000 !important;
}


.show > .btn-tertiary.dropdown-toggle{
  background-color: #14181c !important;
  background-image: none !important;
  border-color: #0f1114 #0f1114 #000000 !important;
}

.btn-quaternary {
  background-color: #000;
  border-color: #000 #000 #000000;
  color: #fff;
}

.btn-quaternary:hover,
.btn-quaternary.hover {
  background-color: #131313;
  border-color: #1a1a1a #1a1a1a #000;
  color: #fff;
}

.btn-quaternary:focus,
.btn-quaternary.focus {
  background-color: #000000;
  border-color: #000000 #000000 #000000;
  color: #fff;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
}

.btn-quaternary.disabled,
.btn-quaternary:disabled {
  background-color: #000;
  border-color: #000 #000 #000000;
}

.btn-quaternary:active,
.btn-quaternary.active {
  background-color: #000000 !important;
  background-image: none !important;
  border-color: #000000 #000000 #000000 !important;
}



.show > .btn-quaternary.dropdown-toggle{
  background-color: #000000 !important;
  background-image: none !important;
  border-color: #000000 #000000 #000000 !important;
}

.btn-dark {
  background-color: #212529;
  border-color: #212529 #212529 #0a0c0d;
  color: #fff;
}

.btn-dark:hover,
.btn-dark.hover {
  background-color: #32383e;
  border-color: #383f45 #383f45 #212529;
  color: #fff;
}

.btn-dark:focus,
.btn-dark.focus {
  background-color: #101214;
  border-color: #0a0c0d #0a0c0d #000000;
  color: #fff;
  box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #212529;
  border-color: #212529 #212529 #0a0c0d;
}

.btn-dark:active,
.btn-dark.active {
  background-color: #101214 !important;
  background-image: none !important;
  border-color: #0a0c0d #0a0c0d #000000 !important;
}

.show > .btn-dark.dropdown-toggle {
  background-color: #101214 !important;
  background-image: none !important;
  border-color: #0a0c0d #0a0c0d #000000 !important;
}

.btn-light {
  background-color: #fff;
  border-color: #fff #fff #e6e6e6;
  color: #777;
}

.btn-light:hover,
.btn-light.hover {
  background-color: #ffffff;
  border-color: #ffffff #ffffff #fff;
  color: #777;
}

.btn-light:focus,
.btn-light.focus {
  background-color: #ececec;
  border-color: #e6e6e6 #e6e6e6 #cccccc;
  color: #777;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #fff;
  border-color: #fff #fff #e6e6e6;
}

.btn-light:active,
.btn-light.active {
  background-color: #ececec !important;
  background-image: none !important;
  border-color: #e6e6e6 #e6e6e6 #cccccc !important;
}

.btn-light-scale-2 {
  background-color: #e6e6e6;
  border-color: #e6e6e6 #e6e6e6 #cccccc;
  color: #777;
}

.btn-light-scale-2:hover,
.btn-light-scale-2.hover {
  background-color: #f9f9f9;
  border-color: #ffffff #ffffff #e6e6e6;
  color: #777;
}

.btn-light-scale-2:focus,
.btn-light-scale-2.focus {
  background-color: #d2d2d2;
  border-color: #cccccc #cccccc #b3b3b3;
  color: #777;
  box-shadow: 0 0 0 3px rgba(230, 230, 230, 0.5);
}

.btn-light-scale-2.disabled,
.btn-light-scale-2:disabled {
  background-color: #e6e6e6;
  border-color: #e6e6e6 #e6e6e6 #cccccc;
}

.btn-light-scale-2:active,
.btn-light-scale-2.active {
  background-color: #d2d2d2 !important;
  background-image: none !important;
  border-color: #cccccc #cccccc #b3b3b3 !important;
}

.show > .btn-light.dropdown-toggle,
.show > .btn-light-scale-2.dropdown-toggle {
  background-color: #ececec !important;
  background-image: none !important;
  border-color: #e6e6e6 #e6e6e6 #cccccc !important;
}

.btn-outline.btn-primary {
  color: #002345;
  background-color: transparent;
  background-image: none;
  border-color: #002345;
}

.btn-outline.btn-primary:hover,
.btn-outline.btn-primary.hover {
  color: #fff;
  background-color: #002345;
  border-color: #002345;
}

.btn-outline.btn-primary:focus,
.btn-outline.btn-primary.focus {
  box-shadow: 0 0 0 3px rgba(244, 100, 90, 0.5);
}

.btn-outline.btn-primary.disabled,
.btn-outline.btn-primary:disabled {
  color: #002345;
  background-color: transparent;
}

.btn-outline.btn-primary:active,
.btn-outline.btn-primary.active {
  color: #fff !important;
  background-color: #002345 !important;
  border-color: #002345 !important;
}

.show > .btn-outline.btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #002345 !important;
  border-color: #002345 !important;
}

.btn-outline.btn-secondary {
  color: #ff6600;
  background-color: transparent;
  background-image: none;
  border-color: #ff6600;
}

.btn-outline.btn-secondary:hover,
.btn-outline.btn-secondary.hover {
  color: #777;
  background-color: #ff6600;
  border-color: #ff6600;
}

.btn-outline.btn-secondary:focus,
.btn-outline.btn-secondary.focus {
  box-shadow: 0 0 0 3px rgba(255, 243, 242, 0.5);
}

.btn-outline.btn-secondary.disabled,
.btn-outline.btn-secondary:disabled {
  color: #ff6600;
  background-color: transparent;
}

.btn-outline.btn-secondary:active,
.btn-outline.btn-secondary.active {
  color: #777 !important;
  background-color: #ff6600 !important;
  border-color: #ff6600 !important;
}

.show > .btn-outline.btn-secondary.dropdown-toggle {
  color: #777 !important;
  background-color: #ff6600 !important;
  border-color: #ff6600 !important;
}

.btn-outline.btn-tertiary {
  color: #6d7275;
  background-color: transparent;
  background-image: none;
  border-color: #6d7275;
}

.btn-outline.btn-tertiary:hover,
.btn-outline.btn-tertiary.hover {
  color: #fff;
  background-color: #6d7275;
  border-color: #6d7275;
}

.btn-outline.btn-tertiary:focus,
.btn-outline.btn-tertiary.focus {
  box-shadow: 0 0 0 3px rgba(36, 43, 50, 0.5);
}

.btn-outline.btn-tertiary.disabled,
.btn-outline.btn-tertiary:disabled {
  color: #6d7275;
  background-color: transparent;
}

.btn-outline.btn-tertiary:active,
.btn-outline.btn-tertiary.active {
  color: #fff !important;
  background-color: #6d7275 !important;
  border-color: #6d7275 !important;
}

.show > .btn-outline.btn-tertiary.dropdown-toggle {
  color: #fff !important;
  background-color: #6d7275 !important;
  border-color: #6d7275 !important;
}

.btn-outline.btn-quaternary {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #000;
}

.btn-outline.btn-quaternary:hover,
.btn-outline.btn-quaternary.hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline.btn-quaternary:focus,
.btn-outline.btn-quaternary.focus {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
}

.btn-outline.btn-quaternary.disabled,
.btn-outline.btn-quaternary:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline.btn-quaternary:active,
.btn-outline.btn-quaternary.active {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

.show > .btn-outline.btn-quaternary.dropdown-toggle {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

.btn-outline.btn-dark {
  color: #212529;
  background-color: transparent;
  background-image: none;
  border-color: #212529;
}

.btn-outline.btn-dark:hover,
.btn-outline.btn-dark.hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline.btn-dark:focus,
.btn-outline.btn-dark.focus {
  box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
}

.btn-outline.btn-dark.disabled,
.btn-outline.btn-dark:disabled {
  color: #212529;
  background-color: transparent;
}

.btn-outline.btn-dark:active,
.btn-outline.btn-dark.active {
  color: #fff !important;
  background-color: #212529 !important;
  border-color: #212529 !important;
}

.show > .btn-outline.btn-dark.dropdown-toggle {
  color: #fff !important;
  background-color: #212529 !important;
  border-color: #212529 !important;
}

.btn-outline.btn-light {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
}

.btn-outline.btn-light:hover,
.btn-outline.btn-light.hover {
  color: #777;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline.btn-light:focus,
.btn-outline.btn-light.focus {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.btn-outline.btn-light.disabled,
.btn-outline.btn-light:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline.btn-light:active,
.btn-outline.btn-light.active {
  color: #777 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.show > .btn-outline.btn-light.dropdown-toggle {
  color: #777 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}


.pagination > a,
.pagination > a:hover,
.pagination > a:focus,
.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #002345;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.pagination .page-item.active .page-link {
  background-color: #002345 !important;
  border-color: #002345;
}


.pagination > .active > a,
body.dark .pagination > .active > a {
  background-color: #002345;
  border-color: #002345 #002345 #f1372a;
  color: #fff;
}

.pagination > .active > a:hover,
body.dark .pagination > .active > a:hover,
.pagination > .active > a.hover,
body.dark .pagination > .active > a.hover {
  background-color: #f6867e;
  border-color: #f7918a #f7918a #002345;
  color: #fff;
}

.pagination > .active > a:focus,
body.dark .pagination > .active > a:focus,
.pagination > .active > a.focus,
body.dark .pagination > .active > a.focus {
  background-color: #f24236;
  border-color: #f1372a #f1372a #da1c0e;
  color: #fff;
  box-shadow: 0 0 0 3px rgba(244, 100, 90, 0.5);
}

.pagination > .active > a.disabled,
body.dark .pagination > .active > a.disabled,
.pagination > .active > a:disabled,
body.dark .pagination > .active > a:disabled {
  background-color: #002345;
  border-color: #002345 #002345 #f1372a;
}

.pagination > .active > a:active,
body.dark .pagination > .active > a:active,
.pagination > .active > a.active,
body.dark .pagination > .active > a.active {
  background-color: #f24236 !important;
  background-image: none !important;
  border-color: #f1372a #f1372a #da1c0e !important;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #002345;
}

.custom-checkbox-switch:checked {
  background-color: #002345;
  border-color: #002345;
}

.alert-primary {
  background-color: #002345;
  border-color: #f3574c;
  color: #fff;
}

.alert-primary .alert-link {
  color: #fff;
}

.alert-secondary {
  background-color: #ff6600;
  border-color: #ffe5e3;
  color: #777;
}

.alert-secondary .alert-link {
  color: #777;
}

.alert-tertiary {
  background-color: #6d7275;
  border-color: #1e2329;
  color: #fff;
}

.alert-tertiary .alert-link {
  color: #fff;
}

.alert-quaternary {
  background-color: #000;
  border-color: #000000;
  color: #fff;
}

.alert-quaternary .alert-link {
  color: #fff;
}

.alert-dark {
  background-color: #212529;
  border-color: #1a1d21;
  color: #fff;
}

.alert-dark .alert-link {
  color: #fff;
}

.alert-light {
  background-color: #fff;
  border-color: #f7f7f7;
  color: #777;
}

.alert-light .alert-link {
  color: #777;
}

.process .process-step-circle {
  border-color: #ff6600;
}

.process .process-step:hover .process-step-circle {
  background-color: #ff6600;
}

.process .process-step:hover .process-step-circle .process-step-circle-content {
  color: #fff !important;
}

.process.process-shapes .process-step-circle .process-step-circle-content,
.process.process-shapes .process-step-circle:before,
.process.process-shapes .process-step-circle:after {
  background-color: #002345;
}

.process-connecting-line .connecting-line {
  background: #002345;
}


section.section-primary {
  background-color: #002345 !important;
  border-color: #f24e42 !important;
}

section.section-primary h1,
section.section-primary h2,
section.section-primary h3,
section.section-primary h4,
section.section-primary h5,
section.section-primary h6 {
  color: #fff;
}

section.section-primary p {
  color: #e6e6e6;
}

section.section-primary-scale-2 {
  background-color: #f1372a !important;
  border-color: #ef2112 !important;
}

section.section-primary-scale-2
  .sort-source.sort-source-style-2
  > li.active
  > a:after {
  border-top-color: #f1372a;
}

section.section-secondary {
  background-color: #ff6600 !important;
  border-color: #ffdbd9 !important;
}

section.section-secondary h1,
section.section-secondary h2,
section.section-secondary h3,
section.section-secondary h4,
section.section-secondary h5,
section.section-secondary h6 {
  color: #777;
}

section.section-secondary p {
  color: #5e5e5e;
}

section.section-secondary-scale-2 {
  background-color: #ffc4bf !important;
  border-color: #ffaca5 !important;
}

section.section-secondary-scale-2
  .sort-source.sort-source-style-2
  > li.active
  > a:after {
  border-top-color: #ffc4bf;
}

section.section-tertiary {
  background-color: #6d7275 !important;
  border-color: #191e23 !important;
}

section.section-tertiary h1,
section.section-tertiary h2,
section.section-tertiary h3,
section.section-tertiary h4,
section.section-tertiary h5,
section.section-tertiary h6 {
  color: #fff;
}

section.section-tertiary p {
  color: #e6e6e6;
}

section.section-tertiary-scale-2 {
  background-color: #0f1114 !important;
  border-color: #040506 !important;
}

section.section-tertiary-scale-2
  .sort-source.sort-source-style-2
  > li.active
  > a:after {
  border-top-color: #0f1114;
}

section.section-quaternary {
  background-color: #000 !important;
  border-color: #000000 !important;
}

section.section-quaternary h1,
section.section-quaternary h2,
section.section-quaternary h3,
section.section-quaternary h4,
section.section-quaternary h5,
section.section-quaternary h6 {
  color: #fff;
}

section.section-quaternary p {
  color: #e6e6e6;
}

section.section-quaternary-scale-2 {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

section.section-quaternary-scale-2
  .sort-source.sort-source-style-2
  > li.active
  > a:after {
  border-top-color: #000000;
}

section.section-dark {
  background-color: #212529 !important;
  border-color: #16181b !important;
}

section.section-dark h1,
section.section-dark h2,
section.section-dark h3,
section.section-dark h4,
section.section-dark h5,
section.section-dark h6 {
  color: #fff;
}

section.section-dark p {
  color: #e6e6e6;
}

section.section-dark-scale-2 {
  background-color: #0a0c0d !important;
  border-color: #000000 !important;
}

section.section-dark-scale-2
  .sort-source.sort-source-style-2
  > li.active
  > a:after {
  border-top-color: #0a0c0d;
}

section.section-light {
  background-color: #fff !important;
  border-color: #f2f2f2 !important;
}

section.section-light h1,
section.section-light h2,
section.section-light h3,
section.section-light h4,
section.section-light h5,
section.section-light h6 {
  color: #777;
}

section.section-light p {
  color: #5e5e5e;
}

section.section-light-scale-2 {
  background-color: #e6e6e6 !important;
  border-color: #d9d9d9 !important;
}

section.section-light-scale-2
  .sort-source.sort-source-style-2
  > li.active
  > a:after {
  border-top-color: #e6e6e6;
}

section.section.section-background-half-primary-half-secondary {
  background: linear-gradient(to right, #002345 50%, #ff6600 50%);
}

section.section.section-overlay-opacity-gradient:before {
  background: linear-gradient(135deg, #002345 0%, #ff6600 80%);
}

.bg-color-light-scale-1 {
  background-color: #f7f7f7 !important;
}

.section.bg-color-light-scale-1,
.bg-color-light-scale-border-top-1 {
  border-top-color: #efefef !important;
}

.bg-color-light-scale-2 {
  background-color: #e8e8e8 !important;
}

.section.bg-color-light-scale-2,
.bg-color-light-scale-border-top-2 {
  border-top-color: #e0e0e0 !important;
}

.bg-color-light-scale-3 {
  background-color: #e0e0e0 !important;
}

.section.bg-color-light-scale-3,
.bg-color-light-scale-border-top-3 {
  border-top-color: #d8d8d8 !important;
}

.bg-color-light-scale-4 {
  background-color: #d8d8d8 !important;
}

.section.bg-color-light-scale-4,
.bg-color-light-scale-border-top-4 {
  border-top-color: #d1d1d1 !important;
}

.bg-color-light-scale-5 {
  background-color: #d1d1d1 !important;
}

.section.bg-color-light-scale-5,
.bg-color-light-scale-border-top-5 {
  border-top-color: #c9c9c9 !important;
}

.bg-color-light-scale-6 {
  background-color: #c9c9c9 !important;
}

.section.bg-color-light-scale-6,
.bg-color-light-scale-border-top-6 {
  border-top-color: #c1c1c1 !important;
}

.bg-color-light-scale-7 {
  background-color: #c1c1c1 !important;
}

.section.bg-color-light-scale-7,
.bg-color-light-scale-border-top-7 {
  border-top-color: #bababa !important;
}

.bg-color-light-scale-8 {
  background-color: #bababa !important;
}

.section.bg-color-light-scale-8,
.bg-color-light-scale-border-top-8 {
  border-top-color: #b2b2b2 !important;
}

.bg-color-light-scale-9 {
  background-color: #b2b2b2 !important;
}

.section.bg-color-light-scale-9,
.bg-color-light-scale-border-top-9 {
  border-top-color: #ababab !important;
}

.bg-color-dark-scale-1 {
  background-color: #212529 !important;
}

.section.bg-color-dark-scale-1,
.bg-color-dark-scale-border-top-1 {
  border-top-color: #1a1d21 !important;
}

.bg-color-dark-scale-2 {
  background-color: #1c2023 !important;
}

.section.bg-color-dark-scale-2,
.bg-color-dark-scale-border-top-2 {
  border-top-color: #16181b !important;
}

.bg-color-dark-scale-3 {
  background-color: #181b1e !important;
}

.section.bg-color-dark-scale-3,
.bg-color-dark-scale-border-top-3 {
  border-top-color: #111315 !important;
}

.bg-color-dark-scale-4 {
  background-color: #131618 !important;
}

.section.bg-color-dark-scale-4,
.bg-color-dark-scale-border-top-4 {
  border-top-color: #0d0e10 !important;
}

.bg-color-dark-scale-5 {
  background-color: #0f1112 !important;
}

.section.bg-color-dark-scale-5,
.bg-color-dark-scale-border-top-5 {
  border-top-color: #08090a !important;
}

.bg-color-dark-scale-6 {
  background-color: #0a0c0d !important;
}

.section.bg-color-dark-scale-6,
.bg-color-dark-scale-border-top-6 {
  border-top-color: #030404 !important;
}

.bg-color-dark-scale-7 {
  background-color: #060607 !important;
}

.section.bg-color-dark-scale-7,
.bg-color-dark-scale-border-top-7 {
  border-top-color: #000000 !important;
}

.bg-color-dark-scale-8 {
  background-color: #010101 !important;
}

.section.bg-color-dark-scale-8,
.bg-color-dark-scale-border-top-8 {
  border-top-color: #000000 !important;
}

.bg-color-dark-scale-9 {
  background-color: #000000 !important;
}

.section.bg-color-dark-scale-9,
.bg-color-dark-scale-border-top-9 {
  border-top-color: #000000 !important;
}

section.page-header .page-header-title-border {
  background-color: #002345 !important;
}

section.page-header.custom-product {
  background-color: #f24e42;
  border-top-color: #f35b50;
}

// .page-header-color.page-header-primary {
//   background-color: #002345;
//   border-bottom-color: #002345;
//   color: #fff;
// }

// .page-header-color.page-header-primary h1 {
//   color: #fff;
// }

// .page-header-color.page-header-primary h1 span {
//   color: #fff;
// }

// .page-header-color.page-header-primary a {
//   color: #fff;
// }

// .page-header-color.page-header-primary .breadcrumb > .active {
//   color: #fff;
// }

// .page-header-color.page-header-secondary {
//   background-color: #ff6600;
//   border-bottom-color: #ff6600;
//   color: #777;
// }

// .page-header-color.page-header-secondary h1 {
//   color: #777;
// }

// .page-header-color.page-header-secondary h1 span {
//   color: #777;
// }

// .page-header-color.page-header-secondary a {
//   color: #777;
// }

// .page-header-color.page-header-secondary .breadcrumb > .active {
//   color: #777;
// }

// .page-header-color.page-header-tertiary {
//   background-color: #6d7275;
//   border-bottom-color: #6d7275;
//   color: #fff;
// }

// .page-header-color.page-header-tertiary h1 {
//   color: #fff;
// }

// .page-header-color.page-header-tertiary h1 span {
//   color: #fff;
// }

// .page-header-color.page-header-tertiary a {
//   color: #fff;
// }

// .page-header-color.page-header-tertiary .breadcrumb > .active {
//   color: #fff;
// }

// .page-header-color.page-header-quaternary {
//   background-color: #000;
//   border-bottom-color: #000;
//   color: #fff;
// }

// .page-header-color.page-header-quaternary h1 {
//   color: #fff;
// }

// .page-header-color.page-header-quaternary h1 span {
//   color: #fff;
// }

// .page-header-color.page-header-quaternary a {
//   color: #fff;
// }

// .page-header-color.page-header-quaternary .breadcrumb > .active {
//   color: #fff;
// }

// .page-header-color.page-header-dark {
//   background-color: #212529;
//   border-bottom-color: #212529;
//   color: #fff;
// }

// .page-header-color.page-header-dark h1 {
//   color: #fff;
// }

// .page-header-color.page-header-dark h1 span {
//   color: #fff;
// }

// .page-header-color.page-header-dark a {
//   color: #fff;
// }

// .page-header-color.page-header-dark .breadcrumb > .active {
//   color: #fff;
// }

// .page-header-color.page-header-light {
//   background-color: #fff;
//   border-bottom-color: #fff;
//   color: #777;
// }

// .page-header-color.page-header-light h1 {
//   color: #777;
// }

// .page-header-color.page-header-light h1 span {
//   color: #777;
// }

// .page-header-color.page-header-light a {
//   color: #777;
// }

// .page-header-color.page-header-light .breadcrumb > .active {
//   color: #777;
// }

// .toggle-primary .toggle label,
// .toggle-primary .toggle .toggle-title {
//   color: #002345;
//   border-left-color: #002345;
//   border-right-color: #002345;
// }

// .toggle-primary .toggle.active > label,
// .toggle-primary .toggle.active > .toggle-title {
//   background-color: #002345;
//   border-color: #002345;
//   color: #fff;
// }

// .toggle-primary.toggle-simple .toggle > label:after,
// .toggle-primary.toggle-simple .toggle > .toggle-title:after {
//   background-color: #002345;
// }

// .toggle-primary.toggle-minimal .toggle.active > label,
// .toggle-primary.toggle-minimal .toggle.active > .toggle-title {
//   color: #002345;
// }

// .toggle-secondary .toggle label,
// .toggle-secondary .toggle .toggle-title {
//   color: #ff6600;
//   border-left-color: #ff6600;
//   border-right-color: #ff6600;
// }

// .toggle-secondary .toggle.active > label,
// .toggle-secondary .toggle.active > .toggle-title {
//   background-color: #ff6600;
//   border-color: #ff6600;
//   color: #777;
// }

// .toggle-secondary.toggle-simple .toggle > label:after,
// .toggle-secondary.toggle-simple .toggle > .toggle-title:after {
//   background-color: #ff6600;
// }

// .toggle-secondary.toggle-minimal .toggle.active > label,
// .toggle-secondary.toggle-minimal .toggle.active > .toggle-title {
//   color: #ff6600;
// }

// .toggle-tertiary .toggle label,
// .toggle-tertiary .toggle .toggle-title {
//   color: #6d7275;
//   border-left-color: #6d7275;
//   border-right-color: #6d7275;
// }

// .toggle-tertiary .toggle.active > label,
// .toggle-tertiary .toggle.active > .toggle-title {
//   background-color: #6d7275;
//   border-color: #6d7275;
//   color: #fff;
// }

// .toggle-tertiary.toggle-simple .toggle > label:after,
// .toggle-tertiary.toggle-simple .toggle > .toggle-title:after {
//   background-color: #6d7275;
// }

// .toggle-tertiary.toggle-minimal .toggle.active > label,
// .toggle-tertiary.toggle-minimal .toggle.active > .toggle-title {
//   color: #6d7275;
// }

// .toggle-quaternary .toggle label,
// .toggle-quaternary .toggle .toggle-title {
//   color: #000;
//   border-left-color: #000;
//   border-right-color: #000;
// }

// .toggle-quaternary .toggle.active > label,
// .toggle-quaternary .toggle.active > .toggle-title {
//   background-color: #000;
//   border-color: #000;
//   color: #fff;
// }

// .toggle-quaternary.toggle-simple .toggle > label:after,
// .toggle-quaternary.toggle-simple .toggle > .toggle-title:after {
//   background-color: #000;
// }

// .toggle-quaternary.toggle-minimal .toggle.active > label,
// .toggle-quaternary.toggle-minimal .toggle.active > .toggle-title {
//   color: #000;
// }

// .toggle-dark .toggle label,
// .toggle-dark .toggle .toggle-title {
//   color: #212529;
//   border-left-color: #212529;
//   border-right-color: #212529;
// }

// .toggle-dark .toggle.active > label,
// .toggle-dark .toggle.active > .toggle-title {
//   background-color: #212529;
//   border-color: #212529;
//   color: #fff;
// }

// .toggle-dark.toggle-simple .toggle > label:after,
// .toggle-dark.toggle-simple .toggle > .toggle-title:after {
//   background-color: #212529;
// }

// .toggle-dark.toggle-minimal .toggle.active > label,
// .toggle-dark.toggle-minimal .toggle.active > .toggle-title {
//   color: #212529;
// }

// .toggle-light .toggle label,
// .toggle-light .toggle .toggle-title {
//   color: #fff;
//   border-left-color: #fff;
//   border-right-color: #fff;
// }

// .toggle-light .toggle.active > label,
// .toggle-light .toggle.active > .toggle-title {
//   background-color: #fff;
//   border-color: #fff;
//   color: #777;
// }

// .toggle-light.toggle-simple .toggle > label:after,
// .toggle-light.toggle-simple .toggle > .toggle-title:after {
//   background-color: #fff;
// }

// .toggle-light.toggle-minimal .toggle.active > label,
// .toggle-light.toggle-minimal .toggle.active > .toggle-title {
//   color: #fff;
// }

// .thumb-info .thumb-info-type,
// .thumb-info .thumb-info-action-icon,
// .thumb-info-social-icons a,
// .thumbnail .zoom,
// .img-thumbnail .zoom,
// .thumb-info-ribbon {
//   background-color: #002345;
// }

// .thumb-info .thumb-info-action-icon-primary {
//   background-color: #002345;
// }

// .thumb-info .thumb-info-action-icon-primary i {
//   color: #fff;
// }

// .thumb-info .thumb-info-action-icon-secondary {
//   background-color: #ff6600;
// }

// .thumb-info .thumb-info-action-icon-secondary i {
//   color: #777;
// }

// .thumb-info .thumb-info-action-icon-tertiary {
//   background-color: #6d7275;
// }

// .thumb-info .thumb-info-action-icon-tertiary i {
//   color: #fff;
// }

// .thumb-info .thumb-info-action-icon-quaternary {
//   background-color: #000;
// }

// .thumb-info .thumb-info-action-icon-quaternary i {
//   color: #fff;
// }

// .thumb-info .thumb-info-action-icon-dark {
//   background-color: #212529;
// }

// .thumb-info .thumb-info-action-icon-dark i {
//   color: #fff;
// }

// .thumb-info .thumb-info-action-icon-light {
//   background-color: #fff;
// }

// .thumb-info .thumb-info-action-icon-light i {
//   color: #777;
// }

// .thumb-info-ribbon:before {
//   border-right-color: #ef2112;
//   border-left-color: #ef2112;
// }

// .thumb-info.thumb-info-block-primary .thumb-info-wrapper:before {
//   background: rgba(244, 100, 90, 0.9);
// }

// .thumb-info-floating-caption-title .thumb-info-floating-caption-type {
//   background-color: #002345;
// }

.inverted {
  background-color: #002345;
}

.inverted-primary {
  background-color: #002345;
}

.inverted-secondary {
  background-color: #ff6600;
}

.inverted-tertiary {
  background-color: #6d7275;
}

.inverted-quaternary {
  background-color: #000;
}

.inverted-dark {
  background-color: #212529;
}

.inverted-light {
  background-color: #fff;
}

/* .owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
	background-color: #f2493d;
}

.owl-carousel.show-nav-title .owl-nav button[class*="owl-"],
.owl-carousel.show-nav-title .owl-nav button[class*="owl-"]:hover,
.owl-carousel.show-nav-title .owl-nav button[class*="owl-"].hover {
	color: #002345;
}

.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"] {
	background-color: #002345;
	border-color: #002345 #002345 #f1372a;
	color: #FFF;
} */

/* .owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"]:hover,
.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"].hover {
	background-color: #f6867e;
	border-color: #f7918a #f7918a #002345;
}

.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"]:active,
.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"].active {
	background-color: #f24236;
	background-image: none;
	border-color: #f1372a #f1372a #da1c0e;
}

.owl-carousel.nav-with-transparency:not(.nav-style-1):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*="owl-"] {
	background-color: rgba(244, 100, 90, 0.4);
} */

/* .owl-carousel.nav-style-1 .owl-nav .owl-next,
.owl-carousel.nav-style-1 .owl-nav .owl-prev {
	color: #002345 !important;
}

.owl-carousel.nav-style-2 .owl-nav .owl-next:before,
.owl-carousel.nav-style-2 .owl-nav .owl-prev:before,
.owl-carousel.nav-style-2 .owl-nav .owl-next:after,
.owl-carousel.nav-style-2 .owl-nav .owl-prev:after {
	border-color: #002345;
}

.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev svg polygon,
.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next svg polygon {
	fill: #002345;
	stroke: #002345;
}

.owl-carousel.nav-arrows-1 .owl-nav .owl-prev,
.owl-carousel.nav-arrows-1 .owl-nav .owl-next {
	color: #002345;
}

.owl-carousel.carousel-center-active-item-2 .owl-item.active > div {
	background: #002345;
} */

body .tabs .nav-tabs .nav-link,
.dark body .tabs .nav-tabs .nav-link,
body .tabs .nav-tabs .nav-link:hover,
.dark body .tabs .nav-tabs .nav-link:hover,
body .tabs .nav-tabs li.active .nav-link,
.dark body .tabs .nav-tabs li.active .nav-link {
  color: #002345;
}

body .tabs .nav-tabs .nav-link:hover,
.dark body .tabs .nav-tabs .nav-link:hover,
body .tabs .nav-tabs .nav-link:focus,
.dark body .tabs .nav-tabs .nav-link:focus,
body .tabs .nav-tabs li.active .nav-link,
.dark body .tabs .nav-tabs li.active .nav-link,
body .tabs .nav-tabs.nav-justified .nav-link:hover,
.dark body .tabs .nav-tabs.nav-justified .nav-link:hover,
body .tabs .nav-tabs.nav-justified .nav-link:focus,
.dark body .tabs .nav-tabs.nav-justified .nav-link:focus {
  border-top-color: #002345;
}

// body .tabs.tabs-bottom .nav-tabs li .nav-link:hover,
// .dark body .tabs.tabs-bottom .nav-tabs li .nav-link:hover,
// body .tabs.tabs-bottom .nav-tabs li.active .nav-link,
// .dark body .tabs.tabs-bottom .nav-tabs li.active .nav-link,
// body .tabs.tabs-bottom .nav-tabs li.active .nav-link:hover,
// .dark body .tabs.tabs-bottom .nav-tabs li.active .nav-link:hover,
// body .tabs.tabs-bottom .nav-tabs li.active .nav-link:focus,
// .dark body .tabs.tabs-bottom .nav-tabs li.active .nav-link:focus {
//   border-bottom-color: #002345;
// }

// body .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover,
// .dark body .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover,
// body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link,
// .dark body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link,
// body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:hover,
// .dark body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:hover,
// body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:focus,
// .dark body .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:focus {
//   border-left-color: #002345;
// }

// body .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover,
// .dark body .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover,
// body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link,
// .dark body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link,
// body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:hover,
// .dark body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:hover,
// body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:focus,
// .dark body .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:focus {
//   border-right-color: #002345;
// }

// body .tabs.tabs-simple .nav-tabs > li.active .nav-link,
// .dark body .tabs.tabs-simple .nav-tabs > li.active .nav-link,
// body .tabs.tabs-simple .nav-tabs > li.active .nav-link:focus,
// .dark body .tabs.tabs-simple .nav-tabs > li.active .nav-link:focus,
// body .tabs.tabs-simple .nav-tabs > li .nav-link:hover,
// .dark body .tabs.tabs-simple .nav-tabs > li .nav-link:hover,
// body .tabs.tabs-simple .nav-tabs > li.active .nav-link:hover,
// .dark body .tabs.tabs-simple .nav-tabs > li.active .nav-link:hover {
//   border-top-color: #002345;
//   color: #002345;
// }

// body .tabs-primary .nav-tabs li .nav-link,
// .dark body .tabs-primary .nav-tabs li .nav-link,
// body .tabs-primary .nav-tabs.nav-justified li .nav-link,
// .dark body .tabs-primary .nav-tabs.nav-justified li .nav-link,
// body .tabs-primary .nav-tabs li .nav-link:hover,
// .dark body .tabs-primary .nav-tabs li .nav-link:hover,
// body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover,
// .dark body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover {
//   color: #002345;
// }

// body .tabs-primary .nav-tabs li .nav-link:hover,
// .dark body .tabs-primary .nav-tabs li .nav-link:hover,
// body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover,
// .dark body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover {
//   border-top-color: #002345;
// }

// body .tabs-primary .nav-tabs li.active .nav-link,
// .dark body .tabs-primary .nav-tabs li.active .nav-link,
// body .tabs-primary .nav-tabs.nav-justified li.active .nav-link,
// .dark body .tabs-primary .nav-tabs.nav-justified li.active .nav-link,
// body .tabs-primary .nav-tabs li.active .nav-link:hover,
// .dark body .tabs-primary .nav-tabs li.active .nav-link:hover,
// body .tabs-primary .nav-tabs.nav-justified li.active .nav-link:hover,
// .dark body .tabs-primary .nav-tabs.nav-justified li.active .nav-link:hover,
// body .tabs-primary .nav-tabs li.active .nav-link:focus,
// .dark body .tabs-primary .nav-tabs li.active .nav-link:focus,
// body .tabs-primary .nav-tabs.nav-justified li.active .nav-link:focus,
// .dark body .tabs-primary .nav-tabs.nav-justified li.active .nav-link:focus {
//   border-top-color: #002345;
//   color: #002345;
// }

// body .tabs-primary.tabs-bottom .nav-tabs li .nav-link:hover,
// .dark body .tabs-primary.tabs-bottom .nav-tabs li .nav-link:hover,
// body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
// .dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
// body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link,
// .dark body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link,
// body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
// .dark
//   body
//   .tabs-primary.tabs-bottom
//   .nav-tabs.nav-justified
//   li.active
//   .nav-link,
// body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link:hover,
// .dark body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link:hover,
// body
//   .tabs-primary.tabs-bottom
//   .nav-tabs.nav-justified
//   li.active
//   .nav-link:hover,
// .dark
//   body
//   .tabs-primary.tabs-bottom
//   .nav-tabs.nav-justified
//   li.active
//   .nav-link:hover,
// body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link:focus,
// .dark body .tabs-primary.tabs-bottom .nav-tabs li.active .nav-link:focus,
// body
//   .tabs-primary.tabs-bottom
//   .nav-tabs.nav-justified
//   li.active
//   .nav-link:focus,
// .dark
//   body
//   .tabs-primary.tabs-bottom
//   .nav-tabs.nav-justified
//   li.active
//   .nav-link:focus {
//   border-bottom-color: #002345;
// }

// body .tabs-primary.tabs-simple .nav-tabs li .nav-link:hover,
// .dark body .tabs-primary.tabs-simple .nav-tabs li .nav-link:hover,
// body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
// .dark body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
// body .tabs-primary.tabs-simple .nav-tabs li.active .nav-link,
// .dark body .tabs-primary.tabs-simple .nav-tabs li.active .nav-link,
// body .tabs-primary.tabs-simple .nav-tabs.nav-justified li.active .nav-link,
// .dark
//   body
//   .tabs-primary.tabs-simple
//   .nav-tabs.nav-justified
//   li.active
//   .nav-link,
// body .tabs-primary.tabs-simple .nav-tabs li.active .nav-link:hover,
// .dark body .tabs-primary.tabs-simple .nav-tabs li.active .nav-link:hover,
// body
//   .tabs-primary.tabs-simple
//   .nav-tabs.nav-justified
//   li.active
//   .nav-link:hover,
// .dark
//   body
//   .tabs-primary.tabs-simple
//   .nav-tabs.nav-justified
//   li.active
//   .nav-link:hover,
// body .tabs-primary.tabs-simple .nav-tabs li.active .nav-link:focus,
// .dark body .tabs-primary.tabs-simple .nav-tabs li.active .nav-link:focus,
// body
//   .tabs-primary.tabs-simple
//   .nav-tabs.nav-justified
//   li.active
//   .nav-link:focus,
// .dark
//   body
//   .tabs-primary.tabs-simple
//   .nav-tabs.nav-justified
//   li.active
//   .nav-link:focus {
//   color: #002345 !important;
//   border-bottom-color: #002345;
// }

// body .tabs-primary.tabs-vertical.tabs-left li .nav-link:hover,
// .dark body .tabs-primary.tabs-vertical.tabs-left li .nav-link:hover,
// body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link,
// .dark body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link,
// body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link:hover,
// .dark body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link:hover,
// body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link:focus,
// .dark body .tabs-primary.tabs-vertical.tabs-left li.active .nav-link:focus {
//   border-left-color: #002345;
// }

// body .tabs-primary.tabs-vertical.tabs-right li .nav-link:hover,
// .dark body .tabs-primary.tabs-vertical.tabs-right li .nav-link:hover,
// body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link,
// .dark body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link,
// body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link:hover,
// .dark body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link:hover,
// body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link:focus,
// .dark body .tabs-primary.tabs-vertical.tabs-right li.active .nav-link:focus {
//   border-right-color: #002345;
// }

// body .tabs-secondary .nav-tabs li .nav-link,
// .dark body .tabs-secondary .nav-tabs li .nav-link,
// body .tabs-secondary .nav-tabs.nav-justified li .nav-link,
// .dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link,
// body .tabs-secondary .nav-tabs li .nav-link:hover,
// .dark body .tabs-secondary .nav-tabs li .nav-link:hover,
// body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover,
// .dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover {
//   color: #ff6600;
// }

// body .tabs-secondary .nav-tabs li .nav-link:hover,
// .dark body .tabs-secondary .nav-tabs li .nav-link:hover,
// body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover,
// .dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover {
//   border-top-color: #ff6600;
// }

// body .tabs-secondary .nav-tabs li.active .nav-link,
// .dark body .tabs-secondary .nav-tabs li.active .nav-link,
// body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link,
// .dark body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link,
// body .tabs-secondary .nav-tabs li.active .nav-link:hover,
// .dark body .tabs-secondary .nav-tabs li.active .nav-link:hover,
// body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link:hover,
// .dark body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link:hover,
// body .tabs-secondary .nav-tabs li.active .nav-link:focus,
// .dark body .tabs-secondary .nav-tabs li.active .nav-link:focus,
// body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link:focus,
// .dark body .tabs-secondary .nav-tabs.nav-justified li.active .nav-link:focus {
//   border-top-color: #ff6600;
//   color: #ff6600;
// }

body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link:hover,
.dark body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link:hover,
body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
.dark
  body
  .tabs-secondary.tabs-bottom
  .nav-tabs.nav-justified
  li
  .nav-link:hover,
body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link,
.dark body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link,
body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
.dark
  body
  .tabs-secondary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link,
body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link:hover,
.dark body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link:hover,
body
  .tabs-secondary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
.dark
  body
  .tabs-secondary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link:focus,
.dark body .tabs-secondary.tabs-bottom .nav-tabs li.active .nav-link:focus,
body
  .tabs-secondary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus,
.dark
  body
  .tabs-secondary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus {
  border-bottom-color: #ff6600;
}

body .tabs-secondary.tabs-simple .nav-tabs li .nav-link:hover,
.dark body .tabs-secondary.tabs-simple .nav-tabs li .nav-link:hover,
body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
.dark
  body
  .tabs-secondary.tabs-simple
  .nav-tabs.nav-justified
  li
  .nav-link:hover,
body .tabs-secondary.tabs-simple .nav-tabs li.active .nav-link,
.dark body .tabs-secondary.tabs-simple .nav-tabs li.active .nav-link,
body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li.active .nav-link,
.dark
  body
  .tabs-secondary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link,
body .tabs-secondary.tabs-simple .nav-tabs li.active .nav-link:hover,
.dark body .tabs-secondary.tabs-simple .nav-tabs li.active .nav-link:hover,
body
  .tabs-secondary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
.dark
  body
  .tabs-secondary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
body .tabs-secondary.tabs-simple .nav-tabs li.active .nav-link:focus,
.dark body .tabs-secondary.tabs-simple .nav-tabs li.active .nav-link:focus,
body
  .tabs-secondary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus,
.dark
  body
  .tabs-secondary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus {
  color: #ff6600 !important;
  border-bottom-color: #ff6600;
}

body .tabs-secondary.tabs-vertical.tabs-left li .nav-link:hover,
.dark body .tabs-secondary.tabs-vertical.tabs-left li .nav-link:hover,
body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link,
.dark body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link,
body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link:hover,
.dark body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link:hover,
body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link:focus,
.dark body .tabs-secondary.tabs-vertical.tabs-left li.active .nav-link:focus {
  border-left-color: #ff6600;
}

body .tabs-secondary.tabs-vertical.tabs-right li .nav-link:hover,
.dark body .tabs-secondary.tabs-vertical.tabs-right li .nav-link:hover,
body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link,
.dark body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link,
body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link:hover,
.dark body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link:hover,
body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link:focus,
.dark body .tabs-secondary.tabs-vertical.tabs-right li.active .nav-link:focus {
  border-right-color: #ff6600;
}

body .tabs-tertiary .nav-tabs li .nav-link,
.dark body .tabs-tertiary .nav-tabs li .nav-link,
body .tabs-tertiary .nav-tabs.nav-justified li .nav-link,
.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link,
body .tabs-tertiary .nav-tabs li .nav-link:hover,
.dark body .tabs-tertiary .nav-tabs li .nav-link:hover,
body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover {
  color: #6d7275;
}

body .tabs-tertiary .nav-tabs li .nav-link:hover,
.dark body .tabs-tertiary .nav-tabs li .nav-link:hover,
body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover {
  border-top-color: #6d7275;
}

body .tabs-tertiary .nav-tabs li.active .nav-link,
.dark body .tabs-tertiary .nav-tabs li.active .nav-link,
body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link,
.dark body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link,
body .tabs-tertiary .nav-tabs li.active .nav-link:hover,
.dark body .tabs-tertiary .nav-tabs li.active .nav-link:hover,
body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link:hover,
.dark body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link:hover,
body .tabs-tertiary .nav-tabs li.active .nav-link:focus,
.dark body .tabs-tertiary .nav-tabs li.active .nav-link:focus,
body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link:focus,
.dark body .tabs-tertiary .nav-tabs.nav-justified li.active .nav-link:focus {
  border-top-color: #6d7275;
  color: #6d7275;
}

body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link:hover,
.dark body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link:hover,
body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
.dark
  body
  .tabs-tertiary.tabs-bottom
  .nav-tabs.nav-justified
  li
  .nav-link:hover,
body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link,
.dark body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link,
body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
.dark
  body
  .tabs-tertiary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link,
body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link:hover,
.dark body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link:hover,
body
  .tabs-tertiary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
.dark
  body
  .tabs-tertiary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link:focus,
.dark body .tabs-tertiary.tabs-bottom .nav-tabs li.active .nav-link:focus,
body
  .tabs-tertiary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus,
.dark
  body
  .tabs-tertiary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus {
  border-bottom-color: #6d7275;
}

body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link:hover,
.dark body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link:hover,
body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
.dark
  body
  .tabs-tertiary.tabs-simple
  .nav-tabs.nav-justified
  li
  .nav-link:hover,
body .tabs-tertiary.tabs-simple .nav-tabs li.active .nav-link,
.dark body .tabs-tertiary.tabs-simple .nav-tabs li.active .nav-link,
body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li.active .nav-link,
.dark
  body
  .tabs-tertiary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link,
body .tabs-tertiary.tabs-simple .nav-tabs li.active .nav-link:hover,
.dark body .tabs-tertiary.tabs-simple .nav-tabs li.active .nav-link:hover,
body
  .tabs-tertiary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
.dark
  body
  .tabs-tertiary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
body .tabs-tertiary.tabs-simple .nav-tabs li.active .nav-link:focus,
.dark body .tabs-tertiary.tabs-simple .nav-tabs li.active .nav-link:focus,
body
  .tabs-tertiary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus,
.dark
  body
  .tabs-tertiary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus {
  color: #6d7275 !important;
  border-bottom-color: #6d7275;
}

body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link:hover,
.dark body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link:hover,
body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link,
.dark body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link,
body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link:hover,
.dark body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link:hover,
body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link:focus,
.dark body .tabs-tertiary.tabs-vertical.tabs-left li.active .nav-link:focus {
  border-left-color: #6d7275;
}

body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link:hover,
.dark body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link:hover,
body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link,
.dark body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link,
body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link:hover,
.dark body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link:hover,
body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link:focus,
.dark body .tabs-tertiary.tabs-vertical.tabs-right li.active .nav-link:focus {
  border-right-color: #6d7275;
}

body .tabs-quaternary .nav-tabs li .nav-link,
.dark body .tabs-quaternary .nav-tabs li .nav-link,
body .tabs-quaternary .nav-tabs.nav-justified li .nav-link,
.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link,
body .tabs-quaternary .nav-tabs li .nav-link:hover,
.dark body .tabs-quaternary .nav-tabs li .nav-link:hover,
body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover {
  color: #000;
}

body .tabs-quaternary .nav-tabs li .nav-link:hover,
.dark body .tabs-quaternary .nav-tabs li .nav-link:hover,
body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover {
  border-top-color: #000;
}

body .tabs-quaternary .nav-tabs li.active .nav-link,
.dark body .tabs-quaternary .nav-tabs li.active .nav-link,
body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link,
.dark body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link,
body .tabs-quaternary .nav-tabs li.active .nav-link:hover,
.dark body .tabs-quaternary .nav-tabs li.active .nav-link:hover,
body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link:hover,
.dark body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link:hover,
body .tabs-quaternary .nav-tabs li.active .nav-link:focus,
.dark body .tabs-quaternary .nav-tabs li.active .nav-link:focus,
body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link:focus,
.dark body .tabs-quaternary .nav-tabs.nav-justified li.active .nav-link:focus {
  border-top-color: #000;
  color: #000;
}

body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link:hover,
.dark body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link:hover,
body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
.dark
  body
  .tabs-quaternary.tabs-bottom
  .nav-tabs.nav-justified
  li
  .nav-link:hover,
body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link,
.dark body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link,
body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
.dark
  body
  .tabs-quaternary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link,
body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link:hover,
.dark body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link:hover,
body
  .tabs-quaternary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
.dark
  body
  .tabs-quaternary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link:focus,
.dark body .tabs-quaternary.tabs-bottom .nav-tabs li.active .nav-link:focus,
body
  .tabs-quaternary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus,
.dark
  body
  .tabs-quaternary.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus {
  border-bottom-color: #000;
}

body .tabs-quaternary.tabs-simple .nav-tabs li .nav-link:hover,
.dark body .tabs-quaternary.tabs-simple .nav-tabs li .nav-link:hover,
body .tabs-quaternary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
.dark
  body
  .tabs-quaternary.tabs-simple
  .nav-tabs.nav-justified
  li
  .nav-link:hover,
body .tabs-quaternary.tabs-simple .nav-tabs li.active .nav-link,
.dark body .tabs-quaternary.tabs-simple .nav-tabs li.active .nav-link,
body .tabs-quaternary.tabs-simple .nav-tabs.nav-justified li.active .nav-link,
.dark
  body
  .tabs-quaternary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link,
body .tabs-quaternary.tabs-simple .nav-tabs li.active .nav-link:hover,
.dark body .tabs-quaternary.tabs-simple .nav-tabs li.active .nav-link:hover,
body
  .tabs-quaternary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
.dark
  body
  .tabs-quaternary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
body .tabs-quaternary.tabs-simple .nav-tabs li.active .nav-link:focus,
.dark body .tabs-quaternary.tabs-simple .nav-tabs li.active .nav-link:focus,
body
  .tabs-quaternary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus,
.dark
  body
  .tabs-quaternary.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus {
  color: #000 !important;
  border-bottom-color: #000;
}

body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link:hover,
.dark body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link:hover,
body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link,
.dark body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link,
body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link:hover,
.dark body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link:hover,
body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link:focus,
.dark body .tabs-quaternary.tabs-vertical.tabs-left li.active .nav-link:focus {
  border-left-color: #000;
}

body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link:hover,
.dark body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link:hover,
body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link,
.dark body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link,
body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link:hover,
.dark body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link:hover,
body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link:focus,
.dark body .tabs-quaternary.tabs-vertical.tabs-right li.active .nav-link:focus {
  border-right-color: #000;
}

body .tabs-dark .nav-tabs li .nav-link,
.dark body .tabs-dark .nav-tabs li .nav-link,
body .tabs-dark .nav-tabs.nav-justified li .nav-link,
.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link,
body .tabs-dark .nav-tabs li .nav-link:hover,
.dark body .tabs-dark .nav-tabs li .nav-link:hover,
body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover {
  color: #212529;
}

body .tabs-dark .nav-tabs li .nav-link:hover,
.dark body .tabs-dark .nav-tabs li .nav-link:hover,
body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover {
  border-top-color: #212529;
}

body .tabs-dark .nav-tabs li.active .nav-link,
.dark body .tabs-dark .nav-tabs li.active .nav-link,
body .tabs-dark .nav-tabs.nav-justified li.active .nav-link,
.dark body .tabs-dark .nav-tabs.nav-justified li.active .nav-link,
body .tabs-dark .nav-tabs li.active .nav-link:hover,
.dark body .tabs-dark .nav-tabs li.active .nav-link:hover,
body .tabs-dark .nav-tabs.nav-justified li.active .nav-link:hover,
.dark body .tabs-dark .nav-tabs.nav-justified li.active .nav-link:hover,
body .tabs-dark .nav-tabs li.active .nav-link:focus,
.dark body .tabs-dark .nav-tabs li.active .nav-link:focus,
body .tabs-dark .nav-tabs.nav-justified li.active .nav-link:focus,
.dark body .tabs-dark .nav-tabs.nav-justified li.active .nav-link:focus {
  border-top-color: #212529;
  color: #212529;
}

body .tabs-dark.tabs-bottom .nav-tabs li .nav-link:hover,
.dark body .tabs-dark.tabs-bottom .nav-tabs li .nav-link:hover,
body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link,
.dark body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link,
body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link:hover,
.dark body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link:hover,
body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
.dark
  body
  .tabs-dark.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link:focus,
.dark body .tabs-dark.tabs-bottom .nav-tabs li.active .nav-link:focus,
body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus,
.dark
  body
  .tabs-dark.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus {
  border-bottom-color: #212529;
}

body .tabs-dark.tabs-simple .nav-tabs li .nav-link:hover,
.dark body .tabs-dark.tabs-simple .nav-tabs li .nav-link:hover,
body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
body .tabs-dark.tabs-simple .nav-tabs li.active .nav-link,
.dark body .tabs-dark.tabs-simple .nav-tabs li.active .nav-link,
body .tabs-dark.tabs-simple .nav-tabs.nav-justified li.active .nav-link,
.dark body .tabs-dark.tabs-simple .nav-tabs.nav-justified li.active .nav-link,
body .tabs-dark.tabs-simple .nav-tabs li.active .nav-link:hover,
.dark body .tabs-dark.tabs-simple .nav-tabs li.active .nav-link:hover,
body .tabs-dark.tabs-simple .nav-tabs.nav-justified li.active .nav-link:hover,
.dark
  body
  .tabs-dark.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
body .tabs-dark.tabs-simple .nav-tabs li.active .nav-link:focus,
.dark body .tabs-dark.tabs-simple .nav-tabs li.active .nav-link:focus,
body .tabs-dark.tabs-simple .nav-tabs.nav-justified li.active .nav-link:focus,
.dark
  body
  .tabs-dark.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus {
  color: #212529 !important;
  border-bottom-color: #212529;
}

body .tabs-dark.tabs-vertical.tabs-left li .nav-link:hover,
.dark body .tabs-dark.tabs-vertical.tabs-left li .nav-link:hover,
body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link,
.dark body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link,
body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link:hover,
.dark body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link:hover,
body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link:focus,
.dark body .tabs-dark.tabs-vertical.tabs-left li.active .nav-link:focus {
  border-left-color: #212529;
}

body .tabs-dark.tabs-vertical.tabs-right li .nav-link:hover,
.dark body .tabs-dark.tabs-vertical.tabs-right li .nav-link:hover,
body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link,
.dark body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link,
body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link:hover,
.dark body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link:hover,
body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link:focus,
.dark body .tabs-dark.tabs-vertical.tabs-right li.active .nav-link:focus {
  border-right-color: #212529;
}

body .tabs-light .nav-tabs li .nav-link,
.dark body .tabs-light .nav-tabs li .nav-link,
body .tabs-light .nav-tabs.nav-justified li .nav-link,
.dark body .tabs-light .nav-tabs.nav-justified li .nav-link,
body .tabs-light .nav-tabs li .nav-link:hover,
.dark body .tabs-light .nav-tabs li .nav-link:hover,
body .tabs-light .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-light .nav-tabs.nav-justified li .nav-link:hover {
  color: #fff;
}

body .tabs-light .nav-tabs li .nav-link:hover,
.dark body .tabs-light .nav-tabs li .nav-link:hover,
body .tabs-light .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-light .nav-tabs.nav-justified li .nav-link:hover {
  border-top-color: #fff;
}

body .tabs-light .nav-tabs li.active .nav-link,
.dark body .tabs-light .nav-tabs li.active .nav-link,
body .tabs-light .nav-tabs.nav-justified li.active .nav-link,
.dark body .tabs-light .nav-tabs.nav-justified li.active .nav-link,
body .tabs-light .nav-tabs li.active .nav-link:hover,
.dark body .tabs-light .nav-tabs li.active .nav-link:hover,
body .tabs-light .nav-tabs.nav-justified li.active .nav-link:hover,
.dark body .tabs-light .nav-tabs.nav-justified li.active .nav-link:hover,
body .tabs-light .nav-tabs li.active .nav-link:focus,
.dark body .tabs-light .nav-tabs li.active .nav-link:focus,
body .tabs-light .nav-tabs.nav-justified li.active .nav-link:focus,
.dark body .tabs-light .nav-tabs.nav-justified li.active .nav-link:focus {
  border-top-color: #fff;
  color: #fff;
}

body .tabs-light.tabs-bottom .nav-tabs li .nav-link:hover,
.dark body .tabs-light.tabs-bottom .nav-tabs li .nav-link:hover,
body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link,
.dark body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link,
body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active .nav-link,
body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link:hover,
.dark body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link:hover,
body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:hover,
.dark
  body
  .tabs-light.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link:focus,
.dark body .tabs-light.tabs-bottom .nav-tabs li.active .nav-link:focus,
body .tabs-light.tabs-bottom .nav-tabs.nav-justified li.active .nav-link:focus,
.dark
  body
  .tabs-light.tabs-bottom
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus {
  border-bottom-color: #fff;
}

body .tabs-light.tabs-simple .nav-tabs li .nav-link:hover,
.dark body .tabs-light.tabs-simple .nav-tabs li .nav-link:hover,
body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
.dark body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
body .tabs-light.tabs-simple .nav-tabs li.active .nav-link,
.dark body .tabs-light.tabs-simple .nav-tabs li.active .nav-link,
body .tabs-light.tabs-simple .nav-tabs.nav-justified li.active .nav-link,
.dark body .tabs-light.tabs-simple .nav-tabs.nav-justified li.active .nav-link,
body .tabs-light.tabs-simple .nav-tabs li.active .nav-link:hover,
.dark body .tabs-light.tabs-simple .nav-tabs li.active .nav-link:hover,
body .tabs-light.tabs-simple .nav-tabs.nav-justified li.active .nav-link:hover,
.dark
  body
  .tabs-light.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:hover,
body .tabs-light.tabs-simple .nav-tabs li.active .nav-link:focus,
.dark body .tabs-light.tabs-simple .nav-tabs li.active .nav-link:focus,
body .tabs-light.tabs-simple .nav-tabs.nav-justified li.active .nav-link:focus,
.dark
  body
  .tabs-light.tabs-simple
  .nav-tabs.nav-justified
  li.active
  .nav-link:focus {
  color: #fff !important;
  border-bottom-color: #fff;
}

body .tabs-light.tabs-vertical.tabs-left li .nav-link:hover,
.dark body .tabs-light.tabs-vertical.tabs-left li .nav-link:hover,
body .tabs-light.tabs-vertical.tabs-left li.active .nav-link,
.dark body .tabs-light.tabs-vertical.tabs-left li.active .nav-link,
body .tabs-light.tabs-vertical.tabs-left li.active .nav-link:hover,
.dark body .tabs-light.tabs-vertical.tabs-left li.active .nav-link:hover,
body .tabs-light.tabs-vertical.tabs-left li.active .nav-link:focus,
.dark body .tabs-light.tabs-vertical.tabs-left li.active .nav-link:focus {
  border-left-color: #fff;
}

body .tabs-light.tabs-vertical.tabs-right li .nav-link:hover,
.dark body .tabs-light.tabs-vertical.tabs-right li .nav-link:hover,
body .tabs-light.tabs-vertical.tabs-right li.active .nav-link,
.dark body .tabs-light.tabs-vertical.tabs-right li.active .nav-link,
body .tabs-light.tabs-vertical.tabs-right li.active .nav-link:hover,
.dark body .tabs-light.tabs-vertical.tabs-right li.active .nav-link:hover,
body .tabs-light.tabs-vertical.tabs-right li.active .nav-link:focus,
.dark body .tabs-light.tabs-vertical.tabs-right li.active .nav-link:focus {
  border-right-color: #fff;
}

[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover,
[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link,
[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:hover,
[dir="rtl"] .tabs.tabs-vertical.tabs-left .nav-tabs li.active .nav-link:focus {
  border-right-color: #002345;
  border-left-color: transparent;
}

[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover,
[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link,
[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:hover,
[dir="rtl"] .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:focus {
  border-right-color: transparent;
  border-left-color: #002345;
}

.list.list-icons li > [class*="fa-"]:first-child,
.list.list-icons li a:first-child > [class*="fa-"]:first-child,
.list.list-icons li > .icons:first-child,
.list.list-icons li a:first-child > .icons:first-child {
  color: #002345;
  border-color: #002345;
}

.list.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-style-3
  li
  a:first-child
  > [class*="fa-"]:first-child,
.list.list-icons.list-icons-style-3 li > .icons:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
  background-color: #002345;
}

.list.list-ordened li:before {
  color: #002345;
  border-color: #002345;
}

.list.list-ordened.list-ordened-style-3 li:before {
  background-color: #002345;
}

.list-primary.list-icons li > [class*="fa-"]:first-child,
.list-primary.list-icons li a:first-child > [class*="fa-"]:first-child,
.list-primary.list-icons li > .icons:first-child,
.list-primary.list-icons li a:first-child > .icons:first-child {
  color: #002345;
  border-color: #002345;
}

.list-primary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
.list-primary.list-icons.list-icons-style-3
  li
  a:first-child
  > [class*="fa-"]:first-child,
.list-primary.list-icons.list-icons-style-3 li > .icons:first-child,
.list-primary.list-icons.list-icons-style-3
  li
  a:first-child
  > .icons:first-child {
  background-color: #002345;
  color: #fff;
}

.list-primary.list-ordened li:before {
  color: #002345;
}

.list-primary.list-ordened.list-ordened-style-3 li:before {
  background-color: #002345;
  color: #fff;
}

.list-secondary.list-icons li > [class*="fa-"]:first-child,
.list-secondary.list-icons li a:first-child > [class*="fa-"]:first-child,
.list-secondary.list-icons li > .icons:first-child,
.list-secondary.list-icons li a:first-child > .icons:first-child {
  color: #ff6600;
  border-color: #ff6600;
}

.list-secondary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
.list-secondary.list-icons.list-icons-style-3
  li
  a:first-child
  > [class*="fa-"]:first-child,
.list-secondary.list-icons.list-icons-style-3 li > .icons:first-child,
.list-secondary.list-icons.list-icons-style-3
  li
  a:first-child
  > .icons:first-child {
  background-color: #ff6600;
  color: #777;
}

.list-secondary.list-ordened li:before {
  color: #ff6600;
}

.list-secondary.list-ordened.list-ordened-style-3 li:before {
  background-color: #ff6600;
  color: #777;
}

.list-tertiary.list-icons li > [class*="fa-"]:first-child,
.list-tertiary.list-icons li a:first-child > [class*="fa-"]:first-child,
.list-tertiary.list-icons li > .icons:first-child,
.list-tertiary.list-icons li a:first-child > .icons:first-child {
  color: #6d7275;
  border-color: #6d7275;
}

.list-tertiary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
.list-tertiary.list-icons.list-icons-style-3
  li
  a:first-child
  > [class*="fa-"]:first-child,
.list-tertiary.list-icons.list-icons-style-3 li > .icons:first-child,
.list-tertiary.list-icons.list-icons-style-3
  li
  a:first-child
  > .icons:first-child {
  background-color: #6d7275;
  color: #fff;
}

.list-tertiary.list-ordened li:before {
  color: #6d7275;
}

.list-tertiary.list-ordened.list-ordened-style-3 li:before {
  background-color: #6d7275;
  color: #fff;
}

.list-quaternary.list-icons li > [class*="fa-"]:first-child,
.list-quaternary.list-icons li a:first-child > [class*="fa-"]:first-child,
.list-quaternary.list-icons li > .icons:first-child,
.list-quaternary.list-icons li a:first-child > .icons:first-child {
  color: #000;
  border-color: #000;
}

.list-quaternary.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
.list-quaternary.list-icons.list-icons-style-3
  li
  a:first-child
  > [class*="fa-"]:first-child,
.list-quaternary.list-icons.list-icons-style-3 li > .icons:first-child,
.list-quaternary.list-icons.list-icons-style-3
  li
  a:first-child
  > .icons:first-child {
  background-color: #000;
  color: #fff;
}

.list-quaternary.list-ordened li:before {
  color: #000;
}

.list-quaternary.list-ordened.list-ordened-style-3 li:before {
  background-color: #000;
  color: #fff;
}

.list-dark.list-icons li > [class*="fa-"]:first-child,
.list-dark.list-icons li a:first-child > [class*="fa-"]:first-child,
.list-dark.list-icons li > .icons:first-child,
.list-dark.list-icons li a:first-child > .icons:first-child {
  color: #212529;
  border-color: #212529;
}

.list-dark.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
.list-dark.list-icons.list-icons-style-3
  li
  a:first-child
  > [class*="fa-"]:first-child,
.list-dark.list-icons.list-icons-style-3 li > .icons:first-child,
.list-dark.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
  background-color: #212529;
  color: #fff;
}

.list-dark.list-ordened li:before {
  color: #212529;
}

.list-dark.list-ordened.list-ordened-style-3 li:before {
  background-color: #212529;
  color: #fff;
}

.list-light.list-icons li > [class*="fa-"]:first-child,
.list-light.list-icons li a:first-child > [class*="fa-"]:first-child,
.list-light.list-icons li > .icons:first-child,
.list-light.list-icons li a:first-child > .icons:first-child {
  color: #fff;
  border-color: #fff;
}

.list-light.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
.list-light.list-icons.list-icons-style-3
  li
  a:first-child
  > [class*="fa-"]:first-child,
.list-light.list-icons.list-icons-style-3 li > .icons:first-child,
.list-light.list-icons.list-icons-style-3
  li
  a:first-child
  > .icons:first-child {
  background-color: #fff;
  color: #777;
}

.list-light.list-ordened li:before {
  color: #fff;
}

.list-light.list-ordened.list-ordened-style-3 li:before {
  background-color: #fff;
  color: #777;
}

.parallax blockquote i.fa-quote-left {
  color: #002345;
}

section.video blockquote i.fa-quote-left {
  color: #002345;
}

.accordion .card-header a {
  color: #002345;
}

.accordion.accordion-primary .card-header {
  background-color: #002345 !important;
}

.accordion.accordion-primary .card-header a {
  color: #fff;
}

.accordion.accordion-secondary .card-header {
  background-color: #ff6600 !important;
}

.accordion.accordion-secondary .card-header a {
  color: #777;
}

.accordion.accordion-tertiary .card-header {
  background-color: #6d7275 !important;
}

.accordion.accordion-tertiary .card-header a {
  color: #fff;
}

.accordion.accordion-quaternary .card-header {
  background-color: #000 !important;
}

.accordion.accordion-quaternary .card-header a {
  color: #fff;
}

.accordion.accordion-dark .card-header {
  background-color: #212529 !important;
}

.accordion.accordion-dark .card-header a {
  color: #fff;
}

.accordion.accordion-light .card-header {
  background-color: #fff !important;
}

.accordion.accordion-light .card-header a {
  color: #777;
}

.divider.divider-primary [class*="fa-"],
.divider.divider-primary .icons {
  color: #002345;
}

.divider.divider-primary.divider-small hr {
  background: #002345;
}

.divider.divider-secondary [class*="fa-"],
.divider.divider-secondary .icons {
  color: #ff6600;
}

.divider.divider-secondary.divider-small hr {
  background: #ff6600;
}

.divider.divider-tertiary [class*="fa-"],
.divider.divider-tertiary .icons {
  color: #6d7275;
}

.divider.divider-tertiary.divider-small hr {
  background: #6d7275;
}

.divider.divider-quaternary [class*="fa-"],
.divider.divider-quaternary .icons {
  color: #000;
}

.divider.divider-quaternary.divider-small hr {
  background: #000;
}

.divider.divider-dark [class*="fa-"],
.divider.divider-dark .icons {
  color: #212529;
}

.divider.divider-dark.divider-small hr {
  background: #212529;
}

.divider.divider-light [class*="fa-"],
.divider.divider-light .icons {
  color: #fff;
}

.divider.divider-light.divider-small hr {
  background: #fff;
}

.divider.divider-style-2.divider-primary [class*="fa-"],
.divider.divider-style-2.divider-primary .icons {
  background: #002345;
  color: #fff;
}

.divider.divider-style-2.divider-secondary [class*="fa-"],
.divider.divider-style-2.divider-secondary .icons {
  background: #ff6600;
  color: #777;
}

.divider.divider-style-2.divider-tertiary [class*="fa-"],
.divider.divider-style-2.divider-tertiary .icons {
  background: #6d7275;
  color: #fff;
}

.divider.divider-style-2.divider-quaternary [class*="fa-"],
.divider.divider-style-2.divider-quaternary .icons {
  background: #000;
  color: #fff;
}

.divider.divider-style-2.divider-dark [class*="fa-"],
.divider.divider-style-2.divider-dark .icons {
  background: #212529;
  color: #fff;
}

.divider.divider-style-2.divider-light [class*="fa-"],
.divider.divider-style-2.divider-light .icons {
  background: #fff;
  color: #777;
}

.divider.divider-style-3.divider-primary [class*="fa-"],
.divider.divider-style-3.divider-primary .icons {
  border-color: #002345;
}

.divider.divider-style-3.divider-secondary [class*="fa-"],
.divider.divider-style-3.divider-secondary .icons {
  border-color: #ff6600;
}

.divider.divider-style-3.divider-tertiary [class*="fa-"],
.divider.divider-style-3.divider-tertiary .icons {
  border-color: #6d7275;
}

.divider.divider-style-3.divider-quaternary [class*="fa-"],
.divider.divider-style-3.divider-quaternary .icons {
  border-color: #000;
}

.divider.divider-style-3.divider-dark [class*="fa-"],
.divider.divider-style-3.divider-dark .icons {
  border-color: #212529;
}

.divider.divider-style-3.divider-light [class*="fa-"],
.divider.divider-style-3.divider-light .icons {
  border-color: #fff;
}

#header .tip {
  background: #002345;
  color: #fff;
}

/* #header .tip:before {
	border-right-color: #002345;
	border-left-color: transparent;
}

 #header .tip-primary {
	background: #002345 !important;
	color: #FFF !important;
}

 #header .tip-primary:before {
	border-right-color: #002345 !important;
}

 #header .tip-secondary {
	background: #ff6600 !important;
	color: #777 !important;
}

 #header .tip-secondary:before {
	border-right-color: #ff6600 !important;
}

 #header .tip-tertiary {
	background: #6D7275 !important;
	color: #FFF !important;
}

 #header .tip-tertiary:before {
	border-right-color: #6D7275 !important;
}

 #header .tip-quaternary {
	background: #000 !important;
	color: #FFF !important;
}

 #header .tip-quaternary:before {
	border-right-color: #000 !important;
}

 #header .tip-dark {
	background: #212529 !important;
	color: #FFF !important;
}

 #header .tip-dark:before {
	border-right-color: #212529 !important;
}

 #header .tip-light {
	background: #FFF !important;
	color: #777 !important;
}

 #header .tip-light:before {
	border-right-color: #FFF !important;
}

[dir="rtl"] #header .tip:before {
	border-left-color: #002345;
}

[dir="rtl"] #header .tip.skin:before {
	border-left-color: #002345;
}

[dir="rtl"] #header .tip-primary:before {
	border-left-color: #002345 !important;
	border-right-color: transparent !important;
}

[dir="rtl"] #header .tip-secondary:before {
	border-left-color: #ff6600 !important;
	border-right-color: transparent !important;
}

[dir="rtl"] #header .tip-tertiary:before {
	border-left-color: #6D7275 !important;
	border-right-color: transparent !important;
}

[dir="rtl"] #header .tip-quaternary:before {
	border-left-color: #000 !important;
	border-right-color: transparent !important;
}

[dir="rtl"] #header .tip-dark:before {
	border-left-color: #212529 !important;
	border-right-color: transparent !important;
}

[dir="rtl"] #header .tip-light:before {
	border-left-color: #FFF !important;
	border-right-color: transparent !important;
} */

#header .header-btn-collapse-nav {
  background: #002345;
}

@media (min-width: 992px) {
  #header .header-nav-main nav > ul > li > a {
    color: #002345;
  }

  #header .header-nav-main nav > ul > li.open > a,
  #header .header-nav-main nav > ul > li:hover > a {
    background: #002345;
  }

  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
    border-top-color: #002345;
  }

  #header .header-nav-main nav > ul > li.dropdown.open > a:before,
  #header .header-nav-main nav > ul > li.dropdown:hover > a:before {
    border-bottom-color: #002345;
  }

  #header .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu {
    border-top-color: #002345;
  }

  #header
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega-signin
    .dropdown-menu
    li
    a {
    color: #002345;
  }

  #header .header-nav-main nav > ul:not(:hover) > li > a.active {
    background-color: #002345;
    color: #fff;
  }

  // #header .header-nav.header-nav-stripe nav > ul > li:hover > a,
  // #header .header-nav.header-nav-stripe nav > ul > li.open > a {
  //   background-color: #002345;
  // }

  // #header .header-nav.header-nav-stripe nav > ul:not(:hover) > li > a.active {
  //   background-color: #002345;
  // }

  // #header .header-nav.header-nav-stripe nav > ul:hover > li > a.active {
  //   color: #002345;
  // }

  // #header .header-nav.header-nav-links nav > ul li:hover > a {
  //   color: #002345;
  // }

  // #header .header-nav.header-nav-links nav > ul:not(:hover) > li > a.active {
  //   color: #002345;
  // }

  #header
    .header-nav.header-nav-links.header-nav-links-vertical-slide
    nav
    ul
    li.dropdown
    .dropdown-menu::-webkit-scrollbar-thumb {
    background: #002345 !important;
  }

  #header .header-nav.header-nav-line nav > ul li:hover > a {
    color: #002345;
  }

  #header .header-nav.header-nav-line nav > ul li:hover > a:before {
    background: #002345;
  }

  #header .header-nav.header-nav-line nav > ul:not(:hover) li > a.active {
    color: #002345;
  }

  #header
    .header-nav.header-nav-line
    nav
    > ul:not(:hover)
    li
    > a.active:before {
    background: #002345;
  }

  #header .header-nav-main.header-nav-main-slide nav > ul > li.open > span > a,
  #header
    .header-nav-main.header-nav-main-slide
    nav
    > ul
    > li:hover
    > span
    > a {
    color: #002345;
  }

  #header
    .header-nav-main.header-nav-main-slide
    nav
    > ul:not(:hover)
    > li
    > span
    > a.active {
    color: #002345;
  }

  .side-header
    #header.side-header
    .header-nav-main
    nav
    > ul
    li.dropdown.open
    > .dropdown-menu,
  .side-header
    #header.side-header
    .header-nav-main
    nav
    > ul
    li.dropdown:hover
    > .dropdown-menu {
    border-left-color: #002345;
  }

  .side-header-right
    #header.side-header
    .header-nav-main
    nav
    > ul
    li.dropdown.open
    > .dropdown-menu,
  .side-header-right
    #header.side-header
    .header-nav-main
    nav
    > ul
    li.dropdown:hover
    > .dropdown-menu {
    border-right-color: #002345;
  }
}

@media (min-width: 992px) {
  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    .dropdown-menu {
    background-color: #002345;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    .dropdown-menu
    li
    a {
    color: #fff;
    border-bottom-color: #f3574c;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    .dropdown-menu
    li:hover
    > a {
    background: #f57168;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary.open
    > a:before,
  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary:hover
    > a:before {
    border-bottom-color: #002345;
    background: #002345;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-mega.dropdown-primary
    .dropdown-mega-sub-title {
    color: #fff;
    opacity: 0.7;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary:hover
    > a {
    background: #002345;
    color: #fff;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    .dropdown-menu
    li
    a {
    color: #fff;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-primary
    > a.active {
    background: #002345;
    color: #fff;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    a:focus,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    a:hover {
    color: #002345;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    a:focus:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    a:hover:before {
    background-color: #002345;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary:hover
    > a {
    color: #002345;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary.open
    > a:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary:hover
    > a:before {
    background: #002345;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-primary
    > a.active {
    color: #002345;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-primary
    > a.active:before {
    background-color: #002345;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    a {
    color: #002345;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    a:focus,
  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    a:hover {
    color: #002345;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary:hover
    > a {
    color: #002345;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-primary
    > a.active {
    color: #002345;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    .dropdown-menu {
    background-color: #ff6600;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    .dropdown-menu
    li
    a {
    color: #777;
    border-bottom-color: #ffe5e3;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    .dropdown-menu
    li:hover
    > a {
    background: #ffffff;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary.open
    > a:before,
  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary:hover
    > a:before {
    border-bottom-color: #ff6600;
    background: #ff6600;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-mega.dropdown-secondary
    .dropdown-mega-sub-title {
    color: #777;
    opacity: 0.7;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary:hover
    > a {
    background: #ff6600;
    color: #777;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    .dropdown-menu
    li
    a {
    color: #777;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-secondary
    > a.active {
    background: #ff6600;
    color: #777;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    a:focus,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    a:hover {
    color: #ff6600;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    a:focus:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    a:hover:before {
    background-color: #ff6600;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary:hover
    > a {
    color: #ff6600;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary.open
    > a:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary:hover
    > a:before {
    background: #ff6600;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-secondary
    > a.active {
    color: #ff6600;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-secondary
    > a.active:before {
    background-color: #ff6600;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    a {
    color: #ff6600;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    a:focus,
  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    a:hover {
    color: #ff6600;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary:hover
    > a {
    color: #ff6600;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-secondary
    > a.active {
    color: #ff6600;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    .dropdown-menu {
    background-color: #6d7275;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    .dropdown-menu
    li
    a {
    color: #fff;
    border-bottom-color: #1e2329;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    .dropdown-menu
    li:hover
    > a {
    background: #2a333b;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary.open
    > a:before,
  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary:hover
    > a:before {
    border-bottom-color: #6d7275;
    background: #6d7275;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-mega.dropdown-tertiary
    .dropdown-mega-sub-title {
    color: #fff;
    opacity: 0.7;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary:hover
    > a {
    background: #6d7275;
    color: #fff;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    .dropdown-menu
    li
    a {
    color: #fff;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-tertiary
    > a.active {
    background: #6d7275;
    color: #fff;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    a:focus,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    a:hover {
    color: #6d7275;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    a:focus:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    a:hover:before {
    background-color: #6d7275;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary:hover
    > a {
    color: #6d7275;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary.open
    > a:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary:hover
    > a:before {
    background: #6d7275;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-tertiary
    > a.active {
    color: #6d7275;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-tertiary
    > a.active:before {
    background-color: #6d7275;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    a {
    color: #6d7275;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    a:focus,
  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    a:hover {
    color: #6d7275;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary:hover
    > a {
    color: #6d7275;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-tertiary
    > a.active {
    color: #6d7275;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    .dropdown-menu {
    background-color: #000;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    .dropdown-menu
    li
    a {
    color: #fff;
    border-bottom-color: #000000;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    .dropdown-menu
    li:hover
    > a {
    background: #080808;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary.open
    > a:before,
  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary:hover
    > a:before {
    border-bottom-color: #000;
    background: #000;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-mega.dropdown-quaternary
    .dropdown-mega-sub-title {
    color: #fff;
    opacity: 0.7;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary:hover
    > a {
    background: #000;
    color: #fff;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    .dropdown-menu
    li
    a {
    color: #fff;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-quaternary
    > a.active {
    background: #000;
    color: #fff;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    a:focus,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    a:hover {
    color: #000;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    a:focus:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    a:hover:before {
    background-color: #000;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary:hover
    > a {
    color: #000;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary.open
    > a:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary:hover
    > a:before {
    background: #000;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-quaternary
    > a.active {
    color: #000;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-quaternary
    > a.active:before {
    background-color: #000;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    a {
    color: #000;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    a:focus,
  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    a:hover {
    color: #000;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary:hover
    > a {
    color: #000;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-quaternary
    > a.active {
    color: #000;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    .dropdown-menu {
    background-color: #212529;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    .dropdown-menu
    li
    a {
    color: #fff;
    border-bottom-color: #1a1d21;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    .dropdown-menu
    li:hover
    > a {
    background: #282d31;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark.open
    > a:before,
  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark:hover
    > a:before {
    border-bottom-color: #212529;
    background: #212529;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-mega.dropdown-dark
    .dropdown-mega-sub-title {
    color: #fff;
    opacity: 0.7;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark:hover
    > a {
    background: #212529;
    color: #fff;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    .dropdown-menu
    li
    a {
    color: #fff;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-dark
    > a.active {
    background: #212529;
    color: #fff;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    a:focus,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    a:hover {
    color: #212529;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    a:focus:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    a:hover:before {
    background-color: #212529;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark:hover
    > a {
    color: #212529;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark.open
    > a:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark:hover
    > a:before {
    background: #212529;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-dark
    > a.active {
    color: #212529;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-dark
    > a.active:before {
    background-color: #212529;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    a {
    color: #212529;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    a:focus,
  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark
    a:hover {
    color: #212529;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-dark:hover
    > a {
    color: #212529;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-dark
    > a.active {
    color: #212529;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    .dropdown-menu {
    background-color: #fff;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    .dropdown-menu
    li
    a {
    color: #777;
    border-bottom-color: #f7f7f7;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    .dropdown-menu
    li:hover
    > a {
    background: #ffffff;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light.open
    > a:before,
  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light:hover
    > a:before {
    border-bottom-color: #fff;
    background: #fff;
  }

  #header
    .header-nav
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-mega.dropdown-light
    .dropdown-mega-sub-title {
    color: #777;
    opacity: 0.7;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light:hover
    > a {
    background: #fff;
    color: #777;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    .dropdown-menu
    li
    a {
    color: #777;
  }

  #header
    .header-nav:not(.header-nav-line):not(.header-nav-links)
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-light
    > a.active {
    background: #fff;
    color: #777;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    a:focus,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    a:hover {
    color: #fff;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    a:focus:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    a:hover:before {
    background-color: #fff;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light:hover
    > a {
    color: #fff;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light.open
    > a:before,
  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light:hover
    > a:before {
    background: #fff;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-light
    > a.active {
    color: #fff;
  }

  #header
    .header-nav-line
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-light
    > a.active:before {
    background-color: #fff;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    a {
    color: #fff;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    a:focus,
  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light
    a:hover {
    color: #fff;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-light:hover
    > a {
    color: #fff;
  }

  #header
    .header-nav-links
    .header-nav-main
    nav
    > ul:not(:hover)
    > li.dropdown-full-color.dropdown-light
    > a.active {
    color: #fff;
  }
}

@media (min-width: 992px) {
  #header
    .header-nav-force-light-text-active-skin-color
    .header-nav-main
    nav
    > ul
    > li:hover
    > a,
  #header
    .header-nav-force-light-text-active-skin-color
    .header-nav-main
    nav
    > ul
    > li
    .wrapper-items-cloned:hover
    > a {
    color: #002345 !important;
  }

  #header
    .header-nav-force-light-text-active-skin-color
    .header-nav-main
    nav
    > ul:not(:hover)
    > li
    > a.active,
  #header
    .header-nav-force-light-text-active-skin-color
    .header-nav-main
    nav
    > ul:not(:hover)
    > li
    .wrapper-items-cloned
    > a.active {
    color: #002345 !important;
  }
}

@media (max-width: 991px) {
  #header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a {
    color: #002345;
  }

  #header
    .header-nav-main:not(.header-nav-main-mobile-dark)
    nav
    > ul
    > li
    > a:active {
    color: #002345;
  }

  #header
    .header-nav-main:not(.header-nav-main-mobile-dark)
    nav
    > ul
    > li
    > a.active {
    background: #002345;
  }

  #header
    .header-nav-main:not(.header-nav-main-mobile-dark)
    nav
    > ul
    > li
    > a.active:focus,
  #header
    .header-nav-main:not(.header-nav-main-mobile-dark)
    nav
    > ul
    > li
    > a.active:hover {
    background: #002345;
  }

  #header
    .header-nav-main.header-nav-main-mobile-dark
    nav
    > ul
    > li
    > a.active {
    background: #002345;
  }

  #header
    .header-nav-main.header-nav-main-mobile-dark
    nav
    > ul
    > li
    > a.active:focus,
  #header
    .header-nav-main.header-nav-main-mobile-dark
    nav
    > ul
    > li
    > a.active:hover {
    background: #002345;
  }

  .side-header-hamburguer-sidebar
    #header.side-header
    .header-nav.header-nav-links
    .header-nav-main.header-nav-main-mobile-dark
    nav
    > ul
    > li
    > a.active {
    color: #002345 !important;
  }

  .side-header-hamburguer-sidebar
    #header.side-header
    .header-nav.header-nav-links
    nav::-webkit-scrollbar-thumb {
    background-color: #002345;
  }

  .side-header-hamburguer-sidebar
    #header.side-header
    .header-nav.header-nav-links
    nav
    > ul
    > li
    > a.active {
    color: #002345 !important;
  }

  .side-header-hamburguer-sidebar
    #header.side-header
    .header-nav.header-nav-links
    nav
    > ul
    > li:hover
    > a {
    color: #002345 !important;
  }
}

@media (max-width: 991px) {
  #header
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    > a {
    color: #002345;
  }

  #header
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-primary
    > a.active {
    background: #002345 !important;
    color: #fff !important;
  }

  #header
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    > a {
    color: #ff6600;
  }

  #header
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-secondary
    > a.active {
    background: #ff6600 !important;
    color: #777 !important;
  }

  #header
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    > a {
    color: #6d7275;
  }

  #header
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-tertiary
    > a.active {
    background: #6d7275 !important;
    color: #fff !important;
  }

  #header
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    > a {
    color: #000;
  }

  #header
    .header-nav-main
    nav
    > ul
    > li.dropdown-full-color.dropdown-quaternary
    > a.active {
    background: #000 !important;
    color: #fff !important;
  }
}

#header .header-top.header-top-primary {
  background: #002345;
  border-top-color: #f24e42;
}

#header .header-top.header-top-primary .header-nav-top ul > li.open > a {
  background: #001e47;
}

#header .header-top.header-top-primary .header-nav-top ul > li > a:hover,
#header .header-top.header-top-primary .header-nav-top ul > li.open > a:hover,
#header .header-top.header-top-primary .header-nav-top ul > li > a:focus,
#header .header-top.header-top-primary .header-nav-top ul > li.open > a:focus {
  background: #001e47;
}

#header .header-top.header-top-primary .dropdown-menu {
  background: #001e47;
  border-color: #001e47;
}

#header .header-top.header-top-primary .dropdown-menu a:hover,
#header .header-top.header-top-primary .dropdown-menu a:focus {
  background: #f7918a !important;
}



@media (min-width: 992px) {
  #header .header-nav-bar-primary {
    background: #002345 !important;
    color: #fff !important;
  }

  #header .header-nav-bar-secondary {
    background: #ff6600 !important;
    color: #777 !important;
  }

  
}

@media (min-width: 992px) {
  #header .header-nav-main.header-nav-main-light nav > ul > li.open > a,
  #header .header-nav-main.header-nav-main-light nav > ul > li:hover > a {
    color: #002345;
  }

  #header .header-nav-main.header-nav-main-light nav > ul > li > a.active {
    color: #002345;
  }
}

#header
  .header-nav-features
  .header-nav-features-search-reveal-big-search
  .form-control {
  border-bottom-color: #0088cc;
}



.circular-bar.only-icon [class*="fa-"],
.circular-bar.only-icon .icons {
  color: #002345;
}

.form-control.bg-primary,
.form-control.bg-color-primary {
  color: #fff;
  border-color: #002345;
}

.form-control.bg-primary::-webkit-input-placeholder,
.form-control.bg-color-primary::-webkit-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-primary::-moz-placeholder,
.form-control.bg-color-primary::-moz-placeholder {
  color: #e6e6e6;
}

.form-control.bg-primary:-ms-input-placeholder,
.form-control.bg-color-primary:-ms-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-primary:-moz-placeholder,
.form-control.bg-color-primary:-moz-placeholder {
  color: #e6e6e6;
}

.form-control .testimonial-primary blockquote {
  background: #001e47;
}

.form-control .testimonial-primary .testimonial-arrow-down {
  border-top-color: #001e47;
}

.form-control .testimonial-primary.testimonial-style-7 {
  background: #001e47;
}

.form-control .testimonial-quotes-primary blockquote:before,
.form-control .testimonial-quotes-primary blockquote:after {
  color: #002345 !important;
}

.form-control.bg-secondary,
.form-control.bg-color-secondary {
  color: #777;
  border-color: #ff6600;
}

.form-control.bg-secondary::-webkit-input-placeholder,
.form-control.bg-color-secondary::-webkit-input-placeholder {
  color: #5e5e5e;
}

.form-control.bg-secondary::-moz-placeholder,
.form-control.bg-color-secondary::-moz-placeholder {
  color: #5e5e5e;
}

.form-control.bg-secondary:-ms-input-placeholder,
.form-control.bg-color-secondary:-ms-input-placeholder {
  color: #5e5e5e;
}

.form-control.bg-secondary:-moz-placeholder,
.form-control.bg-color-secondary:-moz-placeholder {
  color: #5e5e5e;
}


.form-control.bg-tertiary,
.form-control.bg-color-tertiary {
  color: #fff;
  border-color: #6d7275;
}

.form-control.bg-tertiary::-webkit-input-placeholder,
.form-control.bg-color-tertiary::-webkit-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-tertiary::-moz-placeholder,
.form-control.bg-color-tertiary::-moz-placeholder {
  color: #e6e6e6;
}

.form-control.bg-tertiary:-ms-input-placeholder,
.form-control.bg-color-tertiary:-ms-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-tertiary:-moz-placeholder,
.form-control.bg-color-tertiary:-moz-placeholder {
  color: #e6e6e6;
}

.form-control .testimonial-tertiary blockquote {
  background: #2f3841;
}

.form-control .testimonial-tertiary .testimonial-arrow-down {
  border-top-color: #2f3841;
}

.form-control.bg-dark,
.form-control.bg-color-dark {
  color: #fff;
  border-color: #212529;
}

.form-control.bg-dark::-webkit-input-placeholder,
.form-control.bg-color-dark::-webkit-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-dark::-moz-placeholder,
.form-control.bg-color-dark::-moz-placeholder {
  color: #e6e6e6;
}

.form-control.bg-dark:-ms-input-placeholder,
.form-control.bg-color-dark:-ms-input-placeholder {
  color: #e6e6e6;
}

.form-control.bg-dark:-moz-placeholder,
.form-control.bg-color-dark:-moz-placeholder {
  color: #e6e6e6;
}

.icon-featured {
  background-color: #002345;
}



@-webkit-keyframes icons {
  0% {
    stroke: #002345;
    fill: rgba(244, 100, 90, 0);
  }

  90% {
    stroke: #002345;
    fill: rgba(244, 100, 90, 0);
  }

  100% {
    stroke: rgba(244, 100, 90, 0);
    fill: #002345;
  }
}

@keyframes icons {
  0% {
    stroke: #002345;
    fill: rgba(244, 100, 90, 0);
  }

  90% {
    stroke: #002345;
    fill: rgba(244, 100, 90, 0);
  }

  100% {
    stroke: rgba(244, 100, 90, 0);
    fill: #002345;
  }
}
/*
* General Styles
*/
svg circle.icons-circle {
  stroke: #002345;
}

.custom-bg-color-1 {
  background-color: hsl(3.8961039, 78%, 56%) !important;
}
/*
* Custom Classes
*/
/* .custom-btn-style-1,
.custom-btn-style-1:active {
  background: #002345;
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #002345),
    color-stop(100%, hsl(3.8961039, 100%, 75%))
  );
  background: linear-gradient(
    to right,
    #002345 0%,
    hsl(3.8961039, 100%, 75%) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002345', endColorstr='#ff857c', GradientType=1);
} */

.custom-btn-style-1._borders {
  border: 1px solid hsl(3.8961039, 100%, 75%);
}

/* .custom-btn-style-1._borders:hover,
.custom-btn-style-1._borders:active,
.custom-btn-style-1._borders:focus {
  border-color: hsl(3.8961039, 100%, 75%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #002345),
    color-stop(100%, hsl(3.8961039, 100%, 75%))
  );
  background: linear-gradient(
    to right,
    #002345 0%,
    hsl(3.8961039, 100%, 75%) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002345', endColorstr='#ff857c', GradientType=1);
} */

h2.custom-bar:after {
  border-bottom-color: #002345;
}
/*
* FAQ'S
*/
.custom-accordion-style-1 .card-header .card-title a:not(.collapsed) {
  color: #002345 !important;
  border-left-color: #002345;
  background: #ff6600;
}
.custom-accordion-style-1 .card-header .card-title a:not(.collapsed):after {
  border-color: #002345 transparent transparent transparent;
}
#footer .footer-copyright nav ul li:hover a {
  color: #002345 !important;
}


::-moz-selection {
  color: #fff;
  background: #002345;
}

::selection {
  color: #fff;
  background: #002345;
}