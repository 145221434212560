.accordion .card {
	margin-top: 5px;
}

.accordion .card:first-child {
	margin-top: 0;
}

.accordion .card:first-of-type, .accordion .card:not(:first-of-type):not(:last-of-type), .accordion .card:last-of-type {
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: .25rem;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
	border-radius: .25rem;
}

.accordion .card-title {
	font-size: 16px;
}

.accordion .card-header {
	padding: 0;
	border-radius: .25rem;
	border-bottom: 0;
	margin: -1px;
}

.accordion .card-header a {
	cursor: pointer;
	display: block;
	padding: 10px 20px;
}

.accordion .card-header a:hover, .accordion .card-header a:focus {
	text-decoration: none;
}

.accordion .card-header a [class^="icon-"] {
	margin-right: 4px;
	position: relative;
	top: 1px;
}

.accordion .card-header a [class*="fa-"], .accordion .card-header a .icons {
	display: inline-block;
	margin-right: 5px;
	position: relative;
	top: -1px;
}

.accordion .card-header a .icons {
	top: 1px;
}

// .accordion.accordion-sm .card-header a {
// 	padding: 5px 15px;
// 	font-size: 0.85em;
// }

// .accordion.accordion-sm .card-body {
// 	font-size: 0.9em;
// }

.accordion.accordion-lg .card-header a {
	padding: 15px 15px;
	font-size: 1em;
	font-weight: 600;
}

.accordion.without-bg .card-default {
	background: transparent !important;
}

.accordion.without-bg .card-default > .card-header {
	background: transparent !important;
}

.accordion.without-bg .card-body {
	padding-top: 0;
}

// .accordion.without-borders .card {
// 	border: 0 !important;
// 	box-shadow: none;
// 	border-radius: 0;
// }

// .accordion.without-borders .card-header a {
// 	padding-left: 0;
// 	padding-right: 0;
// }

// .accordion.without-borders .card-body {
// 	padding: 0;
// }

.accordion.accordion-modern .card:first-of-type, .accordion.accordion-modern .card:not(:first-of-type):not(:last-of-type), .accordion.accordion-modern .card:last-of-type {
	border: 4px solid #F7F7F7;
}

.accordion.accordion-modern .card .card-header {
	background-color: #F7F7F7;
	border-radius: 0;
}

.accordion.accordion-modern-grey-scale-1 .card:first-of-type, .accordion.accordion-modern-grey-scale-1 .card:not(:first-of-type):not(:last-of-type), .accordion.accordion-modern-grey-scale-1 .card:last-of-type {
	border-color: #EDEDED;
}

.accordion.accordion-modern-grey-scale-1 .card .card-header {
	background-color: #EDEDED;
}

/* Animations */
.appear-animation {
	opacity: 0;
}

.appear-animation-visible {
	opacity: 1;
}

/* Transitions */
.transition-2ms {
	transition: all 0.2s ease-in-out;
}

/* Side Header Arrow Animations */
// @-webkit-keyframes sideMenuArrow {
// 	0% {
// 		left: 5px;
// 	}

// 	50% {
// 		left: 10px;
// 	}

// 	100% {
// 		left: 5px;
// 	}
// }

// @keyframes sideMenuArrow {
// 	0% {
// 		left: 5px;
// 	}

// 	50% {
// 		left: 10px;
// 	}

// 	100% {
// 		left: 5px;
// 	}
// }

// @-webkit-keyframes sideMenuArrowBack {
// 	0% {
// 		right: 5px;
// 	}

// 	50% {
// 		right: 10px;
// 	}

// 	100% {
// 		right: 5px;
// 	}
// }

// @keyframes sideMenuArrowBack {
// 	0% {
// 		right: 5px;
// 	}

// 	50% {
// 		right: 10px;
// 	}

// 	100% {
// 		right: 5px;
// 	}
// }

// /* Mask Up */
// @-webkit-keyframes maskUp {
// 	from {
// 		transform: translate(0, 100%);
// 	}

// 	to {
// 		transform: translate(0, 0);
// 	}
// }

// @keyframes maskUp {
// 	from {
// 		transform: translate(0, 100%);
// 	}

// 	to {
// 		transform: translate(0, 0);
// 	}
// }

// /* Mask Right */
// @-webkit-keyframes maskRight {
// 	from {
// 		transform: translate(-100%, 0);
// 	}

// 	to {
// 		transform: translate(0, 0);
// 	}
// }

// @keyframes maskRight {
// 	from {
// 		transform: translate(-100%, 0);
// 	}

// 	to {
// 		transform: translate(0, 0);
// 	}
// }

// /* Mask Down */
// @-webkit-keyframes maskDown {
// 	from {
// 		transform: translate(0, -100%);
// 	}

// 	to {
// 		transform: translate(0, 0);
// 	}
// }

// @keyframes maskDown {
// 	from {
// 		transform: translate(0, -100%);
// 	}

// 	to {
// 		transform: translate(0, 0);
// 	}
// }

// /* Mask Left */
// @-webkit-keyframes maskLeft {
// 	from {
// 		transform: translate(100%, 0);
// 	}

// 	to {
// 		transform: translate(0, 0);
// 	}
// }

// @keyframes maskLeft {
// 	from {
// 		transform: translate(100%, 0);
// 	}

// 	to {
// 		transform: translate(0, 0);
// 	}
// }

// .maskUp {
// 	-webkit-animation-name: maskUp;
// 	animation-name: maskUp;
// }

// .maskRight {
// 	-webkit-animation-name: maskRight;
// 	animation-name: maskRight;
// }

// .maskDown {
// 	-webkit-animation-name: maskDown;
// 	animation-name: maskDown;
// }

// .maskLeft {
// 	-webkit-animation-name: maskLeft;
// 	animation-name: maskLeft;
// }

// @-webkit-keyframes headerReveal {
// 	from {
// 		top: -150px;
// 	}

// 	to {
// 		top: 0;
// 	}
// }

// @keyframes headerReveal {
// 	from {
// 		top: -150px;
// 	}

// 	to {
// 		top: 0;
// 	}
// }

// /* Fade In Up Shorter */
// @-webkit-keyframes fadeInUpShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(0, 50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInUpShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(0, 50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInUpShorter {
// 	-webkit-animation-name: fadeInUpShorter;
// 	animation-name: fadeInUpShorter;
// }

// /* Fade In Up Shorter Plus */
// @-webkit-keyframes fadeInUpShorterPlus {
// 	from {
// 		opacity: 0;
// 		transform: translate(0, 15px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInUpShorterPlus {
// 	from {
// 		opacity: 0;
// 		transform: translate(0, 15px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInUpShorterPlus {
// 	-webkit-animation-name: fadeInUpShorterPlus;
// 	animation-name: fadeInUpShorterPlus;
// }

// /* Fade In Left Shorter */
// @-webkit-keyframes fadeInLeftShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(50px, 0);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInLeftShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(50px, 0);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInLeftShorter {
// 	-webkit-animation-name: fadeInLeftShorter;
// 	animation-name: fadeInLeftShorter;
// }

// /* Fade In Left Shorter Plus */
// @-webkit-keyframes fadeInLeftShorterPlus {
// 	from {
// 		opacity: 0;
// 		transform: translate(15px, 0);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInLeftShorterPlus {
// 	from {
// 		opacity: 0;
// 		transform: translate(15px, 0);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInLeftShorterPlus {
// 	-webkit-animation-name: fadeInLeftShorterPlus;
// 	animation-name: fadeInLeftShorterPlus;
// }

// /* Fade In Right Shorter */
// @-webkit-keyframes fadeInRightShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(-50px, 0);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInRightShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(-50px, 0);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInRightShorter {
// 	-webkit-animation-name: fadeInRightShorter;
// 	animation-name: fadeInRightShorter;
// }

// /* Fade In Right Shorter Plus */
// @-webkit-keyframes fadeInRightShorterPlus {
// 	from {
// 		opacity: 0;
// 		transform: translate(-15px, 0);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInRightShorterPlus {
// 	from {
// 		opacity: 0;
// 		transform: translate(-15px, 0);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInRightShorterPlus {
// 	-webkit-animation-name: fadeInRightShorterPlus;
// 	animation-name: fadeInRightShorterPlus;
// }

// /* Fade In Right Shorter With Opacity */
// @-webkit-keyframes fadeInRightShorterOpacity {
// 	from {
// 		opacity: 0;
// 		transform: translate(-50px, 0);
// 	}

// 	to {
// 		opacity: 0.7;
// 		transform: none;
// 	}
// }

// @keyframes fadeInRightShorterOpacity {
// 	from {
// 		opacity: 0;
// 		transform: translate(-50px, 0);
// 	}

// 	to {
// 		opacity: 0.7;
// 		transform: none;
// 	}
// }

// .fadeInRightShorterOpacity {
// 	-webkit-animation-name: fadeInRightShorterOpacity;
// 	animation-name: fadeInRightShorterOpacity;
// }

// /* Fade In Down Shorter */
// @-webkit-keyframes fadeInDownShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(0, -50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInDownShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(0, -50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInDownShorter {
// 	-webkit-animation-name: fadeInDownShorter;
// 	animation-name: fadeInDownShorter;
// }

// /* Fade In Down Shorter Plus */
// @-webkit-keyframes fadeInDownShorterPlus {
// 	from {
// 		opacity: 0;
// 		transform: translate(0, -15px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInDownShorterPlus {
// 	from {
// 		opacity: 0;
// 		transform: translate(0, -15px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInDownShorterPlus {
// 	-webkit-animation-name: fadeInDownShorterPlus;
// 	animation-name: fadeInDownShorterPlus;
// }

// /* Fade In Right and Down Shorter */
// @-webkit-keyframes fadeInRightDownShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(-50px, -50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInRightDownShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(-50px, -50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInRightDownShorter {
// 	-webkit-animation-name: fadeInRightDownShorter;
// 	animation-name: fadeInRightDownShorter;
// }

// /* Fade In Right and Up Shorter */
// @-webkit-keyframes fadeInRightUpShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(-50px, 50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInRightUpShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(-50px, 50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInRightUpShorter {
// 	-webkit-animation-name: fadeInRightUpShorter;
// 	animation-name: fadeInRightUpShorter;
// }

// /* Fade In Left and Down Shorter */
// @-webkit-keyframes fadeInLeftDownShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(50px, -50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInLeftDownShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(50px, -50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInLeftDownShorter {
// 	-webkit-animation-name: fadeInLeftDownShorter;
// 	animation-name: fadeInLeftDownShorter;
// }

// /* Fade In Left and Up Shorter */
// @-webkit-keyframes fadeInLeftUpShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(50px, 50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// @keyframes fadeInLeftUpShorter {
// 	from {
// 		opacity: 0;
// 		transform: translate(50px, 50px);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: none;
// 	}
// }

// .fadeInLeftUpShorter {
// 	-webkit-animation-name: fadeInLeftUpShorter;
// 	animation-name: fadeInLeftUpShorter;
// }

// /* Expand In */
// @-webkit-keyframes expandIn {
// 	from {
// 		opacity: 0;
// 		transform: scale(0.8);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: scale(1);
// 	}
// }

// @keyframes expandIn {
// 	from {
// 		opacity: 0;
// 		transform: scale(0.8);
// 	}

// 	to {
// 		opacity: 1;
// 		transform: scale(1);
// 	}
// }

// .expandIn {
// 	-webkit-animation-name: expandIn;
// 	animation-name: expandIn;
// }

// /* Gradient Transition */
// @-webkit-keyframes gradientTransition {
// 	0% {
// 		background-position: 0% 50%;
// 	}

// 	50% {
// 		background-position: 100% 50%;
// 	}

// 	100% {
// 		background-position: 0% 50%;
// 	}
// }

// @keyframes gradientTransition {
// 	0% {
// 		background-position: 0% 50%;
// 	}

// 	50% {
// 		background-position: 100% 50%;
// 	}

// 	100% {
// 		background-position: 0% 50%;
// 	}
// }

// /* Slider Scroll Button Animation */
// @-webkit-keyframes sliderScrollButton {
// 	0% {
// 		opacity: 1;
// 		transform: translate3d(-50%, 0, 0);
// 	}

// 	70% {
// 		opacity: 1;
// 		transform: translate3d(-50%, 10px, 0);
// 	}

// 	100% {
// 		opacity: 0;
// 		transform: translate3d(-50%, 10px, 0);
// 	}
// }

// @keyframes sliderScrollButton {
// 	0% {
// 		opacity: 1;
// 		transform: translate3d(-50%, 0, 0);
// 	}

// 	70% {
// 		opacity: 1;
// 		transform: translate3d(-50%, 10px, 0);
// 	}

// 	100% {
// 		opacity: 0;
// 		transform: translate3d(-50%, 10px, 0);
// 	}
// }

// /* Arrow Left Right */
// @-webkit-keyframes arrowLeftRight {
// 	0% {
// 		transform: translate3d(-1px, 0, 0);
// 	}

// 	50% {
// 		transform: translate3d(1px, 0, 0);
// 	}

// 	100% {
// 		transform: translate3d(-1px, 0, 0);
// 	}
// }

// @keyframes arrowLeftRight {
// 	0% {
// 		transform: translate3d(-1px, 0, 0);
// 	}

// 	50% {
// 		transform: translate3d(1px, 0, 0);
// 	}

// 	100% {
// 		transform: translate3d(-1px, 0, 0);
// 	}
// }

// /* Nav Item Arrow */
// @-webkit-keyframes navItemArrow {
// 	0% {
// 		position: relative;
// 		left: -1px;
// 	}

// 	50% {
// 		position: relative;
// 		left: 3px;
// 	}

// 	100% {
// 		position: relative;
// 		left: -1px;
// 	}
// }

// @keyframes navItemArrow {
// 	0% {
// 		position: relative;
// 		left: -1px;
// 	}

// 	50% {
// 		position: relative;
// 		left: 3px;
// 	}

// 	100% {
// 		position: relative;
// 		left: -1px;
// 	}
// }

// /* Hover 3d Effect */
// .hover-in {
// 	transition: .1s ease-out !important;
// }

// .hover-out {
// 	transition: .1s ease-in !important;
// }

// .hover-3d {
// 	transition: none;
// }

// /* Hotspot */
// @-webkit-keyframes hotspot-pulsate {
// 	0% {
// 		transform: scale(1);
// 		opacity: 0.8;
// 	}

// 	45% {
// 		transform: scale(1.75);
// 		opacity: 0;
// 	}
// }

// @keyframes hotspot-pulsate {
// 	0% {
// 		transform: scale(1);
// 		opacity: 0.8;
// 	}

// 	45% {
// 		transform: scale(1.75);
// 		opacity: 0;
// 	}
// }

// @-webkit-keyframes stop-hotspot-pulsate {
// 	from {
// 		opacity: 0.4;
// 	}

// 	to {
// 		transform: scale(2);
// 		opacity: 0;
// 	}
// }

// @keyframes stop-hotspot-pulsate {
// 	from {
// 		opacity: 0.4;
// 	}

// 	to {
// 		transform: scale(2);
// 		opacity: 0;
// 	}
// }

// /* Dot Pulse */
// @-webkit-keyframes dotPulse {
// 	from {
// 		opacity: 1;
// 		transform: scale(0.2);
// 	}

// 	to {
// 		opacity: 0;
// 		transform: scale(1);
// 	}
// }

// @keyframes dotPulse {
// 	from {
// 		opacity: 1;
// 		transform: scale(0.2);
// 	}

// 	to {
// 		opacity: 0;
// 		transform: scale(1);
// 	}
// }

// .dotPulse {
// 	-webkit-animation-name: dotPulse;
// 	animation-name: dotPulse;
// 	-webkit-animation-iteration-count: infinite;
// 	animation-iteration-count: infinite;
// 	-webkit-animation-duration: 4s;
// 	animation-duration: 4s;
// }

// /* Blur In */
// @-webkit-keyframes blurIn {
// 	from {
// 		opacity: 0;
// 		filter: blur(20px);
// 		transform: scale(1.3);
// 	}

// 	to {
// 		opacity: 1;
// 		filter: blur(0);
// 		transform: none;
// 	}
// }

// @keyframes blurIn {
// 	from {
// 		opacity: 0;
// 		filter: blur(20px);
// 		transform: scale(1.3);
// 	}

// 	to {
// 		opacity: 1;
// 		filter: blur(0);
// 		transform: none;
// 	}
// }

// .blurIn {
// 	-webkit-animation-name: blurIn;
// 	animation-name: blurIn;
// }

// /* Ken Burns To Right */
// @-webkit-keyframes kenBurnsToRight {
// 	from {
// 		transform: scale(1.2);
// 	}

// 	to {
// 		transform: scale(1);
// 	}
// }

// @keyframes kenBurnsToRight {
// 	from {
// 		transform: scale(1.2);
// 	}

// 	to {
// 		transform: scale(1);
// 	}
// }

// .kenBurnsToRight {
// 	-webkit-animation-name: kenBurnsToRight;
// 	animation-name: kenBurnsToRight;
// 	-webkit-animation-timing-function: linear;
// 	animation-timing-function: linear;
// 	transform-origin: right;
// }

// /* Ken Burns To Left */
// @-webkit-keyframes kenBurnsToLeft {
// 	from {
// 		transform: scale(1.2);
// 	}

// 	to {
// 		transform: scale(1);
// 	}
// }

// @keyframes kenBurnsToLeft {
// 	from {
// 		transform: scale(1.2);
// 	}

// 	to {
// 		transform: scale(1);
// 	}
// }

// .kenBurnsToLeft {
// 	-webkit-animation-name: kenBurnsToLeft;
// 	animation-name: kenBurnsToLeft;
// 	-webkit-animation-timing-function: linear;
// 	animation-timing-function: linear;
// 	transform-origin: left;
// }

// /* Ken Burns To Left and Top */
// .kenBurnsToLeftTop {
// 	-webkit-animation-name: kenBurnsToLeft;
// 	animation-name: kenBurnsToLeft;
// 	-webkit-animation-timing-function: linear;
// 	animation-timing-function: linear;
// 	transform-origin: left top;
// }

// /* Moving From Top to Bottom */
// @-webkit-keyframes bgPositionBottomToTop {
// 	from {
// 		background-position: bottom;
// 	}

// 	to {
// 		background-position: top;
// 	}
// }

// @keyframes bgPositionBottomToTop {
// 	from {
// 		background-position: bottom;
// 	}

// 	to {
// 		background-position: top;
// 	}
// }

// .bgPositionBottomToTop {
// 	-webkit-animation-name: bgPositionBottomToTop;
// 	animation-name: bgPositionBottomToTop;
// 	-webkit-animation-timing-function: linear;
// 	animation-timing-function: linear;
// 	transform-origin: right;
// }

/* Pulse */
@-webkit-keyframes pulseAnim {
	from {
		transform: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(1.4);
		opacity: 0;
	}
}

@keyframes pulseAnim {
	from {
		transform: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(1.4);
		opacity: 0;
	}
}

.pulseAnim:not(:hover):after {
	-webkit-animation-name: pulseAnim;
	animation-name: pulseAnim;
}

.pulseAnim:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: inherit;
	border-radius: inherit;
	z-index: -1;
}

.pulseAnim.pulseAnimAnimated:after {
	-webkit-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.pulseAnim:not(i):after {
	z-index: 0;
}

.pulseAnim:not(i) * {
	position: relative;
	z-index: 1;
}

/* Basic Rotation */
// @-webkit-keyframes basicRotation {
// 	from {
// 		transform: rotate(0deg);
// 	}

// 	to {
// 		transform: rotate(360deg);
// 	}
// }

// @keyframes basicRotation {
// 	from {
// 		transform: rotate(0deg);
// 	}

// 	to {
// 		transform: rotate(360deg);
// 	}
// }


/* Responsive */
@media (max-width: 575px) {
	.arrow {
		display: none;
	}
}

/* Background Scales Overlay */
// .bg-color-scale-overlay {
// 	position: relative;
// }

// .bg-color-scale-overlay:before {
// 	content: "";
// 	display: block;
// 	background: #000;
// 	bottom: 0;
// 	height: 100%;
// 	left: 0;
// 	position: absolute;
// 	right: 0;
// 	top: 0;
// 	width: 100%;
// 	z-index: 1;
// 	opacity: 0;
// }

// .bg-color-scale-overlay .bg-color-scale-overlay-wrapper {
// 	position: relative;
// 	z-index: 2;
// }

// .bg-color-scale-overlay-1:before {
// 	opacity: 0.05 !important;
// }

// .bg-color-scale-overlay-2:before {
// 	opacity: 0.1 !important;
// }

// .bg-color-scale-overlay-3:before {
// 	opacity: 0.15 !important;
// }

// .bg-color-scale-overlay-4:before {
// 	opacity: 0.2 !important;
// }

// .bg-color-scale-overlay-5:before {
// 	opacity: 0.25 !important;
// }

// .bg-color-scale-overlay-6:before {
// 	opacity: 0.3 !important;
// }

// .bg-color-scale-overlay-7:before {
// 	opacity: 0.35 !important;
// }

// .bg-color-scale-overlay-8:before {
// 	opacity: 0.4 !important;
// }

// .bg-color-scale-overlay-9:before {
// 	opacity: 0.45 !important;
// }

/* Background Transparent */
.background-transparent,
.bg-transparent {
	background: transparent !important;
}

.bg-transparent-hover:hover {
	background-color: transparent !important;
}

.bg-color-transparent {
	background-color: transparent !important;
}

/* Background White and Black */
.bg-color-white {
	background: #FFF !important;
}

.bg-color-black {
	background: #000 !important;
}

/* Labels */
.badge-primary {
	background-color: #CCC;
	color: #FFF;
}

.badge-success {
	background-color: #28a745;
	color: #FFF;
}

.badge-warning {
	background-color: #ffc107;
	color: #FFF;
}

.badge-danger {
	background-color: #dc3545;
	color: #FFF;
}

.badge-info {
	background-color: #17a2b8;
	color: #FFF;
}

.badge-dark {
	background-color: #212529;
	color: #FFF;
}

.badge.badge-xs {
	padding: 0.3em 0.5em;
}

.badge.badge-sm {
	padding: 0.5em 0.8em;
}

.badge.badge-md {
	padding: 1em 1.6em;
	font-size: 85%;
}

.badge.badge-lg {
	padding: 1.5em 2em;
	font-size: 90%;
}

.badge.badge-xl {
	padding: 2em 2.5em;
	font-size: 100%;
}

.badge-ecommerce {
	font-size: 9.6px;
	font-size: 0.6rem;
	font-weight: 600;
	border-radius: 0;
	padding: 6.4px 8px;
	padding: 0.4rem 0.5rem;
}

/* Circular Bars */
// .circular-bar {
// 	text-align: center;
// 	margin-bottom: 25px;
// }

// .circular-bar .circular-bar-chart {
// 	position: relative;
// }

// .circular-bar strong {
// 	display: block;
// 	font-weight: 600;
// 	font-size: 18px;
// 	line-height: 30px;
// 	position: absolute;
// 	top: 35%;
// 	width: 80%;
// 	left: 10%;
// 	text-align: center;
// }

// .circular-bar label {
// 	display: block;
// 	font-weight: 100;
// 	font-size: 17px;
// 	line-height: 20px;
// 	position: absolute;
// 	top: 50%;
// 	width: 80%;
// 	left: 10%;
// 	text-align: center;
// }

// .circular-bar.only-icon [class*="fa-"], .circular-bar.only-icon .icons {
// 	display: block;
// 	font-size: 38px;
// 	line-height: 30px;
// 	position: absolute;
// 	top: 40%;
// 	width: 80%;
// 	left: 10%;
// 	text-align: center;
// }

// .circular-bar.single-line strong {
// 	top: 40%;
// }

// .circular-bar.circular-bar-sm label {
// 	font-size: 14px;
// 	top: 36%;
// }

// .circular-bar.circular-bar-lg strong {
// 	font-size: 20px;
// 	top: 40%;
// }

/* Progress */
.progress {
	border-radius: 25px;
	height: 20px;
	background: #FAFAFA;
	overflow: visible;
}

.progress.progress-border-radius {
	border-radius: 3px;
}

.progress.progress-border-radius .progress-bar {
	border-radius: 3px;
}

.progress.progress-no-border-radius {
	border-radius: 0;
}

.progress.progress-no-border-radius .progress-bar {
	border-radius: 0;
}

.progress.progress-xs {
	height: 5px;
}

.progress.progress-xs .progress-bar {
	line-height: 5px;
	height: auto;
}

.progress.progress-sm {
	height: 10px;
}

.progress.progress-sm .progress-bar {
	line-height: 10px;
	height: auto;
}

.progress.progress-lg {
	height: 35px;
}

.progress.progress-lg .progress-bar {
	font-size: 13px;
	line-height: 35px;
	height: auto;
}

.progress.progress-dark {
	background: #333333;
}

.progress.progress-right-side-value {
	position: relative;
	margin-right: 40px;
}

.progress.progress-right-side-value .progress-bar-value {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate3d(40px, -50%, 0);
}

.progress-bar {
	background-color: #CCC;
	box-shadow: none;
	position: relative;
	border-radius: 25px;
	overflow: visible;
}

.progress-bar-tooltip {
	position: absolute;
	padding: 4px 8px;
	background-color: #333;
	color: #FFF;
	line-height: 15px;
	font-size: 11px;
	display: block;
	position: absolute;
	top: -28px;
	right: 5px;
	border-radius: 3px;
	opacity: 0;
}

.progress-bar-tooltip:after {
	border-color: #333 transparent;
	border-style: solid;
	border-width: 5px 5px 0;
	bottom: -5px;
	content: "";
	display: block;
	left: 13px;
	position: absolute;
	width: 0;
}

.progress-bar-primary {
	background-color: #CCC;
	color: #FFF;
}

.progress-bar-success {
	background-color: #28a745;
	color: #FFF;
}

.progress-bar-warning {
	background-color: #ffc107;
	color: #FFF;
}

.progress-bar-danger {
	background-color: #dc3545;
	color: #FFF;
}

.progress-bar-info {
	background-color: #17a2b8;
	color: #FFF;
}

.progress-bar-dark {
	background-color: #212529;
	color: #FFF;
}

/* Borders */
.border-top {
	border-top: 1px solid #DADADA !important;
}

.border-bottom {
	border-bottom: 1px solid #DADADA !important;
}

.border-left {
	border-left: 1px solid #DADADA !important;
}

.border-right {
	border-right: 1px solid #DADADA !important;
}

.border-all {
	border: 1px solid #DADADA !important;
}

.border-top-light {
	border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.border-bottom-light {
	border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.border-left-light {
	border-left: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.border-right-light {
	border-right: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.border-all-light {
	border: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.border-white {
	border-color: #FFF !important;
}

.border-width-1 {
	border-width: 1px !important;
}

.border-width-2 {
	border-width: 2px !important;
}

.border-width-3 {
	border-width: 3px !important;
}

.border-width-4 {
	border-width: 4px !important;
}

.border-width-5 {
	border-width: 5px !important;
}

.border-width-6 {
	border-width: 6px !important;
}

.border-width-7 {
	border-width: 7px !important;
}

.border-width-8 {
	border-width: 8px !important;
}

.border-width-9 {
	border-width: 9px !important;
}

.border-width-10 {
	border-width: 10px !important;
}

@media (max-width: 767px) {
	.border-sm-none {
		border: 0 !important;
	}
}

/* Breadcrumb */
.breadcrumb {
	background: none;
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 1;
}

.breadcrumb > li {
	display: inline-block;
	font-size: 0.8em;
	text-transform: uppercase;
	text-shadow: none;
}

.breadcrumb > li + li:before {
	color: inherit;
	opacity: 0.5;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: "\f105";
	padding: 0 7px 0 5px;
}

.breadcrumb.breadcrumb-dividers-no-opacity > li + li:before {
	opacity: 1;
}

.breadcrumb.breadcrumb-style-2 > li + li:before {
	content: "/";
}

.breadcrumb.breadcrumb-block {
	background: #212529;
	display: inline-block !important;
	padding: 7.2px 19.2px;
	padding: .45rem 1.2rem;
	border-radius: 4px;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.breadcrumb.breadcrumb-light {
	color: #FFF;
}

.breadcrumb.breadcrumb-light a {
	color: #FFF;
}

.breadcrumb.breadcrumb-light .active {
	opacity: 0.7;
}

/* Buttons */
.btn {
	font-size: 12.8px;
	font-size: 0.8rem;
	padding: 8.528px 14.928px;
	padding: 0.533rem 0.933rem;
	cursor: pointer;
}

.btn.btn-xs {
	font-size: 11.2px;
	font-size: 0.7rem;
	padding: 3.2px 8px;
	padding: 0.2rem 0.5rem;
}

.btn.btn-sm {
	font-size: 12.8px;
	font-size: 0.8rem;
	padding: 4.8px 10.4px;
	padding: 0.3rem 0.65rem;
}

.btn.btn-lg {
	font-size: 16px;
	font-size: 1rem;
	padding: 8px 16px;
	padding: 0.5rem 1rem;
}

.btn.btn-xl {
	font-size: 16px;
	font-size: 1rem;
	padding: 12.8px 32px;
	padding: 0.8rem 2rem;
}

.btn.btn-px-1 {
	padding-left: 5.6px;
	padding-left: .35rem;
	padding-right: 5.6px;
	padding-right: .35rem;
}

.btn.btn-px-2 {
	padding-left: 12px;
	padding-left: .75rem;
	padding-right: 12px;
	padding-right: .75rem;
}

.btn.btn-px-3 {
	padding-left: 20px;
	padding-left: 1.25rem;
	padding-right: 20px;
	padding-right: 1.25rem;
}

.btn.btn-px-4 {
	padding-left: 28px;
	padding-left: 1.75rem;
	padding-right: 28px;
	padding-right: 1.75rem;
}

.btn.btn-px-5 {
	padding-left: 38.4px;
	padding-left: 2.4rem;
	padding-right: 38.4px;
	padding-right: 2.4rem;
}

.btn.btn-py-1 {
	padding-top: 5.6px;
	padding-top: .35rem;
	padding-bottom: 5.6px;
	padding-bottom: .35rem;
}

.btn.btn-py-2 {
	padding-top: 12px;
	padding-top: .75rem;
	padding-bottom: 12px;
	padding-bottom: .75rem;
}

.btn.btn-py-3 {
	padding-top: 20px;
	padding-top: 1.25rem;
	padding-bottom: 20px;
	padding-bottom: 1.25rem;
}

.btn.btn-py-4 {
	padding-top: 28px;
	padding-top: 1.75rem;
	padding-bottom: 28px;
	padding-bottom: 1.75rem;
}

.btn.btn-py-5 {
	padding-top: 32px;
	padding-top: 2rem;
	padding-bottom: 32px;
	padding-bottom: 2rem;
}

html body .btn.focus,
html body .btn:focus,
html body .btn.active,
html body .btn:active, html body.body .btn.focus,
html body.body .btn:focus,
html body.body .btn.active,
html body.body .btn:active {
	box-shadow: none !important;
}

/* Dropdown Buttons */
.btn + .dropdown-menu .dropdown-item {
	font-size: 12.8px;
	font-size: 0.8rem;
}

/* Default */
.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
	box-shadow: none !important;
}

.btn-default:hover, .btn-default:active, .btn-default:focus {
	color: #333;
	background-color: #e6e6e6;
	border-color: #adadad;
}

/* Outline */
.btn-outline {
	border-width: 2px;
	padding: 7.728px 14.928px;
	padding: 0.483rem 0.933rem;
}

.btn-outline.btn-xs {
	padding: 2.4px 8px;
	padding: 0.15rem 0.5rem;
}

.btn-outline.btn-sm {
	padding: 4px 10.4px;
	padding: 0.25rem 0.65rem;
}

.btn-outline.btn-lg {
	padding: 7.2px 16px;
	padding: 0.45rem 1rem;
}

.btn-outline.btn-xl {
	padding: 12px 32px;
	padding: 0.75rem 2rem;
}

.btn-outline.btn-outline-thin {
	border-width: 1px;
}

/* Modern */
.btn-modern {
	text-transform: uppercase;
	font-size: .8em;
	padding: 12.8px 24px;
	padding: 0.8rem 1.5rem;
	font-weight: 600;
}

/* Rounded */
.btn-rounded {
	border-radius: 35px;
}

/* Rounded */
.btn-rounded-icon {
	border-radius: 50px;
	padding: 0;
	width: 50px;
	height: 50px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.btn-rounded-icon[data-hash] > i {
	pointer-events: none;
}

/* 3d */
.btn-3d {
	border-bottom-width: 3px;
}

/* Arrow */
.btn-with-arrow {
	background-color: transparent;
	font-weight: 600;
}

.btn-with-arrow span {
	background-color: #ccc;
	display: inline-block;
	height: 25px;
	line-height: 24px;
	width: 24px;
	border-radius: 50%;
	margin-left: 10px;
	transition: all 0.1s linear;
}

.btn-with-arrow i {
	color: #FFF;
	font-size: 8px;
	top: -1px;
	position: relative;
}

.btn-with-arrow:hover span {
	transform: translateX(5px);
}

.btn-with-arrow.btn-lg span, .btn-with-arrow.btn-xl span {
	line-height: 23px;
}

.btn-with-arrow.btn-xs span {
	line-height: 25px;
}

/* Icons	*/
.btn-icon i {
	margin-right: 10px;
}

.btn-icon-right i {
	margin-right: 0;
	margin-left: 10px;
}

/* Dropdown Button */
.btn.dropdown-toggle:after {
	margin-left: .155em;
	vertical-align: .155em;
}

/* Colors */
.btn-primary {
	background-color: #CCC;
	border-color: #CCC #CCC #b3b2b2;
	color: #FFF;
}

.btn-primary:hover, .btn-primary.hover {
	background-color: #dfdfdf;
	border-color: #e6e5e5 #e6e5e5 #CCC;
	color: #FFF;
}

.btn-primary:focus, .btn-primary.focus {
	box-shadow: 0 0 0 3px rgba(204, 204, 204, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
	background-color: #CCC;
	border-color: #CCC #CCC #b3b2b2;
}

.btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
	background-color: #b9b9b9;
	background-image: none;
	border-color: #b3b2b2 #b3b2b2 #999999;
}

.btn-outline.btn-primary {
	color: #CCC;
	background-color: transparent;
	background-image: none;
	border-color: #CCC;
}

.btn-outline.btn-primary:hover, .btn-outline.btn-primary.hover {
	color: #FFF;
	background-color: #CCC;
	border-color: #CCC;
}

.btn-outline.btn-primary:focus, .btn-outline.btn-primary.focus {
	box-shadow: 0 0 0 3px rgba(204, 204, 204, 0.5);
}

.btn-outline.btn-primary.disabled, .btn-outline.btn-primary:disabled {
	color: #CCC;
	background-color: transparent;
}

.btn-outline.btn-primary:active, .btn-outline.btn-primary.active,
.show > .btn-outline.btn-primary.dropdown-toggle {
	color: #FFF;
	background-color: #CCC;
	border-color: #CCC;
}

// .btn-success {
// 	background-color: #28a745;
// 	border-color: #28a745 #28a745 #1e7e34;
// 	color: #FFF;
// }

// .btn-success:hover, .btn-success.hover {
// 	background-color: #2fc652;
// 	border-color: #34ce57 #34ce57 #28a745;
// 	color: #FFF;
// }

// .btn-success:focus, .btn-success.focus {
// 	box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
// }

// .btn-success.disabled, .btn-success:disabled {
// 	background-color: #28a745;
// 	border-color: #28a745 #28a745 #1e7e34;
// }

// .btn-success:active, .btn-success.active,
// .show > .btn-success.dropdown-toggle {
// 	background-color: #218838;
// 	background-image: none;
// 	border-color: #1e7e34 #1e7e34 #145523;
// }

// .btn-outline.btn-success {
// 	color: #28a745;
// 	background-color: transparent;
// 	background-image: none;
// 	border-color: #28a745;
// }

// .btn-outline.btn-success:hover, .btn-outline.btn-success.hover {
// 	color: #FFF;
// 	background-color: #28a745;
// 	border-color: #28a745;
// }

// .btn-outline.btn-success:focus, .btn-outline.btn-success.focus {
// 	box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
// }

// .btn-outline.btn-success.disabled, .btn-outline.btn-success:disabled {
// 	color: #28a745;
// 	background-color: transparent;
// }

// .btn-outline.btn-success:active, .btn-outline.btn-success.active,
// .show > .btn-outline.btn-success.dropdown-toggle {
// 	color: #FFF;
// 	background-color: #28a745;
// 	border-color: #28a745;
// }

// .btn-warning {
// 	background-color: #ffc107;
// 	border-color: #ffc107 #ffc107 #d39e00;
// 	color: #FFF;
// }

// .btn-warning:hover, .btn-warning.hover {
// 	background-color: #ffcb2d;
// 	border-color: #ffce3a #ffce3a #ffc107;
// 	color: #FFF;
// }

// .btn-warning:focus, .btn-warning.focus {
// 	box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
// }

// .btn-warning.disabled, .btn-warning:disabled {
// 	background-color: #ffc107;
// 	border-color: #ffc107 #ffc107 #d39e00;
// }

// .btn-warning:active, .btn-warning.active,
// .show > .btn-warning.dropdown-toggle {
// 	background-color: #e0a800;
// 	background-image: none;
// 	border-color: #d39e00 #d39e00 #a07800;
// }

// .btn-outline.btn-warning {
// 	color: #ffc107;
// 	background-color: transparent;
// 	background-image: none;
// 	border-color: #ffc107;
// }

// .btn-outline.btn-warning:hover, .btn-outline.btn-warning.hover {
// 	color: #FFF;
// 	background-color: #ffc107;
// 	border-color: #ffc107;
// }

// .btn-outline.btn-warning:focus, .btn-outline.btn-warning.focus {
// 	box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
// }

// .btn-outline.btn-warning.disabled, .btn-outline.btn-warning:disabled {
// 	color: #ffc107;
// 	background-color: transparent;
// }

// .btn-outline.btn-warning:active, .btn-outline.btn-warning.active,
// .show > .btn-outline.btn-warning.dropdown-toggle {
// 	color: #FFF;
// 	background-color: #ffc107;
// 	border-color: #ffc107;
// }

.btn-danger {
	background-color: #dc3545;
	border-color: #dc3545 #dc3545 #bd2130;
	color: #FFF;
}

.btn-danger:hover, .btn-danger.hover {
	background-color: #e25663;
	border-color: #e4606d #e4606d #dc3545;
	color: #FFF;
}

.btn-danger:focus, .btn-danger.focus {
	box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
	background-color: #dc3545;
	border-color: #dc3545 #dc3545 #bd2130;
}

.btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
	background-color: #c82333;
	background-image: none;
	border-color: #bd2130 #bd2130 #921925;
}

.btn-outline.btn-danger {
	color: #dc3545;
	background-color: transparent;
	background-image: none;
	border-color: #dc3545;
}

.btn-outline.btn-danger:hover, .btn-outline.btn-danger.hover {
	color: #FFF;
	background-color: #dc3545;
	border-color: #dc3545;
}

.btn-outline.btn-danger:focus, .btn-outline.btn-danger.focus {
	box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}

.btn-outline.btn-danger.disabled, .btn-outline.btn-danger:disabled {
	color: #dc3545;
	background-color: transparent;
}

.btn-outline.btn-danger:active, .btn-outline.btn-danger.active,
.show > .btn-outline.btn-danger.dropdown-toggle {
	color: #FFF;
	background-color: #dc3545;
	border-color: #dc3545;
}

.btn-info {
	background-color: #17a2b8;
	border-color: #17a2b8 #17a2b8 #117a8b;
	color: #FFF;
}

.btn-info:hover, .btn-info.hover {
	background-color: #1bc0da;
	border-color: #1fc8e3 #1fc8e3 #17a2b8;
	color: #FFF;
}

.btn-info:focus, .btn-info.focus {
	box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
	background-color: #17a2b8;
	border-color: #17a2b8 #17a2b8 #117a8b;
}

.btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
	background-color: #138496;
	background-image: none;
	border-color: #117a8b #117a8b #0c525d;
}

.btn-outline.btn-info {
	color: #17a2b8;
	background-color: transparent;
	background-image: none;
	border-color: #17a2b8;
}

.btn-outline.btn-info:hover, .btn-outline.btn-info.hover {
	color: #FFF;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

.btn-outline.btn-info:focus, .btn-outline.btn-info.focus {
	box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}

.btn-outline.btn-info.disabled, .btn-outline.btn-info:disabled {
	color: #17a2b8;
	background-color: transparent;
}

.btn-outline.btn-info:active, .btn-outline.btn-info.active,
.show > .btn-outline.btn-info.dropdown-toggle {
	color: #FFF;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

.btn-dark {
	background-color: #212529;
	border-color: #212529 #212529 #0a0c0d;
	color: #FFF;
}

.btn-dark:hover, .btn-dark.hover {
	background-color: #32383e;
	border-color: #383f45 #383f45 #212529;
	color: #FFF;
}

.btn-dark:focus, .btn-dark.focus {
	box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
	background-color: #212529;
	border-color: #212529 #212529 #0a0c0d;
}

.btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
	background-color: #101214;
	background-image: none;
	border-color: #0a0c0d #0a0c0d black;
}

.btn-outline.btn-dark {
	color: #212529;
	background-color: transparent;
	background-image: none;
	border-color: #212529;
}

.btn-outline.btn-dark:hover, .btn-outline.btn-dark.hover {
	color: #FFF;
	background-color: #212529;
	border-color: #212529;
}

.btn-outline.btn-dark:focus, .btn-outline.btn-dark.focus {
	box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.5);
}

.btn-outline.btn-dark.disabled, .btn-outline.btn-dark:disabled {
	color: #212529;
	background-color: transparent;
}

.btn-outline.btn-dark:active, .btn-outline.btn-dark.active,
.show > .btn-outline.btn-dark.dropdown-toggle {
	color: #FFF;
	background-color: #212529;
	border-color: #212529;
}

/* Button Light */
html body .btn-light,
html body .btn-outline.btn-light {
	border-color: #d9d9d9 #d9d9d9 #bfbfbf;
	color: #777;
}

html body .btn-light.btn-outline-light-opacity-2,
html body .btn-outline.btn-light.btn-outline-light-opacity-2 {
	border-color: rgba(255, 255, 255, 0.2);
}

html body .btn-light:hover, html body .btn-light.hover,
html body .btn-outline.btn-light:hover,
html body .btn-outline.btn-light.hover {
	background-color: #f2f2f2;
	border-color: #e6e5e5 #e6e5e5 #cccccc;
	color: #777;
}

html body .btn-light:focus, html body .btn-light.focus,
html body .btn-outline.btn-light:focus,
html body .btn-outline.btn-light.focus {
	box-shadow: 0 0 0 3px rgba(191, 191, 191, 0.5);
}

html body .btn-light.disabled, html body .btn-light:disabled,
html body .btn-outline.btn-light.disabled,
html body .btn-outline.btn-light:disabled {
	border-color: #bfbfbf #bfbfbf #bfbfbf;
}

html body .btn-light:active, html body .btn-light.active,
.show > html body .btn-light.dropdown-toggle,
html body .btn-outline.btn-light:active,
html body .btn-outline.btn-light.active,
.show >
html body .btn-outline.btn-light.dropdown-toggle {
	border-color: #bfbfbf #bfbfbf #bfbfbf;
}

html body .btn-light-2,
html body .btn-outline.btn-light-2 {
	border-color: #FFF;
	color: #FFF;
}

html body .btn-light-2:hover, html body .btn-light-2.hover,
html body .btn-outline.btn-light-2:hover,
html body .btn-outline.btn-light-2.hover {
	background-color: #FFF;
	border-color: #FFF;
	color: #0e0e0e;
}

html body .btn-light-2:focus, html body .btn-light-2.focus,
html body .btn-outline.btn-light-2:focus,
html body .btn-outline.btn-light-2.focus {
	box-shadow: 0 0 0 3px rgba(179, 178, 178, 0.5);
}

html body .btn-light-2.disabled, html body .btn-light-2:disabled,
html body .btn-outline.btn-light-2.disabled,
html body .btn-outline.btn-light-2:disabled {
	border-color: #b3b2b2 #b3b2b2 #b3b2b2;
}

html body .btn-light-2:active, html body .btn-light-2.active,
.show > html body .btn-light-2.dropdown-toggle,
html body .btn-outline.btn-light-2:active,
html body .btn-outline.btn-light-2.active,
.show >
html body .btn-outline.btn-light-2.dropdown-toggle {
	border-color: #FFF;
}

// html[dir="rtl"] .input-group .form-control:not(:first-child),
// html[dir="rtl"] .input-group-addon:not(:first-child),
// html[dir="rtl"] .input-group-btn:not(:first-child) > .btn,
// html[dir="rtl"] .input-group-btn:not(:first-child) > .btn-group > .btn,
// html[dir="rtl"] .input-group-btn:not(:first-child) > .dropdown-toggle,
// html[dir="rtl"] .input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn,
// html[dir="rtl"] .input-group-btn:not(:last-child) > .btn:not(:first-child) {
// 	border-top-right-radius: 0;
// 	border-bottom-right-radius: 0;
// 	border-top-left-radius: 4px;
// 	border-bottom-left-radius: 4px;
// }

/* Hamburguer */
// .hamburguer-btn {
// 	background: transparent;
// 	outline: 0 !important;
// 	border: none;
// 	width: 30px;
// 	height: 30px;
// 	padding: 0;
// 	margin: 15px;
// 	display: inline-block;
// 	position: relative;
// 	cursor: pointer;
// }

// .hamburguer-btn.hamburguer-btn-side-header {
// 	position: absolute;
// 	top: 15px;
// 	right: 8px;
// }

// .hamburguer-btn.hamburguer-btn-side-header-overlay {
// 	right: 30px;
// 	z-index: 9999;
// }

// .hamburguer-btn .hamburguer {
// 	pointer-events: none;
// }

// .hamburguer-btn .hamburguer span {
// 	position: absolute;
// 	top: 50%;
// 	left: 0;
// 	width: 100%;
// 	height: 1px;
// 	transition: ease width 300ms;
// 	transform: translateY(-50%);
// 	background: #333;
// }

// .hamburguer-btn .hamburguer span:nth-child(1) {
// 	top: 30%;
// }

// .hamburguer-btn .hamburguer span:nth-child(2) {
// 	transition-delay: 100ms;
// }

// .hamburguer-btn .hamburguer span:nth-child(3) {
// 	top: 70%;
// 	transition-delay: 200ms;
// }

// .hamburguer-btn .close {
// 	opacity: 0;
// 	pointer-events: none;
// 	transition: ease all 300ms;
// }

// .hamburguer-btn .close span {
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	width: 0;
// 	height: 1px;
// 	background: #333;
// 	transition: ease all 300ms;
// 	transform-origin: 50% 0;
// }

// .hamburguer-btn .close span:nth-child(1) {
// 	transform: translateX(-50%) rotate(45deg);
// }

// .hamburguer-btn .close span:nth-child(2) {
// 	transform: translateX(-50%) rotate(-45deg);
// }

// .hamburguer-btn.active .hamburguer {
// 	opacity: 0;
// }

// .hamburguer-btn.active .hamburguer span {
// 	width: 0;
// }

// .hamburguer-btn.active .close {
// 	opacity: 1;
// 	transition: ease all 300ms;
// }

// .hamburguer-btn.active .close span {
// 	width: 80%;
// 	transition: ease all 300ms;
// }

// .hamburguer-btn.active .close span:nth-child(2) {
// 	transition-delay: 300ms;
// }

// .hamburguer-btn.hamburguer-btn-light .hamburguer span,
// .hamburguer-btn.hamburguer-btn-light .close span {
// 	background: #FFF;
// }

// html.overflow-hidden .hamburguer-btn.hamburguer-btn-side-header-overlay {
// 	right: 13px;
// }

// /* Hamburguer Sticky Colors */
// html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-light .hamburguer span,
// html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-light .close span,
// .pin-wrapper .sticky-active:not([class*="sticky-wrapper-effect"]) .hamburguer-btn.hamburguer-btn-sticky-light .hamburguer span,
// .pin-wrapper .sticky-active:not([class*="sticky-wrapper-effect"]) .hamburguer-btn.hamburguer-btn-sticky-light .close span,
// .pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-light .hamburguer span,
// .pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-light .close span {
// 	background: #FFF;
// }

// html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-dark .hamburguer span,
// html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-dark .close span,
// .pin-wrapper .sticky-active:not([class*="sticky-wrapper-effect"]) .hamburguer-btn.hamburguer-btn-sticky-dark .hamburguer span,
// .pin-wrapper .sticky-active:not([class*="sticky-wrapper-effect"]) .hamburguer-btn.hamburguer-btn-sticky-dark .close span,
// .pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-dark .hamburguer span,
// .pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-dark .close span {
// 	background: #212529;
// }

/* Badge */
// .btn-badge {
// 	position: relative;
// }

// .btn-badge .badge {
// 	top: -8px;
// 	top: -0.5rem;
// 	right: -8px;
// 	right: -0.5rem;
// 	position: absolute;
// }

/* Gradient */
// .btn-gradient:not(.btn-outline) {
// 	border: 0;
// }

// .btn-gradient:not(.btn-outline):hover, .btn-gradient:not(.btn-outline).hover {
// 	border: 0;
// }

// .btn-gradient:not(.btn-outline):focus, .btn-gradient:not(.btn-outline).focus {
// 	border: 0;
// }

// .btn-gradient:not(.btn-outline).disabled, .btn-gradient:not(.btn-outline):disabled {
// 	border: 0;
// }

// .btn-gradient:not(.btn-outline):active, .btn-gradient:not(.btn-outline).active {
// 	border: 0;
// }

// .btn-gradient.btn-outline {
// 	border: 2px solid transparent;
// 	background: transparent;
// 	border-image-slice: 1 !important;
// }

// .btn-gradient.btn-outline:hover, .btn-gradient.btn-outline.hover {
// 	border: 2px solid transparent;
// }

// .btn-gradient.btn-outline.disabled, .btn-gradient.btn-outline:disabled {
// 	border: 2px solid transparent;
// }

// .btn-gradient.btn-outline:active, .btn-gradient.btn-outline.active {
// 	border: 2px solid transparent;
// }


.play-button-lg {
	width: 6em;
	height: 6em;
	background-color: #002345b0;
	color: white;
	border: none;
}

.play-button-lg:focus{
	outline: none
}
.play-button-lg:hover{
	background-color:red;
}

/* Call to Action */
// .call-to-action {
// 	display: flex;
// 	flex-wrap: wrap;
// 	position: relative;
// 	padding: 32px 55px;
// 	clear: both;
// }

// .call-to-action::after {
// 	clear: both;
// 	content: "";
// 	display: block;
// }

// @media (max-width: 767px) {
// 	.call-to-action {
// 		padding: 10px 10px;
// 	}
// }

// .call-to-action h3 {
// 	text-transform: none;
// 	color: #212529;
// 	padding: 0;
// 	margin: 0 0 9px;
// }

// .call-to-action p {
// 	font-size: 1.2em;
// }

// .call-to-action > [class*="col-"] {
// 	display: flex;
// 	align-items: center;
// }

// .call-to-action > [class*="col-"]:nth-child(2) {
// 	justify-content: flex-end;
// 	text-align: center;
// }

// @media (max-width: 767px) {
// 	.call-to-action > [class*="col-"]:nth-child(2) {
// 		justify-content: center;
// 	}
// }

// .call-to-action .container > .row, .call-to-action .container-fluid > .row {
// 	display: flex;
// 	flex-wrap: wrap;
// }

// .call-to-action .container > .row > [class*="col-"], .call-to-action .container-fluid > .row > [class*="col-"] {
// 	display: flex;
// 	align-items: center;
// }

// .call-to-action .container > .row > [class*="col-"]:nth-child(2), .call-to-action .container-fluid > .row > [class*="col-"]:nth-child(2) {
// 	justify-content: flex-end;
// 	text-align: center;
// }

// @media (max-width: 767px) {
// 	.call-to-action .container > .row > [class*="col-"]:nth-child(2), .call-to-action .container-fluid > .row > [class*="col-"]:nth-child(2) {
// 		justify-content: center;
// 	}
// }

// @media (max-width: 767px) {
// 	.call-to-action > [class*="col-"]:not([class*="col-xs"]) {
// 		width: 100%;
// 	}

// 	.call-to-action .container > .row [class*="col-"]:not([class*="col-xs"]) {
// 		width: 100%;
// 	}
// }

// .call-to-action .call-to-action-content {
// 	text-align: center;
// }

// .call-to-action.call-to-action-big {
// 	padding: 63px 55px;
// }

// @media (max-width: 575px) {
// 	.call-to-action.call-to-action-big {
// 		padding: 63px 0;
// 	}
// }

// .call-to-action.call-to-action-front {
// 	z-index: 1;
// }

// .call-to-action.call-to-action-default {
// 	background: #f7f7f7;
// }

// .call-to-action.call-to-action-strong-grey {
// 	background: #EDEDED;
// }

// .call-to-action.call-to-action-dark {
// 	background: #333940;
// }

// .call-to-action.call-to-action-dark h3, .call-to-action.call-to-action-dark p {
// 	color: #FFF;
// }

// .call-to-action.with-borders {
// 	border-top: 1px solid #DFDFDF;
// 	border-bottom: 1px solid #DFDFDF;
// 	border-left: 1px solid #ECECEC;
// 	border-right: 1px solid #ECECEC;
// 	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
// }

// .call-to-action.with-full-borders {
// 	border: 2px solid #CCC;
// 	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
// }

// .call-to-action.featured {
// 	background: #FFF;
// 	margin: 0;
// 	border-top: 3px solid #CCC;
// 	border-bottom: 1px solid #DFDFDF;
// 	border-left: 1px solid #ECECEC;
// 	border-right: 1px solid #ECECEC;
// 	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
// }

// .call-to-action.featured.featured-primary {
// 	border-top-color: #CCC;
// }

// .call-to-action.featured.featured-secondary {
// 	border-top-color: #e36159;
// }

// .call-to-action.featured.featured-tertiary {
// 	border-top-color: #2BAAB1;
// }

// .call-to-action.featured.featured-quaternary {
// 	border-top-color: #333940;
// }

// .call-to-action.with-button-arrow .call-to-action-btn {
// 	position: relative;
// }

// @media (min-width: 992px) {
// 	.call-to-action.with-button-arrow .call-to-action-btn {
// 		left: -80px;
// 	}
// }

// .call-to-action.with-button-arrow .call-to-action-btn .arrow {
// 	position: absolute;
// }

// @media (max-width: 991px) {
// 	.call-to-action.with-button-arrow .call-to-action-btn .arrow {
// 		display: none;
// 	}
// }

// .call-to-action.with-button-arrow .call-to-action-btn.no-left {
// 	left: 0;
// }

// @media (max-width: 991px) {
// 	.call-to-action.with-button-arrow .call-to-action-btn.no-left {
// 		margin-top: -25px !important;
// 	}
// }

// .call-to-action.with-button-arrow.button-arrow-price-centered .call-to-action-btn > span {
// 	position: relative;
// }

// .call-to-action.with-button-arrow.button-arrow-price-centered .call-to-action-btn > span .arrow {
// 	right: 50% !important;
// }

// .call-to-action.content-align-center .call-to-action-content, .call-to-action.content-align-center .call-to-action-btn {
// 	width: 100%;
// 	text-align: center;
// }

// .call-to-action.button-centered .call-to-action-content {
// 	text-align: center;
// 	width: 100%;
// 	padding: 35px 0 0;
// 	margin: 0;
// }

// .call-to-action.button-centered .call-to-action-btn {
// 	text-align: center;
// 	position: static;
// 	margin: 20px auto 25px;
// 	width: auto;
// }

// .call-to-action.call-to-action-in-footer {
// 	padding-top: 70px;
// 	padding-bottom: 70px;
// 	margin-top: 30px;
// 	margin-bottom: -50px !important;
// }

// .call-to-action.call-to-action-in-footer:before {
// 	content: "";
// 	display: block;
// 	position: absolute;
// 	border-left: 20px solid transparent;
// 	border-right: 20px solid transparent;
// 	border-top: 20px solid #FFF;
// 	height: 20px;
// 	width: 20px;
// 	top: -5px;
// 	left: 50%;
// 	margin-left: -20px;
// }

// .call-to-action.no-top-arrow:before {
// 	display: none;
// }

// .call-to-action.call-to-action-in-footer-margin-top {
// 	margin-top: 60px;
// }

// .container .call-to-action {
// 	border-radius: 8px;
// }

/* Responsive */
@media (max-width: 767px) {
	.call-to-action .call-to-action-content,
		.call-to-action .call-to-action-btn {
		margin: 0;
		padding: 20px;
		position: static;
		width: auto;
	}
}

/*
* IE 11 Fixes
*/
html.ie:not(.ie9) .call-to-action .call-to-action-content {
	width: 100%;
}

html.ie:not(.ie9) .call-to-action.button-centered > [class*="col-"] {
	display: block;
}

html.ie:not(.ie9) .call-to-action.button-centered .container > .row, html.ie:not(.ie9) .call-to-action.button-centered .container-fluid > .row {
	display: block;
}

/*
* IE 9 Fixes
*/
html.ie9 .call-to-action > [class*="col-"] {
	display: table-cell;
	vertical-align: middle;
	float: none;
}

html.ie9 .call-to-action > [class*="col-"]:nth-child(2) {
	text-align: right;
}

html.ie9 .call-to-action .container > .row, html.ie9 .call-to-action .container-fluid > .row {
	display: table;
	width: 100%;
}

html.ie9 .call-to-action .container > .row > [class*="col-"], html.ie9 .call-to-action .container-fluid > .row > [class*="col-"] {
	display: table-cell;
	vertical-align: middle;
	float: none;
}

html.ie9 .call-to-action .container > .row > [class*="col-"]:nth-child(2), html.ie9 .call-to-action .container-fluid > .row > [class*="col-"]:nth-child(2) {
	text-align: center;
}

html.ie9 .call-to-action.button-centered > [class*="col-"] {
	float: left;
}

html.ie9 .call-to-action.button-centered .container > .row > [class*="col-"], html.ie9 .call-to-action.button-centered .container-fluid > .row > [class*="col-"] {
	float: left;
}

/* Cards */
.card {
	position: relative;
	border: 1px solid rgba(0, 0, 0, 0.06);
}

.card-header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.card-footer {
	border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.card-body {
	padding: 32px;
	padding: 2rem;
}

.card-text-color-hover-light:hover * {
	color: #FFF !important;
}

.card-background-image-hover {
	background-size: cover;
	background-position: center;
}

.card-background-image-hover .card-body {
	background: rgba(255, 255, 255, 0.9);
	margin: 16px;
	margin: 1rem;
	padding: 16px;
	padding: 1rem;
}

/* Flip Card */
// .flip-card {
// 	position: relative;
// 	border: none;
// 	perspective: 1000px;
// }

// .flip-card .flip-front,
// .flip-card .flip-back {
// 	padding: 32px;
// 	padding: 2rem;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	background: #FFF;
// 	box-shadow: 0px 0px 44px -10px rgba(0, 0, 0, 0.15);
// 	transform-style: preserve-3d;
// 	transition: ease transform 500ms;
// }

// .flip-card .flip-front .flip-content,
// .flip-card .flip-back .flip-content {
// 	transform: translate3d(0, 0, 1px);
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// }

// .flip-card .flip-front.overlay:before,
// .flip-card .flip-back.overlay:before {
// 	transform: translate3d(0, 0, 0px);
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// }

// .flip-card .flip-front {
// 	transform: translate3d(0, 0, 1px) rotateY(0deg);
// }

// .flip-card .flip-back {
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	transform: translate3d(0, 0, -1px) rotateY(180deg);
// }

// .flip-card:hover .flip-front {
// 	transform: translate3d(0, 0, -1px) rotateY(-180deg);
// }

// .flip-card:hover .flip-back {
// 	transform: translate3d(0, 0, 1px) rotateY(0deg);
// }

// .flip-card.flip-card-vertical .flip-front {
// 	transform: translate3d(0, 0, 1px) rotateX(0deg);
// }

// .flip-card.flip-card-vertical .flip-back {
// 	transform: translate3d(0, 0, -1px) rotateX(180deg);
// }

// .flip-card.flip-card-vertical:hover .flip-front {
// 	transform: translate3d(0, 0, -1px) rotateX(-180deg);
// }

// .flip-card.flip-card-vertical:hover .flip-back {
// 	transform: translate3d(0, 0, 1px) rotateX(0deg);
// }

// .flip-card.flip-card-3d .flip-front .flip-content, .flip-card.flip-card-3d .flip-back .flip-content {
// 	perspective: inherit;
// 	transform: translate3d(0, 0, 60px);
// }

/* Owl Carousel */
// .owl-carousel {
// 	display: block;
// 	margin-bottom: 20px;
// 	opacity: 0;
// 		/* Navigation */
// 		/* Dots */
// 		/* Image Thumbnail */
// }

// .owl-carousel.owl-loaded {
// 	opacity: 1;
// }

// .owl-carousel:not(.owl-loaded):not(.owl-carousel-light) > div, .owl-carousel:not(.owl-loaded):not(.owl-carousel-light) span {
// 	display: none;
// }

// .owl-carousel:not(.owl-loaded):not(.owl-carousel-light) > div:first-child, .owl-carousel:not(.owl-loaded):not(.owl-carousel-light) span:first-child {
// 	display: block;
// }

// .owl-carousel .owl-item img {
// 	transform-style: unset;
// }

// .owl-carousel .owl-item img[data-icon] {
// 	display: inline;
// }

// .owl-carousel .thumbnail {
// 	margin-right: 1px;
// }

// .owl-carousel .item-video {
// 	height: 300px;
// }

// .owl-carousel .owl-nav {
// 	top: 50%;
// 	position: absolute;
// 	width: 100%;
// 	margin-top: 0;
// 	transform: translate3d(0, -50%, 0);
// }

// .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next {
// 	display: inline-block;
// 	position: absolute;
// 	top: 50%;
// 	width: 30px;
// 	height: 30px;
// 	outline: 0;
// 	margin: 0;
// 	transform: translate3d(0, -50%, 0);
// }

// .owl-carousel .owl-nav button.owl-prev {
// 	left: 0;
// }

// .owl-carousel .owl-nav button.owl-prev:before {
// 	font-family: 'Font Awesome 5 Free';
// 	font-weight: 900;
// 	font-size: 8px;
// 	font-size: 0.5rem;
// 	content: "\f053";
// 	position: relative;
// 	left: -1px;
// 	top: -1px;
// }

// .owl-carousel .owl-nav button.owl-next {
// 	right: 0;
// }

// .owl-carousel .owl-nav button.owl-next:before {
// 	font-family: 'Font Awesome 5 Free';
// 	font-weight: 900;
// 	font-size: 8px;
// 	font-size: 0.5rem;
// 	content: "\f054";
// 	position: relative;
// 	left: 1px;
// 	top: -1px;
// }

// .owl-carousel.stage-margin .owl-stage-outer {
// 	margin-left: 40px !important;
// 	margin-right: 40px !important;
// }

// .owl-carousel.stage-margin .owl-stage-outer .owl-stage {
// 	padding-left: 0 !important;
// 	padding-right: 0 !important;
// }

// .owl-carousel.stage-margin.stage-margin-sm .owl-stage-outer {
// 	margin-left: 50px !important;
// 	margin-right: 50px !important;
// }

// .owl-carousel.stage-margin.stage-margin-md .owl-stage-outer {
// 	margin-left: 75px !important;
// 	margin-right: 75px !important;
// }

// .owl-carousel.stage-margin.stage-margin-lg .owl-stage-outer {
// 	margin-left: 100px !important;
// 	margin-right: 100px !important;
// }

// .owl-carousel.top-border {
// 	border-top: 1px solid #eaeaea;
// 	padding-top: 18px;
// }

// .owl-carousel.show-nav-hover .owl-nav {
// 	opacity: 0;
// 	transition: opacity 0.2s ease-in-out;
// }

// .owl-carousel.show-nav-hover .owl-nav button.owl-prev {
// 	left: -15px;
// }

// .owl-carousel.show-nav-hover .owl-nav button.owl-next {
// 	right: -15px;
// }

// .owl-carousel.show-nav-hover:hover .owl-nav {
// 	opacity: 1;
// }

// .owl-carousel.show-nav-title .owl-nav {
// 	top: 0;
// 	right: 0;
// 	margin-top: -25px;
// 	width: auto;
// }

// .owl-carousel.show-nav-title .owl-nav button[class*="owl-"], .owl-carousel.show-nav-title .owl-nav button[class*="owl-"]:hover, .owl-carousel.show-nav-title .owl-nav button[class*="owl-"]:active {
// 	font-size: 18px;
// 	background: transparent !important;
// 	width: 18px;
// }

// .owl-carousel.show-nav-title .owl-nav button.owl-prev {
// 	left: -40px;
// }

// .owl-carousel.show-nav-title .owl-nav button.owl-prev:before, .owl-carousel.show-nav-title .owl-nav button.owl-prev:after, .owl-carousel.show-nav-title .owl-nav button.owl-next:before, .owl-carousel.show-nav-title .owl-nav button.owl-next:after {
// 	font-size: inherit;
// }

// .owl-carousel.show-nav-title.show-nav-title-both-sides .owl-nav {
// 	width: 100%;
// }

// .owl-carousel.show-nav-title.show-nav-title-both-sides button.owl-prev {
// 	left: 0;
// }

// .owl-carousel.show-nav-title.show-nav-title-both-sides button.owl-next {
// 	right: 0;
// }

// .owl-carousel.show-nav-title.show-nav-title-both-sides-style-2 .owl-nav {
// 	margin-top: 15px;
// }

// .owl-carousel.rounded-nav .owl-nav button[class*="owl-"] {
// 	padding: 3px 7px;
// 	border-radius: 50%;
// 	background: transparent;
// 	border: 1px solid #999 !important;
// 	color: #999;
// }

// .owl-carousel.rounded-nav .owl-nav button[class*="owl-"]:hover, .owl-carousel.rounded-nav .owl-nav button[class*="owl-"].hover {
// 	background: transparent !important;
// 	border: 1px solid #a1a1a1 !important;
// 	color: #a1a1a1 !important;
// }

// .owl-carousel.rounded-nav .owl-nav button[class*="owl-"]:active, .owl-carousel.rounded-nav .owl-nav button[class*="owl-"].active {
// 	background: transparent !important;
// 	border: 1px solid #666 !important;
// 	color: #666 !important;
// }

// .owl-carousel.nav-bottom .owl-stage-outer {
// 	margin-bottom: 10px;
// }

// .owl-carousel.nav-bottom .owl-nav {
// 	position: static;
// 	margin: 0;
// 	padding: 0;
// 	width: auto;
// 	transform: none;
// }

// .owl-carousel.nav-bottom .owl-nav button.owl-prev, .owl-carousel.nav-bottom .owl-nav button.owl-next {
// 	position: static;
// 	transform: none;
// }

// .owl-carousel.nav-bottom .owl-nav button.owl-prev {
// 	margin-right: 5px;
// }

// .owl-carousel.nav-bottom .owl-nav button.owl-next {
// 	margin-left: 5px;
// }

// .owl-carousel.nav-bottom-inside .owl-nav {
// 	position: relative;
// 	margin: -68.8px 0 0 0;
// 	margin: -4.3rem 0 0 0;
// 	padding: 0;
// 	width: auto;
// }

// .owl-carousel.nav-bottom-inside .owl-nav button.owl-prev, .owl-carousel.nav-bottom-inside .owl-nav button.owl-next {
// 	position: static;
// }

// .owl-carousel.nav-inside .owl-nav button.owl-prev {
// 	left: 15px;
// }

// .owl-carousel.nav-inside .owl-nav button.owl-next {
// 	right: 15px;
// }

// .owl-carousel.nav-inside.nav-inside-edge .owl-nav button.owl-prev {
// 	left: 0;
// }

// .owl-carousel.nav-inside.nav-inside-edge .owl-nav button.owl-next {
// 	right: 0;
// }

// .owl-carousel.nav-inside.nav-inside-plus .owl-nav button.owl-prev {
// 	left: 30px;
// }

// .owl-carousel.nav-inside.nav-inside-plus .owl-nav button.owl-next {
// 	right: 30px;
// }

// .owl-carousel.nav-inside.nav-bottom .owl-nav {
// 	position: absolute;
// 	top: auto;
// 	bottom: 40px;
// 	width: 100%;
// }

// .owl-carousel.nav-inside.nav-bottom .owl-nav button.owl-prev, .owl-carousel.nav-inside.nav-bottom .owl-nav button.owl-next {
// 	position: relative;
// }

// .owl-carousel.nav-inside.nav-bottom .owl-nav button.owl-prev {
// 	left: 0;
// }

// .owl-carousel.nav-inside.nav-bottom .owl-nav button.owl-next {
// 	right: 0;
// }

// .owl-carousel.nav-inside.nav-bottom.nav-style-2 .owl-nav button.owl-prev, .owl-carousel.nav-inside.nav-bottom.nav-style-2 .owl-nav button.owl-next {
// 	position: relative;
// }

// .owl-carousel.nav-outside .owl-nav button.owl-prev {
// 	left: 0;
// }

// .owl-carousel.nav-outside .owl-nav button.owl-next {
// 	right: 0;
// }

// @media (min-width: 992px) {
// 	.owl-carousel.nav-outside .owl-nav button.owl-prev {
// 		left: -50px;
// 	}

// 	.owl-carousel.nav-outside .owl-nav button.owl-next {
// 		right: -50px;
// 	}
// }

// .owl-carousel.nav-style-1 .owl-nav .owl-next,
// .owl-carousel.nav-style-1 .owl-nav .owl-prev {
// 	width: 20px;
// 	background: transparent !important;
// 	color: #000;
// }

// .owl-carousel.nav-style-1 .owl-nav .owl-next:hover, .owl-carousel.nav-style-1 .owl-nav .owl-next:active,
// .owl-carousel.nav-style-1 .owl-nav .owl-prev:hover,
// .owl-carousel.nav-style-1 .owl-nav .owl-prev:active {
// 	color: #CCC;
// }

// .owl-carousel.nav-style-1 .owl-nav .owl-next:before, .owl-carousel.nav-style-1 .owl-nav .owl-next:after,
// .owl-carousel.nav-style-1 .owl-nav .owl-prev:before,
// .owl-carousel.nav-style-1 .owl-nav .owl-prev:after {
// 	font-size: inherit;
// }

// .owl-carousel.nav-style-2 .owl-nav .owl-next,
// .owl-carousel.nav-style-2 .owl-nav .owl-prev {
// 	background: transparent !important;
// }

// .owl-carousel.nav-style-2 .owl-nav .owl-next:before,
// .owl-carousel.nav-style-2 .owl-nav .owl-prev:before {
// 	content: '';
// 	display: block;
// 	position: absolute;
// 	top: 50%;
// 	left: 1px;
// 	width: 1.3em;
// 	height: 1.3em;
// 	border-top: 2px solid #CCC;
// 	border-left: 2px solid #CCC;
// 	font-size: inherit;
// 	transform: translate3d(0, -50%, 0) rotate(-45deg);
// }

// .owl-carousel.nav-style-2 .owl-nav .owl-next:after,
// .owl-carousel.nav-style-2 .owl-nav .owl-prev:after {
// 	content: '';
// 	display: block;
// 	border-top: 3px solid #CCC;
// 	width: 2.5em;
// 	position: absolute;
// 	top: 50%;
// 	font-size: inherit;
// 	transform: translate3d(0, -50%, 0);
// }

// .owl-carousel.nav-style-2 .owl-nav .owl-next {
// 	transform: rotate(180deg) !important;
// 	transform-origin: 15px 8px;
// }

// .owl-carousel.nav-style-2.nav-bottom.nav-inside .owl-nav .owl-next {
// 	transform-origin: 15px;
// }

// .owl-carousel.nav-style-3 .owl-nav {
// 	top: 25%;
// }

// .owl-carousel.nav-style-3 .owl-nav .owl-next,
// .owl-carousel.nav-style-3 .owl-nav .owl-prev {
// 	width: 30px;
// 	background: transparent !important;
// 	color: #CCC;
// }

// .owl-carousel.nav-style-3 .owl-nav .owl-next:hover, .owl-carousel.nav-style-3 .owl-nav .owl-next:active,
// .owl-carousel.nav-style-3 .owl-nav .owl-prev:hover,
// .owl-carousel.nav-style-3 .owl-nav .owl-prev:active {
// 	color: #CCC;
// }

// .owl-carousel.nav-style-3 .owl-nav .owl-next:before, .owl-carousel.nav-style-3 .owl-nav .owl-next:after,
// .owl-carousel.nav-style-3 .owl-nav .owl-prev:before,
// .owl-carousel.nav-style-3 .owl-nav .owl-prev:after {
// 	font-size: 1.5em;
// }

// .owl-carousel.nav-style-4 .owl-nav .owl-prev {
// 	left: 75px;
// }

// @media (max-width: 991px) {
// 	.owl-carousel.nav-style-4 .owl-nav .owl-prev {
// 		left: 40px;
// 	}
// }

// @media (max-width: 767px) {
// 	.owl-carousel.nav-style-4 .owl-nav .owl-prev {
// 		left: 13px;
// 	}
// }

// .owl-carousel.nav-style-4 .owl-nav .owl-next {
// 	right: 75px;
// }

// @media (max-width: 991px) {
// 	.owl-carousel.nav-style-4 .owl-nav .owl-next {
// 		right: 40px;
// 	}
// }

// @media (max-width: 767px) {
// 	.owl-carousel.nav-style-4 .owl-nav .owl-next {
// 		right: 13px;
// 	}
// }

// .owl-carousel.nav-style-4 .owl-nav .owl-prev, .owl-carousel.nav-style-4 .owl-nav .owl-next {
// 	background: #FFF;
// 	font-size: 11.2px;
// 	font-size: 0.7rem;
// 	width: 40px;
// 	height: 40px;
// 	color: #000;
// 	border-radius: 100%;
// 	box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.3);
// }

// .owl-carousel.nav-style-4 .owl-nav .owl-prev:hover, .owl-carousel.nav-style-4 .owl-nav .owl-next:hover {
// 	color: #FFF;
// }

// .owl-carousel.nav-style-4.nav-style-4-pos-2 .owl-nav .owl-prev {
// 	left: 0px;
// }

// .owl-carousel.nav-style-4.nav-style-4-pos-2 .owl-nav .owl-next {
// 	right: 0px;
// }

// .owl-carousel.nav-style-diamond .owl-nav .owl-prev, .owl-carousel.nav-style-diamond .owl-nav .owl-next {
// 	transform: rotate(45deg);
// 	transform-origin: 100% 0%;
// }

// .owl-carousel.nav-style-diamond .owl-nav .owl-prev:before, .owl-carousel.nav-style-diamond .owl-nav .owl-next:before {
// 	display: block;
// 	transform: rotate(-45deg);
// 	transform-origin: 60% 50%;
// }

// .owl-carousel.nav-style-diamond .owl-nav .owl-next:before {
// 	transform-origin: 50%;
// }

// .owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev, .owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next {
// 	width: 35px;
// 	height: 35px;
// }

// .owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev:before, .owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next:before {
// 	content: none;
// }

// .owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev svg, .owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next svg {
// 	width: 2em;
// }

// .owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev svg polygon, .owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next svg polygon {
// 	fill: #FFF;
// 	stroke: #FFF;
// }

// .owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev svg {
// 	transform: rotate(180deg);
// }

// .owl-carousel.nav-arrows-1 .owl-nav .owl-prev, .owl-carousel.nav-arrows-1 .owl-nav .owl-next {
// 	width: 35px;
// 	height: 35px;
// 	font-size: 19.2px;
// 	font-size: 1.2rem;
// 	background: transparent;
// }

// .owl-carousel.nav-arrows-1 .owl-nav .owl-next:before {
// 	content: '\f061';
// 	font-size: inherit;
// }

// .owl-carousel.nav-arrows-1 .owl-nav .owl-prev:before {
// 	content: '\f060';
// 	font-size: inherit;
// }

// .owl-carousel.nav-arrows-thin .owl-nav .owl-prev:before, .owl-carousel.nav-arrows-thin .owl-nav .owl-next:before {
// 	font-family: simple-line-icons;
// 	font-style: normal;
// 	font-weight: 700;
// 	font-variant: normal;
// 	text-transform: none;
// 	line-height: 1;
// 	-webkit-font-smoothing: antialiased;
// }

// .owl-carousel.nav-arrows-thin .owl-nav .owl-next:before {
// 	content: "\e606";
// 	font-size: inherit;
// }

// .owl-carousel.nav-arrows-thin .owl-nav .owl-prev:before {
// 	content: "\e605";
// 	font-size: inherit;
// }

// .owl-carousel.nav-center-images-only .owl-nav {
// 	top: 37%;
// }

// .owl-carousel.nav-center-outside .owl-nav {
// 	width: calc(100% + 90px);
// 	left: 49.9%;
// 	transform: translate3d(-50%, 0, 0);
// }

// .owl-carousel.full-width .owl-nav button[class*="owl-"], .owl-carousel.full-width .owl-nav button[class*="owl-"]:hover, .owl-carousel.big-nav .owl-nav button[class*="owl-"], .owl-carousel.big-nav .owl-nav button[class*="owl-"]:hover {
// 	height: auto;
// 	padding: 20px 0 !important;
// }

// .owl-carousel.full-width .owl-nav button.owl-prev, .owl-carousel.big-nav .owl-nav button.owl-prev {
// 	border-radius: 0 4px 4px 0;
// }

// .owl-carousel.full-width .owl-nav button.owl-next, .owl-carousel.big-nav .owl-nav button.owl-next {
// 	border-radius: 4px 0 0 4px;
// }

// .owl-carousel.nav-squared .owl-nav button[class*="owl-"] {
// 	border-radius: 0;
// }

// .owl-carousel.nav-md .owl-nav button.owl-prev,
// .owl-carousel.nav-md .owl-nav button.owl-next {
// 	width: 40px;
// 	height: 40px;
// }

// .owl-carousel.nav-lg .owl-nav button.owl-prev,
// .owl-carousel.nav-lg .owl-nav button.owl-next {
// 	width: 45px;
// 	height: 60px;
// }

// .owl-carousel.nav-lg.rounded-nav .owl-nav button.owl-prev,
// .owl-carousel.nav-lg.rounded-nav .owl-nav button.owl-next {
// 	width: 60px;
// 	height: 60px;
// }

// .owl-carousel.nav-font-size-sm .owl-nav button.owl-prev,
// .owl-carousel.nav-font-size-sm .owl-nav button.owl-next {
// 	font-size: 10px;
// }

// .owl-carousel.nav-font-size-sm .owl-nav button.owl-prev:before,
// .owl-carousel.nav-font-size-sm .owl-nav button.owl-next:before {
// 	font-size: inherit;
// }

// .owl-carousel.nav-font-size-md .owl-nav button.owl-prev,
// .owl-carousel.nav-font-size-md .owl-nav button.owl-next {
// 	font-size: 14px;
// }

// .owl-carousel.nav-font-size-md .owl-nav button.owl-prev:before,
// .owl-carousel.nav-font-size-md .owl-nav button.owl-next:before {
// 	font-size: inherit;
// }

// .owl-carousel.nav-font-size-lg .owl-nav button.owl-prev,
// .owl-carousel.nav-font-size-lg .owl-nav button.owl-next {
// 	font-size: 19px;
// }

// .owl-carousel.nav-font-size-lg .owl-nav button.owl-prev:before,
// .owl-carousel.nav-font-size-lg .owl-nav button.owl-next:before {
// 	font-size: inherit;
// 	left: 2px;
// }

// .owl-carousel.nav-light:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*="owl-"] {
// 	background-color: #ececec !important;
// 	border-color: #ececec !important;
// 	color: #212529 !important;
// }

// .owl-carousel.nav-light:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*="owl-"]:hover {
// 	background-color: #FFF !important;
// 	border-color: #FFF !important;
// }

// .owl-carousel.nav-light:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*="owl-"]:active {
// 	background-color: #d2d2d2 !important;
// 	border-color: #d2d2d2 !important;
// }

// .owl-carousel.nav-light:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-with-transparency .owl-nav button[class*="owl-"] {
// 	width: 35px;
// 	height: 45px;
// 	background-color: rgba(236, 236, 236, 0.4) !important;
// 	border-color: transparent !important;
// }

// .owl-carousel.nav-light:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-svg-arrows-1 .owl-nav button[class*="owl-"] svg polygon {
// 	fill: #212529 !important;
// 	stroke: #212529 !important;
// }

// .owl-carousel.nav-light.nav-style-1 .owl-nav .owl-next,
// .owl-carousel.nav-light.nav-style-1 .owl-nav .owl-prev {
// 	color: #FFF !important;
// }

// .owl-carousel.nav-light.nav-style-2 .owl-nav .owl-next:before, .owl-carousel.nav-light.nav-style-2 .owl-nav .owl-next:after,
// .owl-carousel.nav-light.nav-style-2 .owl-nav .owl-prev:before,
// .owl-carousel.nav-light.nav-style-2 .owl-nav .owl-prev:after {
// 	border-color: #FFF !important;
// }

// .owl-carousel.nav-light.nav-style-3 .owl-nav .owl-next,
// .owl-carousel.nav-light.nav-style-3 .owl-nav .owl-prev {
// 	color: #FFF !important;
// }

// .owl-carousel.nav-light.nav-svg-arrows-1 .owl-nav .owl-next svg polygon,
// .owl-carousel.nav-light.nav-svg-arrows-1 .owl-nav .owl-prev svg polygon {
// 	fill: #FFF !important;
// 	stroke: #FFF !important;
// }

// .owl-carousel.nav-light.nav-arrows-1 .owl-nav .owl-next,
// .owl-carousel.nav-light.nav-arrows-1 .owl-nav .owl-prev {
// 	color: #FFF !important;
// }

// .owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*="owl-"] {
// 	background-color: #262a2f !important;
// 	border-color: #262a2f #262a2f #2c3237 !important;
// 	color: #FFF !important;
// }

// .owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*="owl-"]:hover {
// 	background-color: #2c3237 !important;
// 	border-color: #2c3237 !important;
// }

// .owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*="owl-"]:active {
// 	background-color: #212529 !important;
// 	border-color: #212529 !important;
// }

// .owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-with-transparency .owl-nav button[class*="owl-"] {
// 	width: 35px;
// 	height: 45px;
// 	background-color: rgba(38, 42, 47, 0.4) !important;
// 	border-color: transparent !important;
// }

// .owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-svg-arrows-1 .owl-nav button[class*="owl-"] svg polygon {
// 	fill: #FFF !important;
// 	stroke: #FFF !important;
// }

// .owl-carousel.nav-dark.nav-style-1 .owl-nav .owl-next,
// .owl-carousel.nav-dark.nav-style-1 .owl-nav .owl-prev {
// 	color: #212529 !important;
// }

// .owl-carousel.nav-dark.nav-style-2 .owl-nav .owl-next:before, .owl-carousel.nav-dark.nav-style-2 .owl-nav .owl-next:after,
// .owl-carousel.nav-dark.nav-style-2 .owl-nav .owl-prev:before,
// .owl-carousel.nav-dark.nav-style-2 .owl-nav .owl-prev:after {
// 	border-color: #212529 !important;
// }

// .owl-carousel.nav-dark.nav-style-3 .owl-nav .owl-next,
// .owl-carousel.nav-dark.nav-style-3 .owl-nav .owl-prev {
// 	color: #212529 !important;
// }

// .owl-carousel.nav-dark.nav-svg-arrows-1 .owl-nav .owl-next svg polygon,
// .owl-carousel.nav-dark.nav-svg-arrows-1 .owl-nav .owl-prev svg polygon {
// 	fill: #212529 !important;
// 	stroke: #212529 !important;
// }

// .owl-carousel.nav-dark.nav-arrows-1 .owl-nav .owl-next,
// .owl-carousel.nav-dark.nav-arrows-1 .owl-nav .owl-prev {
// 	color: #212529 !important;
// }

// .owl-carousel .owl-dots .owl-dot {
// 	outline: 0;
// }

// .owl-carousel .owl-dots .owl-dot span {
// 	width: 8px;
// 	height: 8px;
// 	margin: 5px 4px;
// }

// .owl-carousel.dots-inside .owl-dots {
// 	position: absolute;
// 	bottom: 2px;
// 	right: 10px;
// 	margin-top: 0;
// }

// .owl-carousel.dots-title .owl-dots {
// 	position: absolute;
// 	margin-top: 0 !important;
// 	top: -51px;
// 	left: 255px;
// }

// .owl-carousel.dots-title .owl-dots .owl-dot span {
// 	width: 8px;
// 	height: 8px;
// 	margin: 5px 4px;
// }

// .owl-carousel.dots-title.dots-title-pos-2 .owl-dots {
// 	left: 235px;
// }

// .owl-carousel.dots-light .owl-dots .owl-dot span {
// 	background: rgba(255, 255, 255, 0.6);
// }

// .owl-carousel.dots-light .owl-dots .owl-dot.active span, .owl-carousel.dots-light .owl-dots .owl-dot:hover span {
// 	background: #FFF !important;
// }

// .owl-carousel.dots-morphing .owl-dots .owl-dot span {
// 	width: 20px;
// 	height: 6px;
// 	transition: ease width 300ms;
// }

// .owl-carousel.dots-morphing .owl-dots .owl-dot.active span, .owl-carousel.dots-morphing .owl-dots .owl-dot:hover span {
// 	width: 40px;
// }

// .owl-carousel.dots-modern .owl-dots {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .owl-carousel.dots-modern .owl-dots .owl-dot {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	margin: 0 2px;
// }

// .owl-carousel.dots-modern .owl-dots .owl-dot span {
// 	width: 4px;
// 	height: 4px;
// 	transition: ease all 300ms 300ms;
// }

// .owl-carousel.dots-modern .owl-dots .owl-dot.active span {
// 	transition: ease all 300ms;
// 	transform: scale(2);
// }

// .owl-carousel.dots-orientation-portrait .owl-dots {
// 	display: flex;
// 	flex-direction: column;
// 	margin-left: 15px !important;
// 	margin-right: 15px !important;
// }

// .owl-carousel.dots-align-left .owl-dots {
// 	text-align: left;
// }

// .owl-carousel.dots-align-right .owl-dots {
// 	text-align: left;
// }

// .owl-carousel.dots-horizontal-center .owl-dots {
// 	left: 0;
// 	right: 0;
// 	width: 100%;
// }

// .owl-carousel.dots-vertical-center .owl-dots {
// 	top: 50%;
// 	bottom: auto;
// 	margin: 0;
// 	transform: translate3d(0, -50%, 0);
// }

// @media (max-width: 575px) {
// 	.owl-carousel.show-dots-xs .owl-dots {
// 		opacity: 1 !important;
// 		visibility: visible !important;
// 	}
// }

// @media (min-width: 576px) and (max-width: 767px) {
// 	.owl-carousel.show-dots-sm .owl-dots {
// 		opacity: 1 !important;
// 		visibility: visible !important;
// 	}
// }

// @media (min-width: 768px) and (max-width: 991px) {
// 	.owl-carousel.show-dots-md .owl-dots {
// 		opacity: 1 !important;
// 		visibility: visible !important;
// 	}
// }

// .owl-carousel.show-dots-hover .owl-dots {
// 	opacity: 0;
// 	visibility: hidden;
// 	transition: ease opacity 300ms;
// }

// .owl-carousel.show-dots-hover:hover .owl-dots {
// 	opacity: 1;
// 	visibility: visible;
// }

// .owl-carousel .img-thumbnail.img-thumbnail-hover-icon {
// 	display: block;
// }

// /* Hover Items with Opacity */
// .owl-carousel [class*="opacity-"] {
// 	transition: ease opacity 300ms;
// }

// .owl-carousel [class*="opacity-"]:hover {
// 	opacity: 1 !important;
// }

// /* Carousel Sync */
// @media (min-width: 576px) {
// 	.owl-carousel.carousel-sync-style-1 {
// 		position: absolute;
// 		top: 50%;
// 		left: -30px;
// 		max-width: 355px;
// 		transform: translate3d(0, -50%, 0);
// 	}
// }

// /* Spaced */
// .owl-carousel-spaced {
// 	margin-left: -5px;
// }

// .owl-carousel-spaced .owl-item > div {
// 	margin: 5px;
// }

// /* Testimonials */
// .owl-carousel.testimonials img {
// 	display: inline-block;
// 	height: 70px;
// 	width: 70px;
// }

// /* Responsive */
// @media (max-width: 575px) {
// 	.owl-carousel-spaced {
// 		margin-left: 0;
// 	}
// }

// /* Carousel Areas */
// .carousel-areas {
// 	background: #f2f2f2;
// 	background: linear-gradient(to bottom, #f2f2f2 0%, rgba(33, 37, 41, 0.5) 100%);
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#212529', GradientType=0 );
// 	margin-bottom: -10px;
// 	padding: 8px 8px 0 8px;
// 	border-radius: 6px 6px 0 0;
// 	box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.07);
// }

// .carousel-areas .owl-carousel {
// 	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
// }

// .carousel-areas .owl-carousel .owl-nav button.owl-prev {
// 	left: -55px;
// }

// .carousel-areas .owl-carousel .owl-nav button.owl-next {
// 	right: -55px;
// }

// .carousel-areas .owl-carousel:first-child img {
// 	border-radius: 6px 6px 0 0;
// }

// /* Responsive */
// @media (max-width: 991px) {
// 	.carousel-areas .owl-carousel .owl-nav {
// 		display: none;
// 	}
// }

// /* Carousel Center Active Item */
// .owl-carousel.carousel-center-active-item .owl-item {
// 	opacity: 0.2;
// 	transition: ease opacity 300ms;
// }

// .owl-carousel.carousel-center-active-item .owl-item.current {
// 	opacity: 1 !important;
// }

// .owl-carousel.carousel-center-active-item.carousel-center-active-item-style-2 .owl-item {
// 	opacity: 0.7;
// }

// /* Carousel Center Active Item 2 */
// .owl-carousel.carousel-center-active-item-2 .owl-stage-outer {
// 	overflow: visible;
// }

// .owl-carousel.carousel-center-active-item-2 .owl-item > div {
// 	width: 66.6666%;
// 	margin-left: auto;
// 	padding: 48px;
// 	padding: 3rem;
// 	background: #fff;
// 	border-radius: 7px;
// 	box-shadow: 0px 0px 70px -40px rgba(0, 0, 0, 0.2);
// }

// .owl-carousel.carousel-center-active-item-2 .owl-item.active > div {
// 	margin-right: auto;
// }

// .owl-carousel.carousel-center-active-item-2 .owl-item.active > div * {
// 	color: #FFF !important;
// }

// .owl-carousel.carousel-center-active-item-2 .owl-item.active + .owl-item > div {
// 	margin-right: auto;
// 	margin-left: 0;
// }

// /* Owl Carousel Performance */
// .owl-carousel-light.owl-carousel-light-init-fadeIn {
// 	transition: ease opacity 300ms;
// }

// .owl-carousel-light .owl-stage-outer,
// .owl-carousel-light .owl-stage {
// 	height: 100%;
// }

// .owl-carousel-light .owl-item {
// 	display: none;
// 	visibility: hidden;
// 	opacity: 0;
// 	position: absolute !important;
// 	width: 100%;
// 	height: 100%;
// 	top: 0;
// 	transition: ease opacity 300ms;
// }

// .owl-carousel-light .owl-item.active {
// 	display: block;
// 	visibility: visible;
// 	opacity: 1;
// }

// .owl-carousel-light .owl-dots .owl-dot span {
// 	margin: 5px 2px;
// }

/* Cascading Images */
.cascading-images-wrapper {
	display: inline-block;
	padding: 12% 20%;
}

.cascading-images-wrapper .cascading-images {
	transform: translate3d(0, -35%, 0);
}

.cascading-images-wrapper .cascading-images img {
	max-width: 100%;
}

/* Text */
.text-color-grey {
	color: #969696 !important;
}

.text-color-grey-lighten {
	color: #a9a9aa !important;
}

.text-color-white {
	color: #FFF !important;
}

.text-color-hover-white:hover {
	color: #FFF !important;
}

.text-color-black {
	color: #000 !important;
}

.text-color-hover-black:hover {
	color: #000 !important;
}

.text-color-danger {
	color: #ff5b5b !important;
}

.text-color-success {
	color: #0cc485 !important;
}

/* Grey */
.bg-color-grey {
	background-color: #F7F7F7 !important;
}

.bg-color-grey-scale-1 {
	background-color: #EDEDED !important;
}

.bg-color-grey-scale-2 {
	background-color: #e8e8e8 !important;
}

.bg-color-grey-scale-3 {
	background-color: #e3e3e3 !important;
}

.bg-color-grey-scale-4 {
	background-color: #dedede !important;
}

.bg-color-grey-scale-5 {
	background-color: #d9d9d9 !important;
}

.bg-color-grey-scale-6 {
	background-color: #d4d3d3 !important;
}

.bg-color-grey-scale-7 {
	background-color: #cecece !important;
}

.bg-color-grey-scale-8 {
	background-color: #c9c9c9 !important;
}

.bg-color-grey-scale-9 {
	background-color: #c4c4c4 !important;
}

.bg-color-grey-scale-10 {
	background-color: #bfbfbf !important;
}

/* Dark */
.bg-color-dark-scale-1 {
	background-color: #212529 !important;
}

.bg-color-dark-scale-2 {
	background-color: #1c2023 !important;
}

.bg-color-dark-scale-3 {
	background-color: #181b1e !important;
}

.bg-color-dark-scale-4 {
	background-color: #131618 !important;
}

.bg-color-dark-scale-5 {
	background-color: #0f1112 !important;
}

/* Borders */
.border-color-light-2 {
	border-color: rgba(255, 255, 255, 0.3) !important;
}

.border-color-light-3 {
	border-color: rgba(255, 255, 255, 0.05) !important;
}

.border-color-light-4 {
	border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-color-grey-1 {
	border-color: #e9e9e9 !important;
}

.border-color-success {
	border-color: #0cc485 !important;
}

/* SVG */
.svg-fill-color-light svg path, .svg-fill-color-light svg rect {
	fill: #FFF !important;
}

.svg-stroke-color-light svg path, .svg-stroke-color-light svg rect {
	stroke: #FFF !important;
}

.svg-fill-color-dark svg path, .svg-fill-color-dark svg rect {
	fill: #212529 !important;
}

.svg-stroke-color-dark svg path, .svg-stroke-color-dark svg rect {
	stroke: #212529 !important;
}

/* Star Rating */
.rating-default .filled-stars {
	color: #6a6a6d;
}

/* Countdown */
// .countdown {
// 	display: flex;
// 	text-align: center;
// }

// .countdown > span {
// 	flex-basis: 25%;
// 	max-width: 25%;
// 	width: 25%;
// 	font-size: 16px;
// 	font-size: 1rem;
// 	line-height: 1.5;
// 	color: #777;
// }

// .countdown > span > span {
// 	display: block;
// 	font-size: 48px;
// 	font-size: 3rem;
// 	line-height: 1;
// 	color: #212529;
// }

// .countdown.countdown-light > span {
// 	color: rgba(255, 255, 255, 0.6);
// }

// .countdown.countdown-light > span > span {
// 	color: #FFF;
// }

// .countdown.countdown-light.countdown-borders > span {
// 	border-color: #FFF;
// }

// .countdown.countdown-borders {
// 	margin-right: -15px;
// 	margin-left: -15px;
// }

// .countdown.countdown-borders > span {
// 	border-width: 2px;
// 	border-style: solid;
// 	border-color: #212529;
// 	margin-right: 15px;
// 	margin-left: 15px;
// 	padding: 24px;
// 	padding: 1.5rem;
// }

// @media (max-width: 767px) {
// 	.countdown {
// 		flex-wrap: wrap;
// 	}

// 	.countdown > span {
// 		flex-basis: calc(50% - 15px);
// 		max-width: calc(50% - 15px);
// 		width: calc(50% - 15px);
// 		margin-bottom: 30px;
// 	}

// 	.countdown > span:nth-child(3), .countdown > span:nth-child(4) {
// 		margin-bottom: 0;
// 	}

// 	.countdown.countdown-borders {
// 		justify-content: space-between;
// 		margin-right: 0;
// 		margin-left: 0;
// 	}

// 	.countdown.countdown-borders > span {
// 		margin-right: 0;
// 		margin-left: 0;
// 	}
// }

// @media (max-width: 576px) {
// 	.countdown > span {
// 		flex-basis: 100%;
// 		max-width: 100%;
// 		width: 100%;
// 		margin-bottom: 50px;
// 	}

// 	.countdown > span:nth-child(3) {
// 		margin-bottom: 50px;
// 	}

// 	.countdown.countdown-borders > span {
// 		margin-bottom: 30px;
// 	}

// 	.countdown.countdown-borders > span:nth-child(3) {
// 		margin-bottom: 30px;
// 	}
// }

// /* Counters */
// .counters .counter {
// 	text-align: center;
// }

// .counters [class*="fa-"], .counters .icons {
// 	display: block;
// 	font-size: 35px;
// 	line-height: 35px;
// 	margin-bottom: 5px;
// }

// .counters strong {
// 	display: block;
// 	font-weight: bold;
// 	font-size: 50px;
// 	line-height: 50px;
// }

// .counters label {
// 	display: block;
// 	font-weight: 100;
// 	font-size: 20px;
// 	line-height: 20px;
// }

// .counters .counter-side-icon [class*="fa-"], .counters .counter-side-icon .icons, .counters .counter-side-icon strong {
// 	display: inline-block;
// }

// .counters .counter-with-unit strong {
// 	display: inline-block;
// }

// .counters .counter-with-unit.counter-unit-on-top {
// 	display: flex;
// 	flex-wrap: wrap;
// 	justify-content: center;
// 	align-items: flex-start;
// }

// .counters .counter-with-unit.counter-unit-on-top .unit {
// 	font-size: 32px;
// 	font-size: 2rem;
// 	line-height: 43.2px;
// 	line-height: 2.7rem;
// 	margin-left: 4.8px;
// 	margin-left: 0.3rem;
// }

// .counters .counter-with-unit.counter-unit-on-top label {
// 	width: 100%;
// 	margin-top: 11.2px;
// 	margin-top: 0.7rem;
// }

// .counters .counter-with-unit.counter-unit-on-bottom {
// 	align-items: flex-end;
// }

// .counters.with-borders .counter {
// 	border-radius: 6px;
// 	border-top: 1px solid #DFDFDF;
// 	border-bottom: 1px solid #DFDFDF;
// 	border-left: 1px solid #ECECEC;
// 	border-right: 1px solid #ECECEC;
// 	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
// 	padding: 25px;
// }

// .counters.counters-sm [class*="fa-"], .counters.counters-sm .icons {
// 	font-size: 25px;
// 	line-height: 25px;
// }

// .counters.counters-sm strong {
// 	font-size: 35px;
// 	line-height: 35px;
// }

// .counters.counters-sm label {
// 	font-size: 15px;
// 	line-height: 15px;
// }

// .counters.counters-lg [class*="fa-"] {
// 	font-size: 45px;
// 	line-height: 45px;
// }

// .counters.counters-lg strong {
// 	font-size: 60px;
// 	line-height: 60px;
// }

// .counters.counters-lg label {
// 	font-size: 25px;
// 	line-height: 25px;
// }

// .counters.counters-text-light .counter {
// 	color: #FFF !important;
// }

// .counters.counters-text-light .counter [class*="fa-"], .counters.counters-text-light .counter .icons, .counters.counters-text-light .counter strong, .counters.counters-text-light .counter label {
// 	color: #FFF !important;
// }

// .counters.counters-text-dark .counter {
// 	color: #333940 !important;
// }

// .counters.counters-text-dark .counter [class*="fa-"], .counters.counters-text-dark .counter .icons, .counters.counters-text-dark .counter strong, .counters.counters-text-dark .counter label {
// 	color: #333940 !important;
// }

/* Dividers */
hr {
	background: rgba(0, 0, 0, 0.06);
	border: 0;
	height: 1px;
	margin: 22px 0;
}

// hr.short {
// 	margin: 11px 0;
// }

// hr.tall {
// 	margin: 44px 0;
// }

// hr.taller {
// 	margin: 66px 0;
// }

// hr.gratient {
// 	background: none;
// 	background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.06), transparent);
// }

// hr.gradient-to-right {
// 	background: none;
// 	background-image: linear-gradient(to left, rgba(0, 0, 0, 0.06), transparent);
// }

// hr.gradient-to-left {
// 	background: none;
// 	background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.06));
// }

// hr.solid {
// 	background: rgba(0, 0, 0, 0.06);
// }

// hr.light {
// 	background: rgba(255, 255, 255, 0.15);
// }

// hr.invisible {
// 	background: none;
// }

// .divider {
// 	border: 0;
// 	height: 1px;
// 	margin: 44px auto;
// 	background: rgba(0, 0, 0, 0.06);
// 	text-align: center;
// 	position: relative;
// 	clear: both;
// }

// .divider.taller {
// 	margin: 66px auto;
// }

// .divider [class*="fa-"], .divider .icons {
// 	text-align: center;
// 	background: #FFF;
// 	border-radius: 50px;
// 	color: #a9a9a9;
// 	display: inline-block;
// 	height: 50px;
// 	line-height: 50px;
// 	position: absolute;
// 	text-align: center;
// 	width: 50px;
// 	font-size: 20px;
// 	margin: 0 auto 0 -25px;
// 	top: -25px;
// 	left: 50%;
// 	z-index: 1;
// }

// .divider.divider-solid {
// 	background: rgba(0, 0, 0, 0.06);
// }

// .divider.divider-icon-sm [class*="fa-"], .divider.divider-icon-sm .icons {
// 	width: 30px;
// 	height: 30px;
// 	line-height: 30px;
// 	top: -15px;
// 	font-size: 15px;
// 	margin-left: -16px;
// }

// .divider.divider-icon-lg {
// 	margin: 86px 0;
// }

// .divider.divider-icon-lg [class*="fa-"], .divider.divider-icon-lg .icons {
// 	width: 60px;
// 	height: 60px;
// 	line-height: 60px;
// 	top: -30px;
// 	font-size: 30px;
// 	margin-left: -30px;
// }

// .divider.divider-icon-lg.taller {
// 	margin: 100px auto;
// }

// .divider.divider-xs {
// 	width: 35%;
// }

// .divider.divider-sm {
// 	width: 67%;
// }

// .divider.divider-left [class*="fa-"], .divider.divider-left .icons {
// 	left: 0;
// 	margin-left: 0;
// 	margin-right: 0;
// 	right: auto;
// }

// .divider.divider-right [class*="fa-"], .divider.divider-right .icons {
// 	left: auto;
// 	margin-left: 0;
// 	margin-right: 0;
// 	right: 0;
// }

// .divider.divider-style-2 [class*="fa-"], .divider.divider-style-2 .icons {
// 	background: #f7f7f7;
// 	color: #a9a9a9;
// }

// .divider.divider-style-3 [class*="fa-"], .divider.divider-style-3 .icons {
// 	border: 1px solid #CECECE;
// }

// .divider.divider-style-4 [class*="fa-"], .divider.divider-style-4 .icons {
// 	border: 1px solid #CECECE;
// }

// .divider.divider-style-4 [class*="fa-"]:after, .divider.divider-style-4 .icons:after {
// 	border: 3px solid #f7f7f7;
// 	border-radius: 50%;
// 	box-sizing: content-box;
// 	content: "";
// 	display: block;
// 	height: 100%;
// 	left: -4px;
// 	padding: 1px;
// 	position: absolute;
// 	top: -4px;
// 	width: 100%;
// }

// .divider.divider-small {
// 	margin: 22px auto;
// 	background: transparent;
// }

// .divider.divider-small hr {
// 	width: 50px;
// 	height: 3px;
// 	background: #555;
// }

// .divider.divider-small.divider-small-center {
// 	text-align: center;
// }

// .divider.divider-small.divider-small-center hr {
// 	margin-left: auto;
// 	margin-right: auto;
// }

// .divider.divider-small.divider-small-right {
// 	text-align: right;
// }

// .divider.divider-small.divider-small-right hr {
// 	margin-left: auto;
// }

// .divider.divider-small.divider-light hr {
// 	background: #DDD;
// }

// .divider.divider-small.divider-small-sm hr {
// 	height: 1px;
// }

// .divider.divider-small.divider-small-lg hr {
// 	height: 6px;
// }

// .divider.divider-small.tall {
// 	margin: 34px 0;
// }

// .divider.divider-small.taller {
// 	margin: 46px 0;
// }

// .divider .divider-small-text {
// 	background: #FFF;
// 	color: #999;
// 	display: inline-block;
// 	text-align: center;
// 	font-size: 0.8em;
// 	height: 30px;
// 	line-height: 30px;
// 	padding: 0 30px;
// 	text-transform: uppercase;
// 	margin: 0;
// 	position: relative;
// 	top: -15px;
// }

// hr.dashed, .divider.dashed {
// 	background: none;
// 	position: relative;
// }

// hr.dashed:after, .divider.dashed:after {
// 	border-top: 1px dashed rgba(0, 0, 0, 0.06);
// 	content: "";
// 	display: block;
// 	height: 0;
// 	position: absolute;
// 	top: 50%;
// 	margin-top: -1px;
// 	width: 100%;
// }

// hr.pattern, .divider.pattern {
// 	background: none;
// 	position: relative;
// }




@-webkit-keyframes dividerProgress25 {
	from {
		width: 0;
	}

	to {
		width: 25%;
	}
}

@keyframes dividerProgress25 {
	from {
		width: 0;
	}

	to {
		width: 25%;
	}
}

.dividerProgress25 {
	-webkit-animation-name: dividerProgress25;
	animation-name: dividerProgress25;
}

/* Divider Borders */
.divider-top-border {
	border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.divider-bottom-border {
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.divider-left-border {
	border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.divider-right-border {
	border-right: 1px solid rgba(0, 0, 0, 0.06);
}

/* Dropdowns */
.dropdown.dropdown-style-1 .dropdown-toggle {
	display: flex;
	align-items: center;
	padding: 16px 17.6px;
	padding: 1rem 1.1rem;
	background: #FFF;
	border-radius: 0;
	border-right: 1px solid #ededed;
	min-width: 280px;
	font-weight: bold;
	text-decoration: none;
}

.dropdown.dropdown-style-1 .dropdown-toggle:after {
	content: '\f107';
	position: absolute;
	right: 17.6px;
	right: 1.1rem;
	top: 50%;
	font-family: 'Font Awesome 5 Free';
	font-weight: bold;
	transform: translate3d(0, -50%, 0);
	border: 0;
	margin: 0;
	width: 10px;
	height: 10px;
	line-height: 10px;
}

.dropdown.dropdown-style-1 .dropdown-toggle > i {
	display: none;
}

.dropdown.dropdown-style-1 .dropdown-menu {
	width: 100%;
	border-radius: 0;
	margin-top: -1px;
	border-color: #ededed;
}

.dropdown.dropdown-style-1 .dropdown-menu .dropdown-item {
	font-size: 14px;
	padding-right: 17.6px;
	padding-right: 1.1rem;
	padding-left: 17.6px;
	padding-left: 1.1rem;
}

.dropdown.dropdown-corner-left-fix .dropdown-menu {
	margin-left: -5px;
}

.dropdown-menu.dropdown-menu-arrow-centered:before {
	right: auto;
	left: 50%;
	transform: translate3d(-50%, -6px, 0);
}

/* Icon Featured */
.icon-featured {
	display: inline-block;
	font-size: 0;
	margin: 15px;
	width: 110px;
	height: 110px;
	border-radius: 50%;
	text-align: center;
	position: relative;
	z-index: 1;
	color: #FFF;
	font-size: 2.3em;
	line-height: 110px;
}

.icon-featured:after {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: '';
	box-sizing: content-box;
}

/* Featured Box */
// .featured-box {
// 	background: #FFF;
// 	box-sizing: border-box;
// 	border-bottom: 1px solid #DFDFDF;
// 	border-left: 1px solid #ECECEC;
// 	border-radius: 8px;
// 	border-right: 1px solid #ECECEC;
// 	box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.05);
// 	margin-bottom: 20px;
// 	margin-left: auto;
// 	margin-right: auto;
// 	margin-top: 20px;
// 	min-height: 100px;
// 	position: relative;
// 	text-align: center;
// 	z-index: 1;
// }

// .featured-box h4 {
// 	font-size: 1.3em;
// 	font-weight: 400;
// 	letter-spacing: -0.7px;
// 	margin-top: 5px;
// 	margin-bottom: 5px;
// }

// .featured-box .box-content {
// 	border-radius: 8px;
// 	border-top: 1px solid rgba(0, 0, 0, 0.06);
// 	border-top-width: 4px;
// 	padding: 28.8px;
// 	padding: 1.8rem;
// 	position: relative;
// }

// .featured-box .box-content:not(.box-content-border-0) {
// 	top: -1px;
// 	border-top-width: 4px;
// }

// .featured-box .box-content.box-content-border-0 {
// 	border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
// 	border-bottom: 0 !important;
// }

// .featured-box .box-content.box-content-border-bottom {
// 	top: 1px;
// }

// .featured-box .box-content-border-bottom {
// 	border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
// 	border-bottom: 4px solid rgba(0, 0, 0, 0.06);
// }

// /* Featured Box Left */
// .featured-box-text-left {
// 	text-align: left;
// }

// /* Featured Boxes - Flat */
// .featured-boxes-flat .featured-box {
// 	box-shadow: none;
// 	margin: 10px 0;
// }

// .featured-boxes-flat .featured-box .box-content {
// 	background: #FFF;
// 	margin-top: 65px;
// }

// .featured-boxes-flat .featured-box .icon-featured {
// 	display: inline-block;
// 	font-size: 2em;
// 	height: 90px;
// 	line-height: 90px;
// 	padding: 0;
// 	width: 90px;
// 	margin: -100px 0 -15px;
// 	position: relative;
// 	top: -40px;
// }

// /* Featured Boxes - Style 2 */
// .featured-boxes-style-2 .featured-box {
// 	background: none;
// 	border: 0;
// 	margin: 10px 0;
// 	box-shadow: none;
// }

// .featured-boxes-style-2 .featured-box .box-content {
// 	border: 0;
// 	padding-top: 0;
// 	padding-bottom: 0;
// }

// .featured-boxes-style-2 .featured-box .icon-featured {
// 	display: inline-block;
// 	font-size: 1.8em;
// 	height: 75px;
// 	line-height: 75px;
// 	padding: 0;
// 	width: 75px;
// 	margin-top: 0;
// }

// /* Featured Boxes - Style 3 */
// .featured-boxes-style-3 .featured-box {
// 	margin: 10px 0;
// }

// .featured-boxes-style-3 .featured-box .icon-featured {
// 	display: inline-block;
// 	font-size: 1.8em;
// 	height: 75px;
// 	line-height: 75px;
// 	padding: 0;
// 	width: 75px;
// 	margin-top: -37px;
// 	top: -37px;
// 	background: #FFF;
// 	border: 2px solid rgba(0, 0, 0, 0.06);
// 	color: rgba(0, 0, 0, 0.06);
// }

// .featured-boxes-style-3:not(.featured-boxes-flat) .featured-box {
// 	background: none;
// 	border: 0;
// 	box-shadow: none;
// }

// .featured-boxes-style-3:not(.featured-boxes-flat) .featured-box .box-content {
// 	border: 0;
// 	padding-top: 0;
// 	padding-bottom: 0;
// }

// /* Featured Boxes - Style 4 */
// .featured-boxes-style-4 .featured-box {
// 	background: none;
// 	border: 0;
// 	margin: 10px 0;
// 	box-shadow: none;
// }

// .featured-boxes-style-4 .featured-box .box-content {
// 	border: 0;
// 	padding-top: 0;
// 	padding-bottom: 0;
// }

// .featured-boxes-style-4 .featured-box .icon-featured {
// 	display: inline-block;
// 	font-size: 35px;
// 	height: 45px;
// 	line-height: 45px;
// 	padding: 0;
// 	width: 45px;
// 	margin-top: 0;
// 	margin-bottom: 10px;
// 	background: transparent;
// 	color: rgba(0, 0, 0, 0.06);
// 	border-radius: 0;
// }

// /* Featured Boxes - Style 5, 6 and 7 */
// .featured-boxes-style-5 .featured-box, .featured-boxes-style-6 .featured-box, .featured-boxes-style-7 .featured-box {
// 	background: none;
// 	border: 0;
// 	box-shadow: none;
// 	margin: 10px 0;
// }

// .featured-boxes-style-5 .featured-box .box-content, .featured-boxes-style-6 .featured-box .box-content, .featured-boxes-style-7 .featured-box .box-content {
// 	border: 0;
// 	padding-top: 0;
// 	padding-bottom: 0;
// }

// .featured-boxes-style-5 .featured-box .box-content h4, .featured-boxes-style-6 .featured-box .box-content h4, .featured-boxes-style-7 .featured-box .box-content h4 {
// 	color: #333940;
// }

// .featured-boxes-style-5 .featured-box .icon-featured, .featured-boxes-style-6 .featured-box .icon-featured, .featured-boxes-style-7 .featured-box .icon-featured {
// 	display: inline-block;
// 	font-size: 2em;
// 	height: 75px;
// 	padding: 0;
// 	width: 75px;
// 	margin-top: 0;
// 	margin-bottom: 10px;
// 	background: #FFF;
// 	border: 1px solid #dfdfdf;
// 	color: #777;
// 	line-height: 73px;
// }

// /* Featured Boxes - Style 6 */
// .featured-boxes-style-6 .featured-box .icon-featured {
// 	border: 1px solid #cecece;
// 	color: #777;
// }

// .featured-boxes-style-6 .featured-box .icon-featured:after {
// 	border: 5px solid #f7f7f7;
// 	border-radius: 50%;
// 	box-sizing: content-box;
// 	content: "";
// 	display: block;
// 	height: 100%;
// 	left: -6px;
// 	padding: 1px;
// 	position: absolute;
// 	top: -6px;
// 	width: 100%;
// }

// /* Featured Boxes - Style 7 */
// .featured-boxes-style-7 .featured-box .icon-featured {
// 	border: 1px solid #dfdfdf;
// 	color: #777;
// }

// /* Featured Boxes - Style 8 */
// .featured-boxes-style-8 .featured-box .icon-featured {
// 	display: inline-block;
// 	font-size: 2em;
// 	height: 75px;
// 	padding: 0;
// 	width: 75px;
// 	margin: -15px -15px 0 0;
// 	background: #FFF;
// 	line-height: 75px;
// 	border: 0;
// 	color: #777;
// }

// .featured-boxes-style-8 .featured-box .icon-featured:after {
// 	display: none;
// }

// /* Featured Boxes Modern */
// .featured-boxes-modern-style-1 .featured-box {
// 	position: relative;
// 	overflow: hidden;
// 	border-radius: 0;
// 	height: auto !important;
// 	margin: 0 !important;
// }

// .featured-boxes-modern-style-1 .featured-box .featured-box-background {
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	transition: cubic-bezier(0.39, 0.66, 0.42, 0.99) all 2s;
// 	transform: scale(1);
// }

// .featured-boxes-modern-style-1 .featured-box .box-content {
// 	padding: 64px;
// 	padding: 4rem;
// 	border: none;
// 	z-index: 3;
// }

// .featured-boxes-modern-style-1 .featured-box .box-content .icon-featured {
// 	color: #FFF;
// 	background: transparent;
// 	width: auto;
// 	height: auto;
// 	line-height: 1.4;
// 	font-size: 46.4px;
// 	font-size: 2.9rem;
// 	margin: 0 0 16px;
// 	margin: 0 0 1rem;
// }

// .featured-boxes-modern-style-1 .featured-box .box-content h2 {
// 	color: #FFF;
// 	font-weight: 600;
// 	font-size: 19.2px;
// 	font-size: 1.2rem;
// 	line-height: 1.3;
// 	margin-bottom: 11.2px;
// 	margin-bottom: 0.7rem;
// }

// .featured-boxes-modern-style-1 .featured-box .box-content p {
// 	color: #999;
// 	margin-bottom: 25.6px;
// 	margin-bottom: 1.6rem;
// }

// .featured-boxes-modern-style-1 .featured-box:hover .featured-box-background {
// 	transform: scale(1.1);
// }

// @media (max-width: 575px) {
// 	.featured-boxes-modern-style-1 .featured-box .box-content {
// 		padding: 2rem 1.5rem;
// 	}
// }

// .featured-boxes-modern-style-2 .featured-box {
// 	position: relative;
// 	background: #6c6ef1;
// 	background: linear-gradient(135deg, #6c6ef1 0%, #e2498e 100%);
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6c6ef1', endColorstr='#e2498e', GradientType=1 );
// 	border-radius: 0;
// 	margin: 0 !important;
// 	overflow: hidden;
// }

// .featured-boxes-modern-style-2 .featured-box:before {
// 	content: '';
// 	position: absolute;
// 	top: 8px;
// 	right: 8px;
// 	bottom: 8px;
// 	left: 8px;
// 	background: #FFF;
// }

// .featured-boxes-modern-style-2 .featured-box .box-content {
// 	padding: 64px;
// 	padding: 4rem;
// 	border: none;
// }

// .featured-boxes-modern-style-2 .featured-box .box-content .icon-featured {
// 	color: #FFF;
// 	background: transparent;
// 	width: auto;
// 	height: auto;
// 	line-height: 1.4;
// 	font-size: 46.4px;
// 	font-size: 2.9rem;
// 	margin: 0 0 16px;
// 	margin: 0 0 1rem;
// }

// .featured-boxes-modern-style-2 .featured-box .box-content h2 {
// 	color: #212529;
// 	font-weight: 700;
// 	font-size: 19.2px;
// 	font-size: 1.2rem;
// 	line-height: 1.3;
// 	margin-bottom: 11.2px;
// 	margin-bottom: 0.7rem;
// }

// .featured-boxes-modern-style-2 .featured-box .box-content p {
// 	color: #999;
// 	margin-bottom: 25.6px;
// 	margin-bottom: 1.6rem;
// }

// @media (max-width: 575px) {
// 	.featured-boxes-modern-style-2 .featured-box .box-content {
// 		padding: 2rem 1.5rem;
// 	}
// }

// .featured-boxes-modern-style-2-hover-only .featured-box:before {
// 	top: 1px;
// 	right: 1px;
// 	bottom: 1px;
// 	left: 1px;
// 	transition: cubic-bezier(0.39, 0.66, 0.42, 0.99) all 200ms;
// }

// .featured-boxes-modern-style-2-hover-only:not(:hover) .featured-box {
// 	background: rgba(0, 0, 0, 0.06) !important;
// 	box-shadow: none;
// 	border: 0;
// }

// .featured-boxes-modern-style-2-hover-only:not(:hover) .featured-box-no-borders {
// 	background: none !important;
// }

// .featured-boxes-modern-style-2-hover-only:not(:hover) .featured-box-box-shadow {
// 	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
// }

// .featured-boxes-modern-style-2-hover-only:hover .featured-box:before {
// 	top: 8px;
// 	right: 8px;
// 	bottom: 8px;
// 	left: 8px;
// }

// /* Featured Boxes */
// .featured-boxes .featured-box {
// 	margin-bottom: 24px;
// 	margin-bottom: 1.5rem;
// 	margin-top: 24px;
// 	margin-top: 1.5rem;
// }

// /* Effects */
// .featured-box-effect-1 .icon-featured:after {
// 	top: -7px;
// 	left: -7px;
// 	padding: 7px;
// 	box-shadow: 0 0 0 3px #FFF;
// 	transition: transform 0.2s, opacity 0.2s;
// 	transform: scale(1);
// 	opacity: 0;
// }

// .featured-box-effect-1:hover .icon-featured:after {
// 	transform: scale(0.8);
// 	opacity: 1;
// }

// .featured-box-effect-2 .icon-featured:after {
// 	top: -7px;
// 	left: -7px;
// 	padding: 7px;
// 	box-shadow: 0 0 0 3px #FFF;
// 	transition: transform 0.2s, opacity 0.2s;
// 	transform: scale(0.8);
// 	opacity: 0;
// }

// .featured-box-effect-2:hover .icon-featured:after {
// 	transform: scale(0.95);
// 	opacity: 1;
// }

// .featured-box-effect-3 .icon-featured:after {
// 	top: 0;
// 	left: 0;
// 	box-shadow: 0 0 0 10px #FFF;
// 	transition: transform 0.2s, opacity 0.2s;
// 	transform: scale(0.9);
// 	opacity: 0;
// }

// .featured-box-effect-3:hover .icon-featured {
// 	color: #FFF !important;
// }

// .featured-box-effect-3:hover .icon-featured:after {
// 	transform: scale(1);
// 	opacity: 0.8;
// }

// .featured-box-effect-4 .icon-featured {
// 	transition: transform 0.2s, transform 0.2s;
// 	transform: scale(1);
// }

// .featured-box-effect-4:hover .icon-featured {
// 	transform: scale(1.15);
// }

// .featured-box-effect-5 .icon-featured {
// 	overflow: hidden;
// 	transition: all 0.3s;
// }

// .featured-box-effect-5:hover .icon-featured {
// 	-webkit-animation: toRightFromLeft 0.3s forwards;
// 	animation: toRightFromLeft 0.3s forwards;
// }

// @-webkit-keyframes toRightFromLeft {
// 	49% {
// 		transform: translate(100%);
// 	}

// 	50% {
// 		opacity: 0;
// 		transform: translate(-100%);
// 	}

// 	51% {
// 		opacity: 1;
// 	}
// }

// @keyframes toRightFromLeft {
// 	49% {
// 		transform: translate(100%);
// 	}

// 	50% {
// 		opacity: 0;
// 		transform: translate(-100%);
// 	}

// 	51% {
// 		opacity: 1;
// 	}
// }

// .featured-box-effect-6 .icon-featured:after {
// 	transition: all 0.2s, transform 0.2s;
// }

// .featured-box-effect-6:hover .box-content .icon-featured:after {
// 	transform: scale(1.1);
// }

// .featured-box-effect-7 .icon-featured:after {
// 	opacity: 0;
// 	box-shadow: 3px 3px #d5d5d5;
// 	transform: rotate(-90deg);
// 	transition: opacity 0.2s, transform 0.2s;
// 	left: 0;
// 	top: -1px;
// }

// .featured-box-effect-7:hover .icon-featured:after {
// 	opacity: 1;
// 	transform: rotate(0deg);
// }

// .featured-box-effect-7 .icon-featured:before {
// 	transform: scale(0.8);
// 	opacity: 0.7;
// 	transition: transform 0.2s, opacity 0.2s;
// }

// .featured-box-effect-7:hover .icon-featured:before {
// 	transform: scale(1);
// 	opacity: 1;
// }

// /* Feature Box */
// .feature-box {
// 	display: flex;
// }

// .feature-box .feature-box-icon {
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// 	flex: 1 0 auto;
// 	background: rgba(0, 0, 0, 0.06);
// 	border-radius: 100%;
// 	color: #FFF;
// 	font-size: 16px;
// 	font-size: 1rem;
// 	position: relative;
// 	top: 7px;
// 	text-align: center;
// 	padding: 0.8em;
// 	height: 1px;
// 	width: 2.7em;
// 	height: 2.7em;
// }

// .feature-box .feature-box-info {
// 	flex: 1 1 100%;
// 	padding-left: 15px;
// }

// .feature-box.align-items-center .feature-box-icon {
// 	top: 0;
// }

// /* Style 2 */
// .feature-box.feature-box-style-2 h4 {
// 	color: #212529;
// }

// .feature-box.feature-box-style-2 .feature-box-icon {
// 	background: transparent;
// 	top: -3px;
// }

// .feature-box.feature-box-style-2 .feature-box-icon [class*="fa-"], .feature-box.feature-box-style-2 .feature-box-icon .icons {
// 	font-size: 1.7em;
// }

// .feature-box.feature-box-style-2.align-items-center .feature-box-icon {
// 	top: 0;
// }

// /* Style 3 */
// .feature-box.feature-box-style-3 h4 {
// 	color: #212529;
// }

// .feature-box.feature-box-style-3 .feature-box-icon {
// 	border: 1px solid rgba(0, 0, 0, 0.06);
// 	background: transparent;
// 	line-height: 32px;
// }

// .feature-box.feature-box-style-3 .feature-box-icon [class*="fa-"], .feature-box.feature-box-style-3 .feature-box-icon .icons {
// 	color: rgba(0, 0, 0, 0.06);
// }

// /* Style 4 */
// .feature-box.feature-box-style-4 {
// 	flex-wrap: wrap;
// }

// .feature-box.feature-box-style-4 h4 {
// 	color: #212529;
// }

// .feature-box.feature-box-style-4 .feature-box-icon {
// 	flex-basis: 100%;
// 	justify-content: flex-start;
// 	background: transparent;
// 	padding: 0 0 19.2px 0;
// 	padding: 0 0 1.2rem 0;
// }

// .feature-box.feature-box-style-4 .feature-box-icon [class*="fa-"], .feature-box.feature-box-style-4 .feature-box-icon .icons {
// 	font-size: 2.35em;
// 	width: auto;
// 	height: auto;
// }

// .feature-box.feature-box-style-4 .feature-box-info {
// 	clear: both;
// 	padding-left: 0;
// }

// /* Style 5 */
// .feature-box.feature-box-style-5 h4 {
// 	color: #212529;
// }

// .feature-box.feature-box-style-5 .feature-box-icon {
// 	background: transparent;
// 	top: 0;
// 	padding: 1em 1.4em;
// 	min-width: 5em;
// }

// .feature-box.feature-box-style-5 .feature-box-icon [class*="fa-"], .feature-box.feature-box-style-5 .feature-box-icon .icons {
// 	font-size: 2.75em;
// }

// .feature-box.feature-box-style-5.align-items-center .feature-box-icon {
// 	top: 0;
// }

// /* Style 6 */
// .feature-box.feature-box-style-6 h4 {
// 	color: #212529;
// }

// .feature-box.feature-box-style-6 .feature-box-icon {
// 	border: 1px solid #cecece;
// 	background: transparent;
// 	line-height: 32px;
// }

// .feature-box.feature-box-style-6 .feature-box-icon:after {
// 	border: 3px solid #f7f7f7;
// 	border-radius: 50%;
// 	box-sizing: content-box;
// 	content: "";
// 	display: block;
// 	height: 100%;
// 	left: -4px;
// 	padding: 1px;
// 	position: absolute;
// 	top: -4px;
// 	width: 100%;
// }

// .feature-box.feature-box-style-6 .feature-box-icon [class*="fa-"], .feature-box.feature-box-style-6 .feature-box-icon .icons {
// 	color: #a9a9a9;
// }

// /* Feature Box Icon Size */
// .feature-box .feature-box-icon.feature-box-icon-large {
// 	width: 3.7em;
// 	height: 3.7em;
// }

// .feature-box .feature-box-icon.feature-box-icon-extra-large {
// 	width: 4.5em;
// 	height: 4.5em;
// }

// /* Reverse All Resolutions */
// .feature-box.reverse-allres {
// 	text-align: right;
// 	flex-direction: row-reverse;
// }

// .feature-box.reverse-allres .feature-box-info {
// 	padding-right: 15px;
// 	padding-left: 0;
// }

// .feature-box.reverse-allres.feature-box-style-4 .feature-box-icon {
// 	justify-content: flex-end;
// }

// .feature-box.reverse-allres.feature-box-style-4 .feature-box-info {
// 	padding-right: 0;
// }

/* Reverse */
// @media (min-width: 992px) {
// 	.feature-box.reverse {
// 		text-align: right;
// 		flex-direction: row-reverse;
// 	}

// 	.feature-box.reverse .feature-box-info {
// 		padding-right: 15px;
// 		padding-left: 0;
// 	}

// 	.feature-box.reverse.feature-box-style-4 .feature-box-icon {
// 		justify-content: flex-end;
// 	}

// 	.feature-box.reverse.feature-box-style-4 .feature-box-info {
// 		padding-right: 0;
// 	}
// }

/* Full Width */
// .featured-boxes-full .featured-box-full {
// 	text-align: center;
// 	padding: 55px;
// }

// .featured-boxes-full .featured-box-full [class*="fa-"], .featured-boxes-full .featured-box-full .icons, .featured-boxes-full .featured-box-full h1, .featured-boxes-full .featured-box-full h2, .featured-boxes-full .featured-box-full h3, .featured-boxes-full .featured-box-full h4, .featured-boxes-full .featured-box-full h5, .featured-boxes-full .featured-box-full h6, .featured-boxes-full .featured-box-full p, .featured-boxes-full .featured-box-full a {
// 	padding: 0;
// 	margin: 0;
// }

// .featured-boxes-full .featured-box-full:not(.featured-box-full-light) [class*="fa-"], .featured-boxes-full .featured-box-full:not(.featured-box-full-light) .icons, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h1, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h2, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h3, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h4, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h5, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) h6, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) p, .featured-boxes-full .featured-box-full:not(.featured-box-full-light) a {
// 	color: #FFF;
// }

// .featured-boxes-full .featured-box-full .icons, .featured-boxes-full .featured-box-full [class*="fa-"] {
// 	font-size: 3.9em;
// 	margin-bottom: 15px;
// }

// .featured-boxes-full .featured-box-full p {
// 	padding-top: 12px;
// 	opacity: 0.8;
// }

// .featured-boxes-full .featured-box-full.featured-box-full-light {
// 	border: 1px solid rgba(0, 0, 0, 0.06);
// }

// .featured-boxes-full .featured-box-full.featured-box-full-light:not(:last-child) {
// 	border-right: 0;
// }

// .featured-boxes-full .featured-box-full.featured-box-full-light:first-child {
// 	border-left: 0;
// }

/*
* IE
*/






/* Forms */
form label {
	font-weight: normal;
}

textarea {
	resize: vertical;
}

select {
	border: 1px solid #E5E7E9;
	border-radius: 6px;
	outline: none;
}

.label {
	font-weight: normal;
}

label.required:after {
	content: "*";
	font-size: 0.8em;
	margin: 0.3em;
	position: relative;
	top: -2px;
}

.form-group:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.form-control {
	border-color: rgba(0, 0, 0, 0.09);
}

.form-control:not(.form-control-lg) {
	font-size: 0.75em;
	line-height: 1.3;
}

.form-control:not(.form-control-sm):not(.form-control-lg) {
	font-size: 1.2em;
}

@media screen and (min-width: 2400px){
	.form-control{
		font-size: 1.5em;
		line-height: 3;
	}

}

.form-control.form-control-focused {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	border-color: #CCC;
}

.form-control:focus {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	border-color: #CCC;
}

.form-control.error {
	border-color: #a94442;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-control.error:focus {
	border-color: #843534;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.page-link {
	border-color: rgba(0, 0, 0, 0.06);
}

.input-group .form-control {
	height: auto;
}

.input-group-rounded .form-control {
	border-radius: 2em 0em 0em 2em;
	border: 0;
	padding-left: 1em;
}

.input-group-rounded .input-group-append .btn {
	border-color: rgba(0, 0, 0, 0.09);
	border-radius: 0rem 2rem 2rem 0rem;
	font-size: 11.2px;
	font-size: 0.7rem;
	padding: 13.92px;
	padding: 0.87rem;
	border-top: 0;
	border-bottom: 0;
	border-right: 0;
	z-index: 3;
}

/* Form - iOS Override */
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
	-webkit-appearance: none;
}

.form-control::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="datetime"]::-webkit-input-placeholder,
input[type="datetime-local"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="month"]::-webkit-input-placeholder,
input[type="time"]::-webkit-input-placeholder,
input[type="week"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="color"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #bdbdbd;
}

.form-control::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="datetime"]::-moz-placeholder,
input[type="datetime-local"]::-moz-placeholder,
input[type="date"]::-moz-placeholder,
input[type="month"]::-moz-placeholder,
input[type="time"]::-moz-placeholder,
input[type="week"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="color"]::-moz-placeholder,
textarea::-moz-placeholder {
	color: #bdbdbd;
}

.form-control:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="datetime"]:-ms-input-placeholder,
input[type="datetime-local"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="month"]:-ms-input-placeholder,
input[type="time"]:-ms-input-placeholder,
input[type="week"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="color"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #bdbdbd;
}

/* Placeholder Light */




/* Form - Bootstrap Override */
.btn-lg,
.btn-group-lg > .btn {
	line-height: 1.334;
}

.input-group-btn .btn {
	padding-top: 8px;
	padding-top: 0.5rem;
	padding-bottom: 8px;
	padding-bottom: 0.5rem;
}

select.form-control-sm, select.form-control-lg {
	line-height: 1;
	height: auto !important;
}



/* Forms Validations */
label.valid {
	display: inline-block;
	text-indent: -9999px;
}

label.error {
	color: #c10000;
	font-size: 0.9em;
	line-height: 18px;
	padding: 5px 0 0;
}

label.error#captcha-error {
	margin-top: 15px;
}

.form-errors-light .form-control.error {
	border-color: #FFF;
}

.form-errors-light label.error {
	color: #FFF;
}



/* Search With Select */
.search-with-select .mobile-search-toggle-btn {
	display: none;
}



/* Captcha */
.captcha {
	clear: both;
	overflow: hidden;
	background: #F2F2F2;
	text-align: center;
	margin-bottom: 20px;
	max-width: 160px;
	height: 78px;
	position: relative;
}

.captcha input {
	border: 0;
}

.captcha-input {
	position: relative;
	top: 15px;
	font-size: 14px;
}

.captcha-image {
	float: left;
	position: relative;
	top: -5px;
	left: -13px;
}

.captcha-refresh {
	position: absolute;
	z-index: 1;
	bottom: 2px;
	right: 2px;
	border-radius: 3px;
	padding: 2px 5px;
}

.captcha-refresh a, .captcha-refresh a:hover, .captcha-refresh a:focus, .captcha-refresh a:active {
	color: #333;
}














/* Floating Labels */


.form-control:focus-visible {
	color: #002345;
	text-shadow: none;
}


.form_input .form-row  {
	position: relative;
	margin-bottom: 1em;
}


@media screen and (min-width: 768px){
	.form_input .form-row:last-child{
		margin-bottom: 0;
	}
}



.form-label-group input, .form-label-group label {
	height: 3.125em;
	padding: .75em;
}

.form-label-group textarea, .form-label-group label  {
	padding: .75em;
}

.form-label-group label {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	margin-bottom: 0;
	line-height: 1.5em;
	color: #002345;
	pointer-events: none;
	cursor: text;
	border: 1px solid transparent;
	border-radius: .25rem;
	transition: all .1s ease-in-out;
}

.form-label-group input::-moz-placeholder, 
.form-label-group textarea::-moz-placeholder,
.form-label-group input::-moz-placeholder , 
.form-label-group textarea::-moz-placeholder:focus{
	color: white;
	outline: none;
}

.form-label-group input:-ms-input-placeholder,
.form-label-group textarea:-ms-input-placeholder {
	color: transparent;
}

.form-label-group input::placeholder,
.form-label-group textarea::placeholder{
	color: transparent;
}

.form-label-group input:not(:-moz-placeholder-shown),
.form-label-group textarea:not(:-moz-placeholder-shown)  {
	padding-top: 20px;
	padding-top: 1.25rem;
	padding-bottom: 4px;
	padding-bottom: .25rem;
}

.form-label-group input:not(:-ms-input-placeholder),
.form-label-group textarea:not(:-ms-input-placeholder) {
	padding-top: 20px;
	padding-top: 1.25rem;
	padding-bottom: 4px;
	padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown),
.form-label-group textarea:not(:placeholder-shown) {
	padding-top: 20px;
	padding-top: 1.25rem;
	padding-bottom: 4px;
	padding-bottom: .25rem;
}

.form-label-group input:not(:-moz-placeholder-shown) ~ label,
.form-label-group textarea:not(:-moz-placeholder-shown) ~ label {
	padding-top: 4px;
	padding-top: .25rem;
	padding-bottom: 4px;
	padding-bottom: .25rem;
	font-size: 12px;
	color: #777;
}

.form-label-group input:not(:-ms-input-placeholder) ~ label,
.form-label-group textarea:not(:-ms-input-placeholder) ~ label {
	padding-top: 4px;
	padding-top: .25rem;
	padding-bottom: 4px;
	padding-bottom: .25rem;
	font-size: 12px;
	color: #494949;
}

.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label {
	padding-top: 4px;
	padding-top: .25rem;
	padding-bottom: 4px;
	padding-bottom: .25rem;
	font-size: .75em;
	color: #494949;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
	.form-label-group {
		display: flex;
		flex-direction: column-reverse;
	}

	.form-label-group label {
		position: static;
	}

	.form-label-group input::-ms-input-placeholder, 
	.form-label-group textarea::-ms-input-placeholder {
		color: #494949;
	}
}

/* Form Squared Borders */
.form-squared-borders .form-control {
	border-radius: 0;
}

/* Form With Icons */
.form-with-icons .form-control {
	padding-left: 41px;
}

/* Form Fields Rounded */
.form-fields-rounded .form-control {
	padding-left: 18px;
	padding-right: 18px;
	border-radius: 35px;
}

.form-fields-rounded textarea.form-control {
	border-radius: 22px;
}

/* Form With Shadow */
.form-with-shadow .form-control {
	box-shadow: 0px 0px 17px -4px rgba(0, 0, 0, 0.1) !important;
}


/* Headings */
h1, h2, h3, h4, h5, h6 {
	color: #212529;
	font-weight: 200;
	letter-spacing: -.05em;
	margin: 0;
	-webkit-font-smoothing: antialiased;
}

h1 {
	font-size: 2.6em;
	line-height: 44px;
	margin: 0 0 32px 0;
}

h2 {
	font-size: 2.2em;
	font-weight: 300;
	line-height: 42px;
	margin: 0 0 32px 0;
}

h3 {
	font-size: 1.8em;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 33px;
}

h4 {
	font-size: 1.4em;
	font-weight: 600;
	line-height: 27px;
	margin: 0 0 14px 0;
}

h5 {
	font-size: 1em;
	font-weight: 600;
	line-height: 18px;
	margin: 0 0 14px 0;
}

h6 {
	color: #333;
	font-size: 1em;
	font-weight: 400;
	line-height: 18px;
	margin: 0 0 14px 0;
}


h1.dark,
h2.dark,
h3.dark,
h4.dark,
h5.dark {
	color: #212529;
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light {
	color: #FFF;
}

h1.text-shadow,
h2.text-shadow,
h3.text-shadow,
h4.text-shadow,
h5.text-shadow,
h6.text-shadow {
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

/* Heading */
.heading.heading-border {
	margin-bottom: 25px;
}

.heading.heading-border h1, .heading.heading-border h2, .heading.heading-border h3, .heading.heading-border h4, .heading.heading-border h5, .heading.heading-border h6 {
	margin: 0;
	padding: 0;
}

// .heading.heading-bottom-border h1 {
// 	border-bottom: 5px solid #eaeaea;
// 	padding-bottom: 10px;
// }

// .heading.heading-bottom-border h2, .heading.heading-bottom-border h3 {
// 	border-bottom: 2px solid #eaeaea;
// 	padding-bottom: 10px;
// }

// .heading.heading-bottom-border h4, .heading.heading-bottom-border h5, .heading.heading-bottom-border h6 {
// 	border-bottom: 1px solid #eaeaea;
// 	padding-bottom: 5px;
// }

// .heading.heading-bottom-double-border h1, .heading.heading-bottom-double-border h2, .heading.heading-bottom-double-border h3 {
// 	border-bottom: 3px double #eaeaea;
// 	padding-bottom: 10px;
// }

// .heading.heading-bottom-double-border h4, .heading.heading-bottom-double-border h5, .heading.heading-bottom-double-border h6 {
// 	border-bottom: 3px double #eaeaea;
// 	padding-bottom: 5px;
// }

// .heading.heading-middle-border {
// 	position: relative;
// }

// .heading.heading-middle-border:before {
// 	border-top: 1px solid #eaeaea;
// 	content: "";
// 	height: 0;
// 	left: auto;
// 	position: absolute;
// 	right: 0;
// 	top: 50%;
// 	width: 100%;
// }

// .heading.heading-middle-border h1, .heading.heading-middle-border h2, .heading.heading-middle-border h3, .heading.heading-middle-border h4, .heading.heading-middle-border h5, .heading.heading-middle-border h6 {
// 	position: relative;
// 	background: #FFF;
// 	display: inline-block;
// }

// .heading.heading-middle-border h1 {
// 	padding-right: 15px;
// }

// .heading.heading-middle-border h2, .heading.heading-middle-border h3 {
// 	padding-right: 10px;
// }

// .heading.heading-middle-border h4, .heading.heading-middle-border h5, .heading.heading-middle-border h6 {
// 	padding-right: 5px;
// }

// .heading.heading-middle-border-reverse {
// 	text-align: right;
// }

// .heading.heading-middle-border-reverse h1, .heading.heading-middle-border-reverse h2, .heading.heading-middle-border-reverse h3, .heading.heading-middle-border-reverse h4, .heading.heading-middle-border-reverse h5, .heading.heading-middle-border-reverse h6 {
// 	padding-right: 0;
// }

// .heading.heading-middle-border-reverse h1 {
// 	padding-left: 15px;
// }

// .heading.heading-middle-border-reverse h2, .heading.heading-middle-border-reverse h3 {
// 	padding-left: 10px;
// }

// .heading.heading-middle-border-reverse h4, .heading.heading-middle-border-reverse h5, .heading.heading-middle-border-reverse h6 {
// 	padding-left: 5px;
// }

// .heading.heading-middle-border-center {
// 	text-align: center;
// }

// .heading.heading-middle-border-center h1 {
// 	padding-left: 15px;
// 	padding-right: 15px;
// }

// .heading.heading-middle-border-center h2, .heading.heading-middle-border-center h3 {
// 	padding-left: 10px;
// 	padding-right: 10px;
// }

// .heading.heading-middle-border-center h4, .heading.heading-middle-border-center h5, .heading.heading-middle-border-center h6 {
// 	padding-left: 5px;
// 	padding-right: 5px;
// }

// .heading.heading-border-xs:before {
// 	border-top-width: 1px;
// }

// .heading.heading-border-sm:before {
// 	border-top-width: 2px;
// }

// .heading.heading-border-lg:before {
// 	border-top-width: 3px;
// }

// .heading.heading-border-xl:before {
// 	border-top-width: 5px;
// }

/* Modal Titles */
.modal-header h3 {
	color: #333;
	text-transform: none;
}

/* Responsive */
@media (max-width: 575px) {
	h2 {
		line-height: 40px;
	}
}

@media (max-width: 767px) {
	h1.big {
		font-size: 3.2em;
		line-height: 42px;
	}
}

/* Icons */
.featured-icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: #CCC;
	border-radius: 100%;
	color: #FFF;
	height: 2.5em;
	line-height: 2.5em;
	margin-right: 10px;
	position: relative;
	text-align: center;
	top: 5px;
	width: 2.5em;
}

.featured-icon i {
	color: #FFF;
	font-size: 0.9em;
}

.featured-icon.featured-icon-style-2 {
	width: 5em;
	height: 5em;
	line-height: 5em;
}

.featured-icon.featured-icon-hover-effect-1 {
	transition: ease transform 300ms;
}

.featured-icon.featured-icon-hover-effect-1:hover {
	transform: scale(1.1);
}

/* Font Awesome List */
.sample-icon-list {
	margin-top: 22px;
}

.sample-icon-list .sample-icon a {
	display: block;
	color: #222222;
	line-height: 36px;
	height: 36px;
	padding-left: 10px;
	border-radius: 4px;
	font-size: 11px;
}

.sample-icon-list .sample-icon a [class*="fa-"], .sample-icon-list .sample-icon a .icons {
	width: 32px;
	font-size: 14px;
	display: inline-block;
	text-align: right;
	margin-right: 10px;
}

.sample-icon-list .sample-icon a:hover {
	color: #ffffff;
	text-decoration: none;
}

.sample-icon-list .sample-icon a:hover [class*="fa-"], .sample-icon-list .sample-icon a:hover .icons {
	font-size: 28px;
	vertical-align: -6px;
}

/* Simple Line Icons List */
#lineIcons .sample-icon-list .sample-icon a {
	line-height: 39px;
}

/* Porto Icons */
// i.pi {
// 	position: relative;
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// 	font-style: normal;
// 	font-size: 14px;
// }

// i.pi.pi-porto-brand:before {
// 	content: '\f111';
// 	font-family: 'Font Awesome 5 Free';
// 	font-weight: bold;
// 	font-size: 0.51em;
// 	color: #FFF;
// 	padding: 1.2em;
// }

// i.pi.pi-porto-brand:after {
// 	content: '';
// 	width: 2em;
// 	height: 2em;
// 	border: 0.5em solid #FFF;
// 	border-radius: 100%;
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate3d(-50%, -50%, 0);
// }

// i.pi.pi-close {
// 	padding: 0.6em;
// 	transform: rotate(45deg);
// }

// i.pi.pi-close:before {
// 	content: '';
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate3d(-50%, -50%, 0);
// 	border-right: 1px solid #FFF;
// 	height: 1em;
// }

// i.pi.pi-close:after {
// 	content: '';
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate3d(-50%, -50%, 0);
// 	border-bottom: 1px solid #FFF;
// 	width: 1em;
// }

/* Area Expanded Change */
.icon-aria-expanded-change[aria-expanded=true] .fa-chevron-down {
	display: none;
}

.icon-aria-expanded-change[aria-expanded=false] .fa-chevron-up {
	display: none;
}

/* Icon Animated */
[data-icon] {
	opacity: 0;
}

.icon-animated {
	opacity: 0;
}

/* Elements Shadow Icon */
.elements-list-shadow-icon {
	font-size: 64px;
	font-size: 4rem;
	position: absolute;
	top: -18px;
	opacity: 0.08;
	right: -18px;
}

/* Custom Image Icons */
.icon-bg {
	display: inline-block;
	width: 80px;
	height: 80px;
}

.icon-1 {
	background-position: -10px -10px;
}

.icon-2 {
	background-position: -110px -10px;
}

.icon-3 {
	background-position: -210px -10px;
}

.icon-plugin-1 {
	background-position: 0 100%;
	width: 115px;
	height: 115px;
}

.icon-plugin-2 {
	background-position: -115px 100%;
	width: 115px;
	height: 115px;
}

.icon-feature-1 {
	background-position: -20px -110px;
}

.icon-feature-2 {
	background-position: -120px -110px;
}

.icon-feature-3 {
	background-position: -220px -110px;
}

.icon-feature-4 {
	background-position: -320px -110px;
}

.icon-feature-5 {
	background-position: -420px -110px;
}

.icon-feature-6 {
	background-position: -520px -110px;
}

.icon-feature-7 {
	background-position: -620px -110px;
}

// .icon-feature-8 {
// 	background-position: -720px -110px;
// }

// .icon-feature-9 {
// 	background-position: -820px -110px;
// }

// .icon-feature-10 {
// 	background-position: -920px -110px;
// }

// .icon-feature-11 {
// 	background-position: -20px -210px;
// }

// .icon-feature-12 {
// 	background-position: -120px -210px;
// }

// .icon-feature-13 {
// 	background-position: -220px -210px;
// }

// .icon-feature-14 {
// 	background-position: -320px -210px;
// }

// .icon-feature-15 {
// 	background-position: -420px -210px;
// }

// .icon-feature-16 {
// 	background-position: -520px -210px;
// }

// .icon-feature-17 {
// 	background-position: -624px -210px;
// }

// .icon-feature-18 {
// 	background-position: -720px -210px;
// }

// .icon-feature-19 {
// 	background-position: -820px -210px;
// }

// .icon-menu-1 {
// 	background-position: -310px -10px;
// }

// .icon-menu-2 {
// 	background-position: -410px -10px;
// }

// .icon-menu-3 {
// 	background-position: -510px -10px;
// }

// .icon-menu-4 {
// 	background-position: -610px -10px;
// }

/* Thumbnails */
.img-thumbnail {
	border-radius: 4px;
	position: relative;
}

.img-thumbnail img {
	border-radius: 4px;
}

.img-thumbnail .zoom {
	background: #CCC;
	border-radius: 100%;
	bottom: 8px;
	color: #FFF;
	display: block;
	height: 30px;
	padding: 6px;
	position: absolute;
	right: 8px;
	text-align: center;
	width: 30px;
}

.img-thumbnail .zoom i {
	font-size: 10px;
	left: 0px;
	position: relative;
	top: -6px;
}

.img-thumbnail.img-thumbnail-hover-icon {
	position: relative;
}

.img-thumbnail.img-thumbnail-hover-icon img {
	position: relative;
	z-index: 1;
}

.img-thumbnail.img-thumbnail-hover-icon:before {
	transition: all 0.2s;
	background: rgba(0, 0, 0, 0.65);
	border-radius: 4px;
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	z-index: 2;
}

.img-thumbnail.img-thumbnail-hover-icon:after {
	transition: all 0.2s;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	color: #FFF;
	content: "\f067";
	font-size: 18px;
	height: 30px;
	position: absolute;
	top: 30%;
	margin-top: -15px;
	display: block;
	text-align: center;
	width: 100%;
	opacity: 0;
	z-index: 3;
}

.img-thumbnail.img-thumbnail-hover-icon:hover:before {
	opacity: 1;
}

.img-thumbnail.img-thumbnail-hover-icon:hover:after {
	top: 50%;
	opacity: 1;
}

.img-thumbnail a.img-thumbnail-hover-icon {
	display: inline-block;
}

.img-thumbnail.img-thumbnail-no-borders {
	border: none;
	padding: 0;
}

/* Image Gallery */
ul.image-gallery {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.image-gallery .image-gallery-item {
	margin-bottom: 35px;
}

/* Thumbnail Gallery */
ul.thumbnail-gallery {
	list-style: none;
	margin: 10px 0;
	padding: 0;
}

ul.thumbnail-gallery li {
	display: inline-block;
	margin: 10px 10px 0 0;
	padding: 0;
}

/* Responsive */
@media (max-width: 991px) {
	.thumbnail {
		border: 0;
		text-align: center;
	}
}

/* Masonry */
.masonry .masonry-item:not(.no-default-style) {
	width: 25%;
}

.masonry .masonry-item:not(.no-default-style).w2 {
	width: 50%;
}

.masonry .masonry-item:not(.no-default-style) img {
	border-radius: 0 !important;
}

/* Masonry Loading */
.masonry-loader {
	height: auto;
	overflow: visible;
	position: relative;
}

.masonry-loader:after {
	transition: all 0.5s;
	content: ' ';
	background: #FFF;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	opacity: 0;
}

.masonry-loader .bounce-loader {
	display: none;
}

.masonry-loader.masonry-loader-showing {
	height: 200px;
	overflow: hidden;
}

.masonry-loader.masonry-loader-showing:after {
	opacity: 1;
}

.masonry-loader.masonry-loader-showing .bounce-loader {
	display: block;
}

.masonry-loader.masonry-loader-loaded:after {
	display: none;
}

/* Mockup */
@media (min-width: 992px) {
	.mockup-landing-page {
		margin-top: -120px;
	}
}

/* Hover Effect 3d */
.hover-effect-3d {
	transform-style: flat !important;
}

.hover-effect-3d .thumb-info {
	will-change: box-shadow;
	transition: box-shadow 300ms;
}

.hover-effect-3d .thumb-info:hover {
	box-shadow: 0px 17px 20px -15px rgba(33, 37, 41, 0.6);
}

.hover-effect-3d .thumb-info .thumb-info-title {
	transition: ease-out opacity .2s !important;
}

.hover-effect-3d .thumb-info .thumb-info-action {
	transition: ease-out opacity .2s !important;
}

.hover-effect-3d .thumb-info .thumb-info-action .thumb-info-action-icon {
	pointer-events: none;
}

.hover-effect-3d .thumb-info.thumb-info-centered-info .thumb-info-title {
	top: 47%;
}

/* Profile Image */
// .profile-image-outer-container {
// 	margin-top: auto;
// 	margin-bottom: auto;
// 	border-radius: 50%;
// 	position: relative;
// 	cursor: pointer;
// }

// .profile-image-outer-container .profile-image-input {
// 	position: absolute;
// 	top: 0;
// 	height: 100%;
// 	opacity: 0;
// 	z-index: 3;
// 	cursor: pointer;
// }

// .profile-image-outer-container .profile-image-inner-container {
// 	border-radius: 50%;
// 	padding: 5px;
// }

// .profile-image-outer-container .profile-image-inner-container img {
// 	height: 200px;
// 	width: 200px;
// 	border-radius: 50%;
// 	border: 5px solid white;
// }

// .profile-image-outer-container .profile-image-inner-container .profile-image-button {
// 	position: absolute;
// 	z-index: 2;
// 	bottom: 15px;
// 	right: 15px;
// 	width: 40px;
// 	height: 40px;
// 	text-align: center;
// 	border-radius: 50%;
// 	line-height: 38px;
// 	transition: ease all 0.1s;
// }

// .profile-image-outer-container:hover .profile-image-button {
// 	transform: scale(1.1);
// }



/* Thumb Gallery */
.thumb-gallery-wrapper .thumb-gallery-thumbs .owl-item {
	border: 2px solid #f7f7f7;
	transition: ease all 300ms;
}

.thumb-gallery-wrapper .thumb-gallery-thumbs .owl-item:hover, .thumb-gallery-wrapper .thumb-gallery-thumbs .owl-item.selected {
	border: 2px solid #212121;
}

/* Arrows */
// button.mfp-arrow {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	top: 50%;
// 	left: 0;
// 	border-radius: 4px;
// 	background: #212529;
// 	width: 35px;
// 	height: 100px;
// 	color: #FFF;
// 	margin: 0 !important;
// 	transform: translate3d(0, -50%, 0);
// }

// button.mfp-arrow:before {
// 	content: none;
// }

// button.mfp-arrow:after {
// 	content: "\f053";
// 	font-family: 'Font Awesome 5 Free';
// 	font-weight: 900;
// 	border: none !important;
// }

// button.mfp-arrow.mfp-arrow-left {
// 	border-top-left-radius: 0;
// 	border-bottom-left-radius: 0;
// }

// button.mfp-arrow.mfp-arrow-left:after {
// 	margin-left: 0;
// 	margin-top: 0;
// 	position: relative;
// 	top: auto;
// 	left: -2px;
// 	width: auto;
// 	height: auto;
// }

// button.mfp-arrow.mfp-arrow-right {
// 	left: auto;
// 	right: 0;
// 	border-top-right-radius: 0;
// 	border-bottom-right-radius: 0;
// }

// button.mfp-arrow.mfp-arrow-right:after {
// 	content: "\f054";
// 	margin-left: 0;
// 	margin-top: 0;
// 	position: relative;
// 	top: auto;
// 	left: auto;
// 	right: -2px;
// 	width: auto;
// 	height: auto;
// }




/* Dialog */
// .dialog {
// 	background: white;
// 	padding: 20px 30px;
// 	text-align: left;
// 	margin: 40px auto;
// 	position: relative;
// 	max-width: 600px;
// }

// .dialog.dialog-xs {
// 	max-width: 200px;
// }

// .dialog.dialog-sm {
// 	max-width: 400px;
// }

// .dialog.dialog-md {
// 	max-width: 600px;
// }

// .dialog.dialog-lg {
// 	max-width: 900px;
// }

// .dialog.mfp-close-out .mfp-close {
// 	top: -50px;
// 	right: -15px;
// 	color: #FFF;
// }

/* White Popup Block */
// .white-popup-block {
// 	background: #FFF;
// 	padding: 20px 30px;
// 	text-align: left;
// 	max-width: 600px;
// 	margin: 40px auto;
// 	position: relative;
// }

// .white-popup-block.white-popup-block-xs {
// 	max-width: 200px;
// }

// .white-popup-block.white-popup-block-sm {
// 	max-width: 400px;
// }

// .white-popup-block.white-popup-block-md {
// 	max-width: 600px;
// }

// .white-popup-block.white-popup-block-lg {
// 	max-width: 900px;
// }

/* Lightbox Opened */
// html.lightbox-opened.sticky-header-active #header .header-body {
// 	padding-right: 17px;
// }

/* Modal Opened */
.modal-open {
	padding-right: 0 !important;
}

/* List */
.list li {
	margin-bottom: 13px;
}

.list.pull-left li {
	text-align: left;
}

.list.list-icons {
	list-style: none;
	padding-left: 0;
	padding-right: 0;
}

/* .list.list-icons li {
	position: relative;
	padding-left: 25px;
}

.list.list-icons li > [class*="fa-"]:first-child,
.list.list-icons li > .icons:first-child, .list.list-icons li a:first-child > [class*="fa-"]:first-child,
.list.list-icons li a:first-child > .icons:first-child {
	position: absolute;
	left: 0;
	top: 3px;
} */

.list.list-icons.list-icons-style-2 li {
	padding-top: 5px;
	padding-left: 36px;
}

.list.list-icons.list-icons-style-2 li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-style-2 li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-style-2 li a:first-child > .icons:first-child {
	border-width: 1px;
	border-style: solid;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	padding: 4px;
	font-size: 0.8em;
	line-height: 1.3;
}

.list.list-icons.list-icons-style-3 li {
	padding-top: 5px;
	padding-left: 36px;
}

.list.list-icons.list-icons-style-3 li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	color: #FFF;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	padding: 5px;
	font-size: 0.8em;
	line-height: 1.3;
}

.list.list-icons.list-icons-sm li {
	padding-left: 13px;
	margin-bottom: 5px;
}

.list.list-icons.list-icons-sm li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-sm li > .icons:first-child, .list.list-icons.list-icons-sm li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-sm li a:first-child > .icons:first-child {
	font-size: 0.8em;
	top: 7px;
}

.list.list-icons.list-icons-sm.list-icons-style-2 li {
	padding-left: 32px;
}

.list.list-icons.list-icons-sm.list-icons-style-2 li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-sm.list-icons-style-2 li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li a:first-child > .icons:first-child {
	width: 20px;
	height: 20px;
	padding: 3px;
}

.list.list-icons.list-icons-sm.list-icons-style-3 li {
	padding-left: 32px;
}

.list.list-icons.list-icons-sm.list-icons-style-3 li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-sm.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li a:first-child > .icons:first-child {
	width: 20px;
	height: 20px;
	padding: 3px;
}

.list.list-icons.list-icons-lg li {
	padding-top: 5px;
	padding-left: 27px;
}

.list.list-icons.list-icons-lg li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-lg li > .icons:first-child, .list.list-icons.list-icons-lg li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-lg li a:first-child > .icons:first-child {
	font-size: 1.3em;
	top: 10px;
}

.list.list-icons.list-icons-lg.list-icons-style-2 li {
	padding-top: 8px;
	padding-left: 42px;
}

.list.list-icons.list-icons-lg.list-icons-style-2 li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-lg.list-icons-style-2 li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li a:first-child > .icons:first-child {
	width: 30px;
	height: 30px;
	padding: 4px;
}

.list.list-icons.list-icons-lg.list-icons-style-3 li {
	padding-top: 8px;
	padding-left: 42px;
}

.list.list-icons.list-icons-lg.list-icons-style-3 li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-lg.list-icons-style-3 li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li a:first-child > .icons:first-child {
	width: 30px;
	height: 30px;
	padding: 4px;
}

.list.list-icons.list-icons-reverse li {
	text-align: right;
	padding-right: 25px;
	padding-left: 0;
}

.list.list-icons.list-icons-reverse li > [class*="fa-"]:first-child,
.list.list-icons.list-icons-reverse li > .icons:first-child, .list.list-icons.list-icons-reverse li a:first-child > [class*="fa-"]:first-child,
.list.list-icons.list-icons-reverse li a:first-child > .icons:first-child {
	position: absolute;
	left: auto;
	right: 0;
	top: 5px;
}

.list.list-icons.list-icons-reverse.list-icons-style-2 li {
	padding-right: 36px;
}

.list.list-icons.list-icons-reverse.list-icons-style-3 li {
	padding-right: 36px;
}

.list.list-icons.list-icons-reverse.list-icons-sm li {
	padding-left: 0;
	padding-right: 13px;
}

.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-2 li {
	padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-3 li {
	padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-lg li {
	padding-left: 0;
	padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-2 li {
	padding-right: 42px;
}

.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-3 li {
	padding-right: 42px;
}

// .list.list-borders li {
// 	border-bottom: 1px solid #eaeaea;
// 	margin-top: 9px;
// 	margin-bottom: 0;
// 	padding-bottom: 9px;
// }

// .list.list-borders li:last-child {
// 	border-bottom: 0;
// }

// .list.list-side-borders {
// 	border-left: 1px solid #eaeaea;
// 	padding-left: 10px;
// }

// .list.list-ordened {
// 	counter-reset: custom-counter;
// 	list-style: none;
// }

// .list.list-ordened li {
// 	position: relative;
// 	padding-left: 22px;
// }

// .list.list-ordened li:before {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	content: counter(custom-counter);
// 	counter-increment: custom-counter;
// 	font-weight: bold;
// }

// .list.list-ordened.list-ordened-style-2 li, .list.list-ordened.list-ordened-style-3 li {
// 	padding-left: 32px;
// }

// .list.list-ordened.list-ordened-style-2 li:before, .list.list-ordened.list-ordened-style-3 li:before {
// 	border-radius: 100%;
// 	border-style: solid;
// 	border-width: 1px;
// 	min-width: 26px;
// 	padding: 0;
// 	text-align: center;
// }

// .list.list-ordened.list-ordened-style-3 li:before {
// 	color: #FFF;
// 	border: 0;
// }

.list.list-unstyled {
	padding-left: 0;
	padding-right: 0;
}

.list-unstyled {
	padding-left: 0;
	padding-right: 0;
}

dl dd {
	margin-bottom: 15px;
}

/* Sitemap */
// ul.sitemap > li > a {
// 	font-weight: bold;
// }

// ul.sitemap.list li {
// 	margin: 0 0 3px 0;
// }

// ul.sitemap.list ul {
// 	margin-bottom: 5px;
// 	margin-top: 5px;
// }

/* Loading Overlay */
// .loading-overlay-showing {
// 	overflow: hidden;
// }

// .loading-overlay-showing > .loading-overlay {
// 	opacity: 1;
// 	visibility: visible;
// }

// .loading-overlay {
// 	transition: visibility 0s ease-in-out 0.5s, opacity 0.5s ease-in-out;
// 	bottom: 0;
// 	left: 0;
// 	position: absolute;
// 	opacity: 0;
// 	right: 0;
// 	top: 0;
// 	visibility: hidden;
// 	background: #FFF !important;
// }

// body > .loading-overlay {
// 	position: fixed;
// 	z-index: 999999;
// }

/* Bounce Loading */
// .bounce-loader {
// 	transition: all 0.2s;
// 	margin: -9px 0 0 -35px;
// 	text-align: center;
// 	width: 70px;
// 	left: 50%;
// 	position: absolute;
// 	top: 50%;
// 	z-index: 10000;
// }

// .bounce-loader .bounce1,
// .bounce-loader .bounce2,
// .bounce-loader .bounce3 {
// 	-webkit-animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
// 	animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
// 	background-color: #CCC;
// 	border-radius: 100%;
// 	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
// 	display: inline-block;
// 	height: 18px;
// 	width: 18px;
// }

// .bounce-loader .bounce1 {
// 	-webkit-animation-delay: -0.32s;
// 	animation-delay: -0.32s;
// }

// .bounce-loader .bounce2 {
// 	-webkit-animation-delay: -0.16s;
// 	animation-delay: -0.16s;
// }

// @-webkit-keyframes bouncedelay {
// 	0%, 80%, 100% {
// 		transform: scale(0);
// 	}

// 	40% {
// 		transform: scale(1);
// 	}
// }

// @keyframes bouncedelay {
// 	0%, 80%, 100% {
// 		transform: scale(0);
// 	}

// 	40% {
// 		transform: scale(1);
// 	}
// }

// html.safari.iphone .bounce-loader .bounce1,
// html.safari.iphone .bounce-loader .bounce2,
// html.safari.iphone .bounce-loader .bounce3 {
// 	-webkit-animation: 1.4s ease-in-out infinite bouncedelay;
// 	animation: 1.4s ease-in-out infinite bouncedelay;
// }

/* Loading Overlay Percentage */
// .loading-overlay.loading-overlay-percentage {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .loading-overlay.loading-overlay-percentage .page-loader-progress-wrapper {
// 	display: flex;
// 	font-size: 65.6px;
// 	font-size: 4.1rem;
// 	font-weight: bold;
// 	letter-spacing: -1.5px;
// 	color: #212121;
// }

// .loading-overlay.loading-overlay-percentage.loading-overlay-percentage-effect-2 .loading-overlay-background-layer {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	bottom: 0;
// 	right: 0;
// 	width: 0px;
// 	background: #f4f4f4;
// 	z-index: 0;
// 	transition: ease width 300ms;
// }

// .loading-overlay.loading-overlay-percentage.loading-overlay-percentage-effect-2 .page-loader-progress-wrapper {
// 	position: relative;
// 	z-index: 1;
// }

/* Cubes Effect */
// .cssload-thecube {
// 	width: 40px;
// 	height: 40px;
// 	margin: 0 auto;
// 	margin-top: -20px;
// 	position: relative;
// 	transform: rotateZ(45deg);
// }

// .cssload-thecube .cssload-cube {
// 	position: relative;
// 	float: left;
// 	width: 50%;
// 	height: 50%;
// 	transform: rotateZ(45deg);
// 	transform: scale(1.1);
// }

// .cssload-thecube .cssload-cube:before {
// 	content: "";
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	background-color: #CCC;
// 	-webkit-animation: cssload-fold-thecube 1.68s infinite linear both;
// 	animation: cssload-fold-thecube 1.68s infinite linear both;
// 	transform-origin: 100% 100%;
// }

// .cssload-thecube .cssload-c2 {
// 	transform: scale(1.1) rotateZ(90deg);
// }

// .cssload-thecube .cssload-c2:before {
// 	-webkit-animation-delay: 0.205s;
// 	animation-delay: 0.205s;
// }

// .cssload-thecube .cssload-c3 {
// 	transform: scale(1.1) rotateZ(180deg);
// }

// .cssload-thecube .cssload-c3:before {
// 	-webkit-animation-delay: 0.42s;
// 	animation-delay: 0.42s;
// }

// .cssload-thecube .cssload-c4 {
// 	transform: scale(1.1) rotateZ(270deg);
// }

// .cssload-thecube .cssload-c4:before {
// 	-webkit-animation-delay: 0.625s;
// 	animation-delay: 0.625s;
// }

// @-webkit-keyframes cssload-fold-thecube {
// 	0%, 10% {
// 		transform: perspective(129px) rotateX(-180deg);
// 		opacity: 0;
// 	}

// 	25%,
// 		75% {
// 		transform: perspective(129px) rotateX(0deg);
// 		opacity: 1;
// 	}

// 	90%,
// 		100% {
// 		transform: perspective(129px) rotateY(180deg);
// 		opacity: 0;
// 	}
// }

// @keyframes cssload-fold-thecube {
// 	0%, 10% {
// 		transform: perspective(129px) rotateX(-180deg);
// 		opacity: 0;
// 	}

// 	25%,
// 		75% {
// 		transform: perspective(129px) rotateX(0deg);
// 		opacity: 1;
// 	}

// 	90%,
// 		100% {
// 		transform: perspective(129px) rotateY(180deg);
// 		opacity: 0;
// 	}
// }

// /* Cube Progress Effect */
// .cssload-cube-progress {
// 	display: block;
// 	margin: 0 auto;
// 	width: 29px;
// 	height: 29px;
// 	position: relative;
// 	border: 4px solid #CCC;
// 	-webkit-animation: cssload-cube-progress 2.3s infinite ease;
// 	animation: cssload-cube-progress 2.3s infinite ease;
// }

// .cssload-cube-progress-inner {
// 	vertical-align: top;
// 	display: inline-block;
// 	width: 100%;
// 	background-color: #CCC;
// 	-webkit-animation: cssload-cube-progress-inner 2.3s infinite ease-in;
// 	animation: cssload-cube-progress-inner 2.3s infinite ease-in;
// }

// @-webkit-keyframes cssload-cube-progress {
// 	0% {
// 		transform: rotate(0deg);
// 	}

// 	25% {
// 		transform: rotate(180deg);
// 	}

// 	50% {
// 		transform: rotate(180deg);
// 	}

// 	75% {
// 		transform: rotate(360deg);
// 	}

// 	100% {
// 		transform: rotate(360deg);
// 	}
// }

// @keyframes cssload-cube-progress {
// 	0% {
// 		transform: rotate(0deg);
// 	}

// 	25% {
// 		transform: rotate(180deg);
// 	}

// 	50% {
// 		transform: rotate(180deg);
// 	}

// 	75% {
// 		transform: rotate(360deg);
// 	}

// 	100% {
// 		transform: rotate(360deg);
// 	}
// }

// @-webkit-keyframes cssload-cube-progress-inner {
// 	0% {
// 		height: 0%;
// 	}

// 	25% {
// 		height: 0%;
// 	}

// 	50% {
// 		height: 100%;
// 	}

// 	75% {
// 		height: 100%;
// 	}

// 	100% {
// 		height: 0%;
// 	}
// }

// @keyframes cssload-cube-progress-inner {
// 	0% {
// 		height: 0%;
// 	}

// 	25% {
// 		height: 0%;
// 	}

// 	50% {
// 		height: 100%;
// 	}

// 	75% {
// 		height: 100%;
// 	}

// 	100% {
// 		height: 0%;
// 	}
// }

// /* Float Rings Effect */
// .cssload-float-rings-loader {
// 	position: relative;
// 	left: calc(50% - 15px);
// 	width: 40px;
// 	height: 40px;
// 	border-radius: 50%;
// 	perspective: 380px;
// }

// .cssload-float-rings-inner {
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	box-sizing: border-box;
// 	border-radius: 50%;
// }

// .cssload-float-rings-inner.cssload-one {
// 	left: 0%;
// 	top: 0%;
// 	-webkit-animation: cssload-rotate-one 1.15s linear infinite;
// 	animation: cssload-rotate-one 1.15s linear infinite;
// 	border-bottom: 1px solid #CCC;
// }

// .cssload-float-rings-inner.cssload-two {
// 	right: 0%;
// 	top: 0%;
// 	-webkit-animation: cssload-rotate-two 1.15s linear infinite;
// 	animation: cssload-rotate-two 1.15s linear infinite;
// 	border-right: 1px solid #CCC;
// }

// .cssload-float-rings-inner.cssload-three {
// 	right: 0%;
// 	bottom: 0%;
// 	-webkit-animation: cssload-rotate-three 1.15s linear infinite;
// 	animation: cssload-rotate-three 1.15s linear infinite;
// 	border-top: 1px solid #CCC;
// }

// @-webkit-keyframes cssload-rotate-one {
// 	0% {
// 		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
// 	}

// 	100% {
// 		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
// 	}
// }

// @keyframes cssload-rotate-one {
// 	0% {
// 		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
// 	}

// 	100% {
// 		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
// 	}
// }

// @-webkit-keyframes cssload-rotate-two {
// 	0% {
// 		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
// 	}

// 	100% {
// 		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
// 	}
// }

// @keyframes cssload-rotate-two {
// 	0% {
// 		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
// 	}

// 	100% {
// 		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
// 	}
// }

// @-webkit-keyframes cssload-rotate-three {
// 	0% {
// 		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
// 	}

// 	100% {
// 		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
// 	}
// }

// @keyframes cssload-rotate-three {
// 	0% {
// 		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
// 	}

// 	100% {
// 		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
// 	}
// }

/* Float Bars Effect */
// .cssload-float-bars-container {
// 	margin: -70px auto 0 auto;
// 	max-width: 545px;
// 	transform: rotate(90deg);
// }

// .cssload-float-bars-container * {
// 	box-sizing: border-box;
// }

// .cssload-float-bars-container ul li {
// 	list-style: none;
// }

// .cssload-float-bars-flex-container {
// 	display: flex;
// 	flex-direction: row;
// 	flex-wrap: wrap;
// 	justify-content: space-around;
// }

// .cssload-float-bars-flex-container li {
// 	padding: 10px;
// 	height: 97px;
// 	width: 97px;
// 	margin: 29px 19px;
// 	position: relative;
// 	text-align: center;
// }

// .cssload-float-bars-loading,
// .cssload-float-bars-loading:after,
// .cssload-float-bars-loading:before {
// 	display: inline-block;
// 	position: relative;
// 	width: 5px;
// 	height: 49px;
// 	background: #CCC;
// 	margin-top: 5px;
// 	border-radius: 975px;
// 	-webkit-animation: cssload-upDown2 1.15s ease infinite;
// 	animation: cssload-upDown2 1.15s ease infinite;
// 	-webkit-animation-direction: alternate;
// 	animation-direction: alternate;
// 	-webkit-animation-delay: 0.29s;
// 	animation-delay: 0.29s;
// }

// .cssload-float-bars-loading:after,
// .cssload-float-bars-loading:before {
// 	position: absolute;
// 	content: '';
// 	-webkit-animation: cssload-upDown 1.15s ease infinite;
// 	animation: cssload-upDown 1.15s ease infinite;
// 	-webkit-animation-direction: alternate;
// 	animation-direction: alternate;
// }

// .cssload-float-bars-loading:before {
// 	left: -10px;
// }

// .cssload-float-bars-loading:after {
// 	left: 10px;
// 	-webkit-animation-delay: 0.58s;
// 	animation-delay: 0.58s;
// }

// @-webkit-keyframes cssload-upDown {
// 	from {
// 		transform: translateY(19px);
// 	}

// 	to {
// 		transform: translateY(-19px);
// 	}
// }

// @keyframes cssload-upDown {
// 	from {
// 		transform: translateY(19px);
// 	}

// 	to {
// 		transform: translateY(-19px);
// 	}
// }

// @-webkit-keyframes cssload-upDown2 {
// 	from {
// 		transform: translateY(29px);
// 	}

// 	to {
// 		transform: translateY(-19px);
// 	}
// }

// @keyframes cssload-upDown2 {
// 	from {
// 		transform: translateY(29px);
// 	}

// 	to {
// 		transform: translateY(-19px);
// 	}
// }

/* Speeding Wheel */
// .cssload-speeding-wheel-container {
// 	width: 100%;
// 	height: 49px;
// 	text-align: center;
// }

// .cssload-speeding-wheel {
// 	width: 49px;
// 	height: 49px;
// 	margin: 0 auto;
// 	border: 3px solid #CCC;
// 	border-radius: 50%;
// 	border-left-color: transparent;
// 	border-right-color: transparent;
// 	-webkit-animation: cssload-spin 575ms infinite linear;
// 	animation: cssload-spin 575ms infinite linear;
// }

// @-webkit-keyframes cssload-spin {
// 	100% {
// 		transform: rotate(360deg);
// 		transform: rotate(360deg);
// 	}
// }

// @keyframes cssload-spin {
// 	100% {
// 		transform: rotate(360deg);
// 		transform: rotate(360deg);
// 	}
// }

/* Zenith Effect */
// .cssload-zenith-container {
// 	width: 100%;
// 	height: 49px;
// 	text-align: center;
// }

// .cssload-zenith {
// 	width: 49px;
// 	height: 49px;
// 	margin: 0 auto;
// 	border-radius: 50%;
// 	border-top-color: transparent;
// 	border-left-color: transparent;
// 	border-right-color: transparent;
// 	box-shadow: 3px 3px 1px #CCC;
// 	-webkit-animation: cssload-spin 690ms infinite linear;
// 	animation: cssload-spin 690ms infinite linear;
// }

// @keyframes cssload-spin {
// 	100% {
// 		transform: rotate(360deg);
// 		transform: rotate(360deg);
// 	}
// }

// /* Spinning Square Effect */
// .cssload-spinning-square-loading {
// 	position: relative;
// 	width: 30px;
// 	height: 30px;
// 	background: #CCC;
// 	display: block;
// 	margin: auto;
// 	-webkit-animation: 1.15s cssload-spin infinite;
// 	animation: 1.15s cssload-spin infinite;
// }

// .cssload-spinning-square-loading:before, .cssload-spinning-square-loading:after {
// 	content: "";
// 	position: absolute;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	-webkit-animation: 0.58s infinite alternate linear;
// 	animation: 0.58s infinite alternate linear;
// }

// .cssload-spinning-square-loading:before {
// 	background: #999;
// 	-webkit-animation-name: cssload-blur1;
// 	animation-name: cssload-blur1;
// }

// .cssload-spinning-square-loading:after {
// 	background: white;
// 	-webkit-animation-name: cssload-blur2;
// 	animation-name: cssload-blur2;
// }

// @keyframes cssload-spin {
// 	to {
// 		transform: rotate(360deg);
// 	}
// }

// @-webkit-keyframes cssload-blur1 {
// 	to {
// 		transform: rotate(-30deg);
// 	}
// }

// @keyframes cssload-blur1 {
// 	to {
// 		transform: rotate(-30deg);
// 	}
// }

// @-webkit-keyframes cssload-blur2 {
// 	to {
// 		transform: rotate(-15deg);
// 	}
// }

// @keyframes cssload-blur2 {
// 	to {
// 		transform: rotate(-15deg);
// 	}
// }

/* Pulse */
// .wrapper-pulse {
// 	margin-top: -60px;
// }

// .cssload-pulse-loader {
// 	width: 49px;
// 	height: 49px;
// 	border-radius: 50%;
// 	margin: 3em;
// 	display: inline-block;
// 	position: relative;
// 	vertical-align: middle;
// 	background: black;
// }

// .cssload-pulse-loader,
// .cssload-pulse-loader:before,
// .cssload-pulse-loader:after {
// 	-webkit-animation: 1.15s infinite ease-in-out;
// 	animation: 1.15s infinite ease-in-out;
// }

// .cssload-pulse-loader:before,
// .cssload-pulse-loader:after {
// 	width: 100%;
// 	height: 100%;
// 	border-radius: 50%;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// }

// .cssload-pulse-loader {
// 	-webkit-animation-name: cssload-pulse-loader;
// 	animation-name: cssload-pulse-loader;
// }

// @-webkit-keyframes cssload-pulse-loader {
// 	from {
// 		transform: scale(0);
// 		opacity: 1;
// 	}

// 	to {
// 		transform: scale(1);
// 		opacity: 0;
// 	}
// }

// @keyframes cssload-pulse-loader {
// 	from {
// 		transform: scale(0);
// 		opacity: 1;
// 	}

// 	to {
// 		transform: scale(1);
// 		opacity: 0;
// 	}
// }

/* Maps */
.google-map {
	background: #E5E3DF;
	align-self: center;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 978px) {
	.google-map {
		height: 24em;
	}
	
}

.google-map.small {
	height: 250px;
	border-radius: 6px;
	margin: 0;
	clear: both;
}

.google-map img {
	max-width: 9999px;
}

.google-map-borders {
	border: 1px solid #DDD;
	padding: 4px;
	border-radius: 4px;
}

/* Map Pins */
// @-webkit-keyframes mapPinPulse {
// 	from {
// 		transform: scale(1);
// 		opacity: 0.7;
// 	}

// 	to {
// 		transform: scale(10);
// 		opacity: 0;
// 	}
// }

// @keyframes mapPinPulse {
// 	from {
// 		transform: scale(1);
// 		opacity: 0.7;
// 	}

// 	to {
// 		transform: scale(10);
// 		opacity: 0;
// 	}
// }

/* Map Info Window */
// .map-info-window {
// 	position: absolute;
// 	bottom: 30px;
// 	left: 50%;
// 	font-size: 12.8px;
// 	font-size: 0.8rem;
// 	background: #FFF;
// 	border-radius: 4px;
// 	padding: 15px 20px;
// 	white-space: nowrap;
// 	transform: translate3d(-50%, 0, 0);
// }

// .map-info-window:after {
// 	content: '';
// 	position: absolute;
// 	bottom: -7px;
// 	left: 50%;
// 	transform: translate3d(-50%, 0, 0);
// 	width: 0;
// 	height: 0;
// 	border-top: 7px solid #FFF;
// 	border-left: 7px solid transparent;
// 	border-right: 7px solid transparent;
// }

// .map-info-window .map-info-window-close {
// 	position: absolute;
// 	top: 3px;
// 	right: 3px;
// }

// .map-rounded-pin {
// 	width: 13px;
// 	height: 13px;
// 	background: #CCC;
// 	border-radius: 100%;
// }

// .map-rounded-pin.map-rounded-pin-animated {
// 	position: relative;
// }

// .map-rounded-pin.map-rounded-pin-animated:before, .map-rounded-pin.map-rounded-pin-animated:after {
// 	content: '';
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	background: inherit;
// 	border-radius: inherit;
// 	-webkit-animation-name: mapPinPulse;
// 	animation-name: mapPinPulse;
// 	-webkit-animation-duration: 1.5s;
// 	animation-duration: 1.5s;
// 	-webkit-animation-iteration-count: infinite;
// 	animation-iteration-count: infinite;
// 	-webkit-animation-delay: 1s;
// 	animation-delay: 1s;
// }

// .map-rounded-pin.map-rounded-pin-animated:after {
// 	-webkit-animation-delay: 1.250s;
// 	animation-delay: 1.250s;
// }

/* Google Map on Footer */
.google-map-footer {
	margin: 35px 0 -50px !important;
}

/* RTL Popup */
html[dir="rtl"] .gm-style .gm-style-iw-c {
	padding: 20px 20px 0 0 !important;
}

/* Clearfix */
.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .clearfix {
	display: block;
}

* html .clearfix {
	height: 1%;
}

/* Container */
@media (min-width: 1440px) {
	.container-lg-custom {
		max-width: 1440px;
	}

	.container-xl-custom {
		max-width: 1630px;
	}
}

/* Fluid Col */
[class*="fluid-col-"] .fluid-col {
	position: absolute;
	left: 15px;
}

[class*="fluid-col-"] .fluid-col.fluid-col-left {
	left: auto;
	right: 15px;
}

.fluid-col-6 {
	min-height: 33vw;
}

.fluid-col-6 .fluid-col {
	width: calc(50vw - 15px);
}

.fluid-col-sm-6 .fluid-col, .fluid-col-md-6 .fluid-col, .fluid-col-lg-6 .fluid-col, .fluid-col-xl-6 .fluid-col {
	width: calc(100vw - 30px);
}

@media (min-width: 576px) {
	.fluid-col-sm-6 {
		min-height: 33vw;
	}

	.fluid-col-sm-6 .fluid-col {
		width: calc(55vw - 15px);
	}
}

@media (min-width: 768px) {
	.fluid-col-md-6 {
		min-height: 33vw;
	}

	.fluid-col-md-6 .fluid-col {
		width: calc(50vw - 15px);
	}
}

@media (min-width: 992px) {
	.fluid-col-lg-6 {
		min-height: 33vw;
	}

	.fluid-col-lg-6 .fluid-col {
		width: calc(50vw - 15px);
	}
}

@media (min-width: 1200px) {
	.fluid-col-xl-6 {
		min-height: 33vw;
	}

	.fluid-col-xl-6 .fluid-col {
		width: calc(50vw - 15px);
	}
}

@media (max-width: 1199px) {
	.fluid-col-xl-6 {
		min-height: 64.5vw !important;
	}
}

@media (max-width: 991px) {
	.fluid-col-lg-6 {
		min-height: 64.5vw !important;
	}
}

@media (max-width: 767px) {
	.fluid-col-md-6 {
		min-height: 64.5vw !important;
	}
}

@media (max-width: 575px) {
	.fluid-col-sm-6 {
		min-height: 64.5vw !important;
	}
}

/* Gutter SM */
// .row-gutter-sm {
// 	margin-right: -10px;
// 	margin-left: -10px;
// }

// .row-gutter-sm .col, .row-gutter-sm .col-1, .row-gutter-sm .col-10, .row-gutter-sm .col-11, .row-gutter-sm .col-12, .row-gutter-sm .col-2, .row-gutter-sm .col-3, .row-gutter-sm .col-4, .row-gutter-sm .col-5, .row-gutter-sm .col-6, .row-gutter-sm .col-7, .row-gutter-sm .col-8, .row-gutter-sm .col-9, .row-gutter-sm .col-auto, .row-gutter-sm .col-lg, .row-gutter-sm .col-lg-1, .row-gutter-sm .col-lg-10, .row-gutter-sm .col-lg-11, .row-gutter-sm .col-lg-12, .row-gutter-sm .col-lg-2, .row-gutter-sm .col-lg-3, .row-gutter-sm .col-lg-4, .row-gutter-sm .col-lg-5, .row-gutter-sm .col-lg-6, .row-gutter-sm .col-lg-7, .row-gutter-sm .col-lg-8, .row-gutter-sm .col-lg-9, .row-gutter-sm .col-lg-auto, .row-gutter-sm .col-md, .row-gutter-sm .col-md-1, .row-gutter-sm .col-md-10, .row-gutter-sm .col-md-11, .row-gutter-sm .col-md-12, .row-gutter-sm .col-md-2, .row-gutter-sm .col-md-3, .row-gutter-sm .col-md-4, .row-gutter-sm .col-md-5, .row-gutter-sm .col-md-6, .row-gutter-sm .col-md-7, .row-gutter-sm .col-md-8, .row-gutter-sm .col-md-9, .row-gutter-sm .col-md-auto, .row-gutter-sm .col-sm, .row-gutter-sm .col-sm-1, .row-gutter-sm .col-sm-10, .row-gutter-sm .col-sm-11, .row-gutter-sm .col-sm-12, .row-gutter-sm .col-sm-2, .row-gutter-sm .col-sm-3, .row-gutter-sm .col-sm-4, .row-gutter-sm .col-sm-5, .row-gutter-sm .col-sm-6, .row-gutter-sm .col-sm-7, .row-gutter-sm .col-sm-8, .row-gutter-sm .col-sm-9, .row-gutter-sm .col-sm-auto, .row-gutter-sm .col-xl, .row-gutter-sm .col-xl-1, .row-gutter-sm .col-xl-10, .row-gutter-sm .col-xl-11, .row-gutter-sm .col-xl-12, .row-gutter-sm .col-xl-2, .row-gutter-sm .col-xl-3, .row-gutter-sm .col-xl-4, .row-gutter-sm .col-xl-5, .row-gutter-sm .col-xl-6, .row-gutter-sm .col-xl-7, .row-gutter-sm .col-xl-8, .row-gutter-sm .col-xl-9, .row-gutter-sm .col-xl-auto {
// 	padding-left: 10px;
// 	padding-right: 10px;
// }

/* Min Height */
.min-height-screen {
	min-height: 100vh;
}

/* Hide Text */
.hide-text {
	display: block;
	text-indent: -9999px;
	width: 0;
	height: 0;
}

/* Box Shadow */
.box-shadow-1:before {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	opacity: 0.33;
	content: '';
	box-shadow: 0 30px 90px #BBB;
	transition: all 0.2s ease-in-out;
}

.box-shadow-1.box-shadow-1-hover:before {
	opacity: 0;
}

.box-shadow-1.box-shadow-1-hover:hover:before {
	opacity: 0.33;
}

.box-shadow-2 {
	box-shadow: 0 .2em .4em -.2em rgba(0, 0, 0, 0.45);
}

.box-shadow-3 {
	box-shadow: 0 30px 60px -30px rgba(0, 0, 0, 0.45);
}

.box-shadow-4 {
	box-shadow: 0px 0px 21px -5px rgba(0, 0, 0, 0.2);
}

.box-shadow-5 {
	box-shadow: 0 15px 60px -36px rgba(0, 0, 0, 0.45);
}

/* Border Radius */
.border-radius {
	border-radius: 4px !important;
}

/* Magnific Popup Demo Options */
// .mfp-demo-options.mfp-bg {
// 	opacity: 0.98 !important;
// 	background: #f7f7f7;
// }

// .mfp-demo-options.mfp-bg.mfp-removing {
// 	opacity: 0 !important;
// }

// .mfp-demo-options .mfp-close {
// 	position: fixed;
// 	top: 0;
// 	right: 17px;
// 	z-index: 3000;
// }

// .mfp-demo-options.mfp-wrap {
// 	height: 100vh !important;
// }

// .mfp-demo-options.mfp-wrap:before {
// 	background: linear-gradient(to bottom, #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
// 	content: '';
// 	display: block;
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 80px;
// 	z-index: 2000;
// 	opacity: 1;
// 	opacity: 0.7;
// }

// .mfp-demo-options.mfp-wrap:after {
// 	background: linear-gradient(to top, #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
// 	content: '';
// 	display: block;
// 	position: fixed;
// 	bottom: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 80px;
// 	z-index: 2000;
// 	opacity: 1;
// 	opacity: 0.7;
// }

// .mfp-demo-options.mfp-wrap.mfp-removing:before, .mfp-demo-options.mfp-wrap.mfp-removing:after {
// 	opacity: 0 !important;
// }

/* Pagination */
.pagination {
	position: relative;
	z-index: 1;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	color: #CCC;
	box-shadow: none !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #CCC;
	border-color: #CCC;
	box-shadow: none !important;
}

.pagination.pagination-rounded .page-item {
	margin-right: 8px;
	margin-right: .5rem;
}

.pagination.pagination-rounded .page-item:last-child {
	margin-right: 0;
}

.pagination.pagination-rounded .page-link {
	border-radius: 50% !important;
	padding: 0;
	width: 30px;
	text-align: center;
	height: 30px;
	line-height: 30px;
}

.pagination.pagination-rounded.pagination-md .page-link {
	padding: 0;
	width: 40px;
	text-align: center;
	height: 40px;
	line-height: 40px;
	font-size: 16px;
	font-size: 1rem;
}

.pagination.pagination-rounded.pagination-lg .page-link {
	padding: 0;
	width: 60px;
	text-align: center;
	height: 60px;
	line-height: 60px;
}


/* Offset Anchor */
.offset-anchor {
	display: block;
	position: relative;
	visibility: hidden;
	top: -100px;
}

/* Responsive */
@media (max-width: 991px) {
	.offset-anchor {
		top: 0;
	}
}

/* Show Grid */
// .show-grid [class*="col"] .show-grid-block {
// 	background-color: #EEE;
// 	line-height: 40px;
// 	min-height: 40px;
// 	text-align: center;
// 	border: 1px solid #FFF;
// 	display: block;
// }

/* Alerts */
.alert [class*="fa-"], .alert .icons {
	margin-right: 8px;
	font-size: 1.1em;
}

.alert ul {
	padding: 0;
	margin: 7px 0 0 40px;
}

.alert ul li {
	font-size: 0.9em;
}

.alert.alert-sm {
	padding: 5px 10px;
	font-size: 0.9em;
}

.alert.alert-lg {
	padding: 20px;
	font-size: 1.2em;
}

.alert.alert-default {
	background-color: #f2f2f2;
	border-color: #eaeaea;
	color: #737272;
}

.alert.alert-default .alert-link {
	color: #4c4c4c;
}

.alert.alert-dark {
	background-color: #383f45;
	border-color: #0a0c0d;
	color: #d3d8dc;
}

.alert.alert-dark .alert-link {
	color: #fefefe;
}

/* Embed Responsive */
.embed-responsive.embed-soundcloud {
	padding-bottom: 19.25%;
}

.embed-responsive.embed-responsive-borders {
	border: 1px solid #DDD;
	padding: 4px;
	border-radius: 4px;
}

@media (max-width: 991px) {
	.col-md-3 .sidebar {
		clear: both;
	}
}

/* Half Section */
// .col-half-section {
// 	width: 100%;
// 	min-height: 1px;
// 	padding-left: 15px;
// 	padding-right: 15px;
// 	position: relative;
// 	max-width: 555px;
// 	margin-left: 30px;
// 	margin-right: 0;
// }

// .col-half-section.col-half-section-right {
// 	margin-left: 0;
// 	margin-right: 30px;
// }

// @media (max-width: 1199px) {
// 	.col-half-section {
// 		max-width: 465px;
// 	}
// }

// @media (max-width: 991px) {
// 	.col-half-section {
// 		max-width: 720px;
// 		margin: 0 auto !important;
// 		padding-left: 30px !important;
// 		padding-right: 30px !important;
// 	}
// }

// @media (max-width: 767px) {
// 	.col-half-section {
// 		max-width: 540px;
// 	}
// }

// @media (max-width: 575px) {
// 	.col-half-section {
// 		max-width: 100%;
// 	}
// }

/* Content Grid */
// .content-grid {
// 	overflow: hidden;
// 	margin: 0;
// 	padding: 0;
// }

// .content-grid .content-grid-item {
// 	position: relative;
// 	padding: 10px;
// 	margin: 0;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .content-grid .content-grid-item:before, .content-grid .content-grid-item:after {
// 	content: '';
// 	position: absolute;
// }

// .content-grid .content-grid-item:before {
// 	height: 100%;
// 	top: 0;
// 	left: -1px;
// 	border-left: 1px solid #DADADA;
// }

// .content-grid .content-grid-item:after {
// 	width: 100%;
// 	height: 0;
// 	top: auto;
// 	left: 0;
// 	bottom: -1px;
// 	border-bottom: 1px solid #DADADA;
// }

// .content-grid .content-grid-item .img-fluid {
// 	display: inline-block;
// 	flex: 0 0 auto;
// }

// .content-grid.content-grid-dashed .content-grid-item:before {
// 	border-left: 1px dashed #DADADA;
// }

// .content-grid.content-grid-dashed .content-grid-item:after {
// 	border-bottom: 1px dashed #DADADA;
// }



/* Not Included */
.not-included {
	color: #b7b7b7;
	display: block;
	font-size: 0.8em;
	font-style: normal;
	margin: -4px 0;
	padding: 0;
}



/* Notice Top Bar */
// .notice-top-bar {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	background: #d01a34;
// 	z-index: -1;
// }

// .notice-top-bar .notice-button {
// 	background: rgba(235, 34, 63, 0.7);
// 	color: #FFF;
// 	border-radius: 35px;
// 	transition: ease background 300ms;
// 	text-decoration: none;
// }

// .notice-top-bar .notice-button:hover {
// 	background: #eb223f;
// }

// .notice-top-bar .notice-top-bar-close {
// 	position: absolute;
// 	top: 7px;
// 	right: 7px;
// }

/* Hover effetcs */
// .hover-effect-1:not(.portfolio-item) {
// 	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0);
// 	transition: ease transform 300ms, ease box-shadow 300ms;
// }

// .hover-effect-1:not(.portfolio-item):hover {
// 	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.4);
// 	transform: translate3d(0, -15px, 0);
// }

// .hover-effect-1.portfolio-item {
// 	transition: ease transform 300ms;
// }

// .hover-effect-1.portfolio-item .thumb-info,
// .hover-effect-1.portfolio-item .owl-carousel {
// 	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
// 	transition: ease box-shadow 300ms;
// }

// .hover-effect-1.portfolio-item:hover .thumb-info,
// .hover-effect-1.portfolio-item:hover .owl-carousel, .hover-effect-1.portfolio-item.show .thumb-info,
// .hover-effect-1.portfolio-item.show .owl-carousel {
// 	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.4);
// }

// .hover-effect-1 a {
// 	transition: ease color 300ms;
// }

// .hover-effect-2 {
// 	opacity: 1;
// 	transition: ease opacity 300ms;
// }

// .hover-effect-2:hover {
// 	opacity: 0.85;
// }

// .hover-effect-3 {
// 	opacity: 0.3;
// 	transition: ease opacity 300ms;
// }

// .hover-effect-3:hover {
// 	opacity: 1;
// }

/* Border Radius */
.border-radius-0 {
	border-radius: 0 !important;
}

/* Sticky Wrapper */
// .sticky-wrapper {
// 	position: fixed;
// 	width: 100% !important;
// 	z-index: 11;
// }

// .sticky-wrapper .sticky-body {
// 	display: flex;
// 	background: #FFF;
// 	width: 100%;
// 	z-index: 10;
// 	transition: ease background 300ms;
// }

// .sticky-wrapper.sticky-wrapper-transparent .sticky-body {
// 	background: transparent;
// }

// .sticky-wrapper.sticky-wrapper-effect-1 {
// 	position: absolute !important;
// }

// .sticky-wrapper.sticky-wrapper-effect-1.sticky-effect-active .sticky-body {
// 	position: fixed;
// 	background: #FFF;
// 	-webkit-animation: headerReveal 0.4s ease-in;
// 	animation: headerReveal 0.4s ease-in;
// }

// .sticky-wrapper.sticky-wrapper-effect-1.sticky-wrapper-effect-1-dark.sticky-effect-active .sticky-body {
// 	background: #212529;
// }

// .sticky-wrapper.sticky-wrapper-border-bottom.sticky-effect-active .sticky-body {
// 	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
// }

/* Highlighted Word */
// @-webkit-keyframes pencilAnimation {
// 	from {
// 		width: 0;
// 	}

// 	to {
// 		width: 74px;
// 	}
// }

// @keyframes pencilAnimation {
// 	from {
// 		width: 0;
// 	}

// 	to {
// 		width: 74px;
// 	}
// }

// @-webkit-keyframes pencilAnimation2 {
// 	from {
// 		width: 0;
// 	}

// 	to {
// 		width: 115px;
// 	}
// }

// @keyframes pencilAnimation2 {
// 	from {
// 		width: 0;
// 	}

// 	to {
// 		width: 115px;
// 	}
// }

// .highlighted-word {
// 	font-family: "Shadows Into Light", cursive;
// 	font-weight: 500;
// }

// .highlighted-word.highlighted-word-animation-1 {
// 	position: relative;
// }

// .highlighted-word.highlighted-word-animation-1:after {
// 	content: '';
// 	position: absolute;
// 	right: calc(100% - 74px);
// 	bottom: -6px;
// 	/* background: url(../img/pencil-blue-line.png); */
// 	width: 0;
// 	height: 15px;
// 	-webkit-animation-name: pencilAnimation;
// 	animation-name: pencilAnimation;
// 	-webkit-animation-duration: 500ms;
// 	animation-duration: 500ms;
// 	-webkit-animation-delay: 2s;
// 	animation-delay: 2s;
// 	-webkit-animation-fill-mode: forwards;
// 	animation-fill-mode: forwards;
// 	overflow: hidden;
// 	transform: rotate(180deg);
// }

// .highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-light:after {
// 	filter: brightness(0) invert(1);
// }



// .highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-no-rotate:after {
// 	transform: rotate(0);
// }

// .highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-no-animate:after {
// 	-webkit-animation-duration: 0ms;
// 	animation-duration: 0ms;
// 	-webkit-animation-delay: 0ms;
// 	animation-delay: 0ms;
// }

// .highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-2:after {
// 	-webkit-animation-name: pencilAnimation2;
// 	animation-name: pencilAnimation2;
// 	right: calc(100% - 115px);
// 	background-repeat: no-repeat;
// }

// .highlighted-word.highlighted-word-rotate {
// 	transform: rotate(-15deg);
// 	transform-origin: 0 0;
// }




/* Vertical Divider */
.vertical-divider {
	display: inline;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
	border-right: 1px solid transparent;
}



/* Image Hotspots */
// .image-hotspots {
// 	position: relative;
// }

// .image-hotspot {
// 	cursor: pointer;
// 	position: absolute;
// }

// .image-hotspot strong {
// 	color: #FFF;
// 	z-index: 5;
// 	font-size: 0.75em;
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate3d(-50%, -50%, 0);
// 	font-weight: 600 !important;
// }

// .image-hotspot .circle {
// 	position: absolute;
// 	left: 50%;
// 	top: 50%;
// 	width: 28px;
// 	height: 28px;
// 	margin: -0.666em auto auto -0.666em;
// 	background: #CCC;
// 	border-radius: 50%;
// 	opacity: 0.6;
// 	transform-origin: 50% 50%;
// 	transition: opacity .2s ease-in, transform .1s ease-out;
// 	color: white;
// 	font-size: 1.5em;
// 	padding: 0;
// 	text-align: center;
// 	line-height: 28px;
// 	overflow: hidden;
// }

// .image-hotspot .ring {
// 	display: block;
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	width: 2em;
// 	height: 2em;
// 	margin: -1em auto auto -1em;
// 	transform-origin: 50% 50%;
// 	border-radius: 50%;
// 	border: 1px solid rgba(204, 204, 204, 0.9);
// 	opacity: 0;
// 	-webkit-animation: hotspot-pulsate 3s ease-out infinite;
// 	animation: hotspot-pulsate 3s ease-out infinite;
// }

/* Page Transition */
// body[data-plugin-page-transition] {
// 	transition: ease opacity 300ms;
// }

// body.page-transition-active {
// 	opacity: 0 !important;
// }

/* 
Image 360º Viewer
Credits: Codyhouse (https://codyhouse.co/gem/360-degrees-product-viewer)
*/
// .cd-product-viewer-wrapper {
// 	text-align: center;
// 	padding: 2em 0;
// }

// .cd-product-viewer-wrapper > div {
// 	display: inline-block;
// }

// @media (max-width: 1199px) {
// 	.cd-product-viewer-wrapper > div {
// 		width: 100% !important;
// 	}
// }

// .cd-product-viewer-wrapper .product-viewer {
// 	position: relative;
// 	z-index: 1;
// 	display: inline-block;
// 	overflow: hidden;
// }

// @media (max-width: 1199px) {
// 	.cd-product-viewer-wrapper .product-viewer {
// 		width: 100% !important;
// 	}
// }

// .cd-product-viewer-wrapper img {
// 	display: block;
// 	position: relative;
// 	width: 100%;
// 	z-index: 1;
// }

// .cd-product-viewer-wrapper .product-sprite {
// 	position: absolute;
// 	z-index: 2;
// 	top: 0;
// 	left: 0;
// 	height: 100%;
// 	width: 1600%;
// 	background-size: 100%;
// 	background-repeat: no-repeat;
// 	background-position: center center;
// 	opacity: 0;
// 	transition: ease opacity 300ms;
// }

// .cd-product-viewer-wrapper.loaded .product-sprite {
// 	opacity: 1;
// 	cursor: ew-resize;
// }

// .cd-product-viewer-handle {
// 	position: relative;
// 	z-index: 2;
// 	width: 60%;
// 	max-width: 300px;
// 	border-radius: 50em;
// 	margin: 1em auto 3em;
// 	height: 4px;
// }

// .cd-product-viewer-handle .fill {
// 	position: absolute;
// 	z-index: 1;
// 	left: 0;
// 	top: 0;
// 	height: 100%;
// 	width: 100%;
// 	border-radius: inherit;
// 	background: #212529;
// 	transform: scaleX(0);
// 	transform-origin: left center;
// 	transition: ease transform 500ms;
// }

// .no-csstransitions .cd-product-viewer-handle .fill {
// 	display: none;
// }

// .loaded .cd-product-viewer-handle .fill {
// 	opacity: 1;
// 	background-image: none !important;
// 	transition: ease transform 300ms, ease opacity 200ms 300ms;
// }

// .cd-product-viewer-handle .handle {
// 	position: absolute;
// 	z-index: 2;
// 	display: inline-block;
// 	height: 44px;
// 	width: 44px;
// 	left: 0;
// 	top: -20px;
// 	background: #212529;
// 	border-radius: 50%;
// 	border: 2px solid #212529;
// 	text-indent: 100%;
// 	white-space: nowrap;
// 	overflow: hidden;
// 	color: transparent;
// 	transform: translateX(-50%) scale(0);
// 	transition: ease box-shadow 200ms;
// }

// .cd-product-viewer-handle .handle .bars {
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	width: 4px;
// 	height: 20px;
// 	background: #FFF;
// 	transform: translate3d(-50%, -50%, 0);
// }

// .cd-product-viewer-handle .handle .bars:before, .cd-product-viewer-handle .handle .bars:after {
// 	content: '';
// 	position: absolute;
// 	top: 50%;
// 	width: 4px;
// 	height: 60%;
// 	background: #FFF;
// 	transform: translate3d(0, -50%, 0);
// }

// .cd-product-viewer-handle .handle .bars:before {
// 	left: -8px;
// }

// .cd-product-viewer-handle .handle .bars:after {
// 	right: -8px;
// }

// .loaded .cd-product-viewer-handle .handle {
// 	transform: translateX(-50%) scale(1);
// 	-webkit-animation: cd-bounce 300ms 300ms;
// 	animation: cd-bounce 300ms 300ms;
// 	-webkit-animation-fill-mode: both;
// 	animation-fill-mode: both;
// 	cursor: ew-resize;
// }

// @-webkit-keyframes cd-bounce {
// 	0% {
// 		transform: translateX(-50%) scale(0);
// 	}

// 	60% {
// 		transform: translateX(-50%) scale(1.1);
// 	}

// 	100% {
// 		transform: translateX(-50%) scale(1);
// 	}
// }

// @keyframes cd-bounce {
// 	0% {
// 		transform: translateX(-50%) scale(0);
// 	}

// 	60% {
// 		transform: translateX(-50%) scale(1.1);
// 	}

// 	100% {
// 		transform: translateX(-50%) scale(1);
// 	}
// }

/* Video Open Icon */
.video-open-icon {
	display: inline-block;
	width: 64px;
	width: 4rem;
	height: 64px;
	height: 4rem;
	padding: 6px;
	padding: .375rem;
	transition: padding .2s;
	border-radius: 2rem;
	background: rgba(255, 255, 255, 0.07);
	position: relative;
	vertical-align: middle;
	margin-right: 8px;
	margin-right: .5rem;
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.04);
}

.video-open-icon:before {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 2rem;
	background: #fff;
}

.video-open-icon:after {
	content: '';
	border-left: 12px solid #08c;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -6px 0 0 -5px;
}

.video-open-icon:hover {
	padding: 4px;
	padding: .25rem;
}

/* Landing Page Text Reasons */
// .text-reasons {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	position: relative;
// 	transform: rotate(-10deg);
// }

// .text-reasons h3 {
// 	padding: 12px 56px;
// 	padding: .75rem 3.5rem;
// 	text-transform: uppercase;
// 	border-radius: 4px;
// 	margin-bottom: 6.4px;
// 	margin-bottom: .4rem;
// 	font-weight: 800;
// }

// .text-reasons h3:nth-child(2) {
// 	background: rgba(25, 25, 25, 0.15);
// 	margin-left: -10%;
// }

// .text-reasons h3:nth-child(3) {
// 	background: rgba(25, 25, 25, 0.57);
// 	font-size: 28px;
// 	font-size: 1.75rem;
// 	line-height: 1.5;
// 	padding-left: 80px;
// 	padding-left: 5rem;
// 	margin-left: 32px;
// 	margin-left: 2rem;
// }

// .text-reasons h3:nth-child(4) {
// 	background: #222529;
// 	font-size: 36px;
// 	font-size: 2.25rem;
// 	padding: 8px 20px;
// 	padding: .5rem 1.25rem;
// 	margin-left: 15%;
// }

// .text-reasons label {
// 	font-size: 200px;
// 	font-size: 12.5rem;
// 	line-height: .8;
// 	font-weight: 800;
// 	position: absolute;
// 	left: 0;
// 	top: 0;
// }

// .text-reasons .highlighted-word {
// 	letter-spacing: -.025em;
// }

// .text-reasons .highlighted-word:after {
// 	bottom: -0.33em;
// 	left: 10%;
// 	transform: scaleY(-1);
// }

/* Strong Shadow */
// .strong-shadow {
// 	position: relative;
// 	display: inline-block;
// }

// .strong-shadow:before {
// 	content: '';
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	background: #494b4e;
// 	z-index: -1;
// }

// .strong-shadow.strong-shadow-top-right:before {
// 	top: -25px;
// 	left: 25px;
// 	right: -25px;
// 	bottom: 25px;
// }

// .strong-shadow.rounded:before {
// 	border-radius: 5px;
// }





/* ElevateZoom Fix */
// .zoomContainer {
// 	z-index: 1;
// }

// /* Animated Letters */
// [data-plugin-animated-letters] {
// 	opacity: 0;
// 	visibility: hidden;
// }

// [data-plugin-animated-letters].initialized {
// 	opacity: 1;
// 	visibility: visible;
// }

/* Read More Rendered (JS Plugin) */
// .readmore-rendered {
// 	position: relative;
// 	overflow: hidden;
// }

// .readmore-rendered .readmore-overlay {
// 	position: absolute;
// 	bottom: 0px;
// 	left: 0px;
// 	width: 100%;
// 	z-index: 1;
// }

// .readmore-rendered .readmore-button-wrapper {
// 	position: absolute;
// 	bottom: 0px;
// 	left: 0px;
// 	width: 100%;
// 	z-index: 2;
// }

/* Style Switcher Open Loader Button */
// .style-switcher-open-loader {
// 	color: #FFF !important;
// 	background-color: #151719;
// 	display: block;
// 	height: 40px;
// 	position: fixed;
// 	left: 0;
// 	top: 135px;
// 	width: 40px;
// 	font-size: 12px;
// 	line-height: 40px;
// 	color: #FFF;
// 	text-align: center;
// 	text-decoration: none;
// 	border-radius: 0 4px 4px 0;
// 	z-index: 9999;
// }

// .style-switcher-open-loader.style-switcher-open-loader-loading > i {
// 	-webkit-animation-name: basicRotation;
// 	animation-name: basicRotation;
// 	-webkit-animation-duration: 1.5s;
// 	animation-duration: 1.5s;
// 	-webkit-animation-iteration-count: infinite;
// 	animation-iteration-count: infinite;
// 	-webkit-animation-timing-function: linear;
// 	animation-timing-function: linear;
// }

// .style-switcher-open-loader.style-switcher-open-loader-loading > i:before {
// 	content: "\f1ce";
// }

// .style-switcher-open-loader .style-switcher-tooltip {
// 	visibility: hidden;
// 	opacity: 0;
// 	position: absolute;
// 	top: 50%;
// 	left: calc(100% + 20px);
// 	width: 200px;
// 	background: #282d31;
// 	text-align: left;
// 	padding: 22.4px;
// 	padding: 1.4rem;
// 	border-radius: 4px;
// 	box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.2);
// 	transform: translate3d(-20px, -50%, 0);
// 	transition: ease opacity 300ms, ease transform 300ms;
// }

// .style-switcher-open-loader .style-switcher-tooltip:before {
// 	content: '';
// 	display: block;
// 	position: absolute;
// 	top: 50%;
// 	left: -10px;
// 	width: 0;
// 	height: 0;
// 	border-top: 10px solid transparent;
// 	border-bottom: 10px solid transparent;
// 	border-right: 10px solid #282d31;
// 	transform: translate3d(0, -50%, 0);
// }

// .style-switcher-open-loader .style-switcher-tooltip > strong {
// 	font-size: 13.6px;
// 	font-size: 0.85rem;
// 	font-family: "Open Sans";
// 	line-height: 1.3;
// 	display: block;
// 	margin-bottom: 8px;
// 	margin-bottom: 0.5rem;
// }

// .style-switcher-open-loader .style-switcher-tooltip > p {
// 	color: #e2e2e2;
// 	font-size: 12.8px;
// 	font-size: 0.8rem;
// 	line-height: 1.5;
// 	margin-bottom: 0;
// 	opacity: 0.8;
// }

// .style-switcher-open-loader .style-switcher-tooltip.active {
// 	visibility: visible;
// 	opacity: 1;
// 	transform: translate3d(0, -50%, 0);
// }

// @media (max-width: 767px) {
// 	.style-switcher-open-loader {
// 		display: none;
// 	}
// }






/* Video */
// [data-plugin-video] {
// 	position: relative;
// }

// [data-plugin-video] > img {
// 	position: relative;
// 	z-index: 1;
// }

// [data-plugin-video] > video {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	z-index: 0;
// }

// [data-plugin-video] > video.active {
// 	z-index: 1;
// }

/* Cursor Effect */
// .cursor-outer {
// 	position: fixed;
// 	left: 0;
// 	top: 0;
// 	width: 40px;
// 	height: 40px;
// 	border: 1px solid #CCC;
// 	border-radius: 100%;
// 	pointer-events: none;
// 	z-index: 9998;
// 	transition: ease-out all .08s .010s;
// }

// .cursor-outer.cursor-outer-fit {
// 	left: 0 !important;
// 	top: 0 !important;
// }

// .cursor-outer.cursor-color-light {
// 	border-color: #FFF !important;
// }

// .cursor-outer.cursor-color-dark {
// 	border-color: #212121 !important;
// }

// .cursor-inner {
// 	position: fixed;
// 	left: 17px;
// 	top: 17px;
// 	width: 6px;
// 	height: 6px;
// 	background: #CCC;
// 	border-radius: 100%;
// 	pointer-events: none;
// 	z-index: 9999;
// 	transition: all .08s ease-out;
// }

// .cursor-inner.cursor-inner-plus {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	background-color: transparent !important;
// }

// .cursor-inner.cursor-inner-plus:before {
// 	content: '+';
// 	color: #FFF;
// 	font-size: 27.2px;
// 	font-size: 1.7rem;
// }

// .cursor-inner.cursor-color-light:not(.cursor-inner-plus) {
// 	background-color: #FFF !important;
// }

// .cursor-inner.cursor-color-light.cursor-inner-plus:before {
// 	color: #FFF !important;
// }

// .cursor-inner.cursor-color-dark:not(.cursor-inner-plus) {
// 	background-color: #212121 !important;
// }

// .cursor-inner.cursor-color-dark.cursor-inner-plus:before {
// 	color: #212121 !important;
// }

.hide-mouse-cursor {
	cursor: none;
}

.hide-mouse-cursor a,
.hide-mouse-cursor button,
.hide-mouse-cursor input,
.hide-mouse-cursor textarea,
.hide-mouse-cursor .mfp-auto-cursor .mfp-content {
	cursor: none !important;
}

.cursor-effect-size-small .cursor-outer {
	width: 20px;
	height: 20px;
	left: 10px;
	top: 9px;
}

.cursor-effect-size-small .cursor-inner {
	width: 4px;
	height: 4px;
	left: 18px;
}

.cursor-effect-size-small .cursor-inner.cursor-inner-plus:before {
	font-size: 17.6px;
	font-size: 1.1rem;
}

.cursor-effect-size-big .cursor-outer {
	width: 60px;
	height: 60px;
	left: -9px;
	top: -10px;
}

.cursor-effect-size-big .cursor-inner {
	width: 8px;
	height: 8px;
}

.cursor-effect-size-big .cursor-inner.cursor-inner-plus:before {
	font-size: 41.6px;
	font-size: 2.6rem;
}

.cursor-effect-style-square .cursor-outer {
	border-radius: 0;
}

.cursor-effect-style-square .cursor-inner {
	border-radius: 0;
}

/* Simple Copy To Clipboard */
.copy-to-clipboard-wrapper .btn {
	position: relative;
}

.copy-to-clipboard-wrapper .btn:before {
	content: 'Copy to Clipboard';
	position: absolute;
	top: -41px;
	left: 50%;
	background: #212121;
	border-radius: 4px;
	transform: translate3d(-50%, 0, 0);
	opacity: 0;
	display: none;
	transition: ease opacity 300ms;
	white-space: nowrap;
	padding: 6px 14px;
	font-size: 12px;
	font-size: 0.75rem;
	z-index: 10;
}

.copy-to-clipboard-wrapper .btn:after {
	content: '';
	position: absolute;
	top: -12px;
	left: 50%;
	width: 0;
	height: 0;
	border-top: 7px solid #212121;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	transform: translate3d(-50%, 0, 0);
	opacity: 0;
	display: none;
	transition: ease opacity 300ms;
}

.copy-to-clipboard-wrapper .btn:hover:before, .copy-to-clipboard-wrapper .btn:hover:after, .copy-to-clipboard-wrapper .btn.copied:before, .copy-to-clipboard-wrapper .btn.copied:after {
	display: block;
	opacity: 1;
}

.copy-to-clipboard-wrapper .btn.copied:before {
	content: 'Copied!';
}

/* Popup */
.popup-inline-content,
.mfp-content .ajax-container {
	background: none repeat scroll 0 0 #FFF;
	margin: 40px auto;
	max-width: 1170px;
	padding: 20px 30px;
	position: relative;
	text-align: left;
	display: none;
}

.mfp-content .ajax-container {
	display: block;
}

.mfp-wrap .popup-inline-content {
	display: block;
}

.modal-footer > :not(:first-child) {
	margin-left: 4px;
	margin-left: .25rem;
	margin-right: 0px;
	margin-right: 0rem;
}

.modal-footer > :not(:last-child) {
	margin-right: 4px;
	margin-right: .25rem;
	margin-left: 0px;
	margin-left: 0rem;
}

/* Nav */
.nav > li > a:hover, .nav > li > a:focus {
	background: transparent;
}

/* Nav List */
ul.nav-list li {
	margin: 0;
	padding: 0;
	display: block;
	position: relative;
}

ul.nav-list li a {
	transition: background 0.1s;
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
	padding: 8px 0 8px 8px;
	display: block;
	color: #666;
	font-size: 0.9em;
	text-decoration: none;
	line-height: 20px;
}



ul.nav-list li a:hover {
	text-decoration: none;
}

ul.nav-list li a:hover:before,
ul.nav-list li a:hover i[class*="fa-chevron"] {
	-webkit-animation-name: arrowLeftRight;
	animation-name: arrowLeftRight;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

ul.nav-list li a i[class*="fa-chevron"] {
	position: relative;
	top: -1px;
	font-size: 8px;
	font-size: 0.5rem;
	opacity: 0.8;
}

ul.nav-list li > a.active {
	font-weight: bold;
	color:#ff6600;
}

ul.nav-list li ul {
	list-style: none;
	margin: 0 0 0 25px;
	padding: 0;
}

ul.nav-list li:last-child a {
	border-bottom: 0;
}

ul.nav-list.hide-not-active li ul {
	display: none;
}

ul.nav-list.hide-not-active li.active ul {
	display: block;
}

ul.nav-list.show-bg-active .active > a,
ul.nav-list.show-bg-active a.active {
	background-color: #f5f5f5;
}

ul.nav-list.show-bg-active .active > a:hover,
ul.nav-list.show-bg-active a.active:hover {
	background-color: #eee;
}

ul.nav-list.no-arrows li a:before {
	content: none;
}

ul.nav-list-simple li {
	margin: 0;
	padding: 0;
	display: block;
	position: relative;
}

ul.nav-list-simple li a {
	transition: background 0.1s;
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
	padding: 8px 0;
	display: block;
	color: #666;
	font-size: 0.9em;
	text-decoration: none;
	line-height: 20px;
	transition: ease padding 300ms;
}

ul.nav-list-simple li > a.active {
	font-weight: bold;
}

ul.nav-list-simple li:hover a {
	padding-left: 4px;
}

ul.nav-list-simple li ul {
	list-style: none;
	margin: 0 0 0 25px;
	padding: 0;
}

ul.narrow li a {
	padding: 4px 0;
}



/* Nav Pills */
.nav-pills-center {
	text-align: center;
}

.nav-pills-center > li {
	display: inline-block;
	float: none;
}

.nav-pills-sm > li > a {
	padding: 7px 12px;
}

.nav-pills-xs > li > a {
	padding: 5px 10px;
	font-size: 0.9em;
}

.nav-pills .nav-link.active {
	background-color: transparent;
}

.show > .nav-pills .nav-link {
	background-color: transparent;
}

/* Nav Light */
.nav-light > li > a {
	color: #FFF;
}

/* Nav Active Style - Active Bottom Border */
.nav-active-style-1 {
	min-height: 100px;
	align-items: center;
}

.nav-active-style-1 > li {
	align-self: stretch;
}

.nav-active-style-1 > li > a {
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 !important;
	margin: 0 15px;
	border-bottom: 2px solid transparent;
	transition: ease border-color 300ms;
}

.nav-active-style-1 > li > a:hover, .nav-active-style-1 > li > a:focus, .nav-active-style-1 > li > a.active {
	border-bottom-color: #CCC;
}

@media (max-width: 575px) {
	.nav-active-style-1 > li > a {
		min-height: 45px;
		justify-content: center;
	}
}

html.ie .nav-active-style-1 > li {
	min-height: 100px;
}

/* Nav Secondary */
.nav-secondary {
	background: #fafafa;
	margin: 0;
	padding: 20px 0;
	text-align: center;
	z-index: 99;
	width: 100% !important;
}

.nav-secondary .nav-pills > li {
	float: none;
	display: inline-block;
}

.nav-secondary .nav-pills > li a {
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	padding: 10px 13px;
	text-transform: uppercase;
}

.nav-secondary.sticky-active {
	border-bottom-color: #e9e9e9;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

/* Boxed */
html.boxed .nav-secondary.sticky-active {
	max-width: 1200px;
	left: auto !important;
}

/* Section Scroll - Dots Navigation */
// .section-scroll-dots-navigation {
// 	display: flex;
// 	flex-direction: column;
// 	position: fixed;
// 	top: 50%;
// 	right: 30px;
// 	background: #FFF;
// 	border-radius: 35px;
// 	padding: 9.6px;
// 	padding: 0.6rem;
// 	z-index: 10;
// 	transform: translate3d(0, -50%, 0);
// }

// .section-scroll-dots-navigation > ul {
// 	margin: 8px 0;
// 	margin: 0.5rem 0;
// }

// .section-scroll-dots-navigation > ul:hover > li.active:hover > a > span {
// 	opacity: 1;
// 	visibility: visible;
// }

// .section-scroll-dots-navigation > ul:hover > li.active > a > span {
// 	opacity: 0;
// 	visibility: hidden;
// }

// .section-scroll-dots-navigation > ul > li {
// 	position: relative;
// 	margin-bottom: 4.8px;
// 	margin-bottom: 0.3rem;
// }

// .section-scroll-dots-navigation > ul > li:last-child {
// 	margin-bottom: 0;
// }

// .section-scroll-dots-navigation > ul > li:hover {
// 	background: transparent;
// }

// .section-scroll-dots-navigation > ul > li:hover > a > span {
// 	opacity: 1;
// 	visibility: visible;
// }

// .section-scroll-dots-navigation > ul > li.active > a:before {
// 	opacity: 1;
// }

// .section-scroll-dots-navigation > ul > li > a {
// 	position: relative;
// 	width: 15px;
// 	height: 15px;
// 	display: block;
// 	padding: 4.8px;
// 	padding: 0.3rem;
// }

// .section-scroll-dots-navigation > ul > li > a:before {
// 	content: '';
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate3d(-50%, -50%, 0);
// 	width: 8px;
// 	height: 8px;
// 	border-radius: 100%;
// 	opacity: 0.3;
// 	background: #CCC;
// 	transition: ease opacity 300ms;
// }

// .section-scroll-dots-navigation > ul > li > a > span {
// 	position: absolute;
// 	top: 50%;
// 	right: calc(100% + 20px);
// 	background: #FFF;
// 	color: #212529;
// 	font-size: 12.8px;
// 	font-size: 0.8rem;
// 	font-weight: 800;
// 	padding: 3.2px 16px;
// 	padding: 0.2rem 1rem;
// 	border-radius: 4px;
// 	white-space: nowrap;
// 	opacity: 0;
// 	visibility: hidden;
// 	transform: translate3d(0, -50%, 0);
// 	transition: ease opacity 300ms;
// }

// .section-scroll-dots-navigation > ul > li > a > span:after {
// 	content: '';
// 	position: absolute;
// 	top: 50%;
// 	left: 100%;
// 	width: 0;
// 	height: 0;
// 	border-top: 6px solid transparent;
// 	border-bottom: 6px solid transparent;
// 	border-left: 6px solid #F7F7F7;
// 	transform: translate3d(0, -50%, 0);
// }

// .section-scroll-dots-navigation.section-scroll-dots-navigation-style-2 {
// 	background: transparent;
// }

// .section-scroll-dots-navigation.section-scroll-dots-navigation-light > ul > li > a:before {
// 	background: #FFF;
// }

// .section-scroll-dots-navigation.section-scroll-dots-navigation-light > ul > li > a > span {
// 	right: calc(100% + 10px);
// }

// @media (max-width: 767px) {
// 	.section-scroll-dots-navigation {
// 		right: 15px;
// 	}
// }

/* Overlays */
.overlay {
	position: relative;
		/* Backward */
}

.overlay:before {
	content: ' ';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	background: #212529;
	opacity: 0;
	pointer-events: none;
	transition: ease opacity 300ms 100ms;
}

.overlay:hover:before, .overlay.overlay-show:before, .overlay.show:before {
	opacity: 0.8;
}

.overlay.overlay-op-1:hover:before, .overlay.overlay-op-1.overlay-show:before, .overlay.overlay-op-1.show:before {
	opacity: 0.1;
}

.overlay.overlay-op-2:hover:before, .overlay.overlay-op-2.overlay-show:before, .overlay.overlay-op-2.show:before {
	opacity: 0.2;
}

.overlay.overlay-op-3:hover:before, .overlay.overlay-op-3.overlay-show:before, .overlay.overlay-op-3.show:before {
	opacity: 0.3;
}

.overlay.overlay-op-4:hover:before, .overlay.overlay-op-4.overlay-show:before, .overlay.overlay-op-4.show:before {
	opacity: 0.4;
}

.overlay.overlay-op-5:hover:before, .overlay.overlay-op-5.overlay-show:before, .overlay.overlay-op-5.show:before {
	opacity: 0.5;
}

.overlay.overlay-op-6:hover:before, .overlay.overlay-op-6.overlay-show:before, .overlay.overlay-op-6.show:before {
	opacity: 0.6;
}

.overlay.overlay-op-7:hover:before, .overlay.overlay-op-7.overlay-show:before, .overlay.overlay-op-7.show:before {
	opacity: 0.7;
}

.overlay.overlay-op-8:hover:before, .overlay.overlay-op-8.overlay-show:before, .overlay.overlay-op-8.show:before {
	opacity: 0.8;
}

.overlay.overlay-op-9:hover:before, .overlay.overlay-op-9.overlay-show:before, .overlay.overlay-op-9.show:before {
	opacity: 0.9;
}

.overlay.overlay-backward:hover:before, .overlay.overlay-backward.overlay-show:before, .overlay.overlay-backward.show:before {
	z-index: 0;
}

.overlay .container,
.overlay .container-fluid {
	position: relative;
	z-index: 3;
}

/* Overlay Colors */
.overlay-light:before {
	background-color: #FFF !important;
}

.overlay-dark:before {
	background-color: #212529 !important;
}

/* Overlay Gradient */
// .overlay-gradient:before {
// 	background-color: #6c6ef1;
// 	background-image: linear-gradient(to right, #6c6ef1 0%, #e2498e 100%);
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6c6ef1', endColorstr='#e2498e', GradientType=1 );
// }

// .overlay-gradient.overlay-gradient-animated:before {
// 	background-size: 200% 200%;
// 	-webkit-animation-name: gradientTransition;
// 	animation-name: gradientTransition;
// 	-webkit-animation-duration: 20000ms;
// 	animation-duration: 20000ms;
// 	-webkit-animation-iteration-count: infinite;
// 	animation-iteration-count: infinite;
// }

/* Parallax */
// .parallax {
// 	background-color: transparent;
// 	background-position: 50% 50%;
// 	background-repeat: repeat;
// 	background-attachment: fixed;
// 	position: relative;
// 	clear: both;
// }

// .parallax:not(.page-header):not(.match-height):not([class*="section-height-"]) {
// 	margin: 70px 0;
// 	padding: 50px 0;
// }

// .parallax blockquote {
// 	border: 0;
// 	padding: 0 10%;
// }

// .parallax blockquote i.fa-quote-left {
// 	font-size: 34px;
// 	position: relative;
// 	left: -5px;
// }

// .parallax blockquote p {
// 	font-size: 24px;
// 	line-height: 30px;
// }

// .parallax blockquote span {
// 	font-size: 16px;
// 	line-height: 20px;
// 	color: #999;
// 	position: relative;
// 	left: -5px;
// }

// .parallax-disabled .parallax-background {
// 	background-attachment: scroll !important;
// 	background-position: 50% 50% !important;
// }




/* Process */
.process {
	justify-content: space-between;
}

.process .process-step {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.process-step-update {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	border-radius: .2em;
	width: 30%;	
}

@media (max-width: 566px){
	.process{
		justify-content: center;
		align-items: center;
	}
	.process-step-update {
		width: 90%;	
	}

}

.process-step-circle {
	display: flex;
	justify-content: center;
	height: 5em;
	text-align: center;
	width: 5em;
	border: .1rem solid #ff6600;
	border-radius: 50%;
	align-items: center;
	position: relative;
	background-color: transparent;
	transition: all 0.2s ease-in-out;
}

.process-step-circle:hover{
	background-color: #ff6600;
	color: #002345;
}
.process-item-link:hover{
    color: #002345;
    text-decoration: none;
}
.process-step-content {
	padding-top: 20px;
	overflow: hidden;
}

.process-step-circle-content {
	font-weight: bold;
	color: #212529;
	font-size: 1.6em;
	letter-spacing: -1px;
	transition: all 0.2s ease-in-out;
}

.process-step:hover .process-step-circle {
	background-color: #CCC;
}

.process .process-step:hover .process-step-circle .process-step-circle-content {
	color: #FFF;
}

.process .process-step:hover .process-step-circle .process-step-circle-content .icons {
	color: #FFF !important;
}

.process.process-shapes .process-step-circle {
	position: relative;
	width: auto;
	height: auto;
	border: 0;
	margin-bottom: 19.2px;
	margin-bottom: 1.2rem;
}

.process.process-shapes .process-step-circle .process-step-circle-content {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 45px;
	height: 45px;
	background-color: #CCC;
	border-radius: 100%;
	color: #FFF;
	z-index: 3;
}

.process.process-shapes .process-step-circle:before, .process.process-shapes .process-step-circle:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	background-color: #777;
	border-radius: 100%;
	opacity: 0.6;
	transform: translate3d(-50%, -50%, 0) scale(1.5);
	transition: ease transform 1s;
	z-index: 2;
}

.process.process-shapes .process-step-circle:after {
	background-color: #999;
	opacity: 0.3;
	transform: translate3d(-50%, -50%, 0) scale(2);
	z-index: 1;
}









@-webkit-keyframes processShapesScaleOne {
	0% {
		opacity: 0.6;
		transform: translate3d(-50%, -50%, 0) scale(1.5);
	}

	25% {
		opacity: 0;
		transform: translate3d(-50%, -50%, 0) scale(1.8);
	}

	50% {
		opacity: 0;
		transform: translate3d(-50%, -50%, 0) scale(1);
	}

	100% {
		opacity: 0.6;
		transform: translate3d(-50%, -50%, 0) scale(1.5);
	}
}

@keyframes processShapesScaleOne {
	0% {
		opacity: 0.6;
		transform: translate3d(-50%, -50%, 0) scale(1.5);
	}

	25% {
		opacity: 0;
		transform: translate3d(-50%, -50%, 0) scale(1.8);
	}

	50% {
		opacity: 0;
		transform: translate3d(-50%, -50%, 0) scale(1);
	}

	100% {
		opacity: 0.6;
		transform: translate3d(-50%, -50%, 0) scale(1.5);
	}
}

@-webkit-keyframes processShapesScaleTwo {
	0% {
		opacity: 0.3;
		transform: translate3d(-50%, -50%, 0) scale(2);
	}

	25% {
		opacity: 0;
		transform: translate3d(-50%, -50%, 0) scale(2.3);
	}

	50% {
		opacity: 0;
		transform: translate3d(-50%, -50%, 0) scale(1);
	}

	100% {
		opacity: 0.3;
		transform: translate3d(-50%, -50%, 0) scale(2);
	}
}

@keyframes processShapesScaleTwo {
	0% {
		opacity: 0.3;
		transform: translate3d(-50%, -50%, 0) scale(2);
	}

	25% {
		opacity: 0;
		transform: translate3d(-50%, -50%, 0) scale(2.3);
	}

	50% {
		opacity: 0;
		transform: translate3d(-50%, -50%, 0) scale(1);
	}

	100% {
		opacity: 0.3;
		transform: translate3d(-50%, -50%, 0) scale(2);
	}
}

@media (min-width: 992px) {
	.process .process-step:before {
		height: .1em;
		display: block;
		background-color: #EDEDED;
		position: absolute;
		content: '';
		width: 50%;
		top: 2.5em;
		left: 75%;
	}

	.process .process-step:last-child:before {
		display: none;
	}

	.process.process-shapes .process-step:before {
		top: 23px;
	}
}

.process-vertical {
	text-align: left;
}

.process-vertical .process-step {
	width: 100%;
	flex-direction: row;
	justify-content: start;
	align-items: start;
	position: relative;
	padding-bottom: 60px;
}

.process-vertical .process-step:before {
	display: none;
}

.process-vertical .process-step:after {
	width: 2px;
	display: block;
	background-color: #EDEDED;
	position: absolute;
	content: '';
	height: calc(100% - 80px);
	top: 70px;
	left: 30px;
}

.process-vertical .process-step:last-child:after {
	display: none;
}

.process-vertical .process-step-circle {
	height: 60px;
	width: 60px;
	margin: 0 15px 0 0;
	min-height: 60px;
	min-width: 60px;
}

.process-vertical .process-step-content {
	padding-top: 0;
}

.process-connecting-line {
	position: relative;
	text-align: left;
}

.process-connecting-line .connecting-line {
	content: '';
	position: absolute;
	top: 25px;
	left: 15px;
	right: 15px;
	height: 3px;
	background: #CCC;
}

.process-connecting-line .process-step {
	align-items: flex-start;
	padding-right: 48px;
	padding-right: 3rem;
	padding-left: 48px;
	padding-left: 3rem;
}

.process-connecting-line .process-step:before {
	content: none;
}

.process-connecting-line .process-step-circle {
	background: #FFF;
	width: 50px;
	height: 50px;
	border: 3px solid #CCC;
}

/* Scroll to Top */
.scroll-to-top-button {
	transition: opacity 0.3s;
	background: #ff6600;
	border-radius: 4px 4px 0 0;
	bottom: 0;
	color: #FFF;
	display: none;
	height: 3rem;
	opacity: 0;
	padding: 10px 10px;
	position: fixed;
	right: 10px;
	text-align: center;
	text-decoration: none;
	min-width: 50px;
	z-index: 1040;
	font-size: 0.8em;
	border: none;
}
.scroll-to-top-button:focus{
	outline: none;
}

.scroll-to-top-button:hover {
	opacity: 1 !important;
}

.scroll-to-top-button.visible {
	opacity: 0.75;
}

.scroll-to-top-button span {
	display: inline-block;
	padding: 0 5px;
}

html.lightbox-opened .scroll-to-top-button {
	right: 27px;
}

html.ie11 .scroll-to-top-button {
	right: 25px;
}

/* Responsive */
@media (max-width: 991px) {
	html .scroll-to-top-button.hidden-mobile {
		display: none !important;
	}
}

/* Scrollable */
.scrollable {
	overflow: hidden;
	position: relative;
	width: 100%;
}

// .scrollable .scrollable-content {
// 	bottom: 0;
// 	left: 0;
// 	overflow: hidden;
// 	position: absolute;
// 	right: 0;
// 	top: 0;
// 	padding: 0 37px 0 0;
// 	overflow-x: hidden;
// 	overflow-y: scroll;
// 	outline: none;
// }

// .scrollable .scrollable-content::-webkit-scrollbar {
// 	visibility: hidden;
// }

// .scrollable .scrollable-pane {
// 	bottom: 0;
// 	opacity: 0.01;
// 	position: absolute;
// 	right: 5px;
// 	top: 0;
// 	transition: all 0.2s ease 0s;
// 	width: 4px;
// }

// .scrollable .scrollable-slider {
// 	border-radius: 5px;
// 	background: none repeat scroll 0 0 #CCC;
// 	margin: 0;
// 	position: relative;
// 	transition: opacity 0.2s ease 0s;
// 	opacity: 0;
// }

// .scrollable.scrollable-padding .scrollable-content {
// 	padding: 10px 24px 10px 10px;
// }

// .scrollable:hover .scrollable-slider, .scrollable.visible-slider .scrollable-slider {
// 	opacity: 1;
// }

/* Section Featured */
section.section {
	border-top: 5px solid #f1f1f1;
	margin: 30px 0;
	padding: 50px 0;
}

section.section.section-height-1 {
	padding: 12.307px 0;
	padding: 0.7692307692rem 0;
}

section.section.section-height-2 {
	padding: 36.923px 0;
	padding: 2.3076923077rem 0;
}

section.section.section-height-3 {
	padding: 73.846px 0;
	padding: 4.6153846154rem 0;
}

section.section.section-height-4 {
	padding: 110.769px 0;
	padding: 6.9230769231rem 0;
}

section.section.section-height-5 {
	padding: 147.692px 0;
	padding: 9.2307692308rem 0;
}

section.section.section-bg-strong-grey {
	background: #EDEDED;
}

section.section.section-no-background {
	background: transparent;
	border-top-color: transparent;
}

section.section.section-center {
	text-align: center;
}

section.section.section-text-light h1, section.section.section-text-light h2, section.section.section-text-light h3, section.section.section-text-light h4, section.section.section-text-light h5, section.section.section-text-light h6 {
	color: #FFF;
}

section.section.section-text-light p {
	color: #e6e5e5;
}

section.section.section-background {
	background-repeat: repeat;
	border: 0;
}

section.section.section-background-change-anim {
	-webkit-animation-name: colorTransition;
	animation-name: colorTransition;
	-webkit-animation-duration: 25s;
	animation-duration: 25s;
	-webkit-animation-direction: alternate;
	animation-direction: alternate;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}

section.section.section-funnel {
	position: relative;
}

section.section.section-funnel .section-funnel-layer-top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 96px 0;
	padding: 6rem 0;
	transform: translate3d(0, -50%, 0);
}

section.section.section-funnel .section-funnel-layer-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 96px 0;
	padding: 6rem 0;
	transform: translate3d(0, 50%, 0);
}

section.section.section-funnel .section-funnel-layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #FFF;
}

section.section.section-funnel .section-funnel-layer:nth-child(1) {
	transform: skewY(-4.7deg);
}

section.section.section-funnel .section-funnel-layer:nth-child(2) {
	transform: skewY(4.7deg);
}

section.section.section-angled {
	position: relative;
	margin: 0;
	overflow: hidden;
}

section.section.section-angled .section-angled-layer-top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 48px 0;
	padding: 3rem 0;
	transform: skewY(-0.7deg) translate3d(0, -50%, 0);
}

section.section.section-angled .section-angled-layer-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 48px 0;
	padding: 3rem 0;
	transform: skewY(0.7deg) translate3d(0, 50%, 0);
}

section.section.section-angled .section-angled-layer-top + .section-angled-content,
section.section.section-angled .section-angled-layer-top + .section-angled-layer-bottom + .section-angled-content {
	margin-top: 40px;
	margin-top: 2.5rem;
}

section.section.section-angled .section-angled-layer-bottom + .section-angled-content,
section.section.section-angled .section-angled-layer-bottom + .section-angled-layer-top + .section-angled-content {
	margin-bottom: 40px;
	margin-bottom: 2.5rem;
}

section.section.section-angled .section-angled-layer-increase-angle.section-angled-layer-top {
	transform: skewY(-1.7deg) translate3d(0, -50%, 0);
}



section.section.section-angled .section-angled-layer-increase-angle.section-angled-layer-bottom {
	transform: skewY(1.7deg) translate3d(0, 50%, 0);
}

section.section.section-angled .section-angled-layer-increase-angle-2.section-angled-layer-top {
	padding: 80px 0;
	padding: 5rem 0;
	transform: skewY(-4.7deg) translate3d(0, -50%, 0);
}

section.section.section-angled .section-angled-layer-increase-angle-2.section-angled-layer-bottom {
	padding: 80px 0;
	padding: 5rem 0;
	transform: skewY(4.7deg) translate3d(0, 50%, 0);
}

section.section.section-angled.section-angled-reverse .section-angled-layer-top {
	transform: skewY(0.7deg) translate3d(0, -50%, 0);
}

section.section.section-angled.section-angled-reverse .section-angled-layer-bottom {
	transform: skewY(-0.7deg) translate3d(0, 50%, 0);
}

section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle.section-angled-layer-top {
	transform: skewY(1.7deg) translate3d(0, -50%, 0);
}

section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle.section-angled-layer-bottom {
	transform: skewY(-1.7deg) translate3d(0, 50%, 0);
}

section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle-2.section-angled-layer-top {
	padding: 80px 0;
	padding: 5rem 0;
	transform: skewY(4.7deg) translate3d(0, -50%, 0);
}

section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle-2.section-angled-layer-bottom {
	padding: 80px 0;
	padding: 5rem 0;
	transform: skewY(-4.7deg) translate3d(0, 50%, 0);
}

section.section.section-angled + .section-angled {
	padding-top: 0;
}

section.section.overlay .section-angled-layer-top,
section.section.overlay .section-angled-layer-bottom {
	z-index: 99 !important;
}

section.section.section-overlay {
	position: relative;
}

section.section.section-overlay:before {
	content: "";
	display: block;
	background: rgba(33, 37, 41, 0.8);
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

section.section.section-overlay .section-angled-layer-top,
section.section.section-overlay .section-angled-layer-bottom {
	z-index: 99 !important;
}



section.section.section-overlay-opacity {
	position: relative;
}

section.section.section-overlay-opacity:before {
	content: "";
	display: block;
	background: rgba(0, 0, 0, 0.5);
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-1:before {
	background: rgba(33, 37, 41, 0.1);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-2:before {
	background: rgba(33, 37, 41, 0.2);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-3:before {
	background: rgba(33, 37, 41, 0.3);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-4:before {
	background: rgba(33, 37, 41, 0.4);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-5:before {
	background: rgba(33, 37, 41, 0.5);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-6:before {
	background: rgba(33, 37, 41, 0.6);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-7:before {
	background: rgba(33, 37, 41, 0.7);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-8:before {
	background: rgba(33, 37, 41, 0.8);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-9:before {
	background: rgba(33, 37, 41, 0.9);
}

section.section.section-overlay-opacity-light:before {
	background: rgba(255, 255, 255, 0.5);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-1:before {
	background: rgba(255, 255, 255, 0.1);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-2:before {
	background: rgba(255, 255, 255, 0.2);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-3:before {
	background: rgba(255, 255, 255, 0.3);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-4:before {
	background: rgba(255, 255, 255, 0.4);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-5:before {
	background: rgba(255, 255, 255, 0.5);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-6:before {
	background: rgba(255, 255, 255, 0.6);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-7:before {
	background: rgba(255, 255, 255, 0.7);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-8:before {
	background: rgba(255, 255, 255, 0.8);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-9:before {
	background: rgba(255, 255, 255, 0.9);
}

section.section.section-overlay-opacity-gradient:before {
	opacity: 0.85;
}

section.section.section-video {
	background: none !important;
	border: 0;
}

section.section.section-parallax {
	background-color: transparent;
	background-position: 50% 50%;
	background-repeat: repeat;
	background-attachment: fixed;
	border: 0;
}

section.section.section-parallax.parallax-disabled {
	background-attachment: scroll !important;
	background-position: 50% 50% !important;
}

section.section.section-no-border {
	border: 0;
}

section.section.section-with-divider {
	margin: 56px 0 35px;
}

section.section.section-with-divider .divider {
	margin: -56px 0 44px;
}

section.section.section-footer {
	margin-bottom: -50px;
}

section.section.section-with-mockup {
	margin-top: 120px;
}

section.section.section-front {
	position: relative;
	z-index: 1;
}

/* Responsive */
@media (max-width: 991px) {
	.container-fluid > .row > .p-0 section.section {
		margin-bottom: 0;
		margin-top: 0;
	}

	.container-fluid > .row > .p-0:first-child section.section {
		margin-bottom: 0;
		margin-top: 30px;
	}

	.container-fluid > .row > .p-0:last-child section.section {
		margin-bottom: 30px;
		margin-top: 0;
	}
}

/* Section With Shape Divider */
@-webkit-keyframes shapeDividerToLeft {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(-200%, 0, 0);
	}
}

@keyframes shapeDividerToLeft {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(-200%, 0, 0);
	}
}

@-webkit-keyframes shapeDividerToRight {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(200%, 0, 0);
	}
}

@keyframes shapeDividerToRight {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(200%, 0, 0);
	}
}

.section.section-with-shape-divider {
	position: relative;
	overflow: hidden;
}

.section.section-with-shape-divider .shape-divider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	z-index: 1;
}

.section.section-with-shape-divider .shape-divider svg {
	position: absolute;
	top: 0;
	left: 50%;
	height: 100%;
	transform: translate3d(-50%, 0, 0);
}

@media (min-width: 1950px) {
	.section.section-with-shape-divider .shape-divider svg {
		width: 100%;
	}
}

.section.section-with-shape-divider .shape-divider.shape-divider-bottom {
	top: auto;
	bottom: 0;
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y {
	transform: rotateY(180deg);
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x {
	transform: rotateX(180deg);
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy {
	transform: rotateY(180deg) rotateX(180deg);
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	-webkit-animation-duration: 45s;
	animation-duration: 45s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation > svg {
	width: 100vw;
	left: 0;
	transform: none;
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right.start {
	-webkit-animation-name: shapeDividerToRight;
	animation-name: shapeDividerToRight;
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(2) {
	transform: rotateY(180deg) translate3d(99.9%, 0, 0);
}

@media (max-width: 575px) {
	.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(2) {
		transform: rotateY(180deg) translate3d(99.8%, 0, 0);
	}
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(3) {
	transform: translate3d(-199.8%, 0, 0);
}

@media (max-width: 575px) {
	.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(3) {
		transform: translate3d(-199.6%, 0, 0);
	}
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left.start {
	-webkit-animation-name: shapeDividerToLeft;
	animation-name: shapeDividerToLeft;
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(2) {
	transform: rotateY(180deg) translate3d(-99.9%, 0, 0);
}

@media (max-width: 575px) {
	.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(2) {
		transform: rotateY(180deg) translate3d(99.8%, 0, 0);
	}
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(3) {
	transform: translate3d(199.8%, 0, 0);
}

@media (max-width: 575px) {
	.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(3) {
		transform: translate3d(199.6%, 0, 0);
	}
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right.start, .section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right.start {
	-webkit-animation-name: shapeDividerToLeft;
	animation-name: shapeDividerToLeft;
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(2), .section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(2) {
	transform: rotateY(180deg) translate3d(-99.9%, 0, 0);
}

@media (max-width: 575px) {
	.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(2), .section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(2) {
		transform: rotateY(180deg) translate3d(-99.8%, 0, 0);
	}
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(3), .section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(3) {
	transform: translate3d(199.8%, 0, 0);
}

@media (max-width: 575px) {
	.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(3), .section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right > svg:nth-child(3) {
		transform: translate3d(199.6%, 0, 0);
	}
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left.start, .section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left.start {
	-webkit-animation-name: shapeDividerToRight;
	animation-name: shapeDividerToRight;
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(2), .section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(2) {
	transform: rotateY(180deg) translate3d(99.9%, 0, 0);
}

@media (max-width: 575px) {
	.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(2), .section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(2) {
		transform: rotateY(180deg) translate3d(99.8%, 0, 0);
	}
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(3), .section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(3) {
	transform: translate3d(-199.8%, 0, 0);
}

@media (max-width: 575px) {
	.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(3), .section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(3) {
		transform: translate3d(-199.6%, 0, 0);
	}
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right.start {
	-webkit-animation-name: shapeDividerToRight;
	animation-name: shapeDividerToRight;
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left.start {
	-webkit-animation-name: shapeDividerToLeft;
	animation-name: shapeDividerToLeft;
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(2) {
	transform: rotateY(180deg) translate3d(-99.9%, 0, 0);
}

@media (max-width: 575px) {
	.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(2) {
		transform: rotateY(180deg) translate3d(-99.8%, 0, 0);
	}
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(3) {
	transform: translate3d(199.8%, 0, 0);
}

@media (max-width: 575px) {
	.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left > svg:nth-child(3) {
		transform: translate3d(199.6%, 0, 0);
	}
}

.section.section-with-shape-divider.overlay:before {
	z-index: 1;
}

/* Sidebar */
aside ul.nav-list > li > a {
	color: #666;
	font-size: 0.9em;
	padding: 8px 0 8px 0;
}

aside .twitter [class*="fa-"], aside .twitter .icons {
	clear: both;
	font-size: 1.5em;
	position: relative;
	top: 3px;
	margin-right: -22px;
	left: -30px;
	color: #212529;
}

aside .twitter .meta {
	display: block;
	font-size: 0.9em;
	padding-top: 3px;
}

aside .twitter ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

aside .twitter ul li {
	padding-bottom: 20px;
	padding-left: 30px;
}

aside .twitter ul li:last-child {
	padding-bottom: 0;
}

aside .twitter-account {
	display: block;
	font-size: 0.9em;
	margin: -15px 0 5px;
	opacity: 0.55;
}

aside .twitter-account:hover {
	opacity: 1;
}

/* Side Panel */
html.side-panel .body:before {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(33, 37, 41, 0.5);
	opacity: 0;
	visibility: hidden;
	z-index: 9998;
	transition: ease opacity 300ms;
}

html.side-panel .side-panel-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 300px;
	height: 100vh;
	background: #FFF;
	padding: 32px;
	padding: 2rem;
	box-shadow: 0px 0px 30px -13px rgba(0, 0, 0, 0.2);
	overflow-y: auto;
	transform: translate3d(-100%, 0, 0);
	transition: ease transform 300ms;
	z-index: 9999;
}

html.side-panel .side-panel-wrapper .side-panel-close {
	position: absolute;
	top: 0;
	right: 0;
}

html.side-panel.side-panel-push .body {
	transform: translate3d(0, 0, 0);
	transition: ease transform 300ms;
}

html.side-panel.side-panel-right .side-panel-wrapper {
	left: auto;
	right: 0;
	transform: translate3d(100%, 0, 0);
}

html.side-panel.side-panel-without-overlay .body:before {
	content: none;
}

html.side-panel.side-panel-open .body:before {
	opacity: 1;
	visibility: visible;
}

html.side-panel.side-panel-open .side-panel-wrapper {
	transform: translate3d(0, 0, 0);
}

html.side-panel.side-panel-open.side-panel-push .body {
	transform: translate3d(300px, 0, 0);
}

html.side-panel.side-panel-open.side-panel-push.side-panel-right .body {
	transform: translate3d(-300px, 0, 0);
}

/* Home Slider - Revolution Slider */
/* .slider-container {
	background: #151719;
	height: 500px;
	overflow: hidden;
	width: 100%;
	direction: ltr;
}

.slider-container .tparrows {
	border-radius: 6px;
}

.slider-container .slider-single-slide .tparrows {
	display: none;
}

.slider-container .top-label {
	color: #FFF;
	font-size: 24px;
	font-weight: 300;
}

.slider-container .main-label {
	color: #FFF;
	font-size: 62px;
	line-height: 62px;
	font-weight: 800;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.slider-container .featured-label {
	color: #FFF;
	font-size: 52px;
	line-height: 52px;
	font-weight: 800;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}

.slider-container .bottom-label {
	color: #FFF;
	font-size: 20px;
	line-height: 20px;
	font-weight: 300;
}

.slider-container .blackboard-text {
	color: #BAB9BE;
	font-size: 46px;
	line-height: 46px;
	font-family: "Shadows Into Light", cursive;
	text-transform: uppercase;
}

.slider-container .btn.btn-lg.btn-slider-action {
	font-size: 22px;
	font-weight: 600;
	line-height: 20px;
	padding: 19.2px 28.8px;
	padding: 1.2rem 1.8rem;
}

.slider-container .top-label-dark, .slider-container .main-label-dark, .slider-container .bottom-label-dark {
	color: #333;
}

.slider-container .tp-caption-dark a {
	color: #333;
}

.slider-container .tp-caption-dark a:hover {
	color: #333;
}

.slider-container .tp-caption-overlay {
	background: #000;
	padding: 10px;
}

.slider-container .tp-caption-overlay-opacity {
	background: rgba(0, 0, 0, 0.4);
	padding: 10px;
}

.slider-container .tp-opacity-overlay {
	background: #000;
	opacity: 0.75;
}

.slider-container .tp-opacity-overlay-light {
	background: #FFF;
	opacity: 0.75;
}

.slider-container.transparent {
	background-color: transparent;
}

.slider-container.light {
	background-color: #EAEAEA;
}

.slider-container.light .tp-bannershadow {
	opacity: 0.05;
	top: 0;
	bottom: auto;
	z-index: 1000;
}

.slider-container.light .featured-label, .slider-container.light .top-label, .slider-container.light .main-label, .slider-container.light .bottom-label {
	color: #333;
}

.slider-container.light .tp-caption a {
	color: #333;
}

.slider-container.light .tp-caption a:hover {
	color: #333;
} */

/* Revolution Slider Typewriter Addon */
/* .tp-caption[data-typewriter]:after {
	transform: translateX(-9px);
}



.rev_slider li.slide-overlay .slotholder:after {
	width: 100%;
	height: 100%;
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0.9;
	background: #212529;
	z-index: 2;
}

.rev_slider li.slide-overlay.slide-overlay-light .slotholder:after {
	background: #FFF;
}

.rev_slider li.slide-overlay.slide-overlay-gradient .slotholder:after {
	background-color: #6c6ef1;
	background-image: linear-gradient(to right, #6c6ef1 0%, #e2498e 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6c6ef1', endColorstr='#e2498e', GradientType=1 );
}

.rev_slider li.slide-overlay.slide-overlay-gradient.slide-overlay-gradient-animated .slotholder:after {
	background-size: 200% 200%;
	-webkit-animation-name: gradientTransition;
	animation-name: gradientTransition;
	-webkit-animation-duration: 20000ms;
	animation-duration: 20000ms;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.rev_slider li.slide-overlay.slide-overlay-level-0 .slotholder:after {
	opacity: 0.0;
}

.rev_slider li.slide-overlay.slide-overlay-level-1 .slotholder:after {
	opacity: 0.1;
}

.rev_slider li.slide-overlay.slide-overlay-level-2 .slotholder:after {
	opacity: 0.2;
}

.rev_slider li.slide-overlay.slide-overlay-level-3 .slotholder:after {
	opacity: 0.3;
}

.rev_slider li.slide-overlay.slide-overlay-level-4 .slotholder:after {
	opacity: 0.4;
}

.rev_slider li.slide-overlay.slide-overlay-level-5 .slotholder:after {
	opacity: 0.5;
}

.rev_slider li.slide-overlay.slide-overlay-level-6 .slotholder:after {
	opacity: 0.6;
}

.rev_slider li.slide-overlay.slide-overlay-level-7 .slotholder:after {
	opacity: 0.7;
}

.rev_slider li.slide-overlay.slide-overlay-level-8 .slotholder:after {
	opacity: 0.8;
}

.rev_slider li.slide-overlay.slide-overlay-level-9 .slotholder:after {
	opacity: 0.9;
}

.rev_slider li.slide-overlay.slide-overlay-level-10 .slotholder:after {
	opacity: 0.10;
} */

/* Slider Scroll Button */
// .slider-scroll-button {
// 	position: relative;
// 	width: 57px;
// 	height: 57px;
// 	border-radius: 100%;
// 	text-indent: -9999px;
// }

// .slider-scroll-button:before {
// 	content: '';
// 	position: absolute;
// 	top: 50%;
// 	left: 49%;
// 	width: 23px;
// 	height: 34px;
// 	border: 1px solid #FFF;
// 	border-radius: 15px;
// 	transform: translate3d(-50%, -50%, 0);
// }

// .slider-scroll-button:after {
// 	content: '';
// 	width: 6px;
// 	height: 10px;
// 	border-radius: 10px;
// 	border: 1px solid #FFF;
// 	position: absolute;
// 	top: 19px;
// 	left: 49%;
// 	transform: translate3d(-50%, 0, 0);
// 	-webkit-animation-name: sliderScrollButton;
// 	animation-name: sliderScrollButton;
// 	-webkit-animation-duration: 2s;
// 	animation-duration: 2s;
// 	-webkit-animation-iteration-count: infinite;
// 	animation-iteration-count: infinite;
// }

// .slider-scroll-button.slider-scroll-button-dark:before {
// 	border-color: #212529;
// }

// .slider-scroll-button.slider-scroll-button-dark:after {
// 	border-color: #212529;
// }

/* Arrows */
/* .tparrows.tparrows-carousel {
	display: inline-block;
	position: absolute;
	width: 30px;
	height: auto;
	padding: 20px 0;
}

.tparrows.tparrows-carousel.tp-leftarrow {
	right: auto !important;
	left: -30px !important;
	border-radius: 0 4px 4px 0;
}

.tparrows.tparrows-carousel.tp-leftarrow:before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: "\f053";
	position: relative;
	left: -1px;
	top: 1px;
}

.tparrows.tparrows-carousel.tp-rightarrow {
	right: -60px !important;
	left: auto !important;
	border-radius: 4px 0 0 4px;
}

.tparrows.tparrows-carousel.tp-rightarrow:before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: "\f054";
	position: relative;
	left: 1px;
	top: 1px;
}

.tparrows.arrows-style-1 {
	background: transparent !important;
}

.tparrows.arrows-style-2 {
	background: transparent !important;
}

.tparrows.arrows-style-2.tp-leftarrow:before, .tparrows.arrows-style-2.tp-rightarrow:before {
	content: '';
	display: block;
	position: absolute;
	left: 1.5px;
	top: 50%;
	width: 0.8em;
	height: 0.8em;
	border-top: 1px solid #FFF;
	border-left: 1px solid #FFF;
	transition: ease opacity 400ms;
	font-size: inherit;
	opacity: 0.7;
}

.tparrows.arrows-style-2.tp-leftarrow:after, .tparrows.arrows-style-2.tp-rightarrow:after {
	content: '';
	display: block;
	border-top: 1px solid #FFF;
	transition: ease opacity 400ms;
	width: 1.5em;
	position: absolute;
	top: 50%;
	font-size: inherit;
	transform: translate3d(0, -50%, 0);
	opacity: 0.7;
}

.tparrows.arrows-style-2.tp-leftarrow:hover:before, .tparrows.arrows-style-2.tp-rightarrow:hover:before {
	opacity: 1;
}

.tparrows.arrows-style-2.tp-leftarrow:hover:after, .tparrows.arrows-style-2.tp-rightarrow:hover:after {
	opacity: 1;
}

.tparrows.arrows-style-2.tp-leftarrow:before {
	left: 0.5px;
	transform: translate3d(0, -50%, 0) rotate(-45deg);
}

.tparrows.arrows-style-2.tp-rightarrow:before {
	left: 9.5px;
	transform: translate3d(0, -50%, 0) rotate(135deg);
}

@media (max-width: 768px) {
	.tparrows.arrows-style-2 {
		display: none;
	}
}

.tparrows.arrows-dark:before {
	color: #111111 !important;
}

.tparrows.arrows-big:before {
	font-size: 25px;
}

.tparrows.arrows-rounded {
	border-radius: 100%;
} */

/* Bullets */
// .tp-bullets.bullets-style-1 .tp-bullet {
// 	width: 8px;
// 	height: 8px;
// 	border-radius: 100%;
// }

// .tp-bullets.bullets-style-2 .tp-bullet {
// 	width: 8px;
// 	height: 8px;
// 	border-radius: 100%;
// 	background: #78888D;
// }

/* Slider IE Fix */
// html.ie .forcefullwidth_wrapper_tp_banner {
// 	overflow: hidden;
// }

// /* Embed Border */
// .rev_slider embed,
// .rev_slider iframe,
// .rev_slider object,
// .rev_slider video {
// 	border: 0 !important;
// }

// /* Defaults */
// .hesperiden-custom {
// 	padding: 10px 0 !important;
// }

// .hesperiden-custom .tp-tab {
// 	border: 0 !important;
// }

// /* Slider With Overlay */
// .slider-with-overlay {
// 	position: relative;
// }

// .slider-with-overlay .slider-container {
// 	z-index: auto;
// }

// /* Slider Contact Form */
// .slider-contact-form {
// 	margin: 45px 0;
// }

// .slider-contact-form .featured-box {
// 	text-align: left;
// }

// .slider-contact-form label.error {
// 	display: none !important;
// }

// .slider-contact-form .alert {
// 	padding: 6px;
// 	text-align: center;
// }

// .slider-contact-form textarea {
// 	resize: none;
// }

/* Responsive */
// @media (min-width: 992px) {
// 	.slider-contact-form {
// 		margin: 0;
// 		position: absolute;
// 		top: 50%;
// 		transform: translateY(-50%);
// 		z-index: 99;
// 		width: 100%;
// 	}
// }

/* Boxed */
html.boxed .slider-container {
	width: 100% !important;
	left: auto !important;
}

/* Side Header - Full Screen Slider Height On Mobile */
@media (max-width: 991px) {
	html.side-header .slider-container.fullscreen-slider,
		html.side-header .slider-container.fullscreen-slider + .tp-fullwidth-forcer {
		height: calc(100vh - 84px) !important;
	}
}

/* Social Icons */
.social-icons {
	margin: 0;
	padding: 0;
	width: auto;
}

.social-icons li {
	display: inline-block;
	margin: -1px 1px 0 0;
	padding: 0;
	border-radius: 100%;
	overflow: visible;
}

.social-icons li a {
	transition: all 0.2s ease;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1.8em;
	width: 1.8em;
	line-height: 1.8em;
	text-align: center;
	color: #333 !important;
	text-decoration: none;
	font-size: 1em;

}

.social-icons li:hover a {
	background: #151719;
	color: #FFF !important;
}

.social-icons li:hover.social-icons-twitter a {
	background: #ff6600 !important;
}

.social-icons li:hover.social-icons-facebook a {
	background: #3b5a9a !important;
}

.social-icons li:hover.social-icons-linkedin a {
	background: #0073b2 !important;
}

.social-icons li:hover.social-icons-rss a {
	background: #ff8201 !important;
}

.social-icons li:hover.social-icons-googleplus a {
	background: #dd4b39 !important;
}

.social-icons li:hover.social-icons-pinterest a {
	background: #cc2127 !important;
}

.social-icons li:hover.social-icons-youtube a {
	background: #c3191e !important;
}

.social-icons li:hover.social-icons-instagram a {
	background: #7c4a3a !important;
}

.social-icons li:hover.social-icons-skype a {
	background: #00b0f6 !important;
}

.social-icons li:hover.social-icons-email a {
	background: #dd4b39 !important;
}

.social-icons li:hover.social-icons-vk a {
	background: #6383a8 !important;
}

.social-icons li:hover.social-icons-xing a {
	background: #1a7576 !important;
}

.social-icons li:hover.social-icons-tumblr a {
	background: #304e6c !important;
}

.social-icons li:hover.social-icons-reddit a {
	background: #ff4107 !important;
}

.social-icons li:hover.social-icons-whatsapp a {
	background: #25d366 !important;
}

.social-icons.social-icons-dark li a {
	background: #181818;
}

.social-icons.social-icons-dark li a i {
	color: #FFF;
}

.social-icons.social-icons-dark-2 li a {
	background: #272a2e;
}

.social-icons.social-icons-dark-2 li a i {
	color: #FFF;
}

.social-icons.social-icons-transparent li {
	box-shadow: none;
}

.social-icons.social-icons-transparent li a {
	background: transparent;
}

.social-icons.social-icons-icon-gray li a {
	color: #777 !important;
}

.social-icons.social-icons-icon-gray li:hover a {
	color: #FFF !important;
}

.social-icons.social-icons-icon-light li a i {
	color: #FFF;
}

.social-icons.social-icons-icon-light.social-icons-clean li a i {
	color: #FFF !important;
}

.social-icons.social-icons-medium li a {
	height: 38px;
	line-height: 38px;
	width: 38px;
	font-size: 14.4px;
	font-size: 0.9rem;
}

.social-icons.social-icons-big li a {
	height: 48px;
	line-height: 48px;
	width: 48px;
}

@media (max-width: 400px) {
	.social-icons.social-icons-big li a {
		height: 38px;
		line-height: 38px;
		width: 38px;
	}
}

.social-icons.social-icons-opacity-light li a {
	background: rgba(255, 255, 255, 0.04);
}

.social-icons.social-icons-clean-with-border li a {
	background: transparent;
	color: #FFF !important;
	border: 1px solid rgba(255, 255, 255, 0.1);
}

.social-icons.social-icons-clean-with-border.social-icons-clean-with-border-border-grey li a {
	border: 1px solid #e7e7e7;
}

.social-icons.social-icons-clean-with-border.social-icons-clean-with-border-icon-dark li a {
	color: #212121 !important;
}

.social-icons.social-icons-clean-with-border.social-icons-clean-with-border-icon-dark li:hover a {
	color: #FFF !important;
}

.social-icons:not(.social-icons-opacity-light):not(.social-icons-transparent):not(.social-icons-clean):not(.social-icons-clean-with-border):not(.social-icons-dark):not(.social-icons-dark-2):not(.custom-social-icons) li {
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.social-icons:not(.social-icons-opacity-light):not(.social-icons-transparent):not(.social-icons-clean):not(.social-icons-clean-with-border):not(.social-icons-dark):not(.social-icons-dark-2):not(.custom-social-icons) li a {
	background: #FFF;
}

.social-icons-dark.social-icons-clean li a,
.social-icons-dark-2.social-icons-clean li a {
	background: transparent;
	color: #FFF !important;
}






/* Tables */
.table td, .table th {
	border-color: rgba(0, 0, 0, 0.06);
}

.table > thead > tr > td.primary,
.table > tbody > tr > td.primary,
.table > tfoot > tr > td.primary,
.table > thead > tr > th.primary,
.table > tbody > tr > th.primary,
.table > tfoot > tr > th.primary,
.table > thead > tr.primary > td,
.table > tbody > tr.primary > td,
.table > tfoot > tr.primary > td,
.table > thead > tr.primary > th,
.table > tbody > tr.primary > th,
.table > tfoot > tr.primary > th {
	background-color: #CCC;
	color: #FFF;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
	background-color: #28a745;
	color: #FFF;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
	background-color: #ffc107;
	color: #FFF;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
	background-color: #dc3545;
	color: #FFF;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
	background-color: #17a2b8;
	color: #FFF;
}

.table > thead > tr > td.dark,
.table > tbody > tr > td.dark,
.table > tfoot > tr > td.dark,
.table > thead > tr > th.dark,
.table > tbody > tr > th.dark,
.table > tfoot > tr > th.dark,
.table > thead > tr.dark > td,
.table > tbody > tr.dark > td,
.table > tfoot > tr.dark > td,
.table > thead > tr.dark > th,
.table > tbody > tr.dark > th,
.table > tfoot > tr.dark > th {
	background-color: #212529;
	color: #FFF;
}

/* Tabs */
.tabs {
	border-radius: 4px;
	margin-bottom: 20px;
}

.nav-tabs {
	margin: 0;
	padding: 0;
	border-bottom-color: #EEE;
}

.nav-tabs li:last-child .nav-link {
	margin-right: 0;
}

.nav-tabs li .nav-link {
	border-radius: 5px 5px 0 0;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: -0.2px;
	padding: 11.2px 24px;
	padding: .7rem 1.5rem;
	transition: all .2s;
	margin-right: 1px;
}

.nav-tabs li .nav-link, .nav-tabs li .nav-link:hover {
	background: #f7f7f7;
	border-bottom: none;
	border-left: 1px solid #EEE;
	border-right: 1px solid #EEE;
	border-top: 3px solid #EEE;
	color: #CCC;
}

.nav-tabs li .nav-link:hover {
	border-bottom-color: transparent;
	border-top: 3px solid #CCC;
	box-shadow: none;
}

.nav-tabs li .nav-link:active, .nav-tabs li .nav-link:focus {
	border-bottom: 0;
}

.nav-tabs li.active .nav-link,
.nav-tabs li.active .nav-link:hover,
.nav-tabs li.active .nav-link:focus {
	background: #FFF;
	border-left-color: #EEE;
	border-right-color: #EEE;
	border-top: 3px solid #CCC;
	color: #CCC;
}

.tab-content {
	border-radius: 0 0 4px 4px;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	background-color: #FFF;
	border: 1px solid #EEE;
	border-top: 0;
	padding: 15px;
}

/* Right Aligned */
/* .nav-tabs.nav-right {
	text-align: right;
} */

/* Bottom Tabs */
/* .tabs.tabs-bottom {
	margin: 0 0 20px 0;
	padding: 0;
}

.tabs.tabs-bottom .tab-content {
	border-radius: 4px 4px 0 0;
	border-bottom: 0;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs {
	border-bottom: none;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li {
	margin-bottom: 0;
	margin-top: -1px;
}

.tabs.tabs-bottom .nav-tabs li:last-child .nav-link {
	margin-right: 0;
}

.tabs.tabs-bottom .nav-tabs li .nav-link {
	border-radius: 0 0 5px 5px;
	font-size: 13px;
	margin-right: 1px;
}

.tabs.tabs-bottom .nav-tabs li .nav-link, .tabs.tabs-bottom .nav-tabs li .nav-link:hover {
	border-bottom: 3px solid #EEE;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li .nav-link:hover {
	border-bottom: 3px solid #CCC;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li.active .nav-link,
.tabs.tabs-bottom .nav-tabs li.active .nav-link:hover,
.tabs.tabs-bottom .nav-tabs li.active .nav-link:focus {
	border-bottom: 3px solid #CCC;
	border-top-color: transparent !important;
} */

/* Vertical */
/* .tabs-vertical {
	display: table;
	width: 100%;
	padding: 0;
	border-top: 1px solid #EEE;
}

.tabs-vertical .tab-content {
	display: table-cell;
	vertical-align: top;
}

.tabs-vertical .nav-tabs {
	border-bottom: none;
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	vertical-align: top;
	width: 25%;
}

.tabs-vertical .nav-tabs > li {
	display: block;
}

.tabs-vertical .nav-tabs > li .nav-link {
	border-radius: 0;
	display: block;
	padding-top: 10px;
}

.tabs-vertical .nav-tabs > li .nav-link, .tabs-vertical .nav-tabs > li .nav-link:hover, .tabs-vertical .nav-tabs > li .nav-link:focus {
	border-bottom: none;
	border-top: none;
}

.tabs-vertical .nav-tabs > li.active .nav-link,
.tabs-vertical .nav-tabs > li.active .nav-link:hover,
.tabs-vertical .nav-tabs > li.active .nav-link:focus {
	border-top: none;
} */

/* Vertical - Left Side */
/* .tabs-left {
	padding: 0;
}

.tabs-left .tab-content {
	border-radius: 0 5px 5px 5px;
	border-left: none;
}

.tabs-left .nav-tabs > li {
	margin-right: -1px;
}

.tabs-left .nav-tabs > li:first-child .nav-link {
	border-radius: 5px 0 0 0;
}

.tabs-left .nav-tabs > li:last-child .nav-link {
	border-radius: 0 0 0 5px;
	border-bottom: 1px solid #eee;
}

.tabs-left .nav-tabs > li .nav-link {
	border-right: 1px solid #EEE;
	border-left: 3px solid #EEE;
	margin-right: 1px;
	margin-left: -3px;
}

.tabs-left .nav-tabs > li .nav-link:hover {
	border-left-color: #CCC;
}

.tabs-left .nav-tabs > li.active .nav-link,
.tabs-left .nav-tabs > li.active .nav-link:hover,
.tabs-left .nav-tabs > li.active .nav-link:focus {
	border-left: 3px solid #CCC;
	border-right-color: #FFF;
}

/* Vertical - Right Side */
.tabs-right {
	padding: 0;
}

.tabs-right .tab-content {
	border-radius: 5px 0 5px 5px;
	border-right: none;
}

.tabs-right .nav-tabs > li {
	margin-left: -1px;
}

.tabs-right .nav-tabs > li:first-child .nav-link {
	border-radius: 0 5px 0 0;
}

.tabs-right .nav-tabs > li:last-child .nav-link {
	border-radius: 0 0 5px 0;
	border-bottom: 1px solid #eee;
}

.tabs-right .nav-tabs > li .nav-link {
	border-right: 3px solid #EEE;
	border-left: 1px solid #EEE;
	margin-right: 1px;
	margin-left: 1px;
}

.tabs-right .nav-tabs > li .nav-link:hover {
	border-right-color: #CCC;
}

.tabs-right .nav-tabs > li.active .nav-link,
.tabs-right .nav-tabs > li.active .nav-link:hover,
.tabs-right .nav-tabs > li.active .nav-link:focus {
	border-right: 3px solid #CCC;
	border-left: 1px solid #FFF;
} 

/* Justified */
.nav-tabs.nav-justified {
	padding: 0;
	margin-bottom: -1px;
}

.nav-tabs.nav-justified li {
	margin-bottom: 0;
}

.nav-tabs.nav-justified li:first-child .nav-link,
.nav-tabs.nav-justified li:first-child .nav-link:hover {
	border-radius: 5px 0 0 0;
}

.nav-tabs.nav-justified li:last-child .nav-link,
.nav-tabs.nav-justified li:last-child .nav-link:hover {
	border-radius: 0 5px 0 0;
}

.nav-tabs.nav-justified li .nav-link {
	border-bottom: 1px solid #DDD;
	border-radius: 0;
	margin-right: 0;
}

.nav-tabs.nav-justified li .nav-link:hover, .nav-tabs.nav-justified li .nav-link:focus {
	border-bottom: 1px solid #DDD;
}

.nav-tabs.nav-justified li.active .nav-link,
.nav-tabs.nav-justified li.active .nav-link:hover,
.nav-tabs.nav-justified li.active .nav-link:focus {
	background: #FFF;
	border-left-color: #EEE;
	border-right-color: #EEE;
	border-top-width: 3px;
}

.nav-tabs.nav-justified li.active .nav-link {
	border-bottom: 1px solid #FFF;
}

.nav-tabs.nav-justified li.active .nav-link, .nav-tabs.nav-justified li.active .nav-link:hover, .nav-tabs.nav-justified li.active .nav-link:focus {
	border-top-width: 3px;
}

.nav-tabs.nav-justified li.active .nav-link:hover {
	border-bottom: 1px solid #FFF;
}

/* Bottom Tabs with Justified Nav */
.tabs.tabs-bottom {
	padding: 0;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified {
	border-top: none;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link {
	margin-right: 0;
	border-top-color: transparent;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link {
	border-radius: 0 0 0 5px;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link {
	margin-right: 0;
	border-radius: 0 0 5px 0;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active .nav-link,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active .nav-link:hover,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active .nav-link:focus {
	border-top-color: transparent;
}

/* Center */
.tabs-center .nav-tabs {
	margin: 0 auto;
	text-align: center;
}

/* Navigation */
.tabs-navigation {
	border-radius: 5px;
}

.tabs-navigation .nav-tabs > li {
	margin-bottom: 1px;
}

.tabs-navigation .nav-tabs > li .nav-link {
	color: #777;
	padding: 18px;
	line-height: 100%;
	position: relative;
}

.tabs-navigation .nav-tabs > li .nav-link:before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	font-size: 10px;
	content: "\f054";
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
}

.tabs-navigation .nav-tabs > li .nav-link [class*="fa-"], .tabs-navigation .nav-tabs > li .nav-link .icons {
	margin-right: 4px;
}

.tabs-navigation .nav-tabs li:first-child .nav-link {
	border-radius: 5px 5px 0 0;
}

.tabs-navigation .nav-tabs li.active .nav-link, .tabs-navigation .nav-tabs li.active .nav-link:hover, .tabs-navigation .nav-tabs li.active .nav-link:focus {
	border-right-color: #eee;
}

.tabs-navigation .nav-tabs li:last-child .nav-link {
	border-radius: 0 0 5px 5px;
}

.tabs-navigation-simple {
	border: 0 !important;
	border-radius: 0 !important;
}

.tabs-navigation-simple .nav-tabs {
	border: 0 !important;
	border-radius: 0 !important;
}

.tabs-navigation-simple .nav-tabs .nav-link, .tabs-navigation-simple .nav-tabs .nav-link:hover {
	background: transparent !important;
	border-radius: 0 !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
	border-left: 0 !important;
	padding-left: 0 !important;
}

.tabs-navigation-simple .nav-tabs li:last-child .nav-link, .tabs-navigation-simple .nav-tabs li:last-child .nav-link:hover {
	border-bottom: 0 !important;
}

.tab-pane-navigation {
	display: none;
}

.tab-pane-navigation.active {
	display: block;
}

/* Simple */


// .tabs.tabs-simple .nav-tabs {
// 	justify-content: center;
// 	border: 0;
// 	margin-bottom: 10px;
// }

// .tabs.tabs-simple .nav-tabs > li {
// 	margin-left: 0;
// 	margin-bottom: 20px;
// }

// .tabs.tabs-simple .nav-tabs > li .nav-link, .tabs.tabs-simple .nav-tabs > li .nav-link:hover, .tabs.tabs-simple .nav-tabs > li .nav-link:focus {
// 	padding: 15px 30px;
// 	background: none;
// 	border: 0;
// 	border-bottom: 3px solid #eee;
// 	border-radius: 0;
// 	color: #777;
// 	font-size: 16px;
// }

// .tabs.tabs-simple .tab-content {
// 	background: transparent;
// 	border: 0;
// 	padding: 0;
// 	margin: 0;
// 	box-shadow: none;
// }

// .tabs.tabs-simple.tabs-simple-full-width-line .nav-tabs {
// 	border-bottom: 3px solid #eee;
// }

// .tabs.tabs-simple.tabs-simple-full-width-line .nav-tabs > li {
// 	margin-bottom: -3px;
// }

/* Responsive */
@media (max-width: 575px) {
	.tabs .nav.nav-tabs.nav-justified li {
		display: block;
		margin-bottom: -5px;
	}

	.tabs .nav.nav-tabs.nav-justified li .nav-link {
		border-top-width: 3px !important;
		border-bottom-width: 0 !important;
	}

	.tabs .nav.nav-tabs.nav-justified li:first-child .nav-link,
		.tabs .nav.nav-tabs.nav-justified li:first-child .nav-link:hover {
		border-radius: 5px 5px 0 0;
	}

	.tabs .nav.nav-tabs.nav-justified li:last-child .nav-link,
		.tabs .nav.nav-tabs.nav-justified li:last-child .nav-link:hover {
		border-radius: 0;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li {
		margin-bottom: 0;
		margin-top: -5px;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link {
		border-bottom-width: 3px !important;
		border-top-width: 0 !important;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link,
		.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link:hover {
		border-radius: 0;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link,
		.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link:hover {
		border-radius: 0 0 5px 5px;
	}
}

.responsive-tabs-container .tab-pane {
	margin-bottom: 15px;
}

.responsive-tabs-container .accordion-link {
	display: none;
	margin-bottom: 10px;
	padding: 10px 15px;
	background-color: #f7f7f7;
	border-radius: 3px;
	border: 1px solid #ebebeb;
}

@media (max-width: 767px) {
	.responsive-tabs-container.accordion-xs .nav-tabs {
		display: none;
	}

	.responsive-tabs-container.accordion-xs .accordion-link {
		display: block;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.responsive-tabs-container.accordion-sm .nav-tabs {
		display: none;
	}

	.responsive-tabs-container.accordion-sm .accordion-link {
		display: block;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.responsive-tabs-container.accordion-md .nav-tabs {
		display: none;
	}

	.responsive-tabs-container.accordion-md .accordion-link {
		display: block;
	}
}

@media (min-width: 1200px) {
	.responsive-tabs-container.accordion-lg .nav-tabs {
		display: none;
	}

	.responsive-tabs-container.accordion-lg .accordion-link {
		display: block;
	}
}

/* Testimonials */
/* .testimonial {
	margin-bottom: 20px;
}

.testimonial blockquote {
	background: #CCC;
	border-radius: 5px;
	border: 0;
	color: #666;
	font-family: 'Playfair Display', serif;
	margin: 0;
	padding: 40px 64px 40px 72px;
	padding: 2.5rem 4rem 2.5rem 4.5rem;
	position: relative;
}

.testimonial blockquote:before {
	left: 22px;
	top: 21px;
	color: #FFF;
	content: "“";
	font-size: 85px;
	font-style: normal;
	line-height: 1;
	position: absolute;
}

.testimonial blockquote p {
	color: #FFF;
	font-family: 'Playfair Display', serif;
	font-size: 1.2em;
}

.testimonial .testimonial-arrow-down {
	border-left: 11px solid transparent;
	border-right: 11px solid transparent;
	border-top: 8px solid #CCC;
	height: 0;
	margin: 0 0 0 45px;
	width: 0;
}

.testimonial .testimonial-author {
	display: flex;
	margin: 12px 0 0 0;
}

.testimonial .testimonial-author .testimonial-author-thumbnail {
	width: auto;
}

.testimonial .testimonial-author img {
	max-width: 25px;
}

.testimonial .testimonial-author strong {
	color: #111;
	display: block;
	margin-bottom: 2px;
	font-size: 16px;
	font-size: 1rem;
}

.testimonial .testimonial-author span {
	color: #666;
	display: block;
	font-size: 12.8px;
	font-size: 0.8rem;
	font-weight: 600;
}

.testimonial .testimonial-author p {
	color: #999;
	text-align: left;
	line-height: 1.2;
	margin-left: 9.6px;
	margin-left: 0.6rem;
} */

/* Style 2 */
/* .testimonial.testimonial-style-2 {
	text-align: center;
}

.testimonial.testimonial-style-2 blockquote {
	background: transparent !important;
	border-radius: 4px;
	padding: 22px 20px;
}

.testimonial.testimonial-style-2 blockquote:before, .testimonial.testimonial-style-2 blockquote:after {
	display: none;
}

.testimonial.testimonial-style-2 blockquote p {
	color: #777;
}

.testimonial.testimonial-style-2 .testimonial-arrow-down {
	display: none;
}

.testimonial.testimonial-style-2 .testimonial-author {
	padding: 0;
	margin: 6px 0 0;
	flex-direction: column;
}

.testimonial.testimonial-style-2 .testimonial-author img {
	margin: 0 auto 15px;
	max-width: 60px;
	max-height: 60px;
}

.testimonial.testimonial-style-2 .testimonial-author p {
	text-align: center;
	padding: 0;
	margin: 0;
} */

/* Style 3 */
/* .testimonial.testimonial-style-3 blockquote {
	background: #f2f2f2 !important;
	border-radius: 4px;
	padding: 12px 30px;
}

.testimonial.testimonial-style-3 blockquote:before {
	top: 5px;
	left: 9px;
	color: #777;
	font-size: 35px;
}

.testimonial.testimonial-style-3 blockquote:after {
	color: #777;
	font-size: 35px;
	bottom: -5px;
}

.testimonial.testimonial-style-3 blockquote p {
	color: #777;
	font-family: "Poppins", Arial, sans-serif;
	font-size: 1em;
	font-style: normal;
}

.testimonial.testimonial-style-3 .testimonial-author {
	margin-left: 8px;
	align-items: center;
}

.testimonial.testimonial-style-3 .testimonial-author img {
	max-width: 55px;
}

.testimonial.testimonial-style-3 .testimonial-author p {
	margin-bottom: 0;
}

.testimonial.testimonial-style-3 .testimonial-arrow-down {
	margin-left: 20px;
	border-top: 10px solid #f2f2f2 !important;
} */

/* Style 4 */
/* .testimonial.testimonial-style-4 {
	border-radius: 6px;
	padding: 8px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.testimonial.testimonial-style-4 blockquote {
	background: transparent !important;
	border-radius: 4px;
	padding: 12px 30px;
}

.testimonial.testimonial-style-4 blockquote:before {
	top: 5px;
	left: 9px;
	color: #777;
	font-size: 35px;
}

.testimonial.testimonial-style-4 blockquote:after {
	color: #777;
	font-size: 35px;
	bottom: -5px;
}

.testimonial.testimonial-style-4 blockquote p {
	color: #777;
	font-family: "Poppins", Arial, sans-serif;
	font-size: 1em;
	font-style: normal;
}

.testimonial.testimonial-style-4 .testimonial-arrow-down {
	display: none;
}

.testimonial.testimonial-style-4 .testimonial-author {
	margin-left: 30px;
	align-items: center;
}

.testimonial.testimonial-style-4 .testimonial-author img {
	max-width: 55px;
}

.testimonial.testimonial-style-4 .testimonial-author p {
	margin-bottom: 0;
}

.testimonial.testimonial-style-4.testimonial-no-borders {
	border: 0;
	box-shadow: none;
} */



/* Thumb Info */
// .thumb-info {
// 	display: block;
// 	position: relative;
// 	text-decoration: none;
// 	max-width: 100%;
// 	background-color: #FFF;
// 	border-radius: 4px;
// 	overflow: hidden;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	transform: translate3d(0, 0, 0);
// }

// .thumb-info .thumb-info-wrapper {
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	transform: translate3d(0, 0, 0);
// 	border-radius: 4px;
// 	margin: 0;
// 	overflow: hidden;
// 	display: block;
// 	position: relative;
// }

// .thumb-info .thumb-info-wrapper.thumb-info-wrapper-demos {
// 	max-height: 330px;
// }

// .thumb-info .thumb-info-wrapper:after {
// 	content: "";
// 	background: rgba(33, 37, 41, 0.8);
// 	transition: all 0.3s;
// 	border-radius: 4px;
// 	bottom: -4px;
// 	color: #FFF;
// 	left: -4px;
// 	position: absolute;
// 	right: -4px;
// 	top: -4px;
// 	display: block;
// 	opacity: 0;
// 	z-index: 1;
// }

// .thumb-info .thumb-info-wrapper.thumb-info-wrapper-link {
// 	background-size: cover;
// 	background-position: center;
// 	background-repeat: no-repeat;
// }

// .thumb-info .thumb-info-wrapper.thumb-info-wrapper-link > a {
// 	display: block;
// 	padding-top: 72%;
// 	text-indent: -9999px;
// }

// .thumb-info .thumb-info-wrapper-opacity-0:after {
// 	opacity: 0.0;
// }

// .thumb-info .thumb-info-wrapper-opacity-1:after {
// 	opacity: 0.1;
// }

// .thumb-info .thumb-info-wrapper-opacity-2:after {
// 	opacity: 0.2;
// }

// .thumb-info .thumb-info-wrapper-opacity-3:after {
// 	opacity: 0.3;
// }

// .thumb-info .thumb-info-wrapper-opacity-4:after {
// 	opacity: 0.4;
// }

// .thumb-info .thumb-info-wrapper-opacity-5:after {
// 	opacity: 0.5;
// }

// .thumb-info .thumb-info-wrapper-opacity-6:after {
// 	opacity: 0.6;
// }

// .thumb-info .thumb-info-wrapper-opacity-7:after {
// 	opacity: 0.7;
// }

// .thumb-info .thumb-info-wrapper-opacity-8:after {
// 	opacity: 0.8;
// }

// .thumb-info .thumb-info-wrapper-opacity-9:after {
// 	opacity: 0.9;
// }

// .thumb-info .thumb-info-action-icon {
// 	transition: all 0.3s;
// 	background: #CCC;
// 	border-radius: 0 0 0 4px;
// 	display: inline-block;
// 	font-size: 25px;
// 	height: 50px;
// 	line-height: 50px;
// 	position: absolute;
// 	right: -100px;
// 	text-align: center;
// 	top: -100px;
// 	width: 50px;
// 	z-index: 2;
// }

// .thumb-info .thumb-info-action-icon i {
// 	font-size: 14px;
// 	right: 50%;
// 	position: absolute;
// 	top: 50%;
// 	color: #FFF;
// 	transform: translate(50%, -50%);
// 	margin: -2px -1px 0 0px;
// }

// .thumb-info .thumb-info-inner {
// 	transition: all 0.3s;
// 	display: block;
// }

// .thumb-info .thumb-info-inner em {
// 	display: block;
// 	font-size: 0.8em;
// 	font-style: normal;
// 	font-weight: normal;
// }

// .thumb-info .thumb-info-title {
// 	transition: all 0.3s;
// 	background: rgba(33, 37, 41, 0.8);
// 	color: #FFF;
// 	font-weight: 600;
// 	left: 0;
// 	letter-spacing: -.05em;
// 	position: absolute;
// 	z-index: 2;
// 	max-width: 90%;
// 	font-size: 17px;
// 	padding: 13px 21px 2px;
// 	bottom: 13%;
// }

// .thumb-info .thumb-info-type {
// 	background-color: #CCC;
// 	border-radius: 2px;
// 	display: inline-block;
// 	font-size: 0.6em;
// 	font-weight: 600;
// 	letter-spacing: 0;
// 	margin: 8px -2px -15px 0px;
// 	padding: 2px 12px;
// 	text-transform: uppercase;
// 	z-index: 2;
// 	line-height: 2.3;
// }

// .thumb-info .owl-carousel {
// 	z-index: auto;
// }

// .thumb-info .owl-carousel .owl-dots {
// 	z-index: 2;
// }

// .thumb-info img {
// 	transition: all 0.3s ease;
// 	border-radius: 3px;
// 	position: relative;
// 	width: 100%;
// }

// .thumb-info:hover .thumb-info-wrapper:after {
// 	opacity: 1;
// }

// .thumb-info:hover .thumb-info-action-icon {
// 	right: 0;
// 	top: 0;
// }

// .thumb-info:hover .thumb-info-title {
// 	background: rgba(33, 37, 41, 0.9);
// }

// .thumb-info:hover img {
// 	transform: scale(1.1, 1.1);
// }

// .thumb-info.thumb-info-no-zoom img {
// 	transition: none;
// }

// .thumb-info.thumb-info-no-zoom:hover img {
// 	transform: scale(1, 1);
// }

// .thumb-info.thumb-info-lighten .thumb-info-wrapper:after {
// 	background: rgba(23, 23, 23, 0.25);
// }

// .thumb-info.thumb-info-hide-wrapper-bg .thumb-info-wrapper:after {
// 	display: none;
// }

// .thumb-info.thumb-info-centered-icons .thumb-info-action {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	position: absolute;
// 	top: 50%;
// 	left: 0;
// 	right: auto;
// 	bottom: auto;
// 	width: 100%;
// 	height: 45px;
// 	text-align: center;
// 	opacity: 0;
// 	transition: transform .2s ease-out, opacity .2s ease-out;
// 	transform: translate3d(0, -70%, 0);
// 	z-index: 2;
// }

// .thumb-info.thumb-info-centered-icons .thumb-info-action a {
// 	text-decoration: none;
// }

// .thumb-info.thumb-info-centered-icons .thumb-info-action-icon {
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// 	position: relative;
// 	left: auto;
// 	right: auto;
// 	top: auto;
// 	bottom: auto;
// 	width: 35px;
// 	height: 35px;
// 	font-size: 14px;
// 	border-radius: 50%;
// 	margin-right: 5px;
// 	transition: all 0.1s ease;
// 	z-index: 2;
// }

// .thumb-info.thumb-info-centered-icons .thumb-info-action-icon i {
// 	margin: 0;
// }

// .thumb-info.thumb-info-centered-icons .thumb-info-action-icon:hover {
// 	transform: scale(1.15, 1.15);
// }

// .thumb-info:hover.thumb-info-centered-icons .thumb-info-action {
// 	opacity: 1;
// 	transform: translate3d(0, -50%, 0);
// }

// .thumb-info:hover.thumb-info-centered-icons .thumb-info-action-icon {
// 	position: relative;
// }

// .thumb-info.thumb-info-centered-info .thumb-info-title {
// 	background: transparent;
// 	left: 5%;
// 	width: 90%;
// 	transition: transform .2s ease-out, opacity .2s ease-out;
// 	transform: translate(0%, -50%);
// 	top: 50%;
// 	bottom: auto;
// 	opacity: 0;
// 	text-align: center;
// 	padding: 0;
// 	position: absolute;
// }

// .thumb-info.thumb-info-centered-info .thumb-info-type {
// 	float: none;
// }

// .thumb-info.thumb-info-centered-info:hover .thumb-info-title {
// 	opacity: 1;
// 	transform: translate(0%, -50%);
// }

// .thumb-info.thumb-info-bottom-info .thumb-info-title {
// 	background: #FFF;
// 	left: 0;
// 	width: 100%;
// 	max-width: 100%;
// 	transition: transform .2s ease-out, opacity .2s ease-out;
// 	transform: translate3d(0, 100%, 0);
// 	top: auto;
// 	bottom: 0;
// 	opacity: 0;
// 	padding: 15px;
// 	text-shadow: none;
// 	color: #777;
// }

// .thumb-info.thumb-info-bottom-info .thumb-info-type {
// 	float: none;
// 	background: none;
// 	padding: 0;
// 	margin: 0;
// }

// .thumb-info.thumb-info-bottom-info:hover .thumb-info-title {
// 	transform: translate3d(0, 0, 0);
// 	opacity: 1;
// }

// .thumb-info.thumb-info-bottom-info:hover img {
// 	transform: scale(1.1);
// }

// .thumb-info.thumb-info-bottom-info-linear .thumb-info-title {
// 	background: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0, transparent 100%) repeat scroll 0 0 rgba(255, 255, 255, 0);
// }

// .thumb-info.thumb-info-bottom-info-dark .thumb-info-title {
// 	background: #212529;
// 	color: #FFF;
// }

// .thumb-info.thumb-info-bottom-info-dark-linear .thumb-info-title {
// 	background: linear-gradient(to top, rgba(33, 37, 41, 0.8) 0, transparent 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
// }

// .thumb-info.thumb-info-bottom-info-show-more img {
// 	transform: none !important;
// }

// .thumb-info.thumb-info-bottom-info-show-more .thumb-info-title {
// 	transform: translate3d(0, 0, 0);
// 	opacity: 1;
// }

// .thumb-info.thumb-info-bottom-info-show-more .thumb-info-show-more-content {
// 	transition: ease max-height 800ms;
// 	overflow: hidden;
// 	max-height: 0;
// 	display: block;
// }

// .thumb-info.thumb-info-bottom-info-show-more:hover .thumb-info-show-more-content {
// 	max-height: 300px;
// }

// .thumb-info.thumb-info-push-hor img {
// 	transform: translatex(-18px);
// 	width: calc(100% + 19px);
// 	max-width: none;
// }

// .thumb-info.thumb-info-push-hor:hover img {
// 	transform: translatex(0);
// }

// .thumb-info.thumb-info-block .thumb-info-action-icon {
// 	top: 15px;
// 	right: 15px;
// 	opacity: 0;
// 	transition: all 0.2s ease-in-out;
// }

// .thumb-info.thumb-info-block .thumb-info-wrapper:before {
// 	content: "";
// 	background: rgba(255, 255, 255, 0.9);
// 	transition: all 0.3s;
// 	border-radius: 0;
// 	bottom: 16px;
// 	bottom: 1rem;
// 	color: #FFF;
// 	left: 16px;
// 	left: 1rem;
// 	position: absolute;
// 	right: 16px;
// 	right: 1rem;
// 	top: 16px;
// 	top: 1rem;
// 	display: block;
// 	opacity: 0;
// 	z-index: 2;
// }

// .thumb-info.thumb-info-block:hover .thumb-info-wrapper:before {
// 	opacity: 1;
// }

// .thumb-info.thumb-info-block:hover .thumb-info-action-icon {
// 	opacity: 1;
// }

// .thumb-info.thumb-info-block.thumb-info-block-dark .thumb-info-wrapper:before {
// 	background: rgba(33, 37, 41, 0.9);
// }

// .thumb-info.thumb-info-hide-info-hover .thumb-info-wrapper:after {
// 	opacity: 0.65;
// }

// .thumb-info.thumb-info-hide-info-hover .thumb-info-title {
// 	opacity: 1;
// 	top: 50%;
// }

// .thumb-info.thumb-info-hide-info-hover .thumb-info-type {
// 	float: none;
// }

// .thumb-info.thumb-info-hide-info-hover:hover .thumb-info-wrapper:after {
// 	opacity: 0.1;
// }

// .thumb-info.thumb-info-hide-info-hover:hover .thumb-info-title {
// 	opacity: 0;
// }

// .thumb-info.thumb-info-slide-info-hover .thumb-info-wrapper:after {
// 	opacity: 0.65;
// }

// .thumb-info.thumb-info-slide-info-hover .thumb-info-wrapper-no-opacity:after {
// 	opacity: 1;
// }

// .thumb-info.thumb-info-slide-info-hover .thumb-info-title {
// 	opacity: 1;
// 	top: 0;
// 	height: 100%;
// 	top: 0;
// 	transform: none !important;
// }

// .thumb-info.thumb-info-slide-info-hover .thumb-info-type {
// 	float: none;
// }

// .thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-1,
// .thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-2 {
// 	transition: cubic-bezier(0.55, 0, 0.1, 1) top 500ms;
// 	position: absolute;
// 	left: 0;
// 	width: 100%;
// 	text-align: center;
// 	top: 50%;
// 	transform: translate(0%, -50%);
// }

// .thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-2 {
// 	top: 150%;
// }

// .thumb-info.thumb-info-slide-info-hover:hover .thumb-info-slide-info-hover-1 {
// 	top: -50%;
// }

// .thumb-info.thumb-info-slide-info-hover:hover .thumb-info-slide-info-hover-2 {
// 	top: 50%;
// }

// .thumb-info.thumb-info-no-borders, .thumb-info.thumb-info-no-borders img {
// 	border: 0;
// 	padding: 0;
// 	margin: 0;
// 	border-radius: 0;
// }

// .thumb-info.thumb-info-no-borders .thumb-info-wrapper {
// 	border: 0;
// 	padding: 0;
// 	margin: 0;
// 	border-radius: 0;
// }

// .thumb-info.thumb-info-no-borders .thumb-info-wrapper:after {
// 	border-radius: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	top: 0;
// }

// .thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded,
// .thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded img,
// .thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded .thumb-info-wrapper,
// .thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded .thumb-info-wrapper:after {
// 	border-radius: 4px;
// }

// .thumb-info.thumb-info-preview .thumb-info-wrapper:after {
// 	display: none;
// }

// .thumb-info.thumb-info-preview .thumb-info-image {
// 	min-height: 232px;
// 	display: block;
// 	background-position: center top;
// 	background-repeat: no-repeat;
// 	background-size: 100% auto;
// 	position: relative;
// 	transition: background-position 0.8s linear 0s;
// }

// .thumb-info.thumb-info-preview:hover .thumb-info-image {
// 	transition: background-position 2.5s linear 0s;
// 	background-position: center bottom;
// }

// .thumb-info.thumb-info-preview.thumb-info-preview-short:hover .thumb-info-image {
// 	transition: background-position 1s linear 0s;
// }

// .thumb-info.thumb-info-preview.thumb-info-preview-long:hover .thumb-info-image {
// 	transition: background-position 5s linear 0s;
// }

// .thumb-info.thumb-info-no-overlay .thumb-info-wrapper:after {
// 	content: none;
// }

// .thumb-info.thumb-info-no-bg {
// 	background: transparent;
// }

/* Thumb Info Ribbon */
// .thumb-info-ribbon {
// 	background: #999;
// 	position: absolute;
// 	margin: -16px 0 0 0;
// 	padding: 5px 13px 6px;
// 	right: 15px;
// 	z-index: 1;
// }

// .thumb-info-ribbon:before {
// 	border-right: 10px solid #646464;
// 	border-top: 16px solid transparent;
// 	content: "";
// 	display: block;
// 	height: 0;
// 	left: -10px;
// 	position: absolute;
// 	top: 0;
// 	width: 7px;
// }

// .thumb-info-ribbon span {
// 	color: #FFF;
// 	font-size: 1.1em;
// 	font-family: "Shadows Into Light", cursive;
// }

/* Thumb Info - Full Width */
// .full-width .thumb-info img {
// 	border-radius: 0 !important;
// }

/* Thumb Info Caption	*/
// .thumb-info-caption {
// 	padding: 10px 0;
// }

// .thumb-info-caption .thumb-info-caption-text, .thumb-info-caption p {
// 	font-size: 0.9em;
// 	margin: 0;
// 	padding: 15px 0;
// 	display: block;
// }

/* Thumb Info Side Image	*/
// .thumb-info-side-image .thumb-info-side-image-wrapper {
// 	padding: 0;
// 	float: left;
// 	margin-right: 20px;
// }

// .thumb-info-side-image-right .thumb-info-side-image-wrapper {
// 	float: right;
// 	margin-left: 20px;
// 	margin-right: 0;
// }

/* Thumb Info Social Icons */
// .thumb-info-social-icons {
// 	margin: 0;
// 	padding: 0;
// 	display: block;
// }

// .thumb-info-social-icons a {
// 	background: #CCC;
// 	border-radius: 25px;
// 	display: inline-block;
// 	height: 30px;
// 	line-height: 30px;
// 	text-align: center;
// 	width: 30px;
// }

// .thumb-info-social-icons a:hover {
// 	text-decoration: none;
// }

// .thumb-info-social-icons a span {
// 	display: none;
// }

// .thumb-info-social-icons a i {
// 	color: #FFF;
// 	font-size: 0.9em;
// 	font-weight: normal;
// }

/* Thumb Info Floating Caption */
// .thumb-info-floating-caption-title {
// 	background: rgba(33, 37, 41, 0.8);
// 	color: #FFF;
// 	font-weight: 600;
// 	letter-spacing: -.05em;
// 	font-size: 17px;
// 	padding: 13px 21px;
// }

// .thumb-info-floating-caption-title .thumb-info-floating-caption-type {
// 	position: absolute;
// 	background-color: #CCC;
// 	border-radius: 2px;
// 	display: block;
// 	font-size: 0.6em;
// 	font-weight: 600;
// 	letter-spacing: 0;
// 	margin: 8px -2px -15px 0px;
// 	padding: 2px 12px;
// 	text-transform: uppercase;
// 	line-height: 2.3;
// }

/* Responsive */
// @media (max-width: 991px) {
// 	.thumb-info .thumb-info-title {
// 		font-size: 14px;
// 	}

// 	.thumb-info .thumb-info-more {
// 		font-size: 11px;
// 	}
// }

/* Timeline */
/* section.timeline {
	width: 100%;
	margin: 50px 0 0;
	position: relative;
	float: left;
	padding-bottom: 120px;
}

section.timeline:before {
	background: #505050;
	background: linear-gradient(to bottom, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	content: "";
	display: block;
	height: 100%;
	left: 50%;
	margin-left: -2px;
	position: absolute;
	top: -60px;
	width: 3px;
	z-index: 0;
	filter: alpha(opacity=35);
	opacity: 0.35;
}

section.timeline .timeline-body {
	position: relative;
	z-index: 1 !important;
}

section.timeline .timeline-date {
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	position: relative;
	display: block;
	clear: both;
	width: 200px;
	height: 45px;
	padding: 5px;
	border: 1px solid #E5E5E5;
	margin: 0 auto 10px;
	text-shadow: 0 1px 1px #fff;
	border-radius: 4px;
	background: #FFF;
	z-index: 1;
}

section.timeline .timeline-date h3 {
	display: block;
	text-align: center;
	color: #757575;
	font-size: 0.9em;
	line-height: 32px;
}

section.timeline .timeline-title {
	background: #f7f7f7;
	padding: 12px;
}

section.timeline .timeline-title h4 {
	padding: 0;
	margin: 0;
	color: #151719;
	font-size: 1.4em;
}

section.timeline .timeline-title a {
	color: #151719;
}

section.timeline .timeline-title .timeline-title-tags {
	color: #B1B1B1;
	font-size: 0.9em;
}

section.timeline .timeline-title .timeline-title-tags a {
	color: #B1B1B1;
	font-size: 0.9em;
}

section.timeline .timeline-box {
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	border: 1px solid #E5E5E5;
	border-radius: 4px;
	margin: 20px 10px 10px 10px;
	padding: 10px;
	width: 45.7%;
	float: left;
	z-index: 1;
	margin-bottom: 30px;
	background: #FFF;
}

section.timeline .timeline-box.left {
	clear: both;
}

section.timeline .timeline-box.left:before {
	background: none repeat scroll 0 0 #E5E5E5;
	border-radius: 50%;
	box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #E5E5E5;
	content: "";
	display: block;
	height: 7px;
	margin-right: -5px;
	position: absolute;
	left: 50%;
	margin-top: 22px;
	width: 8px;
	transform: translate3d(-50%, 0, 0);
	z-index: 1;
}

section.timeline .timeline-box.left .timeline-box-arrow {
	transform: rotate(45deg);
	content: '';
	display: block;
	background: #FFF;
	border-right: 1px solid #E5E5E5;
	border-top: 1px solid #E5E5E5;
	float: right;
	height: 14px;
	margin-top: 17px;
	margin-right: -18px;
	width: 14px;
	z-index: 0;
}

section.timeline .timeline-box.right {
	clear: right;
	float: right;
	right: -1px;
	margin-top: 40px;
}

section.timeline .timeline-box.right:before {
	background: none repeat scroll 0 0 #E5E5E5;
	border-radius: 50%;
	box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #E5E5E5;
	content: "";
	display: block;
	height: 7px;
	position: absolute;
	left: 50%;
	margin-top: 32px;
	width: 8px;
	transform: translate3d(-50%, 0, 0);
	z-index: 1;
}

section.timeline .timeline-box.right .timeline-box-arrow {
	transform: rotate(45deg);
	content: '';
	display: block;
	background: #FFF;
	border-left: 1px solid #E5E5E5;
	border-bottom: 1px solid #E5E5E5;
	float: left;
	height: 14px;
	margin-top: 28px;
	margin-left: -18px;
	width: 14px;
	z-index: 0;
}

section.timeline .timeline-box .owl-carousel {
	border-radius: 0;
	padding: 0;
	border: 0;
	box-shadow: none;
}

@media (max-width: 991px) {
	section.timeline .timeline-box {
		float: none;
		clear: both;
		right: auto;
		left: auto;
		margin: 25px auto;
		max-width: 70%;
		width: auto;
	}

	section.timeline .timeline-box.right {
		float: none;
		clear: both;
		right: auto;
		left: auto;
		margin: 25px auto;
		max-width: 70%;
		width: auto;
	}

	section.timeline .timeline-box.left:after, section.timeline .timeline-box.right:after, section.timeline .timeline-box.left:before, section.timeline .timeline-box.right:before {
		display: none;
	}
}

@media (max-width: 767px) {
	section.timeline {
		width: 100%;
		margin: 0;
	}
}

@media (max-width: 575px) {
	section.timeline {
		width: auto;
		float: none;
		margin-left: 0;
		margin-right: 0;
	}

	section.timeline .timeline-box {
		margin: 15px auto;
		max-width: 90%;
	}

	section.timeline .timeline-box.right {
		margin: 15px auto;
		max-width: 90%;
	}

	article.post-large {
		margin-left: 0;
	}

	article.post-large .post-image, article.post-large .post-date {
		margin-left: 0;
	}
} */

/* Toggles */
// .toggle {
// 	margin: 10px 0 20px;
// 	position: relative;
// 	clear: both;
// }

// .toggle .toggle {
// 	margin: 10px 0 0;
// }

// .toggle > input {
// 	cursor: pointer;
// 	filter: alpha(opacity=0);
// 	height: 45px;
// 	margin: 0;
// 	opacity: 0;
// 	position: absolute;
// 	width: 100%;
// 	z-index: 2;
// }

// .toggle > label,
// .toggle > .toggle-title {
// 	transition: all .15s ease-out;
// 	background: #f7f7f7;
// 	border-left: 3px solid #CCC;
// 	border-radius: 5px;
// 	color: #CCC;
// 	display: block;
// 	min-height: 20px;
// 	padding: 12px 20px 12px 15px;
// 	position: relative;
// 	cursor: pointer;
// 	font-weight: 600;
// 	letter-spacing: -0.2px;
// 	text-decoration: none;
// }

// .toggle > label:-moz-selection,
// .toggle > .toggle-title:-moz-selection {
// 	background: none;
// }

// .toggle > label i.fa-minus,
// .toggle > .toggle-title i.fa-minus {
// 	display: none;
// }

// .toggle > label i.fa-plus,
// .toggle > .toggle-title i.fa-plus {
// 	display: inline;
// }

// .toggle > label:before,
// .toggle > .toggle-title:before {
// 	content: " ";
// 	position: absolute;
// 	top: calc(50% + 1px);
// 	right: 14px;
// 	border-color: #CCC;
// 	border-top: 1px solid;
// 	border-right: 1px solid;
// 	width: 8px;
// 	height: 8px;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	transition: transform 0.3s, top 0.3s;
// 	transform: rotate(-45deg) translate3d(0, -50%, 0);
// 	transform-origin: 35%;
// }

// .toggle > label:hover,
// .toggle > .toggle-title:hover {
// 	background: #f5f5f5;
// }

// .toggle > label + p,
// .toggle > .toggle-title + p {
// 	color: #999;
// 	display: block;
// 	overflow: hidden;
// 	padding-left: 30px;
// 	text-overflow: ellipsis;
// 	white-space: nowrap;
// 	height: 25px;
// }

// .toggle > label i,
// .toggle > .toggle-title i {
// 	font-size: 0.7em;
// 	margin-right: 8px;
// 	position: relative;
// 	top: -1px;
// }

// .toggle > .toggle-content {
// 	display: none;
// }

// .toggle > .toggle-content > p {
// 	margin-bottom: 0;
// 	padding: 10px 0;
// }

// .toggle.active i.fa-minus {
// 	display: inline;
// }

// .toggle.active i.fa-plus {
// 	display: none;
// }

// .toggle.active > label,
// .toggle.active > .toggle-title {
// 	background: #f7f7f7;
// 	border-color: #CCC;
// }

// .toggle.active > label:before,
// .toggle.active > .toggle-title:before {
// 	top: calc(50% - 8px);
// 	transform: rotate(135deg);
// }

// .toggle.active > p {
// 	white-space: normal;
// }

// .toggle > p.preview-active {
// 	height: auto;
// 	white-space: normal;
// }

// .toggle.toggle-sm .toggle > label,
// .toggle.toggle-sm .toggle > .toggle-title {
// 	font-size: 0.9em;
// 	padding: 6px 20px 6px 10px;
// }

// .toggle.toggle-sm .toggle > .toggle-content > p {
// 	font-size: 0.9em;
// }

// .toggle.toggle-lg .toggle > label,
// .toggle.toggle-lg .toggle > .toggle-title {
// 	font-size: 1.1em;
// 	padding: 16px 20px 16px 20px;
// }

// .toggle.toggle-lg .toggle > .toggle-content > p {
// 	font-size: 1.1em;
// }

// .toggle.toggle-simple .toggle > label,
// .toggle.toggle-simple .toggle > .toggle-title {
// 	border-radius: 0;
// 	border: 0;
// 	background: transparent;
// 	color: #777;
// 	padding-left: 32px;
// 	margin-bottom: -10px;
// 	font-size: 1em;
// 	font-weight: 600;
// 	color: #212529;
// }

// .toggle.toggle-simple .toggle > label:after,
// .toggle.toggle-simple .toggle > .toggle-title:after {
// 	content: '';
// 	position: absolute;
// 	left: 0;
// 	display: block;
// 	width: 24px;
// 	height: 24px;
// 	background: #CCC;
// 	top: 25px;
// 	margin-top: -12px;
// 	border-radius: 3px;
// 	z-index: 1;
// }

// .toggle.toggle-simple .toggle > label:before,
// .toggle.toggle-simple .toggle > .toggle-title:before {
// 	border: 0;
// 	left: 9px;
// 	right: auto;
// 	margin-top: 0;
// 	top: 12px;
// 	font-family: 'Font Awesome 5 Free';
// 	font-weight: 900;
// 	content: "\f067";
// 	z-index: 2;
// 	color: #FFF;
// 	font-size: 7px;
// 	width: auto;
// 	height: auto;
// 	transform: none;
// }

// .toggle.toggle-simple .toggle.active > label,
// .toggle.toggle-simple .toggle.active > .toggle-title {
// 	background: transparent;
// 	color: #212529;
// }

// .toggle.toggle-simple .toggle.active > label:before,
// .toggle.toggle-simple .toggle.active > .toggle-title:before {
// 	content: "\f068";
// }

// .toggle.toggle-minimal .toggle {
// 	margin: 0;
// }

// .toggle.toggle-minimal .toggle > label,
// .toggle.toggle-minimal .toggle > .toggle-title {
// 	background: transparent;
// 	color: #777;
// 	border-left: 0;
// 	border-bottom: 1px solid #CCCC;
// 	border-radius: 0;
// 	margin: 0;
// 	padding: 12px 20px 12px 0;
// 	font-weight: 600;
// }

// .toggle.toggle-minimal .toggle.active > label,
// .toggle.toggle-minimal .toggle.active > .toggle-title {
// 	background: transparent;
// 	border-bottom-color: #CCC;
// }

/* Blockquotes */
blockquote {
	border-left: 5px solid #eee;
	margin: 0 0 16px 0;
	margin: 0 0 1rem 0;
	padding: 8px 16px;
	padding: 0.5rem 1rem;
	font-size: 1em;
}

blockquote.with-borders {
	border-radius: 6px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
	padding: 20.8px;
	padding: 1.3rem;
}

// blockquote.blockquote-reverse {
// 	text-align: right;
// 	border-right: 5px solid #eee;
// 	border-left: 0px !important;
// }

/* Small */
small, .small {
	font-size: 70%;
}

/* Paragraphs */
/* p {
	color: #777;
	line-height: 26px;
	margin: 0 0 20px;
} */

/* p.featured {
	font-size: 1.6em;
	line-height: 1.5em;
}

p.lead {
	line-height: 28.8px;
	line-height: 1.8rem;
}

p .alternative-font {
	display: inline-block;
	margin-top: -15px;
	position: relative;
	top: 3px;
	margin-bottom: -6px;
} */

/* Lead */
/* .lead {
	letter-spacing: -.05em;
	font-size: 19.2px;
	font-size: 1.2rem;
}

.lead-2 {
	font-size: 17.92px;
	font-size: 1.12rem;
} */

/* Links */
/* a, a:hover, a:focus {
	color: #CCC;
} */

/* Drop Caps
p.drop-caps:first-letter {
	float: left;
	font-size: 75px;
	line-height: 60px;
	padding: 4px;
	margin-right: 5px;
	margin-top: 5px;
	font-family: Georgia;
}

p.drop-caps.drop-caps-style-2:first-letter {
	background-color: #CCC;
	color: #FFF;
	padding: 6px;
	margin-right: 5px;
	border-radius: 4px;
} */



/* Alternative Font Style */
// .alternative-font {
// 	color: #CCC;
// 	font-family: "Shadows Into Light", cursive;
// 	font-size: 1.6em;
// }

// .alternative-font-2 {
// 	font-family: "Oswald", sans-serif;
// }

// .alternative-font-3 {
// 	font-family: 'Segoe Script', 'Zapfino', 'Savoye LET';
// }

// .alternative-font-4 {
// 	font-family: 'Poppins', Arial, sans-serif !important;
// }

// .alternative-font-5 {
// 	font-family: 'Open Sans', Arial, sans-serif !important;
// }

// .alternative-font-6 {
// 	font-family: 'Montserrat', Arial, sans-serif !important;
// }

/* Gradient Text */
// .gradient-text-color {
// 	background-clip:text;
// 	-webkit-background-clip: text !important;
// 	-webkit-text-fill-color: transparent !important;
// }

// .no-backgroundcliptext .gradient-text-color {
// 	background: transparent !important;
// }

/* Small Sup style */
// .sup-text-small {
// 	font-size: 0.5em;
// 	font-weight: 500;
// 	top: -.7em;
// }

/* Text Color Default */
// .text-default,
// .text-color-default {
// 	color: #777 !important;
// }

/* Video */
section.video {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	padding: 50px 0;
	position: relative;
	overflow: hidden;
	width: 100%;
}

section.video .container {
	position: relative;
	z-index: 3;
}

html.boxed section.video > div:first-child {
	z-index: 0 !important;
}

html.boxed section.video > div:first-child video {
	z-index: 0 !important;
}

/* Custom Map */
section.section-custom-map {
	background: transparent center 0 no-repeat;
	margin: 20px 0 0;
	border: 0;
}

section.section-custom-map section.section {
	border-top: 0;
	background: rgba(247, 247, 247, 0.5);
}

/* Home Intro */
// .home-intro {
// 	background-color: #212529;
// 	margin-bottom: 60px;
// 	overflow: hidden;
// 	padding: 38.4px 0;
// 	padding: 2.4rem 0;
// 	position: relative;
// 	text-align: left;
// 	letter-spacing: -0.5px;
// }

// .home-intro p {
// 	color: #FFF;
// 	display: inline-block;
// 	font-size: 1.4em;
// 	font-weight: 600;
// 	margin-bottom: 0;
// }

// .home-intro p span:not(.highlighted-word) {
// 	color: #FFF;
// 	display: block;
// 	font-size: 0.8em;
// 	font-weight: 300;
// 	padding-top: 5px;
// 	opacity: 0.7;
// }

// .home-intro p .highlighted-word {
// 	font-size: 1.6em;
// }

// .home-intro .get-started {
// 	position: relative;
// }

// .home-intro .get-started .btn {
// 	position: relative;
// 	z-index: 1;
// }

// .home-intro .get-started .btn i {
// 	font-size: 1.1em;
// 	font-weight: normal;
// 	margin-left: 5px;
// }

// .home-intro .get-started a {
// 	color: #FFF;
// }

// .home-intro .get-started a:not(.btn) {
// 	color: #FFF;
// 	font-weight: 600;
// }

// .home-intro .learn-more {
// 	color: #FFF;
// 	margin-left: 15px;
// 	font-weight: 300;
// }

// .home-intro.light {
// 	background-color: #EDEDED;
// }

// .home-intro.light.secondary {
// 	background-color: #E0E1E4;
// }

// .home-intro.light p,
// .home-intro.light .learn-more,
// .home-intro.light p span:not(.highlighted-word),
// .home-intro.light .get-started a:not(.btn) {
// 	color: #333333;
// }

/* Home Intro Compact */
// .home-intro-compact {
// 	border-radius: 0 0 0.3rem 0.3rem;
// 	margin-top: -10px;
// 	margin: -10px auto 60px;
// 	max-width: 1110px;
// }

// .home-intro-compact p {
// 	padding-left: 20px;
// }

// .home-intro-compact .get-started {
// 	padding-right: 20px;
// }

/* Responsive */
// @media (max-width: 991px) {
// 		/* Home Intro */
// 	.home-intro p {
// 		margin-bottom: 2rem;
// 	}

// 	.home-intro-compact .get-started {
// 		padding-left: 20px;
// 	}
// }

// @media (max-width: 767px) {
// 		/* Home Intro */
// 	.home-intro-compact {
// 		margin-left: 0;
// 		margin-right: 0;
// 		padding-left: 0;
// 		padding-right: 0;
// 	}
// }

/* Home Concept SVG Animations */
// @-webkit-keyframes animatedLineBg {
// 	from {
// 		stroke-dasharray: 1000;
// 	}

// 	to {
// 		stroke-dasharray: 1360;
// 	}
// }

// @keyframes animatedLineBg {
// 	from {
// 		stroke-dasharray: 1000;
// 	}

// 	to {
// 		stroke-dasharray: 1360;
// 	}
// }

// @-webkit-keyframes animatedLineSmallCircle {
// 	from {
// 		stroke-dasharray: 1000;
// 	}

// 	to {
// 		stroke-dasharray: 1120;
// 	}
// }

// @keyframes animatedLineSmallCircle {
// 	from {
// 		stroke-dasharray: 1000;
// 	}

// 	to {
// 		stroke-dasharray: 1120;
// 	}
// }

// @-webkit-keyframes animatedLineBigCircle {
// 	from {
// 		stroke-dasharray: 1000;
// 	}

// 	to {
// 		stroke-dasharray: 1266;
// 	}
// }

// @keyframes animatedLineBigCircle {
// 	from {
// 		stroke-dasharray: 1000;
// 	}

// 	to {
// 		stroke-dasharray: 1266;
// 	}
// }

// @-webkit-keyframes animatedInitialPin {
// 	0% {
// 		transform: translate(2px, 69px) scale(0.23);
// 	}

// 	33% {
// 		transform: translate(2px, 79px) scale(0.23);
// 	}

// 	66% {
// 		transform: translate(2px, 74px) scale(0.23);
// 	}

// 	100% {
// 		transform: translate(2px, 79px) scale(0.23);
// 	}
// }

// @keyframes animatedInitialPin {
// 	0% {
// 		transform: translate(2px, 69px) scale(0.23);
// 	}

// 	33% {
// 		transform: translate(2px, 79px) scale(0.23);
// 	}

// 	66% {
// 		transform: translate(2px, 74px) scale(0.23);
// 	}

// 	100% {
// 		transform: translate(2px, 79px) scale(0.23);
// 	}
// }

// @-webkit-keyframes animatedFinalPin {
// 	0% {
// 		transform: translate(325px, -19px) scale(0.23) rotate(36deg);
// 	}

// 	33% {
// 		transform: translate(325px, -13px) scale(0.23) rotate(36deg);
// 	}

// 	66% {
// 		transform: translate(325px, -16px) scale(0.23) rotate(36deg);
// 	}

// 	100% {
// 		transform: translate(325px, -13px) scale(0.23) rotate(36deg);
// 	}
// }

// @keyframes animatedFinalPin {
// 	0% {
// 		transform: translate(325px, -19px) scale(0.23) rotate(36deg);
// 	}

// 	33% {
// 		transform: translate(325px, -13px) scale(0.23) rotate(36deg);
// 	}

// 	66% {
// 		transform: translate(325px, -16px) scale(0.23) rotate(36deg);
// 	}

// 	100% {
// 		transform: translate(325px, -13px) scale(0.23) rotate(36deg);
// 	}
// }

// /* Firefox Final Pin Animation */
// @-webkit-keyframes animatedFinalPinFirefox {
// 	0% {
// 		transform: translate(193px, -16px) scale(0.23) rotate(36deg);
// 	}

// 	33% {
// 		transform: translate(193px, -10px) scale(0.23) rotate(36deg);
// 	}

// 	66% {
// 		transform: translate(193px, -13px) scale(0.23) rotate(36deg);
// 	}

// 	100% {
// 		transform: translate(193px, -10px) scale(0.23) rotate(36deg);
// 	}
// }

// @keyframes animatedFinalPinFirefox {
// 	0% {
// 		transform: translate(193px, -16px) scale(0.23) rotate(36deg);
// 	}

// 	33% {
// 		transform: translate(193px, -10px) scale(0.23) rotate(36deg);
// 	}

// 	66% {
// 		transform: translate(193px, -13px) scale(0.23) rotate(36deg);
// 	}

// 	100% {
// 		transform: translate(193px, -10px) scale(0.23) rotate(36deg);
// 	}
// }






/* Team List */
// ul.team-list {
// 	list-style: none;
// 	margin: 0;
// 	padding: 0;
// }

// ul.team-list .team-item {
// 	margin-bottom: 35px;
// }

// ul.team-list .team-item .thumb-info-social-icons {
// 	margin: 0 10px;
// }

/* Responsive */
// @media (max-width: 991px) {
// 	ul.team-list .team-item {
// 		margin: 0 auto 35px;
// 		max-width: 270px;
// 	}
// }

/* Search Results */
.search-results {
	min-height: 300px;
}

/* Page 404 */
// .http-error {
// 	padding: 50px 0;
// }

// .http-error h2 {
// 	font-size: 140px;
// 	font-weight: 700;
// 	line-height: 140px;
// }

// .http-error h4 {
// 	color: #777;
// }

// .http-error p {
// 	font-size: 1.4em;
// 	line-height: 36px;
// }

/* Responsive */
// @media (max-width: 575px) {
// 	.http-error {
// 		padding: 0;
// 	}

// 	.http-error h2 {
// 		font-size: 100px;
// 		letter-spacing: 0;
// 		line-height: 100px;
// 	}
// }
